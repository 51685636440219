.leftsessionbookedimgbox-icon {
    position: relative;
    width: 218.29px;
    height: 203.1px;
    object-fit: cover;
    border-radius: 120px;
  }
  .leftsessionbookedname {
    position: relative;
  }
  .leftsessionbookedexpertise {
    position: relative;
    font-size: 22.1px;
    font-weight: 500;
    color: var(--purple-blue);
  }
  .leftsessionbookeddetailsbox {
    gap: var(--gap-base);
  }
  .leftsessionbookeddetails,
  .leftsessionbookeddetailsbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .leftsessionbooked,
  .leftsessionbookedbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .leftsessionbookedbox {
    gap: var(--gap-61xl);
  }
  .leftsessionbooked {
    border-right: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 627px;
    padding: var(--padding-84xl) 0;
  }
  .rightsessionbookedboxheader {
    position: relative;
  }
  .rightsessionbookedboxcheckbox-icon {
    position: relative;
    width: 131px;
    height: 131px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .rightsessiondateboxtitle1 {
    position: relative;
    line-height: 150%;
  }
  .rightsessiondateboxdate {
    position: relative;
    font-weight: 600;
  }
  .rightsessiondatebox3time {
    position: relative;
    font-size: 24px;
  }
  .rightsessiondatebox1,
  .rightsessiondatebox3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .rightsessiondatebox3 {
    gap: var(--gap-11xs);
    font-size: var(--h2-bold-32-size);
    color: var(--gray-5);
  }
  .rightsessiondatebox1 {
    gap: var(--gap-3xl);
  }
  .session-type {
    margin: 0;
  }
  .rightsessiontypeboxtitle {
    position: relative;
    line-height: 150%;
    display: inline-block;
    width: 155px;
    height: 35px;
    flex-shrink: 0;
  }
  .rightsessiontypeboxtitlemin {
    position: relative;
    font-size: var(--h2-bold-32-size);
    font-weight: 600;
    color: var(--gray-5);
    display: flex;
    align-items: center;
    width: 152px;
  }
  .rightsessionbookeddatesessionb,
  .rightsessiontypebox {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .rightsessiontypebox {
    flex-direction: column;
    gap: var(--gap-3xl);
  }
  .rightsessionbookeddatesessionb {
    flex-direction: row;
    gap: 96px;
    font-size: 23.16px;
    color: var(--color-steelblue-100);
  }
  .rightsessionbuttonboxtext {
    position: relative;
    font-weight: 500;
  }
  .rightsessionbuttonbox {
    border-radius: 9.42px;
    background-color: var(--color-white);
    border: 2px solid var(--color-steelblue-100);
    box-sizing: border-box;
    width: 264px;
    overflow: hidden;
    flex-shrink: 0;
    padding: 8.238937377929688px var(--padding-8xs);
    cursor: pointer;
  }
  .rightsessionbutton,
  .rightsessionbuttonbox,
  .rightsessionbuttonbox1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .rightsessionbuttonbox1 {
    border-radius: 9.42px;
    background-color: var(--color-steelblue-100);
    width: 286px;
    overflow: hidden;
    flex-shrink: 0;
    padding: 8.238937377929688px var(--padding-8xs);
    box-sizing: border-box;
    cursor: pointer;
    color: var(--color-white);
  }
  .rightsessionbutton {
    gap: var(--gap-11xl);
    text-align: center;
    font-size: 18.83px;
    color: var(--gray-5);
  }
  .rightsessionbookedbox1 {
    flex-direction: column;
    gap: var(--gap-9xl);
  }
  .rightsessionbookedbox,
  .rightsessionbookedbox1,
  .sessionbooked {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rightsessionbookedbox {
    width: 627px;
    flex-direction: column;
    padding: var(--padding-84xl) 0;
    box-sizing: border-box;
    font-size: var(--font-size-17xl);
    color: var(--color-black);
  }
  .sessionbooked {
    border-radius: var(--br-xs);
    background-color: var(--color-white);
    width: 1261px;
    height: 662.48px;
    overflow: hidden;
    flex-direction: row;
    gap: var(--gap-6xs);
    max-width: 100%;
    max-height: 100%;
    text-align: left;
    font-size: 44px;
    color: var(--logo-black);
    font-family: var(--h6-medium-12);
  }
  @media screen and (max-width: 1200px) {
    .leftsessionbooked {
      width: 477px;
    }
    .rightsessionbuttonbox,
    .rightsessionbuttonbox1 {
      width: 204px;
      padding-left: var(--padding-8xs);
      padding-right: var(--padding-8xs);
      box-sizing: border-box;
    }
    .rightsessionbuttonbox1 {
      padding-left: var(--padding-xs);
      padding-right: var(--padding-xs);
    }
    .rightsessionbookedbox {
      width: 477px;
    }
    .sessionbooked {
      flex-direction: row;
      padding-top: 0;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 850px) {
    .leftsessionbookedname {
      font-size: var(--font-size-3xl);
    }
    .leftsessionbookedexpertise {
      font-size: var(--font-size-3xs);
    }
    .leftsessionbookedbox {
      gap: var(--gap-0);
    }
    .leftsessionbooked {
      padding-top: 0;
      padding-bottom: 0;
      box-sizing: border-box;
    }
    .rightsessionbookedboxheader {
      font-size: var(--font-size-base);
    }
    .rightsessiondateboxtitle1 {
      font-size: var(--font-size-lgi);
    }
    .rightsessiondateboxdate {
      font-size: var(--font-size-2xl);
    }
    .rightsessiondatebox3time {
      font-size: 15px;
    }
    .rightsessiondatebox1 {
      align-items: center;
      justify-content: center;
    }
    .rightsessiontypeboxtitle {
      font-size: var(--font-size-lgi);
    }
    .rightsessiontypeboxtitlemin {
      font-size: var(--font-size-2xl);
    }
    .rightsessionbookeddatesessionb {
      gap: var(--gap-31xl);
    }
    .rightsessionbuttonboxtext {
      font-size: var(--font-size-4xs);
    }
    .rightsessionbuttonbox {
      width: 150px;
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
    .rightsessionbuttonbox1 {
      width: 150px;
      padding-left: var(--padding-xs);
      padding-right: var(--padding-xs);
      box-sizing: border-box;
    }
    .rightsessionbookedbox1 {
      gap: var(--gap-xs);
    }
    .rightsessionbookedbox {
      padding-top: 0;
      padding-bottom: 0;
      box-sizing: border-box;
    }
    .sessionbooked {
      height: 900px;
      flex-direction: column;
      gap: var(--gap-31xl);
      padding-top: var(--padding-61xl);
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 520px) {
    .rightsessiondateboxdate {
      font-size: var(--h3-medium-20-size);
    }
    .rightsessionbutton {
      gap: var(--gap-mini);
    }
  }
  