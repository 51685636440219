
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h2-bold-32: Poppins;

  /* font sizes */
  --h4-medium-16-size: 16px;
  --font-size-smi: 13px;
  --font-size-5xl: 24px;
  --h3-medium-20-size: 20px;
  --h2-bold-32-size: 32px;
  --font-size-3xl: 22px;
  --font-size-lg: 18px;
  --font-size-4xl-2: 23.2px;
  --font-size-10xl-5: 29.5px;
  --font-size-3xl-1: 22.1px;
  --font-size-mid-4: 17.4px;
  --font-size-3xs: 10px;
  --font-size-mini: 15px;
  --font-size-xl-5: 20.5px;
  --h5-btns-med-14-size: 14px;
  --h6-medium-12-size: 12px;
  --font-size-2xs: 11px;
  --font-size-lg-8: 18.8px;
  --font-size-4xl-4: 23.4px;
  --font-size-lgi: 19px;
  --font-size-mid: 17px;
  --font-size-lg-9: 18.9px;
  --font-size-9xl-1: 28.1px;
  --font-size-smi-4: 12.4px;
  --font-size-lg-4: 18.4px;

  /* Colors */
  --white: #fff;
  --logo-color-2: #4a93cf;
  --color-darkgray-100: #b2b1b1;
  --color-darkgray-200: #959595;
  --color-darkgray-300: rgba(178, 177, 177, 0.25);
  --color-black: #000;
  --color-gray-100: #898989;
  --gray-4: #79797d;
  --color-gray-200: #787878;
  --logo-black: #010101;
  --color-gray-300: rgba(255, 255, 255, 0.5);
  --color-lavender: #cbdff7;
  --gray-1: #f2f2f2;
  --color-yellowgreen: #72c046;
  --color-silver-100: #c4c6c7;
  --color-silver-200: #bbb;
  --color-cornflowerblue: #358fd9;
  --gray-5: #393939;
  --color-darkslategray-100: #263238;
  --gray-2: #e7e7e7;
  --teal: #40cfc3;
  --color-mediumaquamarine: #70d499;
  --royal-blue: #0d0ed9;
  --reviews: #deb00f;
  --purple-blue: #5558cf;

  /* Gaps */
  --gap-3xs: 10px;
  --gap-xl: 20px;
  --gap-81xl: 100px;
  --gap-5xl: 24px;
  --gap-26xl: 45px;
  --gap-8xs: 5px;
  --gap-221xl: 240px;
  --gap-74xl: 93px;
  --gap-51xl: 70px;
  --gap-9xs: 4px;
  --gap-7xs: 6px;
  --gap-29xl: 48px;
  --gap-6xl: 25px;
  --gap-231xl: 250px;
  --gap-241xl: 260px;
  --gap-101xl: 120px;
  --gap-36xl: 55px;
  --gap-22xl: 41px;
  --gap-3xl: 22px;
  --gap-11xs: 2px;
  --gap-14xl: 33px;
  --gap-12xl: 31px;
  --gap-31xl: 50px;
  --gap-2xl: 21px;
  --gap-20xl: 39px;
  --gap-10xl: 29px;
  --gap-25xl: 44px;
  --gap-mini-7: 14.7px;
  --gap-0: 0px;
  --gap-5xs-2: 7.2px;
  --gap-18xl: 37px;
  --gap-13xl: 32px;
  --gap-mini: 15px;
  --gap-lgi: 19px;
  --gap-10xs: 3px;
  --gap-12xs: 1px;
  --gap-7xl: 26px;
  --gap-mid: 17px;
  --gap-9xl: 28px;
  --gap-6xs: 7px;
  --gap-sm: 14px;
  --gap-2xs: 11px;
  --gap-11xl: 30px;
  --gap-3xs-4: 9.4px;
  --gap-base: 16px;
  --gap-mini-1: 14.1px;
  --gap-smi-4: 12.4px;
  --gap-6xs-2: 6.2px;
  --gap-sm-1: 13.1px;
  --gap-5xs: 8px;
  --gap-185xl: 204px;
  --gap-141xl: 160px;
  --gap-235xl: 254px;
  --gap-3xs-2: 9.2px;

  /* Paddings */
  --padding-smi: 13px;
  --padding-22xl: 41px;
  --padding-55xl: 74px;
  --padding-25xl: 44px;
  --padding-8xs: 5px;
  --padding-3xs: 10px;
  --padding-5xl: 24px;
  --padding-61xl: 80px;
  --padding-140xl: 159px;
  --padding-24xl: 43px;
  --padding-lg-3: 18.3px;
  --padding-5xl-4: 24.4px;
  --padding-9xs: 4px;
  --padding-xl: 20px;
  --padding-13xl: 32px;
  --padding-118xl: 137px;
  --padding-57xl: 76px;
  --padding-4xl: 23px;
  --padding-mid: 17px;
  --padding-6xl: 25px;
  --padding-base: 16px;
  --padding-mini: 15px;
  --padding-6xs: 7px;
  --padding-11xl: 30px;
  --padding-17xl: 36px;
  --padding-31xl: 50px;
  --padding-26xl: 45px;
  --padding-42xl: 61px;
  --padding-lgi: 19px;
  --padding-10xs: 3px;
  --padding-98xl: 117px;

  /* border radiuses */
  --br-5xs: 8px;
  --br-9xs: 4px;
  --br-31xl: 50px;
  --br-2xl-3: 21.3px;
  --br-5xl-2: 24.2px;
  --br-xl: 20px;
  --br-4xs: 9px;
  --br-3xs-5: 9.5px;
  --br-6xs-2: 6.2px;
  --br-6xs: 7px;

  /* Effects */
  --shadow-1: 0px 1px 3px rgba(0, 0, 0, 0.25);
}


.settingbox2 {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 500;
  }
  .settingsbox1 {
    width: 1120px;
    height: 36px;
  }
  .settingsbox1,
  .settingsdetailsemailheader {
    position: relative;
  }
  .settingsdetailsemailtext {
    position: relative;
    display: flex;
    margin-left: 2;
    align-items: center;
    width: 346px;
    flex-shrink: 0;
  }
  .settingsdetailsemailedit {
    position: relative;
    color: var(--logo-color-2);
  }
  .settingsdetailsemailbox1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-231xl);
    color: var(--logo-black);
  }
  .settingsdetailsemailline {
    position: relative;
    border-top: 1px solid var(--color-darkgray-300);
    box-sizing: border-box;
    width: 642px;
    height: 1px;
  }
  .settingsdetailsemailbox {
    position: absolute;
    top: 7px;
    left: -1px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-8xs);
  }
  .settingsdetailsemail {
    position: relative;
    width: 640px;
    height: 73px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .settingsdetailsemailtext2 {
    border: 0;
    font-family: var(--h2-bold-32);
    font-size: var(--h4-medium-16-size);
    background-color: transparent;
    position: relative;
    color: var(--logo-black);
    text-align: left;
    display: flex;
    align-items: center;
    width: 346px;
    flex-shrink: 0;
  }
  .settingsdetailsemailbox12 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 173px;
    color: var(--logo-color-2);
  }
  .settingsdetailspasswordtext {
    position: relative;
    display: flex;
    align-items: center;
    width: 346px;
    flex-shrink: 0;
    padding-left: 2px;
  }
  .settingsdetailspasswordbutton {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    font-size: var(--h4-medium-16-size);
    font-family: var(--h2-bold-32);
    color: var(--logo-color-2);
    text-align: left;
    display: inline-block;
  }
  .settingsdetailspasswordbox2 {
    gap: 148px;
    color: var(--logo-black);
  }
  .settingsdetailspassword1box2,
  .settingsdetailspasswordbox2,
  .settingsdetailspasswordhideedi {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .settingsdetailspasswordhideedi {
    gap: var(--gap-29xl);
    color: var(--logo-color-2);
  }
  .settingsdetailspassword1box2 {
    gap: 181px;
  }
  .settingsdetailspassword1box1,
  .settingsdetailspassword1box11 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .settingsdetailspassword1box11 {
    gap: var(--gap-7xs);
    color: var(--logo-black);
  }
  .settingsdetailspassword1box1 {
    position: absolute;
    top: 7px;
    left: -1px;
    gap: var(--gap-9xs);
  }
  .settingsdetailsdeactivateboxic-child {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-31xl);
    background-color: var(--color-darkgray-300);
    width: 54px;
    height: 23px;
  }
  .settingsdetailsdeactivateboxic-item {
    position: absolute;
    top: 3px;
    left: 5px;
    border-radius: 50%;
    background-color: var(--color-yellowgreen);
    width: 18px;
    height: 18px;
  }
  .settingsdetailsdeactivateboxic {
    position: relative;
    width: 54px;
    height: 23px;
  }
  .settingsdetailsdeactivatebox1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-221xl);
    color: var(--logo-black);
  }
  .settingsdetailsdeactivatebox2t {
    position: relative;
    display: flex;
    align-items: center;
    width: 346px;
    flex-shrink: 0;
    opacity: 0.3;
  }
  .settingdetailsbox1 {
    position: absolute;
    top: 74px;
    left: 31px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .settingsdetailsheadersecurityt {
    position: relative;
    font-weight: 500;
  }
  .settingsdetailsheadersecurity-child {
    position: relative;
    border-top: 2px solid var(--logo-color-2);
    box-sizing: border-box;
    width: 132px;
    height: 2px;
  }
  .settingsdetailsheadersecurity {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: var(--gap-8xs);
    color: var(--logo-color-2);
  }
  .settingsdetailsheader1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 72px;
  }
  .settingsdetailsheaderline {
    position: relative;
    border-top: 1px solid var(--color-darkgray-300);
    box-sizing: border-box;
    width: 640px;
    height: 1px;
  }
  .settingsdetailsheader {
    position: absolute;
    top: 20px;
    left: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--logo-black);
  }
  .settingdetailsbox {
    position: relative;
    border-radius: var(--br-5xs);
    background-color: var(--white);
    width: 1120px;
    height: 594px;
    overflow: hidden;
    flex-shrink: 0;
    font-size: var(--h4-medium-16-size);
    color: var(--gray-4);
  }
  .settings,
  .settingsbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .settingsbox {
    gap: var(--gap-5xl);
  }
  .settings {
    position: relative;
    background-color: var(--gray-1);
    overflow: hidden;
    padding: var(--padding-61xl) var(--padding-140xl);
    text-align: left;
    font-size: var(--font-size-5xl);
    color: var(--logo-black);
    font-family: var(--h2-bold-32);
  }
  @media screen and (max-width: 1200px) {
    .settingsbox1 {
      width: 820px;
    }
    .settingsdetailsemailbox1 {
      gap: var(--gap-241xl);
    }
    .settingsdetailsemailbox12 {
      gap: 175px;
    }
    .settingdetailsbox {
      width: 820px;
    }
  }
  @media screen and (max-width: 850px) {
    .settingsbox1 {
      width: 520px;
    }
    .settingsdetailsemailheader {
      font-size: var(--font-size-smi);
    }
    .settingsdetailsemailtext {
      width: 300px;
    }
    .settingsdetailsemailedit,
    .settingsdetailsemailtext {
      font-size: var(--font-size-smi);
    }
    .settingsdetailsemailbox1 {
      gap: var(--gap-101xl);
    }
    .settingsdetailsemailline {
      width: 442px;
    }
    .settingsdetailsemailtext2 {
      font-size: var(--font-size-smi);
      width: 300px;
    }
    .settingsdetailsemailbox12 {
      gap: var(--gap-29xl);
    }
    .settingsdetailspasswordtext {
      width: 300px;
    }
    .settingsdetailspasswordbutton,
    .settingsdetailspasswordtext {
      font-size: var(--font-size-smi);
    }
    .settingsdetailspasswordbox2,
    .settingsdetailspasswordhideedi {
      gap: var(--gap-6xl);
    }
    .settingsdetailspassword1box2 {
      gap: 65px;
    }
    .settingsdetailspassword1box11 {
      gap: var(--gap-7xs);
    }
    .settingsdetailsdeactivatebox1 {
      gap: var(--gap-74xl);
    }
    .settingsdetailsdeactivatebox2t {
      font-size: var(--font-size-smi);
      width: 300px;
    }
    .settingsdetailsheadersecurityt {
      font-size: var(--font-size-smi);
    }
    .settingsdetailsheadersecurity-child {
      width: 102px;
    }
    .settingsdetailsheaderline {
      width: 400px;
    }
    .settingdetailsbox {
      width: 520px;
    }
  }
  @media screen and (max-width: 520px) {
    .settingbox2 {
      font-size: var(--h3-medium-20-size);
    }
    .settingsbox1 {
      width: 400px;
    }
    .settingsdetailsemailheader {
      font-size: var(--font-size-smi);
    }
    .settingsdetailsemailtext {
      width: 260px;
    }
    .settingsdetailsemailedit,
    .settingsdetailsemailtext {
      font-size: var(--font-size-smi);
    }
    .settingsdetailsemailbox1 {
      gap: var(--gap-36xl);
    }
    .settingsdetailsemailline {
      width: 340px;
    }
    .settingsdetailsemailtext2 {
      font-size: var(--font-size-smi);
      width: 220px;
    }
    .settingsdetailsemailbox12 {
      gap: var(--gap-6xl);
    }
    .settingsdetailspasswordtext {
      width: 220px;
    }
    .settingsdetailspasswordbutton,
    .settingsdetailspasswordtext {
      font-size: var(--font-size-smi);
    }
    .settingsdetailspasswordbox2 {
      gap: var(--gap-8xs);
    }
    .settingsdetailspassword1box2 {
      gap: var(--gap-26xl);
    }
    .settingsdetailsdeactivatebox1 {
      gap: var(--gap-51xl);
    }
    .settingsdetailsdeactivatebox2t {
      font-size: var(--font-size-smi);
      width: 220px;
    }
    .settingsdetailsheadersecurityt {
      font-size: var(--font-size-smi);
    }
    .settingsdetailsheader1 {
      gap: var(--gap-26xl);
    }
    .settingsdetailsheaderline {
      width: 330px;
    }
    .settingsdetailsheader {
      width: 420px;
    }
    .settingdetailsbox {
      width: 400px;
    }
  }
  