.headerlogo-icon8 {
  position: relative;
  width: 134px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link-default32 {
  position: relative;
  font-weight: 500;
}
.headernav-link40 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headernav-link41 {
  color: var(--logo-color-1);
}
.headernav-link41,
.headernav-link42,
.left-side-top-nav8,
.top-nav-left8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left-side-top-nav8,
.top-nav-left8 {
  gap: var(--gap-13xl);
}
.left-side-top-nav8 {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  width: 604.51px;
  padding: 0 0 0 var(--padding-13xl);
  box-sizing: border-box;
  align-items: flex-end;
}
.vector-icon52,
.vector-icon53 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon53 {
  height: 50%;
  width: 75%;
  top: 25%;
  right: 12.5%;
  bottom: 25%;
  left: 12.5%;
}
.menu8 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 44px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.messages-icon7,
.notifications-icon7 {
  position: absolute;
  top: 8px;
  left: -3px;
  width: 28.3px;
  height: 28px;
}
.notifications-icon7 {
  left: 53px;
  width: 22.74px;
}
.mask-group-icon7 {
  position: relative;
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.vector-icon54 {
  position: relative;
  width: 10px;
  height: 6px;
}
.mask-group-parent5 {
  position: absolute;
  top: 0;
  left: 106px;
  width: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.messages-parent5 {
  position: relative;
  width: 152px;
  height: 44px;
}
.right-side-top-nav8 {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  width: 263px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-13xl) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-13xl);
}
.info7 {
  align-self: stretch;
  position: relative;
  height: 44px;
}
.scholarships-line-icon7 {
  position: relative;
  width: 89.5px;
  height: 0;
  display: none;
}
.community-line-icon7,
.resources-icon7,
.services-line-icon7 {
  position: relative;
  width: 59.5px;
  height: 0;
  display: none;
}
.community-line-icon7,
.resources-icon7 {
  width: 83px;
}
.resources-icon7 {
  width: 73px;
}
.navbar7 {
  align-self: stretch;
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-3);
  box-sizing: border-box;
  height: 79px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.page-title20 {
  position: relative;
  line-height: 150%;
}
.underline-icon12 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
}
.page-title19 {
  width: 102px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}
.arrow-icon9 {
  position: relative;
  width: 4.28px;
  height: 7.42px;
}
.based-on-your6 {
  position: relative;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  width: 168px;
}
.underline-icon13 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 0;
  flex-shrink: 0;
}
.page-title21 {
  width: 168px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}
.arrow-icon10 {
  position: relative;
  width: 4.25px;
  height: 7.39px;
  display: none;
}
.page-title22 {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}
.headertop-navprimarybreadcr4 {
  width: 340px;
  display: flex;
  flex-direction: row;
  padding: 0 0 0 var(--padding-17xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.based-on-profile1 {
  position: relative;
  display: flex;
  align-items: center;
  width: 570px;
  flex-shrink: 0;
}
.allpage-header5 {
  align-self: stretch;
  height: 106px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xl) 0 var(--padding-58xl) var(--padding-15xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--h1-bold-52-size);
}
.header8,
.search-bar3 {
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
}
.header8 {
  width: 1440px;
  height: 249px;
  flex-direction: column;
  padding: 0 var(--padding-108xl);
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-3xs);
}
.search-bar3 {
  border: 2px solid var(--gray-3);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  font-size: var(--p1-regular-16-size);
  background-color: transparent;
  border-radius: var(--br-5xs);
  width: 1120px;
  height: 65px;
  flex-direction: row;
  padding: 0 var(--padding-base);
  align-items: center;
  justify-content: flex-start;
}
.button-text56 {
  position: relative;
  font-weight: 500;
}
.vector-icon55 {
  position: absolute;
  top: calc(50% - 12.1px);
  left: calc(50% - 12.11px);
  width: 24.21px;
  height: 24.21px;
}
.div44 {
  position: absolute;
  top: 5.6px;
  left: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5px;
  height: 13px;
}
.circle-118 {
  position: relative;
  width: 24.21px;
  height: 24.21px;
  display: none;
  font-size: var(--p2-regular-12-size);
  color: var(--color-black);
}
.chevrondown-icon18 {
  position: relative;
  width: 11.05px;
  height: 6.83px;
}
.arrows19 {
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-9xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.filter-button18 {
  border-radius: var(--br-5xs);
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  width: 147.05px;
  height: 65px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.circle-119 {
  position: relative;
  width: 24.21px;
  height: 24.21px;
  display: none;
}
.button-text57 {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 123px;
  flex-shrink: 0;
}
.button-text-parent7 {
  justify-content: flex-start;
  font-size: var(--p1-regular-16-size);
  color: var(--logo-black);
}
.button-text-parent7,
.button-text-parent8,
.filter-button19 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-9xs);
}
.filter-button19 {
  border-radius: var(--br-5xs);
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  width: 208.05px;
  height: 65px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0 var(--padding-5xl);
  justify-content: center;
  font-size: var(--p2-regular-12-size);
  color: var(--color-black);
  /* background-color: {amount.filters} ; */
}
.button-text-parent8 {
  justify-content: flex-start;
  font-size: var(--p1-regular-16-size);
  color: var(--logo-black);
}
.filter-button20 {
  width: 140.05px;
  font-size: var(--p2-regular-12-size);
  color: var(--color-black);
}
.filter-button20,
.filter-button21,
.filter-button22,
.filter-button23 {
  border-radius: var(--br-5xs);
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  height: 65px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.filter-button21 {
  width: 143.05px;
  font-size: var(--p2-regular-12-size);
  color: var(--color-black);
}
.filter-button22,
.filter-button23 {
  width: 159.05px;
}
.filter-button23 {
  width: 169.05px;
}
.vector-icon61 {
  position: relative;
  width: 26px;
  height: 6px;
}
.ellipse-button3 {
  width: 44px;
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.filter3,
.filters-wrapper3 {
  display: flex;
  justify-content: flex-start;
}
.filters-wrapper3 {
  width: 1070.32px;
  overflow-x: auto;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-3xs);
}
.filter3 {

  display: flex;
width: 1422.319px;
padding: 8px 150px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
}
.tune-icon3 {
  position: relative;
  width: 30px;
  height: 30px;
  object-fit: cover;
  
}
.filters3 {
  position: relative;
  font-weight: 500;
}
.frame-wrapper56,
.tune-parent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tune-parent1 {
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frame-wrapper56 {
  flex: 1;
  background-color: var(--color-ghostwhite);
  overflow: hidden;
  padding: var(--padding-5xs) 0;
  justify-content: center;
  cursor: pointer;
}
.vector-icon62 {
  position: relative;
  width: 30px;
  height: 30px;
}
.frame-wrapper57 {
  flex: 1;
  background-color: var(--color-ghostwhite);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  align-items: center;
  justify-content: center;
}
.frame-parent8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-123xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-14xl);
}
.search-and-filter-inner2 {
  align-self: stretch;
  overflow: hidden;
  display: none;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
}
.search-and-filter3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* gap: var(--gap-smi); */
  text-align: center;
}
.scholarships-available3,
.search5 {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.search5 {
  /* height: 421px; */
  overflow: hidden;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-smi);
  font-size: var(--p1-regular-16-size);
}
.scholarships-available3 {
  width: 348.79px;
}
.scholarships-available3,
.sort-by3 {
  position: relative;
  font-weight: 500;
  color: #000;

/* H3 - Medium 20px */
font-family: Poppins;
font-size: 20px;
font-style: normal;

line-height: normal;
}
.frame-select,
.sort-by-parent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
   width: 244px; 
  /* gap: var(--gap-5xs); */
}
.sort-by-parent1 {
  position: absolute;
  top: 0;
  left: 0;
}
.filter-dropdown-button-inner1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 207px;
  height: 53px;
}
.filter-dropdown-button3 {
  position: relative;
  width: 220px;
  height: 53px;
  color: var(--color-black);
}
.available3,
.body8 {
  display: flex;
  flex-direction: row;
   align-items: center; 
  justify-content: center;
}
.body8 {
  flex: 1;
  height: 44px;
  gap: 518px;
}
.available3 {
  align-self: stretch;
  overflow: hidden;
  padding: 0 var(--padding-136xl);
  font-size: var(--h3-medium-20-size);
}
.scholarship-available3 {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
}
.see-more-progress-bar7 {
  position: relative;
  width: 183px;
  height: 21px;
}
.availble3 {
  height: 101px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  flex-direction: column;
  padding: var(--padding-21xl) var(--padding-619xl) var(--padding-21xl)
    var(--padding-610xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.cardscholarshipsimage-icon32 {
  position: relative;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.cardscholarshipsbackgroundco32 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-mediumslateblue-100);
  width: 256px;
  height: 97px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.title32 {
  position: absolute;
  top: 110px;
  left: 27px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  width: 212px;
  height: 40px;
}
.award-amount33,
.deadline31,
.organization32 {
  position: absolute;
  top: 158px;
  left: 27px;
  line-height: 150%;
}
.award-amount33,
.deadline31 {
  top: 190px;
}
.deadline31 {
  top: 222px;
}
.component-2-icon32 {
  position: absolute;
  top: 223.68px;
  left: 215px;
  width: 24.63px;
  height: 22.95px;
}
.card32 {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  width: 256px;
  height: 265px;
  overflow: hidden;
  flex-shrink: 0;
}
.cardscholarshipsbackgroundco33 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-darkturquoise);
  width: 256px;
  height: 97px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.cardscholarshipsimage-icon34 {
  position: relative;
  border-radius: var(--br-61xl);
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.cardscholarshipsbackgroundco34 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-gold);
  width: 256px;
  height: 97px;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
}
.cardscholarshipsbackgroundco34,
.cardscholarshipsbackgroundco35,
.row-2-wrapper5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.cardscholarshipsbackgroundco35 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-red);
  width: 256px;
  height: 97px;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
}
.row-2-wrapper5 {
  align-items: flex-start;
  gap: var(--gap-13xl);
}
.scholarship-body3 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm) var(--padding-139xl);
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--p1-regular-16-size);
}
.div50 {
  position: absolute;
  top: 0;
  left: 52px;
  font-weight: 500;
}
.button34 {
  position: absolute;
  height: 109.38%;
  width: 101.84%;
  top: -4.69%;
  right: -0.92%;
  bottom: -4.69%;
  left: -0.92%;
  border-radius: var(--br-5xs);
  border: 1.5px solid var(--logo-black);
  box-sizing: border-box;
}
.button-text62 {
  position: absolute;
  height: 95%;
  width: 100%;
  top: 2.5%;
  left: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonlarge26 {
  position: absolute;
  top: 56px;
  left: 0;
  width: 163px;
  height: 32px;
  color: var(--logo-black);
}
.progress-bar-icon4 {
  position: absolute;
  top: 28px;
  left: 2px;
  width: 158.5px;
  height: 2px;
}
.see-more-progress-bar8 {
  position: relative;
  width: 163px;
  height: 88px;
}
.see-more5 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-21xl) var(--padding-619xl);
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--gray-4);
}
.footerlogo-icon8 {
  position: relative;
  width: 148px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link24 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}
.footerlinknav24 {
  position: relative;
  width: 160px;
  height: 19px;
}
.footer-links40 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.footericoninstagram8 {
  position: relative;
  width: 20px;
  height: 20px;
}
.footericonfacbook8 {
  position: relative;
  width: 18.75px;
  height: 18.75px;
}
.footericontwitter8 {
  position: relative;
  width: 19.5px;
  height: 15.84px;
}
.footericonyoutube8 {
  position: relative;
  width: 21px;
  height: 14.72px;
}
.icon-wrapper8 {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.footer-menu40 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.students8 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 160px;
}
.footer-link104 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  display: inline-block;
}
.main-footer-link104 {
  position: relative;
  width: 160px;
  height: 24px;
}
.footer-menu41 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.footer-link116 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
}
.main-footer-link116 {
  position: relative;
  width: 160px;
  height: 71px;
  font-size: var(--p2-regular-12-size);
}
.text-box7 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-7xs);
  background-color: var(--white);
  box-shadow: var(--shadow-1);
}
.input-placeholder7 {
  position: absolute;
  top: 4px;
  left: 10px;
  font-weight: 500;
  display: inline-block;
  width: 181px;
  height: 25px;
}
.subscribe-email-text-box8 {
  position: relative;
  width: 198px;
  height: 28px;
  color: var(--gray-4);
}
.button35 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--purple-blue);
}
.buttonlarge27 {
  position: relative;
  width: 198px;
  height: 32px;
  text-align: center;
}
.footer-links44 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--h5-btns-med-14-size);
}
.footer-grid-row8,
.footer-legal8,
.footer-menu-parent6 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-69xl);
}
.footer-grid-row8,
.footer-legal8 {
  justify-content: center;
}
.footer-legal8 {
  width: 827px;
  justify-content: flex-start;
  gap: var(--gap-365xl);
}
.based-on-profile,
.footer8 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer8 {
  align-self: stretch;
  background-color: var(--gray-4);
  padding: var(--padding-63xl) var(--padding-105xl) var(--padding-20xl);
  align-items: flex-start;
  gap: var(--gap-62xl);
  font-size: var(--p1-regular-16-size);
  color: var(--gray-1);
}
.based-on-profile {
  position: relative;
  background-color: var(--white);
  width: 100%;
  align-items: center;
  gap: var(--gap-11xs);
  text-align: left;
  font-size: var(--h5-btns-med-14-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
}

  /* Hide scrollbar for Chrome, Safari and Opera */
  .filters-wrapper3::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .filters-wrapper3 {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
@media screen and (max-width: 1200px) {
  .menu8 {
    display: none;
  }
  .based-on-profile1 {
    font-size: var(--font-size-17xl);
  }
  .header8 {
    width: 1200px;
    height: 117px;
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--padding-146xl);
    padding-top: var(--padding-xl);
    box-sizing: border-box;
  }
  .search-bar3 {
    width: 800px;
  }
  .button-text56 {
    font-size: var(--font-size-3xs);
  }
  .filter-button18 {
    width: 127px;
    height: 50px;
  }
  .button-text57 {
    font-size: var(--font-size-3xs);
    width: 80px;
  }
  .button-text-parent7 {
    gap: var(--gap-12xs);
  }
  .filter-button19 {
    width: 158px;
    height: 50px;
    padding-left: var(--padding-5xs);
    padding-right: var(--padding-5xs);
    box-sizing: border-box;
  }
  .filter-button20 {
    width: 120px;
    height: 50px;
  }
  .filter-button21 {
    width: 123px;
    height: 50px;
  }
  .filter-button22 {
    width: 139px;
    height: 50px;
  }
  .filter-button23 {
    width: 149px;
    height: 50px;
  }
  .filters-wrapper3 {
    width: 800px;
    flex-direction: row;
    /* align-items: center;
     justify-content: center;  */
   
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
.filters-wrapper3::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.filters-wrapper3 {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
  .filter3 {
    width: 1200px;
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--padding-181xl);
    box-sizing: border-box;
  }
  .search-and-filter3 {
    /* height: 178px; */
  }
  .search5 {
    padding-left: var(--padding-131xl);
    padding-right: var(--padding-131xl);
    box-sizing: border-box;
  }
  .scholarships-available3{
    /* width: 230px; */
    font-size: var(--font-size-lg);
  }
  .sort-by3 {
    font-size: var(--font-size-lg);
  }
  .body8 {
    gap: var(--gap-241xl);
  }
  .footer-menu-parent6,
  .row-2-wrapper5,
  .scholarship-body3 {
    flex-wrap: wrap;
  }
  .footer-grid-row8 {
    flex-direction: column;
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 850px) {
  .top-nav-left8 {
    display: none;
  }
  .menu8 {
    display: flex;
  }
  .messages-parent5 {
    display: none;
  }
  .headertop-navprimarybreadcr4 {
    padding-top: var(--padding-11xl);
    box-sizing: border-box;
  }
  .based-on-profile1 {
    font-size: var(--font-size-11xl);
  }
  .allpage-header5 {
    height: 200px;
    padding-top: var(--padding-8xs);
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .header8 {
    width: 850px;
    height: 140px;
    padding-left: var(--padding-121xl);
    padding-top: 0;
    padding-bottom: var(--padding-3xs);
    box-sizing: border-box;
  }
  .search-bar3 {
    width: 500px;
  }
  .filter-button19 {
    width: 129px;
  }
  .filters-wrapper3 {
    width: 500px;
  }
  .filter3 {
    display: none;
    width: 850px;
    padding-left: var(--padding-157xl);
    box-sizing: border-box;
  }
  .frame-wrapper56 {
    width: 410px;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .frame-parent8 {
    gap: var(--gap-mini);
    padding-left: var(--padding-53xl);
    padding-right: var(--padding-53xl);
    box-sizing: border-box;
  }
  .search-and-filter-inner2 {
    display: flex;
  }
  .search5 {
    padding-top: 0;
    box-sizing: border-box;
  }
  .scholarships-available3 {
    font-size: var(--p1-regular-16-size);
    width: 230px;
  }
  .filter-dropdown-button-inner1,
  .filter-dropdown-button3 {
    display: none;
  }
  .body8 {
    align-items: center;
    justify-content: center;
  }
  .available3 {
    /* display: none; */
    height: 90px;
    /* padding-left: var(--padding-271xl); */
    /* padding-right: var(--padding-161xl); */
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .availble3 {
    display: flex;
    height: 80px;
  }
  .scholarship-body3 {
    padding-left: var(--padding-21xl);
    padding-top: var(--padding-7xs);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }

  .sort-by-parent1{
    display: none;
  }

  .vector-icon62{
    width: 20px;
    height: 20px;
  }

  .tune-icon3{
    width: 20px;
    height: 20px
  }
}
@media screen and (max-width: 520px) {
  .headertop-navprimarybreadcr4 {
    padding-top: var(--padding-xl);
    box-sizing: border-box;
  }
  .based-on-profile1 {
    font-size: var(--font-size-3xl);
  }
  .allpage-header5 {
    height: 10px;
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .header8 {
    width: 520px;
    padding-left: var(--padding-58xl);
    padding-top: var(--padding-xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
  .search-bar3 {
    width: 300px;
    height: 55px;
  }
  .filters-wrapper3 {
    width: 300px;
  }
  .filter3 {
    padding-left: var(--padding-259xl);
    box-sizing: border-box;
  }
  .tune-icon3 {
    width: 20px;
    height: 20px;
  }
  .filters3 {
    font-size: var(--p2-regular-12-size);
  }
  .frame-wrapper56 {
    width: 225px;
    padding-top: 5px;
    padding-bottom: 5px ;
    box-sizing: border-box;
  }
  .vector-icon62 {
    width: 20px;
    height: 20px;
  }
  .frame-wrapper57 {
    padding-top: 5px;
    padding-bottom: 5px;
    box-sizing: border-box;
  }
  .frame-parent8 {
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
  .search-and-filter-inner2 {
    padding-top: var(--padding-mid);
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .scholarships-available3 {
    font-size: var(--p1-regular-16-size);
  }
  .available3 {
    /* padding-left: var(--padding-301xl); */
    box-sizing: border-box;
  }
}
