
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h6-medium-12: Poppins;

  /* font sizes */
  --font-size-2xl-6: 21.6px;
  --font-size-mid: 17px;
  --font-size-sm: 14px;
  --font-size-3xs: 10px;
  --font-size-2xs: 11px;
  --font-size-4xs: 9px;
  --h6-medium-12-size: 12px;
  --font-size-lg-8: 18.8px;
  --h2-bold-32-size: 32px;
  --font-size-2xl: 21px;
  --font-size-4xl-2: 23.2px;
  --font-size-lgi: 19px;
  --h3-medium-20-size: 20px;
  --font-size-17xl: 36px;
  --font-size-base: 16px;
  --font-size-3xl: 22px;
  --font-size-3xl-7: 22.7px;
  --font-size-smi: 13px;
  --font-size-4xl-4: 23.4px;
  --font-size-lg: 18px;
  --font-size-lg-9: 18.9px;
  --font-size-9xl-1: 28.1px;

  /* Colors */
  --color-white: #fff;
  --color-silver-100: #c4c6c7;
  --color-silver-200: #bbb;
  --gray-4: #79797d;
  --color-gray-100: #787878;
  --logo-black: #010101;
  --color-gray-200: rgba(255, 255, 255, 0.5);
  --color-black: #000;
  --purple-blue: #5558cf;
  --color-steelblue-100: #4a93cf;
  --gray-5: #393939;
  --color-darkslategray-100: #263238;
  --teal: #40cfc3;
  --gray-1: #f2f2f2;
  --color-mediumaquamarine: #70d499;
  --royal-blue: #0d0ed9;

  /* Gaps */
  --gap-9xs: 4px;
  --gap-5xs: 8px;
  --gap-7xs: 6px;
  --gap-3xs: 10px;
  --gap-6xs: 7px;
  --gap-31xl: 50px;
  --gap-9xl: 28px;
  --gap-xs: 12px;
  --gap-11xl: 30px;
  --gap-mini: 15px;
  --gap-3xl: 22px;
  --gap-11xs: 2px;
  --gap-61xl: 80px;
  --gap-0: 0px;
  --gap-base: 16px;
  --gap-10xs: 3px;
  --gap-13xl: 32px;
  --gap-3xs-4: 9.4px;
  --gap-2xs: 11px;
  --gap-mini-1: 14.1px;

  /* Paddings */
  --padding-xs: 12px;
  --padding-smi: 13px;
  --padding-61xl: 80px;
  --padding-84xl: 103px;
  --padding-4xs-2: 8.2px;
  --padding-8xs: 5px;
  --padding-9xs: 4px;
  --padding-mid: 17px;
  --padding-55xl: 74px;
  --padding-42xl: 61px;
  --padding-mini: 15px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-xs: 12px;
  --br-3xs-4: 9.4px;
  --br-4xs: 9px;
  --br-3xs-5: 9.5px;
}

.rightbookcoachprofile-icon {
    position: relative;
    width: 152.05px;
    height: 147.05px;
    object-fit: cover;
  }
  .rightbookcoachdetailsname {
    position: relative;
    font-size: var(--h2-bold-32-size);
  }
  .rightbookcoachdetailsexpertise {
    position: relative;
    font-weight: 500;
    color: var(--purple-blue);
  }
  .star-icon {
    position: relative;
    width: 9px;
    height: 8px;
  }
  .rightbookcoachstars {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1px;
  }
  .rightbookcoachratingamount {
    position: relative;
    letter-spacing: 0.08em;
    line-height: 150%;
    font-weight: 500;
  }
  .rightbookcoachratingbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    font-size: var(--h6-medium-12-size);
    color: var(--gray-4);
  }
  .rightbookcoachhoursicon {
    position: relative;
    width: 20px;
    height: 20px;
  }
  .rightbookcoachnumber {
    position: relative;
    font-weight: 500;
  }
  .rightbookcoachhoursbox {
    gap: var(--gap-5xs);
  }
  .rightbookcoachhoursbox,
  .rightbookcoachratebox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .rightbookcoachdetailsbox {
    gap: var(--gap-base);
  }
  .rightbookcoachdetails,
  .rightbookcoachdetailsbox {
    /* display: flex; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
  }
  .rightbookcoach,
  .rightbookcoachinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .rightbookcoachinner {
    gap:10px;
  }
  .rightbookcoach {
    border-right: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 627px;
    padding: var(--padding-84xl) 0;
  }
  .leftbookcoachheading {
    position: relative;
    font-size: var(--h3-medium-20-size);
    text-align: left;
  }
  .vector-icon {
    position: relative;
    width: 41.36px;
    height: 41.36px;
  }
  .leftbookcoachbookingdateboxico {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs);
    align-items: center;
    justify-content: center;
  }
  .leftbookcoachbookingdatetext {
    position: relative;
    font-weight: 500;
  }
  .leftbookcoachbookingdateday {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .vector-icon1-clock {
    position: relative;
    width: 49.64px;
    height: 49.64px;
  }
  .leftbookcoachbookingtimebox1 {
    gap: 19px;
  }
  .leftbookcoachbookingdatebox,
  .leftbookcoachbookingtimebox,
  .leftbookcoachbookingtimebox1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .leftbookcoachbookingdatebox {
    gap: 72px;
    font-size: 22.69px;
  }
  .leftbookcoachbookingrateprice {
    position: relative;
    font-weight: 600;
  }
  .leftbookcoachbookingservice {
    position: relative;
    font-size: 18.83px;
    font-weight: 500;
  }
  .leftbookcoachbookingrate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-10xs);
    font-size: 28.25px;
  }
  .leftbookcoachbookingserviceico-icon {
    position: relative;
    width: 23.54px;
    height: 17.65px;
    cursor: pointer;
  }
  .leftbookcoachbookingservicemin {
    position: relative;
  }
  .leftbookcoachbookingservicebox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: var(--gap-11xs);
    text-align: left;
  }
  .leftbookcoachbookingbuttonsess1 {
    position: relative;
    font-weight: 500;
  }
  .leftbookcoachbookingbuttonsess {
    border-radius: 9.42px;
    background-color: var(--color-white);
    border: 1px solid var(--gray-5);
    box-sizing: border-box;
    width: 264px;
    overflow: hidden;
    flex-shrink: 0;
    padding: 8.238937377929688px var(--padding-8xs);
    cursor: pointer;
  }
  .leftbookcoachbookingbuttonbox,
  .leftbookcoachbookingbuttonsess,
  .leftbookcoachbookingbuttonsess2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .leftbookcoachbookingbuttonsess2 {
    border-radius: 9.42px;
    background-color: var(--logo-black);
    width: 286px;
    overflow: hidden;
    flex-shrink: 0;
    padding: 8.238937377929688px var(--padding-8xs);
    box-sizing: border-box;
    cursor: pointer;
    color: var(--color-white);
  }
  .leftbookcoachbookingbuttonbox {
    gap: var(--gap-11xl);
    color: var(--gray-5);
  }
  .leftbookcoachbookingwontcharge {
    position: relative;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 237px;
  }
  .leftbookcoachdetailsbox {
    flex-direction: column;
    gap: var(--gap-9xl);
  }
  .bookcoach,
  .leftbookcoachdetails,
  .leftbookcoachdetailsbox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .leftbookcoachdetails {
    width: 627px;
    flex-direction: column;
    padding: var(--padding-84xl) 0;
    box-sizing: border-box;
    text-align: center;
    font-size: 18.83px;
    color: var(--color-black);
  }
  .bookcoach {
    border-radius: var(--br-xs);
    background-color: var(--color-white);
    width: 1261px;
    height: 623px;
    overflow: hidden;
    flex-direction: row;
    gap: var(--gap-6xs);
    max-width: 100%;
    max-height: 100%;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--logo-black);
    font-family: var(--h6-medium-12);
  }

  .coach-review-stars-book {
    /* width: 228.23px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 54px;
    font-size: var(--h6-medium-12-size);
    color: var(--gray-4);}
  @media screen and (max-width: 1200px) {
    .rightbookcoach {
      width: 477px;
    }
    .leftbookcoachbookingbuttonsess,
    .leftbookcoachbookingbuttonsess2 {
      width: 204px;
      padding-left: var(--padding-8xs);
      padding-right: var(--padding-8xs);
      box-sizing: border-box;
    }
    .leftbookcoachbookingbuttonsess2 {
      padding-left: var(--padding-xs);
      padding-right: var(--padding-xs);
    }
    .leftbookcoachdetails {
      width: 477px;
    }
    .bookcoach {
      flex-direction: row;
      padding-top: 0;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 850px) {
    .rightbookcoachprofile-icon {
      width: 128px;
      height: 117px;
    }
    .rightbookcoachdetailsname {
      font-size: var(--font-size-3xl);
    }
    .rightbookcoachdetailsexpertise {
      font-size: var(--font-size-3xs);
    }
    .rightbookcoachratingamount {
      font-size: var(--font-size-4xs);
    }
    .rightbookcoachhoursicon {
      width: 15px;
      height: 15px;
    }
    .rightbookcoachnumber {
      font-size: var(--font-size-2xs);
    }
    .rightbookcoachdetails {
      align-items: center;
      justify-content: center;
    }

 
    .rightbookcoachdetailsbox {
      display: flex; 
      flex-direction: column;
      align-items:center;
      justify-content: center;
      gap: 5px;
      padding-bottom: 50px;
    }
    .rightbookcoachinner {
      gap: var(--gap-0);
    }
    .rightbookcoach {
      padding-top: 0;
      padding-bottom: 0;
      box-sizing: border-box;
      border-right: 0px;
      border-bottom: 1px solid black;
    }
    .leftbookcoachheading {
      font-size: var(--font-size-base);
    }
 
    .vector-icon {
      width: 21px;
      height: 21px;
    }
    .leftbookcoachbookingdateboxico {
      width: 39px;
      height: 39px;
    }
    .leftbookcoachbookingdatetext {
      font-size: var(--font-size-sm);
    }
    .vector-icon1-clock{
      width: 21px;
      height: 21px;
    }
    .leftbookcoachbookingdatebox {
      gap: var(--gap-13xl);
    }
    .leftbookcoachbookingrateprice {
      font-size: var(--h3-medium-20-size);
    }
    .leftbookcoachbookingservice {
      font-size: var(--h6-medium-12-size);
    }
    .leftbookcoachbookingserviceico-icon {
      width: 19px;
      height: 12px;
    }
    .leftbookcoachbookingservicemin {
      font-size: var(--h6-medium-12-size);
    }
    .leftbookcoachbookingbuttonsess1 {
      font-size: var(--font-size-4xs);
    }
    .leftbookcoachbookingbuttonsess {
      width: 150px;
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
    .leftbookcoachbookingbuttonsess2 {
      width: 150px;
      padding-left: var(--padding-xs);
      padding-right: var(--padding-xs);
      box-sizing: border-box;
    }
    .leftbookcoachbookingwontcharge {
      font-size: var(--h6-medium-12-size);
    }
    .leftbookcoachdetailsbox {
      gap: var(--gap-xs);
    }
    .leftbookcoachdetails {
      padding-top: 0;
      padding-bottom: 0;
      box-sizing: border-box;
    }
    .bookcoach {
      height: 900px;
      flex-direction: column;
      gap: var(--gap-31xl);
      /* padding-top: var(--padding-61xl); */
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 520px) {
    .leftbookcoachbookingbuttonbox {
      gap: var(--gap-mini);
    }
  }

  @media screen and (max-width: 350px) {
    .rightbookcoach {
      width: 300px; 
    }
    .leftbookcoachbookingsec {
      width: 300px; 
    }
  }
  