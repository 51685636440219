
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h2-bold-32: Poppins;
  --font-inter: Inter;

  /* font sizes */
  --font-size-mini: 15px;
  --p2-regular-12-size: 12px;
  --font-size-4xs: 9px;
  --h2-bold-32-size: 32px;
  --font-size-3xl: 22px;
  --font-size-smi-6: 12.6px;
  --font-size-6xs: 7px;
  --font-size-2xs: 11px;
  --font-size-xl-2: 20.2px;
  --font-size-base-1: 15.1px;
  --font-size-33xl: 52px;
  --font-size-23xl: 42px;
  --font-size-11xl: 30px;
  --font-size-9xl: 28px;
  --h5-btns-med-14-size: 14px;
  --p1-regular-16-size: 16px;
  --h3-medium-20-size: 20px;
  --font-size-lg: 18px;
  --font-size-lgi: 19px;
  --font-size-3xs: 10px;
  --font-size-smi: 13px;
  --font-size-smi-4: 12.4px;
  --font-size-5xl: 24px;
  --font-size-lg-4: 18.4px;
  --font-size-5xs: 8px;
  --font-size-mid: 17px;
  --font-size-29xl: 48px;
  --font-size-21xl: 40px;
  --font-size-2xl-6: 21.6px;
  --font-size-lg-8: 18.8px;
  --font-size-2xl: 21px;
  --font-size-4xl-2: 23.2px;
  --font-size-17xl: 36px;
  --font-size-3xl-7: 22.7px;
  --font-size-4xl-4: 23.4px;
  --font-size-lg-9: 18.9px;
  --font-size-9xl-1: 28.1px;

  /* Colors */
  --white: #fff;
  --gray-1: #f2f2f2;
  --logo-color-2: #4a93cf;
  --color-black: #000;
  --color-gray-100: #fafafa;
  --color-gray-200: #898989;
  --color-gray-300: #7a7a7a;
  --gray-4: #79797d;
  --color-gray-400: #787878;
  --color-gray-500: #757575;
  --logo-black: #010101;
  --color-gray-600: rgba(255, 255, 255, 0.5);
  --gray-5: #393939;
  --color-darkslategray-100: #263238;
  --color-limegreen: #6bc844;
  --royal-blue: #0d0ed9;
  --purple-blue: #5558cf;
  --gray-3: #c4c6c7;
  --color-silver-100: #bbb;
  --color-gold: #f8d100;
  --color-darkturquoise: #00b8c3;
  --color-mediumslateblue: #5d57f9;
  --color-red: #f40000;
  --gray-2: #e7e7e7;
  --color-tomato-100: #ed5043;
  --light-blue: #cbdff7;
  --logo-color-1: #181048;
  --teal: #40cfc3;
  --reviews: #deb00f;
  --color-darkgray-100: #959595;
  --color-khaki: #f8d665;
  --color-mediumaquamarine: #70d499;

  /* Gaps */
  --gap-3xs: 10px;
  --gap-10xs: 3px;
  --gap-11xs: 2px;
  --gap-378xl: 397px;
  --gap-76xl: 95px;
  --gap-40xl: 59px;
  --gap-mid: 17px;
  --gap-48xl: 67px;
  --gap-9xl: 28px;
  --gap-5xs: 8px;
  --gap-12xs: 1px;
  --gap-62xl: 81px;
  --gap-69xl: 88px;
  --gap-31xl: 50px;
  --gap-base: 16px;
  --gap-11xl: 30px;
  --gap-xs: 12px;
  --gap-381xl: 400px;
  --gap-13xl: 32px;
  --gap-6xs: 7px;
  --gap-7xs: 6px;
  --gap-lgi: 19px;
  --gap-mini: 15px;
  --gap-smi-4: 12.4px;
  --gap-6xs-2: 6.2px;
  --gap-sm-1: 13.1px;
  --gap-9xs: 4px;
  --gap-185xl: 204px;
  --gap-141xl: 160px;
  --gap-235xl: 254px;
  --gap-81xl: 100px;
  --gap-xl: 20px;
  --gap-3xs-2: 9.2px;
  --gap-lg: 18px;
  --gap-111xl: 130px;
  --gap-8xs: 5px;
  --gap-43xs: -30px;
  --gap-192xl: 211px;
  --gap-61xl: 80px;
  --gap-254xl: 273px;
  --gap-28xl: 47px;
  --gap-53xl: 72px;
  --gap-3xl: 22px;
  --gap-0: 0px;
  --gap-3xs-4: 9.4px;
  --gap-2xs: 11px;
  --gap-mini-1: 14.1px;

  /* Paddings */
  --padding-61xl: 80px;
  --padding-4xs: 9px;
  --padding-140xl: 159px;
  --padding-106xl: 125px;
  --padding-base: 16px;
  --padding-13xl: 32px;
  --padding-xs: 12px;
  --padding-73xl: 92px;
  --padding-15xl: 34px;
  --padding-5xl: 24px;
  --padding-8xs: 5px;
  --padding-smi: 13px;
  --padding-3xs: 10px;
  --padding-5xs: 8px;
  --padding-134xl: 153px;
  --padding-xl: 20px;
  --padding-6xl: 25px;
  --padding-21xl: 40px;
  --padding-2xs: 11px;
  --padding-281xl: 300px;
  --padding-141xl: 160px;
  --padding-81xl: 100px;
  --padding-mid: 17px;
  --padding-sm: 14px;
  --padding-4xl: 23px;
  --padding-118xl: 137px;
  --padding-57xl: 76px;
  --padding-77xl: 96px;
  --padding-10xs: 3px;
  --padding-161xl: 180px;
  --padding-31xl: 50px;
  --padding-lgi: 19px;
  --padding-16xl: 35px;
  --padding-6xs: 7px;
  --padding-mini: 15px;
  --padding-17xl: 36px;
  --padding-34xl: 53px;
  --padding-9xs: 4px;
  --padding-25xl: 44px;
  --padding-175xl: 194px;
  --padding-69xl: 88px;
  --padding-11xl: 30px;
  --padding-53xl: 72px;
  --padding-52xl: 71px;
  --padding-50xl: 69px;
  --padding-7xs: 6px;
  --padding-9xl: 28px;
  --padding-23xl: 42px;
  --padding-181xl: 200px;
  --padding-28xl: 47px;
  --padding-84xl: 103px;
  --padding-4xs-2: 8.2px;
  --padding-55xl: 74px;
  --padding-42xl: 61px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-6xs-3: 6.3px;
  --br-7xs: 6px;
  --br-61xl: 80px;
  --br-31xl: 50px;
  --br-xl: 20px;
  --br-9xs: 4px;
  --br-6xs-2: 6.2px;
  --br-6xs: 7px;
  --br-3xs: 10px;
  --br-xs: 12px;
  --br-3xs-4: 9.4px;
  --br-4xs: 9px;
  --br-3xs-5: 9.5px;

  /* Effects */
  --shadow-1: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h6-medium-12: Poppins;

  /* font sizes */
  --font-size-2xl-6: 21.6px;
  --font-size-mid: 17px;
  --font-size-sm: 14px;
  --font-size-2xs: 11px;
  --font-size-smi: 13px;
  --font-size-3xs: 10px;
  --font-size-5xs: 8px;
  --h6-medium-12-size: 12px;
  --p1-regular-16-size: 16px;
  --font-size-4xs: 9px;

  /* Colors */
  --color-white: #fff;
  --color-silver: #c4c6c7;
  --color-gray-100: #79797d;
  --logo-black: #010101;
  --color-darkslategray: #393939;
  --color-steelblue: #4a93cf;
  --color-mediumslateblue: #5d57f9;

  /* Gaps */
  --gap-9xs: 4px;
  --gap-5xs: 8px;
  --gap-7xs: 6px;
  --gap-13xl: 32px;
  --gap-3xl: 22px;
  --gap-mid: 17px;
  --gap-xl: 20px;
  --gap-10xs: 3px;
  --gap-11xs: 2px;
  --gap-8xs: 5px;

  /* Paddings */
  --padding-xs: 12px;
  --padding-smi: 13px;
  --padding-61xl: 80px;
  --padding-141xl: 160px;
  --padding-13xl: 32px;
  --padding-6xl: 25px;
  --padding-3xs: 10px;
  --padding-10xl: 29px;
  --padding-mini: 15px;
  --padding-4xl: 23px;
  --padding-7xs-6: 5.6px;
  --padding-5xs-4: 7.4px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-xs: 12px;
  --br-6xs-5: 6.5px;
}

.circular-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  position: relative;
  font-size: 12px;
  color: white;
  transition: all 0.2s;
}

.circular-checkbox.checked {
  border-color: #4caf50; /* Green border for checked state */
  background-color: #4caf50; /* Green fill for checked state */
}

.circular-checkbox.checked::before {
  content: '✓'; /* Checkmark icon */
  font-weight: bold;
}

.dashboardheadercompleteboxtext {
  font-weight: bold;
  color: #2b6cb0;
  text-decoration: underline;
}

.dashboardheadercompleteboxtext1 {
  font-size: 1rem;
  color: #4caf50;
}

.completed {
  color: #6b7280;
}

.dashboardheadercompleteboxsubt1 {
  color: #6b7280;
  font-size: 0.9rem;
  margin-top: 8px;
}

.dashboardheaderchecklist {
  margin-top: 12px;
}

.checklist-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.checklist-text, .checklist-link {
  font-size: 0.9rem;
}

.checklist-link {
  color: #2b6cb0;
  cursor: pointer;
  text-decoration: underline;
}

.checklist-note {
  margin-left: 4px;
  color: #6b7280;
  font-size: 0.9rem;
}


.headerlogo-icon6 {
  position: relative;
  width: 134px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link-default24 {
  position: relative;
  font-weight: 500;
}
.headernav-link30 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headernav-link31 {
  color: var(--logo-color-1);
}
.headernav-link31,
.headernav-link32,
.left-side-top-nav6,
.top-nav-left6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left-side-top-nav6,
.top-nav-left6 {
  gap: var(--gap-13xl);
}
.left-side-top-nav6 {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  width: 611.7px;
  padding: 0 0 0 var(--padding-13xl);
  box-sizing: border-box;
  align-items: flex-end;
}
.vector-icon46,
.vector-icon47 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon47 {
  height: 50%;
  width: 75%;
  top: 25%;
  right: 12.5%;
  bottom: 25%;
  left: 12.5%;
}
.menu6 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 44px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.messages-icon5,
.notifications-icon5 {
  position: absolute;
  top: 8px;
  left: -3px;
  width: 28.3px;
  height: 28px;
}
.notifications-icon5 {
  left: 53px;
  width: 22.74px;
}
.mask-group-icon5 {
  position: relative;
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.vector-icon48 {
  position: relative;
  width: 10px;
  height: 6px;
}
.mask-group-parent3 {
  position: absolute;
  top: 0;
  left: 106px;
  width: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.messages-parent3 {
  position: relative;
  width: 152px;
  height: 44px;
}
.right-side-top-nav6 {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  width: 266.13px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-13xl) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-13xl);
}
.info5 {
  align-self: stretch;
  position: relative;
  height: 44px;
}
.scholarships-line-icon5 {
  position: relative;
  width: 89.5px;
  height: 0;
  display: none;
}
.community-line-icon5,
.resources-icon5,
.services-line-icon5 {
  position: relative;
  width: 59.5px;
  height: 0;
  display: none;
}
.community-line-icon5,
.resources-icon5 {
  width: 83px;
}
.resources-icon5 {
  width: 73px;
}
.navbar5 {
  align-self: stretch;
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-3);
  box-sizing: border-box;
  height: 79px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  font-size: var(--h5-btns-med-14-size);
}
.dashboard2 {
  position: relative;
}
.allpage-header3,
.dashboard1 {
  display: flex;
  flex-direction: row;
  padding: var(--padding-53xl) 0 var(--padding-13xl);
  align-items: center;
  justify-content: flex-start;
}
.dashboard1 {
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  padding: 0 var(--padding-108xl) 0 0;
  align-items: flex-start;
}
.joy {
  color: var(--logo-color-2);
}
.section-text3 {
  position: relative;
}
.b,
.you-have {
  font-family: var(--p1-regular-16);
}
.you-have {
  font-weight: 500;
  color: var(--color-black);
}
.b {
  color: var(--logo-color-2);
}
.see-them-here {
  text-decoration: underline;
  cursor: pointer;
}
.you-have-24-container {
  position: relative;
  font-size: var(--base-1-semibold-size);
  color: var(--logo-color-2);
}
.header7,
.welcome-back {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.welcome-back {
  border-radius: var(--br-5xs);
  background-color: var(--gray-1);
  width: 1128px;
  padding: var(--padding-base) var(--padding-13xl);
  box-sizing: border-box;
  gap: var(--gap-10xs);
  font-size: var(--h2-bold-32-size);
}
.header7 {
  padding: 0 var(--padding-140xl);
  gap: var(--gap-3xs);
  font-size: var(--h1-bold-52-size);
}
.week-in-review {
  position: relative;
  font-weight: 500;
}
.week-in-review-label {
  border-radius: var(--br-5xs);
  background-color: var(--gray-2);
  border: 2px solid var(--gray-2);
  box-sizing: border-box;
  height: 58px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
}
.mon {
  font-weight: 500;
}
.b1,
.mon {
  position: relative;
}
.monday,
.th {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.th {
  border-radius: var(--br-31xl);
  background-color: var(--gray-2);
  width: 60px;
  height: 60px;
  justify-content: center;
  text-align: left;
  font-size: var(--h3-medium-20-size);
  color: var(--gray-3);
}
.monday {
  justify-content: flex-start;
  gap: var(--gap-base);
}
.b2,
.event {
  position: relative;
}
.event {
  border-radius: 50%;
  background-color: var(--gray-2);
  width: 8px;
  height: 8px;
}
.monday-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  flex-wrap: wrap;
}
.event1,
.nd3 {
  background-color: var(--logo-color-2);
}
.nd3 {
  border-radius: var(--br-31xl);
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--h3-medium-20-size);
  color: var(--white);
}
.event1 {
  position: relative;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.rd {
  border-radius: var(--br-31xl);
  background-color: var(--light-blue);
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--h3-medium-20-size);
}
.calendar1,
.week-in-review1 {
  display: flex;
  align-items: flex-start;
}
.week-in-review1 {
  display: flex;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 2px solid var(--gray-2);
  flex-direction: row;
  padding: var(--padding-base);
  justify-content: center;
  gap: var(--gap-13xl);
  margin-top: -16px;
  text-align: center;
}
.calendar1 {
  flex-direction: column;
  justify-content: flex-start;
}
.today-friday-apr {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
  text-indent: 1px;
}
.group-child,
.today-friday-apr-21-parent {
  position: absolute;
  top: 19px;
  left: 0;
  width: 417px;
  height: 3px;
}
.today-friday-apr-21-parent {
  top: 0;
  height: 21px;
  margin-top: 7px; 
}
.event2 {
  position: relative;
  border-radius: 50%;
  background-color: var(--logo-color-2);
  width: 8px;
  height: 8px;
}
.meeting-with-coach {
  position: relative;
  font-weight: 500;
  width: 280px;
}
.dot-event-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.am {
  position: relative;
  font-size: var(--p2-regular-12-size);
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  text-indent: 1px;
}
.event-1 {
  position: absolute;
  top: 29px;
  left: 0;
  width: 417px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: var(--p1-regular-16-size);
}
.event3,
.today-friday-apr-21 {
  position: relative;
  width: 417px;
  height: 53px;
  padding-bottom: 10px;
}
.event3 {
  border-radius: 50%;
  background-color: #fe4a49;
  width: 8px;
  height: 8px;
}
.am1,
.week-in-review2 {
  font-size: var(--p2-regular-12-size);
}
.am1 {
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  text-indent: 1px;
}
/* .week-in-review2 {
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 2px solid var(--gray-2);
  box-sizing: border-box;
  height: 157px;
  padding: var(--padding-base);
  gap: var(--gap-base);
  margin-top: -16px;
  overflow-y: auto;
  width: 446px ;
  max-width: 446px

} */



.week-in-review2 {
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 2px solid var(--gray-2);
  box-sizing: border-box;
  height: 157px;
  padding: var(--padding-base);
  gap: var(--gap-base);
  margin-top: -16px;
  overflow: hidden; /* Remove both horizontal and vertical scrollbars */
  overflow-y: auto;
  width: 446px;
  max-width: 446px;
}

.week-in-review2-loading {
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 2px solid var(--gray-2);
  box-sizing: border-box;
  height: 157px;
  padding: var(--padding-base);
  gap: var(--gap-base);
  margin-top: -16px;
  overflow: hidden; /* Remove both horizontal and vertical scrollbars */
  overflow-y: auto;
  width: 446px;
  max-width: 446px;
}

.upcoming-events{
   display: flex; 
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
}
.week-in-review2 {
  /* display: flex;  */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.week-in-review2-loading {
  display: flex; 
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}
.body5,
.calendar-and-events {
  display: flex;
  flex-direction: row;
}
.body5 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 27px;
}
.calendar-and-events {
  width: 1463.21px;
  padding: var(--padding-6xl) var(--padding-141xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.saved-scholarships {
  position: relative;
  display: flex;
  align-items: center;
  width: 354px;
  flex-shrink: 0;
}
.see-all {
 
  width: 100px;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
  cursor: pointer;
  text-align: center;
  font-size: var(--h3-medium-20-size);
  color: var(--color-black);}
.arrow-forward-icon {
  position: relative;
  width: 24.21px;
  height: 24.22px;
}
.see-all-button {
  width: 120.21px;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
  cursor: pointer;
  text-align: center;
  font-size: var(--h3-medium-20-size);
  color: var(--color-black);
  background-color: white;
  border: none;
}
.body6,
.scholarship-header,
.see-all-button {
  display: flex;
  justify-content: flex-start;
}
.body6 {
  flex-direction: row;
  align-items: center;
  gap: 643px;
}
.scholarship-header {
  height: 69px;
  /* overflow: hidden; */
  flex-shrink: 0;
  flex-direction: column;
  padding: 5px var(--padding-134xl);
  padding-left: 152px;
  box-sizing: border-box;
  align-items: flex-start;
  font-size: var(--h2-bold-32-size);

}
.cardscholarshipsimage-icon24 {
  position: relative;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.cardscholarshipsbackgroundco24 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-mediumslateblue-100);
  width: 256px;
  height: 97px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.title24 {
  position: absolute;
  top: 110px;
  left: 27px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  width: 212px;
  height: 40px;
}
.award-amount25,
.deadline23,
.organization24 {
  position: absolute;
  top: 158px;
  left: 27px;
  line-height: 150%;
}
.award-amount25,
.deadline23 {
  top: 190px;
}
.deadline23 {
  top: 222px;
}
.component-2-icon24 {
  position: absolute;
  top: 223.93px;
  left: 215.25px;
  width: 24.13px;
  height: 22.45px;
}
.card24 {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  width: 256px;
  height: 265px;
  overflow: hidden;
  flex-shrink: 0;
}
.cardscholarshipsbackgroundco25 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-darkturquoise);
  width: 256px;
  height: 97px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.cardscholarshipsimage-icon26 {
  position: relative;
  border-radius: var(--br-61xl);
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.cardscholarshipsbackgroundco26 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-gold);
  width: 256px;
  height: 97px;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
}
.cardscholarshipsbackgroundco26,
.cardscholarshipsbackgroundco27,
.row-2-wrapper3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.cardscholarshipsbackgroundco27 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-red);
  width: 256px;
  height: 97px;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
}
.row-2-wrapper3 {
  align-items: flex-start;
  gap: var(--gap-13xl);
}
.saved-scholarship2 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-141xl) var(--padding-13xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.see-all2 {
  position: relative;
  font-weight: 500;
}
.see-all-button1 {
  width: 99.21px;
  gap: var(--gap-5xs);
}
.see-all-button-wrapper,
.see-all-button1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.see-all1 {
  align-self: stretch;
  height: 52px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  flex-direction: column;
  padding: var(--padding-2xs) var(--padding-134xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  font-size: var(--h3-medium-20-size);
  color: var(--color-black);
}
.applied-scholarships {
  position: relative;
  display: flex;
  align-items: center;
  width: 354px;
  flex-shrink: 0;
}
.applied-header,
.applied-scholarship2 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.applied-header {
  height: 69px;
  flex-shrink: 0;
  padding: var(--padding-2xs) var(--padding-134xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--h2-bold-32-size);
}
.applied-scholarship2 {
  padding: 0 var(--padding-140xl) var(--padding-6xl);
  align-items: center;
  justify-content: center;
}
.see-all-button3 {
  width: 99.21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.see-all4 {
  align-self: stretch;
  overflow: hidden;
  display: none;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-134xl) var(--padding-13xl);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  font-size: var(--h3-medium-20-size);
  color: var(--color-black);
}
.footerlogo-icon6 {
  position: relative;
  width: 148px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link18 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}
.footerlinknav18 {
  position: relative;
  width: 160px;
  height: 19px;
}
.footer-links30 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.footericoninstagram6 {
  position: relative;
  width: 20px;
  height: 20px;
}
.footericonfacbook6 {
  position: relative;
  width: 18.75px;
  height: 18.75px;
}
.footericontwitter6 {
  position: relative;
  width: 19.5px;
  height: 15.84px;
}
.footericonyoutube6 {
  position: relative;
  width: 21px;
  height: 14.72px;
}
.icon-wrapper6 {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.footer-menu30 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.students6 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 160px;
}
.footer-link78 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  display: inline-block;
}
.main-footer-link78 {
  position: relative;
  width: 160px;
  height: 24px;
}
.footer-menu31 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.footer-link90 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
}
.main-footer-link90 {
  position: relative;
  width: 160px;
  height: 71px;
  font-size: var(--p2-regular-12-size);
}
.text-box5 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-7xs);
  background-color: var(--white);
  box-shadow: var(--shadow-1);
}
.input-placeholder5 {
  position: absolute;
  top: 4px;
  left: 10px;
  font-weight: 500;
  display: inline-block;
  width: 181px;
  height: 25px;
}
.subscribe-email-text-box6 {
  position: relative;
  width: 198px;
  height: 28px;
  color: var(--gray-4);
}
.button-text34,
.button12 {
  position: absolute;
  width: 100%;
  left: 0;
}
.button12 {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-5xs);
  background-color: var(--purple-blue);
}
.button-text34 {
  height: 95%;
  top: 2.5%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonlarge23 {
  position: relative;
  width: 198px;
  height: 32px;
  text-align: center;
}
.footer-grid-row6,
.footer-links34,
.footer-menu-parent4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-links34 {
  flex-direction: column;
  gap: var(--gap-5xs);
  font-size: var(--h5-btns-med-14-size);
}
.footer-grid-row6,
.footer-menu-parent4 {
  flex-direction: row;
  gap: var(--gap-69xl);
}
.footer-grid-row6 {
  justify-content: center;
}
.scholist-all-rights6 {
  position: relative;
  line-height: 150%;
}
.footer-legal6 {
  width: 827px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-365xl);
}
.dashboard,
.footer6 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer6 {
  align-self: stretch;
  background-color: var(--gray-4);
  padding: var(--padding-63xl) var(--padding-105xl) var(--padding-20xl);
  align-items: flex-start;
  gap: var(--gap-62xl);
  color: var(--gray-1);
}
.dashboard {
  position: relative;
  background-color: var(--white);
  width: 100%;
  align-items: center;
  gap: var(--gap-12xs);
  text-align: left;
  font-size: var(--p1-regular-16-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
  justify-content: center;
}



.dashboardheaertitletext {
  position: relative;
  font-size: 42px;
}
.dashboardheaertitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.dashboardheadercompleteboxtext {
  text-decoration: underline;
  position: relative;
  font-weight: 700;
  color: inherit;
}
.completed {
  color: var(--color-gray-500);
}
.dashboardheadercompleteboxtext1 {
  position: relative;
  font-size: 20.16px;
  color: var(--color-limegreen);
}
.dashboardheadercompletebox1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-48xl);
}
.a-complete-profile {
  color: #7A7A7A;
}
.dashboardheadercompleteboxsubt1 {
  line-break: anywhere;
  width: 100%;
}
.dashboardheadercompleteboxsubt {
  position: relative;
  font-size: var(--font-size-smi-6);
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 399px;
  color: var(--gray-5);
}
.dashboardheadercomplete,
.dashboardheadercompletebox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.dashboardheadercompletebox {
  gap: var(--gap-mid);
}
.dashboardheadercomplete {
  border-radius: var(--br-6xs-3);
  background-color: #FAFAFA;
  border: 0.6px solid var(--gray-5);
  padding: var(--padding-8xs) var(--padding-smi);
  font-size: 15.12px;
  color: var(--royal-blue);
}
.dashboardheaderbox1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-73xl) 0 var(--padding-15xl);
  gap: 460px;
}
.joy {
  color: var(--logo-color-2);
}
.welcomebackboxtext {
  position: relative;
  font-weight: 700;
}
.b,
.you-have {
  font-family: var(--h2-bold-32);
}
.you-have {
  font-weight: 500;
  color: var(--color-black);
}
.b {
  color: var(--logo-color-2);

}
.see-them-here {
  text-decoration: underline;
}
.welcomebackboxtext1 {
  margin: 0;
  position: relative;
  font-size: var(--font-size-mini);
  color: var(--logo-color-2);
}
.gashboardheader,
.welcomebackbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.welcomebackbox {
  border-radius: var(--br-5xs);
  background-color: var(--gray-1);
  width: 1128px;
  padding: var(--padding-base) var(--padding-13xl);
  box-sizing: border-box;
  gap: var(--gap-10xs);
  font-size: var(--h2-bold-32-size);
}
.gashboardheader {
  padding: 0 var(--padding-140xl);
  gap: var(--gap-3xs);
}
.gashboardheader-parent {
  position: relative;
  background-color: var(--white);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-61xl) var(--padding-4xs);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-33xl);
  color: var(--logo-black);
  font-family: var(--h2-bold-32);
}

.noupcomingHeaderText{

color: #7A7A7A;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.upcomingEventBoxes{
  display: flex;
  width: 100%;
  height: 100%;align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .menu6 {
    display: none;
  }
  .welcome-back {
    width: 80%;
  }
  .body5,
  .calendar-and-events {
    flex-direction: column;
  }
  .calendar-and-events {
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--padding-141xl);
    box-sizing: border-box;
  }
  .body6 {
    gap: 401px;
  }
  .row-2-wrapper3 {
    flex-wrap: wrap;
  }
  .saved-scholarship2 {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .saved-scholarships {
    font-size: 22px;
    width: 242px;
}
  .see-all-button-wrapper {
    gap: var(--gap-381xl);
  }
  .footer-menu-parent4 {
    flex-wrap: wrap;
  }
  .footer-grid-row6 {
    flex-direction: column;
    gap: var(--gap-31xl);
  }
  .dashboard {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .see-all-button{
    display: none;
  }
  .scholarship-header{
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .see-all-button3,
  .see-all4 {
    display: flex;
  }

  .dashboardheaertitletext {
    font-size: var(--font-size-23xl);
  }
  .dashboardheadercompleteboxtext,
  .dashboardheadercompleteboxtext1 {
    font-size: var(--p2-regular-12-size);
  }
  .dashboardheadercompletebox1 {
    gap: var(--gap-mid);
  }
  .dashboardheadercompleteboxsubt {
    font-size: var(--font-size-4xs);
    width: 285px;
  }
  .dashboardheadercomplete {
    padding-right: var(--padding-smi);
    box-sizing: border-box;
  }
  .dashboardheaderbox1 {
    gap: var(--gap-76xl);
  }
  .welcomebackbox {
    width: 650px;
  }
}
@media screen and (max-width: 850px) {
  
.noupcomingHeaderText{
  font-size: 20px;
  }
  
  .top-nav-left6 {
    display: none;
  }
  .menu6 {
    display: flex;
  }
  .messages-parent3 {
    display: none;
  }
  .section-text3 {
    font-size: var(--font-size-3xl);
  }
  .you-have-24-container {
    font-size: var(--p2-regular-12-size);
  }
  .welcome-back {
    width: 70%;
  }
  .header7 {
    padding-left: 125px;
    box-sizing: border-box;
  }
  .th {
    width: 50px;
    height: 50px;
  }
  .b2 {
    font-size: var(--h3-medium-20-size);
  }
  .monday-parent {
    gap: 10px;
  }
  .nd3,
  .rd {
    width: 50px;
    height: 50px;
  }
  .week-in-review1 {
    flex-direction: row;
    gap: var(--gap-3xs);
    flex-wrap: wrap;
  }
  .calendar-and-events {
    width: 700px;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .see-all-button {
    display: none;
  }
  .saved-scholarship2 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .see-all-button1,
  .see-all1 {
    display: flex;
  }
  .applied-scholarship2 {
    gap: var(--gap-3xs);
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .see-all-button3,
  .see-all4 {
    display: flex;
  }
  .footer-legal6 {
    gap: var(--gap-181xl);
  }
  .dashboard {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .dashboardheaertitletext {
    font-size: var(--font-size-11xl);
  }
  .dashboardheadercompleteboxtext,
  .dashboardheadercompleteboxtext1 {
    font-size: var(--font-size-4xs);
  }
  .dashboardheadercompleteboxsubt {
    font-family: var(--h2-bold-32);
    font-size: var(--font-size-6xs);
    width: 191px;
  }
  .dashboardheaderbox1 {
    gap: var(--gap-40xl);
    padding-bottom: var(--padding-5xl);
    box-sizing: border-box;
  }
  .welcomebackboxtext {
    font-size: var(--font-size-3xl);
  }
  .welcomebackboxtext1 {
    font-size: var(--p2-regular-12-size);
  }
  .welcomebackbox {
    width: 450px;
  }
  .gashboardheader {
    padding-left: var(--padding-106xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 520px) {
  .noupcomingHeaderText{
    font-size: 15px;
    }
    
  .dashboard2 {
    font-size: var(--font-size-9xl);
  }
  .allpage-header3 {
    padding-top: 0;
    padding-bottom: var(--padding-8xs);
    box-sizing: border-box;
  }
  .dashboard1 {
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-top: var(--padding-31xl);
    box-sizing: border-box;
  }
  .section-text3 {
    font-size: var(--font-size-lg);
  }
  .welcome-back {
    width: 300px;
    gap: var(--gap-11xs);
    padding-top: var(--padding-base);
    box-sizing: border-box;
  }
  .header7 {
    padding-left: var(--padding-140xl);
    box-sizing: border-box;
  }
  .week-in-review {
    font-size: var(--h5-btns-med-14-size);
  }
  .mon {
    font-size: var(--p2-regular-12-size);
  }
  .b1 {
    font-size: var(--h5-btns-med-14-size);
  }
  .th {
    width: 40px;
    height: 40px;
  }
  .monday {
    gap: var(--gap-7xs);
  }
  .b2 {
    font-size: var(--h5-btns-med-14-size);
  }
  .nd3,
  .rd {
    width: 40px;
    height: 40px;
  }
  .week-in-review1 {
    flex-direction: column;
    gap: var(--gap-11xs);
    align-items: center;
    justify-content: center;
    padding-left: var(--padding-base);
    box-sizing: border-box;
  }
  .calendar1 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .today-friday-apr {
    font-size: var(--font-size-3xs);
  }
  .group-child {
    width: 230px;
  }
  .event2 {
    width: 6px;
    height: 6px;
  }
  .am,
  .meeting-with-coach {
    font-size: var(--font-size-3xs);
  }
  .event-1 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .event3 {
    width: 6px;
    height: 6px;
  }
  .week-in-review2 {
    width: 280px;
  }

  .week-in-review2-loading {
    width: 280px;
  }
  .body5,
  .calendar-and-events {
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
  }
  .body5 {
    width: auto;
    height: auto;
    gap: -10px;
    padding-left: var(--padding-81xl);
    padding-right: var(--padding-141xl);
  }
  .calendar-and-events {
    width: 480px;
    padding-left: 0;
    padding-right: 0;
  }
  .saved-scholarships {
    font-size: 19px;
  }
  .see-all {
    font-size: var(--p1-regular-16-size);
  }
  .body6 {
    flex-direction: column;
    gap: var(--gap-10xs);
    align-items: flex-start;
    justify-content: center;
  }
  .scholarship-header {
    align-items: center;
    justify-content: center;
    /* padding-left: var(--padding-281xl); */
    box-sizing: border-box;
    width: 400px
  }
  .saved-scholarship2 {
    padding-bottom: var(--padding-3xs);
    box-sizing: border-box;
  }
  .see-all2 {
    font-size: var(--h3-medium-20-size);
  }
  .see-all-button1 {
    gap: var(--gap-6xs);
  }
  .see-all-button-wrapper {
    flex-direction: column;
    gap: var(--gap-10xs);
    align-items: flex-start;
    justify-content: center;
  }
  .see-all1 {
    align-self: stretch;
    width: auto;
    padding-left: var(--padding-134xl);
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .applied-scholarships {
    font-size: var(--font-size-lg);
  }
  .applied-header,
  .see-all4 {
    padding-left: var(--padding-281xl);
    box-sizing: border-box;
  }
  .see-all4 {
    align-self: stretch;
    width: auto;
    padding-left: var(--padding-134xl);
    /* padding-top: var(--padding-xl); */
    padding-bottom: var(--padding-xl);
  }
  .footer-legal6 {
    flex-direction: column;
    gap: var(--gap-3xs);
  }
  .dashboard {
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .dashboardheaertitletext {
    font-size: var(--font-size-9xl);
  }
  .dashboardheaertitle {
    padding-top: 0;
    padding-bottom: var(--padding-8xs);
    box-sizing: border-box;
  }
  .dashboardheadercompleteboxtext,
  .dashboardheadercompleteboxtext1 {
    font-size: var(--p2-regular-12-size);
  }
  .dashboardheadercompletebox1 {
    gap: var(--gap-9xl);
  }
  .dashboardheadercompleteboxsubt {
    font-size: var(--font-size-2xs);
    width: 250px;
  }
  .dashboardheaderbox1 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .welcomebackboxtext {
    font-size: var(--font-size-mini);
  }
  .welcomebackboxtext1 {
    font-size: var(--font-size-4xs);
  }
  .welcomebackbox {
    width: 280px;
    gap: var(--gap-11xs);
    padding-left: var(--padding-xs);
    padding-top: var(--padding-base);
    box-sizing: border-box;
  }
  .gashboardheader {
    padding-left: var(--padding-140xl);
    box-sizing: border-box;
  }
  .b {
    color: var(--logo-color-2);
    font-size: 9px;
  }
}


.scholarshipsContainer{

  display: flex;
max-width: 1440px;
padding: 0px 14px;
justify-content: center;
align-items: center;
align-content: flex-start;
gap: 30px;
align-self: stretch;
flex-wrap: wrap;
}

.noscholarships{
  display: flex;
  height: 293px;
  padding: 14px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
/* filter: blur(2px); */
/* border: 1px solid #000; */

}

.noScholarshipText{
  color: #040425;
font-size: 20px;
font-family: Poppins;
font-weight: 800;
}


.scholarshipcardimage-icon {
  position: relative;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.scholarshipcardimagebox {
  background-color: var(--color-mediumslateblue);
  width: 256px;
  height: 97px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.scholarshipcarddetailsname {
  position: relative;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  width: 207px;
  min-height: 38px;
  max-height: 38px;
}
.scholarshipcarddeadlinetext,
.scholarshipcarddetailslink {
  position: relative;
  line-height: 150%;
}
.scholarshipcarddeadlinetext {
  display: flex;
  align-items: flex-end;
  width: 172px;
  flex-shrink: 0;
}
.scholarshipcarddeadlinelike-icon {
  position: relative;
  width: 24.13px;
  height: 22.45px;
  cursor: pointer;
}
.scholarshipcarddeadlinebox,
.scholarshipcarddetailsbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-mid);
}
.scholarshipcarddetailsbox {
  width: 256px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 var(--padding-4xl);
  box-sizing: border-box;
  gap: var(--gap-10xs);
  max-height: 119px;
  min-height: 119px;
}
.dashboardscholarshipcard {
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  border: 2px solid var(--color-silver);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 var(--padding-10xl);
  gap: var(--gap-xl);
  max-height: 269px;
  min-height: 269px;
 
}
.dashboardsavedscholarshipbox1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
}
.dashboardsavedscholarshipbox {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0px var(--padding-13xl);
}
.dashboardcoachboximage-icon {
  position: relative;
  width: 116.8px;
  height: 117.4px;
  object-fit: cover;
  border-radius: 60px;
}
.dashboardcoachbox2 {
  width: 256px;
  height: 151px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.dashboardcoachbox2detailsnameb1 {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  width: 174px;
  flex-shrink: 0;
}
.dashboardcoachbox2detailsiconb1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.dashboardcoachbox2detailsiconb {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 17.22px;
  height: 23.56px;
}
.dashboardcoachbox2detailsnameb {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-10xs);
  font-size: var(--p1-regular-16-size);
}
.dashboardcoachbox2detailsservi-icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.dashboardcoachbox2detailsservi1 {
  position: relative;
  line-height: 150%;
}
.dashboardcoachbox2detailsservi {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}
.dashboardcoachbox2details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-10xs);
  min-height: 78px;
}
.dashboardratingseebuttonicon1 {
  position: absolute;
  height: 84.37%;
  width: 87.56%;
  top: 6.25%;
  right: 6.21%;
  bottom: 9.38%;
  left: 6.23%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.dashboardratingseebuttonicon {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.dashboardratingseebuttontext1,
.dashboardratingseebuttontext2 {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
}
.dashboardratingseebuttontext2 {
  color: var(--color-gray-100);
}
.dashboardratingseebuttonbox {
  width: 98px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.dashboardratingseebuttonboxtex {
  position: relative;
  font-size: 12.98px;
  line-height: 150%;
  font-weight: 600;
  font-family: var(--h6-medium-12);
  color: var(--color-white);
  text-align: left;
}
.dashboardratingseebuttonbox1 {
  cursor: pointer;
  border: 0.9px solid var(--color-darkslategray);
  padding: 5.562766075134277px 7.417021751403809px;
  background-color: var(--color-steelblue);
  border-radius: 6.49px;
  box-sizing: border-box;
  width: 93px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.dashboardratingseebutton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-10xs);
}
.dashboardcoachbox1details,
.dashboardcoachboxdetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.dashboardcoachbox1details {
  gap: var(--gap-3xl);
}
.dashboardcoachboxdetails {
  width: 256px;
  padding: 0 0 0 var(--padding-4xl);
  box-sizing: border-box;
}
.dashboardcoachbox,
.dashboardcoachcard,
.dashboardsavedscholarshipbox-parent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.dashboardcoachcard {
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  border: 2px solid var(--color-silver);
  align-items: center;
  padding: 0 0 var(--padding-10xl);
  gap: var(--gap-xl);
 
}
.dashboardcoachbox,
.dashboardsavedscholarshipbox-parent {
  align-items: flex-start;
  padding: 0 0px var(--padding-13xl);
  font-size: var(--h6-medium-12-size);
}
.dashboardsavedscholarshipbox-parent {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: var(--padding-61xl) 9px;
  box-sizing: border-box;
  gap: 10px;
  text-align: left;
  font-size: var(--p1-regular-16-size);
  color: var(--logo-black);
  font-family: var(--h6-medium-12);
}
@media screen and (max-width: 1200px) {
  .dashboardcoachbox2details {
   
    min-height: 68px;
  }
  .scholarshipcardimagebox {
    width: 226px;
  }
  .scholarshipcarddetailsname {
    width: 174px;
  }
  .scholarshipcarddeadlinetext,
  .scholarshipcarddetailslink,
  .scholarshipcarddetailsname {
    font-size: var(--font-size-smi);
  }
  .scholarshipcarddeadlinetext {
    width: 152px;
  }
  .scholarshipcarddeadlinelike-icon {
    width: 22px;
    height: 20px;
  }
  .scholarshipcarddetailsbox {
    width: 226px;
  }
  .dashboardsavedscholarshipbox1 {
    gap: var(--gap-3xl);
    flex-wrap: wrap;
  }
  .dashboardsavedscholarshipbox {
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: var(--padding-61xl);
    padding-right: var(--padding-61xl);
  }
  .dashboardcoachboximage-icon {
    width: 100px;
    height: 101px;
  }
  .dashboardcoachbox2 {
    width: 226px;
  }
  .dashboardcoachbox2detailsnameb1 {
    font-size: var(--font-size-smi);
    width: 154px;
  }
  .dashboardcoachbox2detailsiconb {
    width: 14px;
    height: 20px;
  }
  .dashboardcoachbox2detailsservi-icon {
    width: 21px;
    height: 21px;
  }
  .dashboardcoachbox2detailsservi1 {
    font-size: var(--font-size-2xs);
  }
  .dashboardratingseebuttonicon {
    width: 14px;
    height: 14px;
  }
  .dashboardratingseebuttonboxtex,
  .dashboardratingseebuttontext1,
  .dashboardratingseebuttontext2 {
    font-size: var(--font-size-3xs);
  }
  .dashboardratingseebuttonbox1 {
    width: 73px;
  }
  .dashboardcoachboxdetails {
    width: 226px;
  }
  .dashboardcoachbox {
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: var(--padding-61xl);
    padding-right: var(--padding-61xl);
  }
}
@media screen and (max-width: 850px) {
  .scholarshipcardimagebox {
    width: 210px;
    height: 97px;
  }
  .scholarshipcarddeadlinetext,
  .scholarshipcarddetailslink,
  .scholarshipcarddetailsname {
    font-size: var(--h6-medium-12-size);
  }
  .scholarshipcarddeadlinetext {
    width: 130px;
  }
  .scholarshipcarddeadlinelike-icon {
    width: 18px;
    height: 16px;
  }
  .scholarshipcarddetailsbox {
    width: 210px;
  }
  .dashboardsavedscholarshipbox1 {
    gap: 9px;
    align-items: center;
    justify-content: center;
  }
  .dashboardsavedscholarshipbox {
    padding-left: var(--padding-6xl);
    padding-right: var(--padding-6xl);
    padding-bottom: 0;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
  .dashboardcoachboximage-icon {
    width: 80px;
    height: 81px;
  }
  .dashboardcoachbox2 {
    width: 210px;
    height: 97px;
  }
  .dashboardcoachbox2detailsnameb1 {
    font-size: var(--h6-medium-12-size);
  }
  .dashboardcoachbox2detailsiconb {
    width: 12px;
    height: 18px;
  }
  .dashboardcoachbox2detailsservi-icon {
    width: 19px;
    height: 19px;
  }
  .dashboardcoachbox2detailsservi1 {
    font-size: var(--font-size-2xs);
  }
  .dashboardcoachboxdetails {
    width: 210px;
  }
  .dashboardcoachcard {
    padding-top: var(--padding-3xs);
  }
  .dashboardcoachbox {
    padding-left: var(--padding-6xl);
    padding-right: var(--padding-6xl);
    padding-bottom: 0;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 520px) {
  .scholarshipcardimage-icon {
    width: 50px;
    height: 50px;
  }
  .scholarshipcardimagebox {
    width: 150px;
    /* height: 67px; */
  }
  .scholarshipcarddetailsname {
    width: 144px;
  }
  /* .scholarshipcarddetailslink,
  .scholarshipcarddetailsname {
    font-size: var(--font-size-3xs);
  }
  .scholarshipcarddeadlinetext {
    font-size: var(--font-size-4xs);
    width: 97px;
  }
  .scholarshipcarddeadlinelike-icon {
    width: 16px;
    height: 14px;
  }
  .scholarshipcarddetailsbox {
    width: 150px;
    padding-left: var(--padding-xs);
  } */
  .dashboardsavedscholarshipbox {
    padding-bottom: var(--padding-3xs);
    box-sizing: border-box;
  }
  .dashboardcoachboximage-icon {
    width: 70px;
    height: 71px;
  }
  .dashboardcoachbox2 {
    width: 150px;
    height: 67px;
  }
  .dashboardcoachbox2detailsnameb1 {
    font-size: var(--font-size-3xs);
    width: 104px;
  }
  .dashboardcoachbox2detailsiconb {
    width: 10px;
    height: 16px;
  }
  .dashboardcoachbox2detailsservi-icon {
    width: 17px;
    height: 17px;
  }
  .dashboardcoachbox2detailsservi1 {
    font-size: var(--font-size-5xs);
  }
  .dashboardratingseebuttonicon1 {
    width: 12px;
    height: 12px;
  }
  .dashboardratingseebuttontext2 {
    display: none;
  }
  .dashboardratingseebuttonbox {
    width: 53px;
  }
  .dashboardratingseebuttonboxtex {
    font-size: var(--font-size-5xs);
  }
  .dashboardratingseebuttonbox1 {
    width: 63px;
  }
  .dashboardcoachboxdetails {
    width: 150px;
    padding-left: var(--padding-xs);
  }
  .dashboardcoachcard {
    padding-top: var(--padding-mini);
  }
  .dashboardcoachbox {
    padding-bottom: var(--padding-3xs);
    box-sizing: border-box;
  }

  .meeting-with-coach {
    
    width: 145px;
}
.eventmeetingtime{
  font-size: 10px;
}
}
