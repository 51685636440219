@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --p1-regular-16: Poppins;
  --font-inter: Inter;

  /* font sizes */
  --p1-regular-16-size: 16px;
  --h5-btns-med-14-size: 14px;
  --p2-regular-12-size: 12px;

  /* Colors */
  --white: #fff;
  --color-gray-100: #79797d;
  --logo-black: #010101;
  --gray-1: #f2f2f2;
  --purple-blue: #5558cf;

  /* Gaps */
  --gap-365xl: 384px;
  --gap-67xl: 86px;
  --gap-base: 16px;
  --gap-5xs: 8px;

  /* Paddings */
  --padding-6xl: 25px;

  /* Border radiuses */
  --br-5xs: 8px;
}

.footerlogo-icon,
.footer-headers {
  overflow: hidden;
  flex-shrink: 0;
}
.footer-headers {
  align-self: stretch;
  height: 95px;
}
.footerlogo-icon {
  width: 148px;
  position: relative;
  height: 45px;
}
.nav-link {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}
.footerlinknav {
  width: 160px;
  position: relative;
  height: 19px;
}
.footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.footericoninstagram {
  width: 20px;
  position: relative;
  height: 20px;
}
.footericonfacbook {
  width: 18.8px;
  position: relative;
  height: 18.8px;
}
.footericontwitter {
  width: 19.5px;
  position: relative;
  height: 15.8px;
}
.footericonyoutube {
  width: 21px;
  position: relative;
  height: 14.7px;
}
.icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  gap: 12px;
}
.footer-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}
.students {
  width: 160px;
  position: relative;
  font-weight: 500;
  display: inline-block;
}
.footer-link {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  cursor : pointer;
  line-height: 150%;
  display: inline-block;
}
.main-footer-link {
  width: 160px;
  position: relative;
  height: 24px;
}
.footer-menu1,
.footer-menu2 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-base);
}
.footer-link12 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
}
.main-footer-link12 {
  width: 160px;
  position: relative;
  height: 71px;
}
.subscribe-email-text-box {
  border: 0;
  outline: 0;
  font-family: var(--p1-regular-16);
  font-size: var(--h5-btns-med-14-size);
  background-color: var(--white);
  width: 163px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  height: 28px;
  padding: 4px 10px 0;
  box-sizing: border-box;
  color: var(--color-gray-100);
}
.buttontextfooter,
.subscribe-email-text-box {
  cursor: default;
  position: relative;
  font-weight: 500;
}
.buttonlargefooter {
  border-radius: var(--br-5xs);
  background-color: var(--purple-blue);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 10.5rem;
  padding: 6px 72px;
  text-align: center;
  font-size: var(--h5-btns-med-14-size);
}
.buttonlargefooter:hover {
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}
.footer-links4,
.footer-menu4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer-links4 {
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--p2-regular-12-size);
}
.footer-menu4 {
  align-self: stretch;
  flex: 1;
  justify-content: center;
  gap: var(--gap-base);
}
.footer-grid-row,
.footer-menu-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.footer-menu-parent {
  flex: 1;
  justify-content: flex-start;
}
.footer-grid-row {
  align-self: stretch;
  justify-content: center;
  gap: var(--gap-67xl);
}
.disclaimer-cookies,
.scholist-all-rights {
  position: absolute;
  line-height: 150%;
}
.disclaimer-cookies {
  height: 24px;
  width: 165px;
  white-space: pre-wrap;
  display: none;
}
.footer-legal {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-365xl);
}
.footer,
.footer1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer1 {
  /* align-self: stretch; */
  background-color: var(--color-gray-100);
  align-items: flex-start;
  padding: 14px 55px;
  gap: 81px;
}
.footer {
  width: 100%;
  position: relative;
 
  align-items: center;
  text-align: left;
  font-size: var(--p1-regular-16-size);
  color: var(--gray-1);
  font-family: var(--p1-regular-16);
  /* margin-top: 50px; */
}
@media screen and (max-width: 1200px) {
  .footer-menu4 {
    padding-top: var(--padding-6xl);
    box-sizing: border-box;
  }
  .footer-menu-parent {
    flex-wrap: wrap;
    flex: unset;
    align-self: stretch;
  }
  .footer-grid-row {
    flex-direction: column;
    gap: var(--gap-67xl);
  }
}
@media screen and (max-width: 850px) {
  .footer-menu1,
  .footer-menu2,
  .footer-menu4 {
    padding-top: var(--padding-6xl);
    box-sizing: border-box;
  }
  .footer-menu-parent {
    gap: 0;
  }
  .footer-legal {
    flex-direction: column;
    gap: var(--gap-365xl);
  }
}
@media screen and (max-width: 520px) {
  .footer-headers {
    padding-left: 159px;
    box-sizing: border-box;
  }
  .footer-menu1 {
    padding-top: var(--padding-6xl);
    box-sizing: border-box;
  }
  .footer {
    align-items: center;
    justify-content: center;
    padding-left: 0;
    box-sizing: border-box;
  }
}


