.filterBody{
    display: flex;
flex-direction: column;
align-items: center;
gap: 13px;
background-color: aquamarine;

}

.filterSearch{
    display: flex;
width: 1120px;
height: 65px;
padding: 0px 16px;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 2px solid var(--gray-3, #C4C6C7);
}


.clearAllBox{
    display: flex;
    width: 1117px;
    height: 40px;
    align-items: flex-start;
    justify-content:flex-start;

}


.circle {
    position: relative;
    width: 24.21px;
    height: 24.21px;
    /* display: none; */
    font-size: var(--p2-regular-12-size);
    color: var(--color-black);
  }
  
  .circletext {
    position: absolute;
    top: calc(50% - 12.1px);
    left: calc(50% - 12.11px);
    width: 24.21px;
    height: 24.21px;
}
  
.filtersearch{
    display: flex;
width: 179px;
/* padding: 4px 22px 4px 6px; */
margin-bottom: 10px;
flex-direction: column;
align-items: flex-start;
gap: 10px;

}
@media screen and (max-width: 1200px) {
    .clearAllBox{
        width: 796px;
        
    
    }

}
@media screen and (max-width: 850px) {
    .clearAllBox{
        display: none;
        
    
    }

}


