/* .headerlogo-icon12 {
  position: relative;
  width: 134px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link-default48 {
  position: relative;
  font-weight: 500;
}
.headernav-link60 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headernav-link61 {
  color: var(--logo-color-1);
}
.headernav-link61,
.headernav-link62,
.left-side-top-nav12,
.top-nav-left12 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left-side-top-nav12,
.top-nav-left12 {
  gap: var(--gap-13xl);
}
.left-side-top-nav12 {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  width: 604.51px;
  padding: 0 0 0 var(--padding-13xl);
  box-sizing: border-box;
  align-items: flex-end;
}
.vector-icon88,
.vector-icon89 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon89 {
  height: 50%;
  width: 75%;
  top: 25%;
  right: 12.5%;
  bottom: 25%;
  left: 12.5%;
}
.menu12 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 44px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.messages-icon11,
.notifications-icon11 {
  position: absolute;
  top: 8px;
  left: -3px;
  width: 28.3px;
  height: 28px;
}
.notifications-icon11 {
  left: 53px;
  width: 22.74px;
}
.mask-group-icon11 {
  position: relative;
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.vector-icon90 {
  position: relative;
  width: 10px;
  height: 6px;
}
.mask-group-parent9 {
  position: absolute;
  top: 0;
  left: 106px;
  width: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.messages-parent9 {
  position: relative;
  width: 152px;
  height: 44px;
}
.right-side-top-nav12 {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  width: 263px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-13xl) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-13xl);
}
.info11 {
  align-self: stretch;
  position: relative;
  height: 44px;
}
.scholarships-line-icon11,
.services-line-icon11 {
  position: relative;
  width: 89.5px;
  height: 0;
  display: none;
}
.services-line-icon11 {
  width: 59.5px;
}
.community-line-icon11,
.resources-icon11 {
  position: relative;
  width: 83px;
  height: 0;
  display: none;
}
.resources-icon11 {
  width: 73px;
}
.navbar11 {
  align-self: stretch;
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-3);
  box-sizing: border-box;
  height: 79px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.scholist-profile {
  position: relative;
}
.header11 {
  width: 1446px;
  height: 191px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-76xl) var(--padding-429xl) var(--padding-smi);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.personal-information {
  position: relative;
  font-size: var(--p1-regular-16-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--white);
  text-align: center;
}
.education-wrapper,
.personal-information-wrapper {
  cursor: pointer;
  padding: var(--padding-mini) 0;
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  width: 272px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.personal-information-wrapper {
  border: 0;
  background-color: var(--royal-blue);
}
.education-wrapper {
  border: 0.8px solid var(--gray-2);
  background-color: var(--gray-1);
}
.student-activities {
  position: relative;
  font-size: var(--p1-regular-16-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--color-black);
  text-align: center;
}
.frame-parent16 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-32xl);
}
.selection1 {
  background-color: var(--white);
  width: 1446px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-2xl) var(--padding-115xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.name1 {
  position: relative;
  line-height: 150%;
}
.multiselectdefault {
  border: 1px solid var(--gray-3);
  background-color: var(--white);
  position: relative;
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-1);
  box-sizing: border-box;
  width: 373px;
  height: 42px;
  overflow: hidden;
  flex-shrink: 0;
}
.name-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 278px;
}
.inputtextboxdateyear,
.quiz-question {
  border-radius: var(--br-5xs);
  display: flex;
}
.quiz-question {
  align-self: stretch;
  border: 1px solid var(--gray-3);
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-34xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.inputtextboxdateyear {
  border: 1px solid var(--gray-2);
  font-family: var(--p1-regular-16);
  font-size: var(--p1-regular-16-size);
  background-color: var(--white);
  position: absolute;
  top: 0;
  left: 0.5px;
  box-shadow: var(--shadow-1);
  box-sizing: border-box;
  width: 126px;
  overflow: hidden;
  flex-direction: row;
  /* padding: var(--padding-4xs) var(--padding-25xl); */
 /*  padding: var(--padding-4xs);
  align-items: center;
  justify-content: center;
}
.inputtextboxdateyear-wrapper {
  position: relative;
  width: 365px;
  height: 42px;
}
.name-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 147px;
}
.quiz-question1 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  padding: var(--padding-3xl) var(--padding-35xl);
  justify-content: flex-start;
}
.inputtextboxdateyear3,
.name-container,
.quiz-question1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.name-container {
  justify-content: flex-start;
  gap: 170px;
}
.inputtextboxdateyear3 {
  border: 1px solid var(--gray-2);
  font-family: var(--p1-regular-16);
  font-size: var(--p1-regular-16-size);
  background-color: var(--white);
  position: absolute;
  top: 0;
  left: 145.5px;
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-1);
  box-sizing: border-box;
  width: 126px;
  overflow: hidden;
  padding: var(--padding-4xs) var(--padding-25xl);
  justify-content: center;
}
.name-parent1,
.name-parent3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-285xl);
}
.name-parent3 {
  gap: var(--gap-336xl);
}
.multiselectdefault1 {
  border: 1px solid var(--gray-3);
  background-color: var(--white);
  position: relative;
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-1);
  box-sizing: border-box;
  width: 373px;
  height: 42px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
align-items: center;
}
.name-parent5 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-232xl);
}
.name-parent5,
.name-parent6,
.quiz-question7 {
  display: flex;
  justify-content: flex-start;
}
.quiz-question7 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-34xl) var(--padding-xl)
    var(--padding-30xl);
  align-items: flex-start;
}
.name-parent6 {
  flex-direction: row;
  align-items: center;
  gap: 287px;
}
.name10 {
  position: relative;
  line-height: 150%;
  display: inline-block;
  width: 143px;
  flex-shrink: 0;
}
.name-parent7,
.scholarship-questions-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-232xl);
}
.scholarship-questions-2 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  flex-direction: column;
  padding: var(--padding-17xl) var(--padding-13xl);
  justify-content: center;
  gap: var(--gap-lg);
}
.exit {
  position: relative;
  font-size: var(--p1-regular-16-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--white);
  text-align: left;
}
.exit-wrapper {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini) var(--padding-52xl);
  background-color: var(--royal-blue);
  border-radius: var(--br-5xs);
  width: 169px;
  height: 54px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.vector-icon91 {
  position: relative;
  width: 10.95px;
  height: 18.95px;
}
.vector-wrapper {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mid) var(--padding-55xl);
  background-color: var(--royal-blue);
  border-radius: var(--br-5xs);
  width: 163.14px;
  height: 53.12px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  box-sizing: border-box;
}
.body12,
.form-body,
.form-body-inner,
.frame-parent17,
.vector-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.frame-parent17 {
  flex-direction: row;
  gap: var(--gap-140xl);
}
.body12,
.form-body,
.form-body-inner {
  overflow: hidden;
  flex-direction: column;
}
.form-body-inner {
  width: 1445px;
  height: 227px;
  flex-shrink: 0;
  padding: var(--padding-6xl) var(--padding-243xl) var(--padding-73xl);
  box-sizing: border-box;
}
.body12,
.form-body {
  align-self: stretch;
  font-size: var(--p1-regular-16-size);
}
.body12 {
  font-size: var(--h1-bold-52-size);
}
.footerlogo-icon12 {
  position: relative;
  width: 148px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link36 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}
.footerlinknav36 {
  position: relative;
  width: 160px;
  height: 19px;
}
.footer-links60 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.footericoninstagram12 {
  position: relative;
  width: 20px;
  height: 20px;
}
.footericonfacbook12 {
  position: relative;
  width: 18.75px;
  height: 18.75px;
}
.footericontwitter12 {
  position: relative;
  width: 19.5px;
  height: 15.84px;
}
.footericonyoutube12 {
  position: relative;
  width: 21px;
  height: 14.72px;
}
.icon-wrapper12 {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.footer-menu60 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.students12 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 160px;
}
.footer-link156 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  display: inline-block;
}
.main-footer-link156 {
  position: relative;
  width: 160px;
  height: 24px;
}
.footer-menu61 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.footer-link168 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
}
.main-footer-link168 {
  position: relative;
  width: 160px;
  height: 71px;
  font-size: var(--p2-regular-12-size);
}
.text-box11 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-7xs);
  background-color: var(--white);
  box-shadow: var(--shadow-1);
}
.input-placeholder11 {
  position: absolute;
  top: 4px;
  left: 10px;
  font-weight: 500;
  display: inline-block;
  width: 181px;
  height: 25px;
}
.subscribe-email-text-box12 {
  position: relative;
  width: 198px;
  height: 28px;
  color: var(--gray-4);
}
.button-text84,
.button49 {
  position: absolute;
  width: 100%;
  left: 0;
}
.button49 {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-5xs);
  background-color: var(--purple-blue);
}
.button-text84 {
  height: 95%;
  top: 2.5%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonlarge33 {
  position: relative;
  width: 198px;
  height: 32px;
  text-align: center;
}
.footer-grid-row12,
.footer-links64,
.footer-menu-parent10 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-links64 {
  flex-direction: column;
  gap: var(--gap-5xs);
  font-size: var(--h5-btns-med-14-size);
}
.footer-grid-row12,
.footer-menu-parent10 {
  flex-direction: row;
  gap: var(--gap-69xl);
}
.footer-grid-row12 {
  justify-content: center;
}
.scholist-all-rights12 {
  position: relative;
  line-height: 150%;
}
.footer-legal12 {
  width: 827px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-365xl);
}
.education-profile,
.footer12 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer12 {
  align-self: stretch;
  background-color: var(--gray-4);
  padding: var(--padding-63xl) var(--padding-105xl) var(--padding-20xl);
  align-items: flex-start;
  gap: var(--gap-62xl);
  font-size: var(--p1-regular-16-size);
  color: var(--gray-1);
}
.education-profile {
  position: relative;
  background-color: var(--white);
  width: 100%;
  align-items: center;
  gap: var(--gap-11xs);
  text-align: left;
  font-size: var(--h5-btns-med-14-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
}


input:invalid ~ span{
  display: block;
}
@media screen and (max-width: 1200px) {
  .menu12 {
    display: none;
  }
  .education-wrapper,
  .personal-information-wrapper {
    width: 222px;
  }
  .frame-parent16 {
    gap: var(--gap-16xl);
  }
  .name-parent {
    gap: var(--gap-111xl);
  }
  .name-group {
    gap: var(--gap-61xl);
  }
  .name-container {
    gap: var(--gap-96xl);
  }
  .name-parent1 {
    gap: var(--gap-161xl);
  }
  .name-parent3 {
    gap: var(--gap-171xl);
  }
  .name-parent5,
  .name-parent6 {
    gap: var(--gap-111xl);
  }
  .name-parent7 {
    gap: var(--gap-61xl);
  }
  .scholarship-questions-2 {
    width: 700px;
  }
  .exit-wrapper {
    width: 90%;
  }
  .form-body {
    justify-content: center;
  }
  .footer-menu-parent10 {
    flex-wrap: wrap;
  }
  .footer-grid-row12 {
    flex-direction: column;
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 850px) {
  .top-nav-left12 {
    display: none;
  }
  .menu12 {
    display: flex;
  }
  .messages-parent9 {
    display: none;
  }
  .scholist-profile {
    font-size: var(--h2-bold-32-size);
  }
  .personal-information {
    font-size: var(--font-size-3xs);
  }
  .education-wrapper,
  .personal-information-wrapper {
    width: 150px;
  }
  .student-activities {
    font-size: var(--font-size-3xs);
  }
  .frame-parent16 {
    gap: var(--gap-xl);
  }
  .name1 {
    font-size: var(--h5-btns-med-14-size);
  }
  .name-parent {
    flex-direction: column;
    gap: var(--gap-8xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .quiz-question {
    padding-top: var(--padding-3xs);
    box-sizing: border-box;
  }
  .inputtextboxdateyear {
    height: 37px;
  }
  .name-group {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .quiz-question1 {
    padding-top: var(--padding-xs);
    padding-bottom: var(--padding-3xl);
    box-sizing: border-box;
  }
  .name-container {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .inputtextboxdateyear3 {
    height: 37px;
  }
  .name-parent1,
  .name-parent3,
  .name-parent5 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .name-parent5 {
    gap: var(--gap-8xs);
  }
  .quiz-question7 {
    padding-top: var(--padding-3xs);
    box-sizing: border-box;
  }
  .name-parent6 {
    flex-direction: column;
    gap: var(--gap-8xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .name10 {
    font-size: var(--h5-btns-med-14-size);
  }
  .name-parent7 {
    flex-direction: column;
    gap: var(--gap-8xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .scholarship-questions-2 {
    width: 450px;
  }
  .exit-wrapper {
    width: 30%;
  }
  .frame-parent17 {
    gap: var(--gap-130xl);
  }
}
@media screen and (max-width: 520px) {
  .personal-information {
    font-size: var(--font-size-5xs);
  }
  .education-wrapper,
  .personal-information-wrapper {
    width: 120px;
  }
  .student-activities {
    font-size: var(--font-size-5xs);
  }
  .frame-parent16 {
    gap: var(--gap-6xs);
  }
  .multiselectdefault {
    width: 303px;
  }
  .name-parent {
    gap: var(--gap-8xs);
  }
  .quiz-question {
    padding-left: var(--padding-4xl);
    padding-right: var(--padding-4xl);
    box-sizing: border-box;
  }
  .inputtextboxdateyear {
    width: 105px;
    height: 32px;
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
  }
  .inputtextboxdateyear-wrapper {
    width: 305px;
  }
  .quiz-question1 {
    padding-left: var(--padding-5xl);
    padding-right: var(--padding-5xl);
    box-sizing: border-box;
  }
  .inputtextboxdateyear3 {
    width: 105px;
    height: 32px;
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
  }
  .multiselectdefault1 {
    width: 313px;
  }
  .name-parent5 {
    gap: var(--gap-8xs);
  }
  .quiz-question7 {
    padding-left: var(--padding-4xl);
    padding-right: var(--padding-4xl);
    box-sizing: border-box;
  }
  .name-parent6,
  .name-parent7 {
    gap: var(--gap-8xs);
  }
  .scholarship-questions-2 {
    display: flex;
    width: 350px;
  }
  .exit-wrapper {
    width: 129px;
  }
  .vector-wrapper {
    width: 20px;
  }
  .frame-parent17 {
    gap: 70px;
  }
  .footer12 {
    padding-top: var(--padding-63xl);
    box-sizing: border-box;
  }
}
*/
:root {
  /* fonts */
  --p1-regular-16: Poppins;

  /* font sizes */
  --p1-regular-16-size: 16px;
  --font-size-sm: 14px;
  --h6-medium-12-size: 12px;
  --font-size-5xs: 8px;
  --h2-bold-32-size: 32px;
  --font-size-mid: 17px;
  --font-size-5xl: 24px;
  --font-size-mini: 15px;
  --font-size-29xl: 48px;
  --font-size-21xl: 40px;
  --font-size-9xl: 28px;
  --font-size-3xl: 22px;
  --h3-medium-20-size: 20px;
  --font-size-2xl-6: 21.6px;
  --font-size-3xs: 10px;
  --font-size-2xs: 11px;
  --font-size-4xs: 9px;
  --font-size-lg-8: 18.8px;
  --font-size-2xl: 21px;
  --font-size-4xl-2: 23.2px;
  --font-size-lgi: 19px;
  --font-size-17xl: 36px;
  --font-size-3xl-7: 22.7px;
  --font-size-smi: 13px;
  --font-size-4xl-4: 23.4px;
  --font-size-lg: 18px;
  --font-size-lg-9: 18.9px;
  --font-size-9xl-1: 28.1px;

  /* Colors */
  --white: #fff;
  --gray-3: #c4c6c7;
  --color-silver-100: #bbb;
  --color-black: #000;
  --gray-4: #79797d;
  --color-gray-100: #787878;
  --logo-black: #010101;
  --color-gray-200: rgba(255, 255, 255, 0.5);
  --gray-2: #e7e7e7;
  --royal-blue: #0d0ed9;
  --gray-1: #f2f2f2;
  --color-khaki: #f8d665;
  --color-tomato: #ed5043;
  --gray-5: #393939;
  --color-darkslategray-100: #263238;
  --purple-blue: #5558cf;
  --color-steelblue-100: #4a93cf;
  --teal: #40cfc3;
  --color-mediumaquamarine: #70d499;

  /* Gaps */
  --gap-11xs: 2px;
  --gap-lg: 18px;
  --gap-111xl: 130px;
  --gap-8xs: 5px;
  --gap-5xs: 8px;
  --gap-13xl: 32px;
  --gap-3xs: 10px;
  --gap-43xs: -30px;
  --gap-192xl: 211px;
  --gap-61xl: 80px;
  --gap-254xl: 273px;
  --gap-28xl: 47px;
  --gap-xs: 12px;
  --gap-6xs: 7px;
  --gap-53xl: 72px;
  --gap-31xl: 50px;
  --gap-9xs: 4px;
  --gap-7xs: 6px;
  --gap-9xl: 28px;
  --gap-11xl: 30px;
  --gap-mini: 15px;
  --gap-3xl: 22px;
  --gap-0: 0px;
  --gap-base: 16px;
  --gap-10xs: 3px;
  --gap-3xs-4: 9.4px;
  --gap-2xs: 11px;
  --gap-mini-1: 14.1px;

  /* Paddings */
  --padding-17xl: 36px;
  --padding-13xl: 32px;
  --padding-xl: 20px;
  --padding-34xl: 53px;
  --padding-3xs: 10px;
  --padding-9xs: 4px;
  --padding-25xl: 44px;
  --padding-5xs: 8px;
  --padding-175xl: 194px;
  --padding-69xl: 88px;
  --padding-81xl: 100px;
  --padding-11xl: 30px;
  --padding-31xl: 50px;
  --padding-mid: 17px;
  --padding-53xl: 72px;
  --padding-8xs: 5px;
  --padding-52xl: 71px;
  --padding-50xl: 69px;
  --padding-6xs: 7px;
  --padding-xs: 12px;
  --padding-7xs: 6px;
  --padding-9xl: 28px;
  --padding-23xl: 42px;
  --padding-181xl: 200px;
  --padding-4xs: 9px;
  --padding-28xl: 47px;
  --padding-mini: 15px;
  --padding-smi: 13px;
  --padding-61xl: 80px;
  --padding-84xl: 103px;
  --padding-4xs-2: 8.2px;
  --padding-55xl: 74px;
  --padding-42xl: 61px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-7xs: 6px;
  --br-3xs: 10px;
  --br-9xs: 4px;
  --br-xs: 12px;
  --br-3xs-4: 9.4px;
  --br-4xs: 9px;
  --br-3xs-5: 9.5px;
}

.submitFormButton{
 
display: flex;
width: 169px;
height: 54px;
padding: 15px 71px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
background: #0D0ED9;
}

.submitbuttontext{
  color: #FFF;
/* H4 - Medium 16px */
 font-size: 16px;
font-family: Poppins;
font-weight: 500;
}

.submitbuttonbody{
  display: flex;
width: 1445px;
height: 227px;
padding: 25px 262px 92px 262px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;

}


.textInput {
  justify-content: center;
  align-items: center;
  background-color: aqua;
}  


.profileeditbodyheader1 {
  width: 1446px;
  height: 1px;
  overflow: hidden;
  flex-shrink: 0;
}
.editprofileformboxtext6 {
  position: relative;
  line-height: 150%;
  display: inline-block;
  width: 373px;
  flex-shrink: 0;
}
.editprofileformboxinputbox6 {
  border: 1px solid var(--gray-3);
  font-family: var(--p1-regular-16);
  font-size: var(--p1-regular-16-size);
  background-color: var(--white);
  position: relative;
  border-radius: var(--br-5xs);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  width: 373px;
  height: 42px;
  overflow: hidden;
  flex-shrink: 0;
}
.editprofileform6,
.editprofileformbox6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.editprofileform6 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-34xl);
  align-items: flex-start;
}
.editprofileformboxinputbox7,
.inputtextboxdateyear {
  border-radius: var(--br-5xs);
  background-color: var(--white);
  overflow: hidden;
}
.inputtextboxdateyear {
  border: 1px solid var(--gray-2);
  font-family: var(--p1-regular-16);
  font-size: var(--p1-regular-16-size);
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  width: 126px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) ;
  align-items: center;
  justify-content: center;
}
.editprofileformboxinputbox7 {
  position: relative;
  width: 373px;
  height: 42px;
  flex-shrink: 0;
}
.editprofileformboxinputbox10,
.inputtextboxdateyear2,
.inputtextboxdateyear3 {
  background-color: var(--white);
  border-radius: var(--br-5xs);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}
.editprofileformboxinputbox10 {
  position: relative;
  border: 1px solid var(--gray-3);
  width: 373px;
  height: 42px;
}
.inputtextboxdateyear2,
.inputtextboxdateyear3 {
  border: 1px solid var(--gray-2);
  font-family: var(--p1-regular-16);
  font-size: var(--p1-regular-16-size);
  width: 115px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) 0;
  align-items: center;
  justify-content: center;
}
.inputtextboxdateyear-parent {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.scholarship-questions-21 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  display: flex;
  flex-direction: column;
  padding: var(--padding-17xl) var(--padding-13xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-lg);
}
.profileeditbodybox1 {
  align-self: stretch;
}
.education-profile,
.profileeditbodybox,
.profileeditbodybox1 {
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}
.profileeditbodybox {
  align-self: stretch;
  height: 1145px;
  flex-shrink: 0;
}
.education-profile {
  background-color: var(--white);
  height: 1353px;
  gap: var(--gap-11xs);
  text-align: left;
  font-size: var(--p1-regular-16-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
}
@media screen and (max-width: 1200px) {
  .editprofileformboxtext6 {
    width: 200px;
  }
  .editprofileformboxinputbox6 {
    width: 253px;
  }
  .editprofileformbox6 {
    gap: var(--gap-111xl);
  }
  .editprofileformboxinputbox10,
  .editprofileformboxinputbox7 {
    width: 253px;
  }
  .inputtextboxdateyear2 {
    align-items: flex-end;
    justify-content: center;
    padding-right: 0;
  }
  .inputtextboxdateyear2,
  .inputtextboxdateyear3 {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .scholarship-questions-21 {
    width: 700px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .profileeditbodybox1 {
    justify-content: center;
  }
}
@media screen and (max-width: 850px) {
  .submitFormButton{
 
    
    width: 109px;
    height: 37px;
    padding: 15px 51px;
 
    }
  .editprofileformboxtext6 {
    font-size: var(--font-size-sm);
    width: 173px;
  }
  .editprofileformboxinputbox6 {
    width: 343px;
  }
  .editprofileformbox6 {
    flex-direction: column;
    gap: var(--gap-8xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .editprofileform6 {
    padding-top: var(--padding-3xs);
    box-sizing: border-box;
  }
  .inputtextboxdateyear {
    height: 37px;
  }
  .editprofileformboxinputbox10,
  .editprofileformboxinputbox7 {
    width: 343px;
  }
  .inputtextboxdateyear2,
  .inputtextboxdateyear3 {
    height: 37px;
  }
  .scholarship-questions-21 {
    width: 450px;
  }
}
@media screen and (max-width: 520px) {

  .editprofileformboxinputbox6 {
    width: 273px;
  }
  .editprofileformbox6 {
    gap: var(--gap-8xs);
  }
  .inputtextboxdateyear {
    width: 105px;
    height: 32px;
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
  }
  .editprofileformboxinputbox10,
  .editprofileformboxinputbox7 {
    width: 273px;
  }
  .inputtextboxdateyear2,
  .inputtextboxdateyear3 {
    width: 105px;
    height: 32px;
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
  }
  .scholarship-questions-21 {
    display: flex;
    width: 350px;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
