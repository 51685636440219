.App {
    
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  background-color: var(--white);
  width: 100%;
  align-items: center;
  text-align: left;
  font-size: var(--h5-btns-med-14-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);

}