.leftsavedscholarshipnotimgbox-icon {
    position: relative;
    width: 48.55px;
    height: 48.55px;
    object-fit: cover;
  }
  .be-bold-no-essay {
    position: relative;
    line-height: 120%;
    display: flex;
    align-items: flex-end;
    width: 164px;
    flex-shrink: 0;
  }
  .leftsavedscholarshipnotificati {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-7xs);
  }
  .rightsavedscholarshipnotboxsav {
    position: relative;
    line-height: 120%;
  }
  .rightsavedscholarshipnoticon {
    position: relative;
    width: 26.03px;
    height: 24.22px;
  }
  .rightsavedscholarshipnotbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    font-size: 21.58px;
  }
  .savedscholarshipnotificationbo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-9xs);
  }
  .savedscholarshipnotification {
    position: relative;
    border-radius: var(--br-5xs);
    background-color: var(--color-white);
    border: 1px solid var(--color-silver-100);
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-xs) var(--padding-smi);
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
    max-height: 100%;
    text-align: left;
    font-size: 14.03px;
    color: var(--logo-black);
    font-family: var(--h6-medium-12);
  }
  @media screen and (max-width: 850px) {
    .leftsavedscholarshipnotimgbox-icon {
      width: 35px;
      height: 35px;
    }
    .be-bold-no-essay {
      font-size: var(--font-size-3xs);
    }
    .rightsavedscholarshipnotboxsav {
      font-size: var(--font-size-mid);
    }
    .rightsavedscholarshipnoticon {
      width: 21px;
      height: 19px;
    }
  }
  