.coachs-dashboard {
  display: flex;
  height: 110vh;
  background-color: var(--gray-0.2);
}

/* Main content styling */
.dashboard-main-content {
  padding: 20px;
  width: 75%;
  overflow-y: auto;
}

.comm-stat h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15.2873px;

  color: #7a7a7a;
}

.comm-stat2 h3 {
  color: #7a7a7a;
  margin-top: 3%;
  margin-bottom: 2%;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15.2873px;
}

.welcome-section {
  text-align: left;
  margin-top: 33px;
  margin-left: 25px;
  font-size: 28px;
  padding-bottom: 8px;
  /* border-bottom: 1px solid #ddd; */
  margin-bottom: 20px;
}

.welcome-section h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
}

.coach-name {
  color: #4a93cf;
}

.community-stats {
  display: flex;
  gap: 5%;
  padding: 20px;
  margin-left: 1%;
  margin-bottom: 20px;
  justify-content: space-between; 
}

.start-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 160px;
  width: 190px;
  background-color: #ffffff;
  border-radius: 10px; /* Rounded corners */
  padding: 15px 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.icon {
  font-size: 28px;
  color: #007bff;
  margin-bottom: 10px;
}

.book-icon {
  margin-top: 8%;
}

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20.0522px;
  display: flex;
  align-items: center;

  color: #4a93cf;
}

.value {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24.4172px;
  display: flex;
  align-items: center;
  margin: 4%;

  color: #6bc844;
}

.value2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 19.8574px;
  margin-top: 4%;
  display: flex;
  align-items: center;

  color: #4a93cf;
}

.currency {
  color: #6bc844;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18.0522px;
}

.description {
  font-size: 14px;
  color: #7a7a7a;
  margin-bottom: 2%;
}

.icon,
.icon-books .fa-book {
  padding-top: 12%;
  font-size: 55px;
  color: #4a93cf;
  margin-bottom: 8px;
}

/* Specific styling for the two book icons */
.icon-books {
  display: flex;
  align-items: center;
  gap: 6px; /* Space between the two book icons */
}

.upcoming-events {
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  gap: 10px;
}

.up-event {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  height: 88px;
  margin-bottom: 2%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: transform 0.2s ease-in-out;
}

.event-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #4a93cf;
  color: #ffffff;
  width: 70px;
  height: 70px;
  margin-left: 3%;
  border-radius: 50%;
  margin-right: 15px;
}

.event-month {
  font-size: 14px;
  font-weight: bold;
}

.event-day {
  font-size: 18px;
  font-weight: bold;
}

.event-details {
  flex: 1;
}

.event-details h4 {
  margin: 0 0 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;

  color: #393939;
}

.event-details p {
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12.406px;

  color: #7a7a7a;
}

.profile-completion {
  margin-top: 14%;
  margin-right: 4%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Push title and progress circle apart */
  margin-bottom: 15px;
}

.profile-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22.9267px;
  color: #4a93cf;
}

.profile-comp p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10.8022px;
  color: #7a7a7a;
  margin-bottom: 15px;
}

.profile-comp p b {
  color: black;
}

.profile-completion ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profile-completion li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.profile-completion li .completed-task::before,
.profile-completion li .pending-task::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.profile-completion li .completed-task::before {
  background-color: green;
}

.profile-completion li .pending-task::before {
  background-color: gray;
}

.progress-bar {
  width: 60px;
  height: 60px;
}

.progress-circle {
  width: 100%;
  height: 100%;
}

.completed-task,
.pending-task {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12.8022px;
  align-items: center;
  color: #7a7a7a;
}

.pending-task span {
  color: #4a93cf;
}

.header {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.progress-circle {
  width: 55px;
  height: 55px;
}

.calendars-container {
  width: 350px;
  margin: 0 auto;
  margin-left: 20px;
  margin-right: 20px;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
}

.react-calendar {
  border: none;
  font-size: 16px;
}

/* Header section containing the Month, Year, and Navigation Keys */
.react-calendar__navigation {
  background-color: #b3d4fc;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

/* Style for the navigation buttons (prev/next arrows) */
.react-calendar__navigation button {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.react-calendar__tile {
  color: #4a93cf;
  position: relative;
  text-align: center;
  padding: 15px 0;
  background: none;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15.8022px;
}

.react-calendar__tile:enabled:hover {
  background-color: #f0f0f0;
}

.react-calendar__tile--now {
  background-color: transparent;
  font-weight: bold;
  color: #000;
}

.react-calendar__tile--active {
  background-color: #b3d4fc;
  border-radius: 50%;
  color: white;
}

.react-calendar__month-view__weekdays {
  color: #7a7a7a;
  text-decoration: none;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.dot {
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  background-color: green;
  border-radius: 50%;
}

.datet {
  margin-top: 28%;
}

.datet2 {
  margin-top: 16%;
}
