.leftsavedcoachnotificationbox-icon {
    position: relative;
    width: 46.72px;
    height: 46.96px;
    object-fit: cover;
    border-radius: 60px;
  }
  .rightsavedcoachnotboxsavedtext,
  .rightsavedcoachnotboxsavedtext1 {
    position: relative;
    line-height: 120%;
  }
  .rightsavedcoachnotboxsavedtext1 {
    color: var(--color-black);
  }
  .leftsavedcoachnotificationbox,
  .rightsavedcoachnotbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-7xs);
  }
  .leftsavedcoachnotificationbox {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .rightsavedcoachnoticonboxtext {
    position: relative;
    line-height: 120%;
  }
  .rightsavedcoachnotbookmarkicon {
    position: relative;
    width: 20.46px;
    height: 28px;
  }
  .rightsavedcoachnoticonbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    font-size: 21.58px;
  }
  .savedcoachnotificationbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-9xs);
  }
  .savedcoachnotification {
    position: relative;
    border-radius: var(--br-5xs);
    background-color: var(--color-white);
    border: 1px solid var(--color-silver-100);
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-xs) var(--padding-smi);
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
    max-height: 100%;
    text-align: left;
    font-size: 14.03px;
    color: var(--logo-black);
    font-family: var(--h6-medium-12);
  }
  @media screen and (max-width: 850px) {
    .leftsavedcoachnotificationbox-icon {
      width: 36px;
      height: 35px;
    }
    .rightsavedcoachnotboxsavedtext,
    .rightsavedcoachnotboxsavedtext1 {
      font-size: var(--font-size-2xs);
    }
    .rightsavedcoachnoticonboxtext {
      font-size: var(--font-size-mid);
    }
    .rightsavedcoachnotbookmarkicon {
      width: 16px;
      height: 24px;
    }
  }
  