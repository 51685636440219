@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h4-medium-16: Poppins;

  /* font sizes */
  --h4-medium-16-size: 16px;
  --font-size-smi-4: 12.4px;
  --h5-btns-med-14-size: 14px;
  --h3-medium-20-size: 20px;
  --h2-bold-32-size: 32px;
  --h6-medium-12-size: 12px;
  --font-size-smi: 13px;
  --font-size-2xs: 11px;
  --font-size-5xl: 24px;
  --font-size-lg: 18px;
  --font-size-lg-4: 18.4px;
  --font-size-5xs: 8px;
  --font-size-mid: 17px;
  --font-size-mini: 15px;
  --font-size-29xl: 48px;
  --font-size-21xl: 40px;
  --font-size-9xl: 28px;
  --font-size-3xl: 22px;
  --font-size-2xl-6: 21.6px;
  --font-size-3xs: 10px;
  --font-size-4xs: 9px;
  --font-size-lg-8: 18.8px;
  --font-size-2xl: 21px;
  --font-size-4xl-2: 23.2px;
  --font-size-lgi: 19px;
  --font-size-17xl: 36px;
  --font-size-3xl-7: 22.7px;
  --font-size-4xl-4: 23.4px;
  --font-size-lg-9: 18.9px;
  --font-size-9xl-1: 28.1px;

  /* Colors */
  --gray-5: #393939;
  --color-darkslategray-100: #263238;
  --color-gray-100: #898989;
  --gray-4: #79797d;
  --color-gray-200: #787878;
  --logo-black: #010101;
  --color-gray-300: rgba(255, 255, 255, 0.5);
  --teal: #40cfc3;
  --reviews: #deb00f;
  --white: #fff;
  --logo-color-2: #4a93cf;
  --color-silver-100: #c4c6c7;
  --color-silver-200: #bbb;
  --color-black: #000;
  --purple-blue: #5558cf;
  --color-darkgray-100: #959595;
  --gray-1: #f2f2f2;
  --gray-2: #e7e7e7;
  --royal-blue: #0d0ed9;
  --color-khaki: #f8d665;
  --color-tomato: #ed5043;
  --color-mediumaquamarine: #70d499;

  /* Gaps */
  --gap-smi-4: 12.4px;
  --gap-6xs-2: 6.2px;
  --gap-sm-1: 13.1px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-lgi: 19px;
  --gap-3xs: 10px;
  --gap-mini: 15px;
  --gap-9xs: 4px;
  --gap-9xl: 28px;
  --gap-11xl: 30px;
  --gap-185xl: 204px;
  --gap-141xl: 160px;
  --gap-235xl: 254px;
  --gap-81xl: 100px;
  --gap-xl: 20px;
  --gap-3xs-2: 9.2px;
  --gap-lg: 18px;
  --gap-111xl: 130px;
  --gap-8xs: 5px;
  --gap-13xl: 32px;
  --gap-11xs: 2px;
  --gap-43xs: -30px;
  --gap-192xl: 211px;
  --gap-61xl: 80px;
  --gap-254xl: 273px;
  --gap-28xl: 47px;
  --gap-xs: 12px;
  --gap-6xs: 7px;
  --gap-53xl: 72px;
  --gap-31xl: 50px;
  --gap-7xs: 6px;
  --gap-3xl: 22px;
  --gap-0: 0px;
  --gap-10xs: 3px;
  --gap-3xs-4: 9.4px;
  --gap-2xs: 11px;
  --gap-mini-1: 14.1px;

  /* Paddings */
  --padding-mid: 17px;
  --padding-4xs: 9px;
  --padding-3xs: 10px;
  --padding-sm: 14px;
  --padding-4xl: 23px;
  --padding-118xl: 137px;
  --padding-57xl: 76px;
  --padding-77xl: 96px;
  --padding-8xs: 5px;
  --padding-10xs: 3px;
  --padding-5xs: 8px;
  --padding-base: 16px;
  --padding-161xl: 180px;
  --padding-31xl: 50px;
  --padding-lgi: 19px;
  --padding-16xl: 35px;
  --padding-xl: 20px;
  --padding-6xs: 7px;
  --padding-mini: 15px;
  --padding-13xl: 32px;
  --padding-17xl: 36px;
  --padding-34xl: 53px;
  --padding-9xs: 4px;
  --padding-25xl: 44px;
  --padding-175xl: 194px;
  --padding-69xl: 88px;
  --padding-81xl: 100px;
  --padding-11xl: 30px;
  --padding-53xl: 72px;
  --padding-52xl: 71px;
  --padding-50xl: 69px;
  --padding-xs: 12px;
  --padding-7xs: 6px;
  --padding-9xl: 28px;
  --padding-23xl: 42px;
  --padding-181xl: 200px;
  --padding-28xl: 47px;
  --padding-smi: 13px;
  --padding-61xl: 80px;
  --padding-84xl: 103px;
  --padding-4xs-2: 8.2px;
  --padding-55xl: 74px;
  --padding-42xl: 61px;

  /* Border radiuses */
  --br-6xs-2: 6.2px;
  --br-5xs: 8px;
  --br-9xs: 4px;
  --br-6xs: 7px;
  --br-7xs: 6px;
  --br-3xs: 10px;
  --br-xs: 12px;
  --br-3xs-4: 9.4px;
  --br-4xs: 9px;
  --br-3xs-5: 9.5px;

  /* Effects */
  --shadow-1: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

/* 
.group-child {
    position: absolute;
    top: 5.32px;
    left: 5.32px;
    border-radius: 50%;
    background-color: #cbdff7;
    width: 28px;
    height: 28px;
  }
  .div5 {
    position: absolute;
    top: 21.2px;
    left: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26.91px;
    height: 27.83px;
    transform: rotate(-51.97deg);
    transform-origin: 0 0;
  }
  .ellipse-parent {
    position: relative;
    width: 38.5px;
    height: 38.34px;
  }
  .writemessagespopup-inner {
    width: 470px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    color: var(--logo-black);
  }
  .new-message {
    position: relative;
    font-weight: 500;
  }
  .new-message-wrapper {
    width: 401px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
  .writemessagesreceiverinputemai {
    border: 0;
    font-family: var(--h4-medium-16);
    font-size: var(--h4-medium-16-size);
    background-color: transparent;
    flex: 1;
    position: relative;
    color: var(--gray-4);
    text-align: left;
  }
  .writemessagesreceiverinput {
    border-radius: var(--br-9xs);
    border: 1px solid var(--color-silver-100);
    box-sizing: border-box;
    width: 401px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs) var(--padding-sm) var(--padding-3xs)
      var(--padding-4xl);
  }
  .writemessagesreceiverbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-mini);
  }
  .jessicahowesgmailcom-nsxbnnc {
    border: 0;
    background-color: transparent;
    font-family: var(--h4-medium-16);
    font-size: var(--h4-medium-16-size);
    flex: 1;
    position: relative;
    color: var(--gray-4);
    text-align: left;
    display: inline-block;
    width: 364px;
  }
  .writemessageinput,
  .writemessagesdetailsbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .writemessageinput {
    border-radius: var(--br-9xs);
    border: 1px solid var(--color-silver-100);
    box-sizing: border-box;
    width: 401px;
    height: 228px;
    padding: var(--padding-3xs) var(--padding-sm) 0 var(--padding-4xl);
  }
  .writemessagesdetailsbox {
    gap: var(--gap-3xs);
  }
  .writemessagessendtext {
    position: relative;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 153px;
    height: 24px;
    flex-shrink: 0;
  }
  .writemessagespopup,
  .writemessagessend {
    overflow: hidden;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* box-sizing: border-box; */
  /* }
  .writemessagessend {
    border-radius: var(--br-9xs);
    background-color: var(--logo-color-2);
    width: 401px;
    height: 42px;
    flex-shrink: 0;
    flex-direction: row;
    padding: var(--padding-4xs) 0;
    color: var(--logo-black);
  }
  .writemessagespopup {
    border-radius: 20px;
    background-color: var(--white);
    width: 569px;
    height: 612px;
    flex-direction: column;
    padding: 11px var(--padding-mid);
    gap: var(--gap-lgi);
    max-width: 100%;
    max-height: 100%;
    text-align: center;
    font-size: var(--h4-medium-16-size);
    color: var(--color-black);
    font-family: var(--h4-medium-16);
  }
  @media screen and (max-width: 850px) {
    .writemessagespopup-inner {
      width: 370px;
    }
    .new-message {
      font-size: var(--font-size-smi);
    }
    .new-message-wrapper {
      width: 350px;
    }
    .writemessagesreceiverinputemai {
      font-size: var(--font-size-smi);
    }
    .writemessagesreceiverinput {
      width: 301px;
    }
    .jessicahowesgmailcom-nsxbnnc {
      font-size: var(--font-size-smi);
      width: 264px;
    }
    .writemessageinput {
      width: 301px;
    }
    .writemessagessendtext {
      font-size: var(--font-size-smi);
    }
    .writemessagessend {
      width: 301px;
    }
    .writemessagespopup {
      width: 469px;
    }
  }
  @media screen and (max-width: 520px) {
    .writemessagespopup-inner {
      width: 270px;
    }
    .new-message {
      font-size: var(--font-size-2xs);
    }
    .new-message-wrapper {
      width: 265px;
    }
    .writemessagesreceiverinputemai {
      font-size: var(--font-size-2xs);
    }
    .writemessagesreceiverinput {
      width: 265px;
    }
    .jessicahowesgmailcom-nsxbnnc {
      font-size: var(--font-size-2xs);
      width: 204px;
    }
    .writemessageinput {
      width: 265px;
    }
    .writemessagessendtext {
      font-size: var(--font-size-2xs);
    }
    .writemessagessend {
      width: 265px;
    }
    .writemessagespopup {
      width: 369px;
    }
  }
   */ 
   .group-child {
    position: absolute;
    top: 5.32px;
    left: 5.32px;
    border-radius: 50%;
    background-color: var(--color-lavender);
    width: 28px;
    height: 28px;
  }
  .div {
    position: absolute;
    top: 21.2px;
    left: 0;
    font-size: var(--h4-medium-16-size);
    font-weight: 500;
    font-family: var(--h4-medium-16);
    color: var(--logo-black);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26.91px;
    height: 27.83px;
    transform: rotate(-51.97deg);
    transform-origin: 0 0;
  }
  .ellipse-parent {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    width: 38.5px;
    height: 38.34px;
  }
  .writemessagespopup-inner {
    width: 470px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .new-message {
    position: relative;
    font-weight: 500;
  }
  .new-message-wrapper {
    width: 401px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
  .writemessagesreceiverinputemai {
    border: 0;
    font-family: var(--h4-medium-16);
    font-size: var(--h4-medium-16-size);
    background-color: transparent;
    flex: 1;
    position: relative;
    color: var(--gray-4);
    text-align: left;
    height: 10px
  }
  .writemessagesreceiverinput {
    border-radius: var(--br-9xs);
    border: 1px solid var(--color-silver-100);
    box-sizing: border-box;
    width: 401px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs) var(--padding-sm) var(--padding-3xs)
      var(--padding-4xl);
  }
  .writemessagesreceiverbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-mini);
  }
  .jessicahowesgmailcom-nsxbnnc {
    border: 0;
    background-color: transparent;
    font-family: var(--h4-medium-16);
    font-size: var(--h4-medium-16-size);
    flex: 1;
    position: relative;
    color: var(--gray-4);
    text-align: left;
    display: inline-block;
    width: 364px;
    border: none;
  }
  .writemessageinput,
  .writemessagesdetailsbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .writemessageinput {
    border-radius: var(--br-9xs);
    border: 1px solid var(--color-silver-100);
    box-sizing: border-box;
    width: 401px;
    height: 228px;
    padding: var(--padding-3xs) var(--padding-sm) 0 var(--padding-4xl);
  }
  .writemessagesdetailsbox {
    gap: var(--gap-3xs);
  }
  .writemessagessendtext {
    position: relative;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 153px;
    height: 24px;
    flex-shrink: 0;
  }
  .writemessagespopup,
  .writemessagessendbutton {
    overflow: hidden;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  .writemessagessendbutton {
    border-radius: var(--br-9xs);
    background-color: var(--logo-color-2);
    width: 401px;
    height: 42px;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: center;
    padding: var(--padding-4xs) 0;
    color: var(--logo-black);
    border: none;
  }
  .writemessagespopup {
    border-radius: var(--br-xl);
    background-color: var(--white);
    width: 569px;
    height: 612px;
    flex-direction: column;
    justify-content: flex-start;
    padding: 11px var(--padding-mid);
    gap: var(--gap-lgi);
    max-width: 100%;
    max-height: 100%;
    text-align: center;
    font-size: var(--h4-medium-16-size);
    color: var(--color-black);
    font-family: var(--h4-medium-16);
  }
  @media screen and (max-width: 850px) {
    .writemessagespopup-inner {
      width: 370px;
    }
    .new-message {
      font-size: var(--font-size-smi);
    }
    .new-message-wrapper {
      width: 350px;
    }
    .writemessagesreceiverinputemai {
      font-size: var(--font-size-smi);
    }
    .writemessagesreceiverinput {
      width: 301px;
    }
    .jessicahowesgmailcom-nsxbnnc {
      font-size: var(--font-size-smi);
      width: 264px;
    }
    .writemessageinput {
      width: 301px;
    }
    .writemessagessendtext {
      font-size: var(--font-size-smi);
    }
    .writemessagessendbutton {
      width: 301px;
    }
    .writemessagespopup {
      width: 469px;
    }
  }
  @media screen and (max-width: 520px) {
    .writemessagespopup-inner {
      width: 270px;
    }
    .new-message {
      font-size: var(--font-size-2xs);
    }
    .new-message-wrapper {
      width: 265px;
    }
    .writemessagesreceiverinputemai {
      font-size: var(--font-size-2xs);
    }
    .writemessagesreceiverinput {
      width: 265px;
    }
    .jessicahowesgmailcom-nsxbnnc {
      font-size: var(--font-size-2xs);
      width: 204px;
    }
    .writemessageinput {
      width: 265px;
    }
    .writemessagessendtext {
      font-size: var(--font-size-2xs);
    }
    .writemessagessendbutton {
      width: 265px;
    }
    .writemessagespopup {
      width: 369px;
    }
  }
  .group-item {
    position: absolute;
    top: 5.32px;
    left: 5.32px;
    border-radius: 50%;
    background-color: var(--color-lavender);
    width: 28px;
    height: 28px;
  }
  .div1 {
    position: absolute;
    top: 21.2px;
    left: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26.91px;
    height: 27.83px;
    transform: rotate(-51.97deg);
    transform-origin: 0 0;
  }
  .ellipse-group {
    position: relative;
    width: 38.5px;
    height: 38.34px;
  }
  .writemessagespopupsent-inner {
    width: 470px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .message-sent {
    position: relative;
  }
  .message-sent-wrapper,
  .writemessagespopupsent {
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .message-sent-wrapper {
    flex-direction: row;
    justify-content: center;
    padding: 116px 59px;
    font-size: var(--h2-bold-32-size);
    color: var(--color-black);
  }
  .writemessagespopupsent {
    border-radius: var(--br-xl);
    background-color: var(--white);
    width: 569px;
    height: 612px;
    flex-direction: column;
    justify-content: flex-start;
    padding: 41px var(--padding-mid);
    box-sizing: border-box;
    gap: var(--gap-lgi);
    max-width: 100%;
    max-height: 100%;
    text-align: center;
    font-size: var(--h4-medium-16-size);
    color: var(--logo-black);
    font-family: var(--h4-medium-16);
  }
  @media screen and (max-width: 850px) {
    .writemessagespopupsent-inner {
      width: 370px;
    }
    .writemessagespopupsent {
      width: 469px;
    }
  }
  @media screen and (max-width: 520px) {
    .writemessagespopupsent-inner {
      width: 270px;
    }
    .writemessagespopupsent {
      width: 369px;
    }
  }
  