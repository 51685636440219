.editpasswordexiticon2 {
    position: absolute;
    top: 5.32px;
    left: 5.32px;
    border-radius: 50%;
    background-color: var(--color-lavender);
    width: 28px;
    height: 28px;
  }
  .editpasswordexiticon1 {
    position: absolute;
    top: 21.2px;
    left: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26.91px;
    height: 27.83px;
    transform: rotate(-51.97deg);
    transform-origin: 0 0;
  }
  .editpasswordexiticon {
    position: relative;
    width: 38.5px;
    height: 38.34px;
  }
  .editpassword {
    width: 427px;
    height: 48px;
    display: flex;
    flex-direction: column;
    padding: 0 var(--padding-5xl) 0 0;
    box-sizing: border-box;
    align-items: flex-end;
    justify-content: center;
  }
  .editpasswordcurrentpasswordbox2 {
    border: 0;
    font-family: var(--h2-bold-32);
    font-size: var(--h4-medium-16-size);
    background-color: transparent;
    position: relative;
    color: var(--color-black);
    text-align: left;
    display: flex;
    align-items: center;
    width: 137px;
    height: 24px;
    flex-shrink: 0;
  }
  .editpasswordcurrentpasswordbox-icon {
    position: relative;
    width: 18px;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .editpasswordcurrentpasswordbox1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-81xl);
  }
  .editpasswordcurrentpasswordbox {
    border-radius: var(--br-9xs);
    border: 1px solid var(--color-darkgray-100);
    display: flex;
    flex-direction: column;
    padding: var(--padding-8xs) var(--padding-3xs);
    align-items: center;
    justify-content: center;
  }
  .editpasswordcurrentpassword {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xs);
  }
  .editpasswordnewpassword {
    position: relative;
  }
  .editpasswordchangbody {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
  }
  .editpasswordchangebuttontext {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 279px;
    height: 35px;
  }
  .editpasswordchangebutton {
    position: absolute;
    top: 274px;
    left: 0;
    border-radius: var(--br-5xs);
    background-color: var(--logo-color-2);
    width: 279px;
    height: 35px;
    text-align: center;
    color: var(--white);
  }
  .editpasswordgroup {
    position: relative;
    width: 279px;
    height: 309px;
  }
  .edit-password,
  .editpasswordmainbody {
    display: flex;
    flex-direction: row;
    padding: 0 var(--padding-55xl);
    align-items: center;
    justify-content: center;
    text-align: left;
    color: var(--gray-4);
  }
  .edit-password {
    position: relative;
    border-radius: var(--br-5xs);
    background-color: var(--white);
    overflow: hidden;
    flex-direction: column;
    padding: var(--padding-smi) 0 var(--padding-22xl);
    box-sizing: border-box;
    align-items: flex-end;
    gap: var(--gap-3xs);
    max-width: 100%;
    max-height: 100%;
    text-align: center;
    font-size: var(--h4-medium-16-size);
    color: var(--logo-black);
    font-family: var(--h2-bold-32);
  }
  @media screen and (max-width: 520px) {
    .editpassword {
      width: 227px;
    }
    .editpasswordmainbody {
      padding-left: var(--padding-25xl);
      padding-right: var(--padding-25xl);
      box-sizing: border-box;
    }
    .edit-password {
      width: 378px;
      height: 396px;
    }
  }
  