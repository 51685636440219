.newmessageboxcloseicon2 {
  position: absolute;
  top: 0.07px;
  left: 0;
  border-radius: 50%;
  background-color: #cbdff7;
  width: 28px;
  height: 28px;
}
.newmessageboxcloseicon1 {
  position: absolute;
  top: 0;
  left: 0.48px;
  font-size: var(--h4-medium-16-size);
  font-weight: 500;
  font-family: var(--h4-medium-16);
  color: var(--logo-black);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26.91px;
  height: 27.83px;
}
.newmessageboxcloseicon {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 28px;
  height: 28.07px;
}
.newmessageboxcloseicon-wrapper {
  align-self: stretch;
  background-color: var(--white);
  height: 58px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.newmessageboxheadertext {
  position: relative;
  font-weight: 500;
}
.newmessageboxheadertext-wrapper {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  color: var(--color-black);
}
.newmessageboxheaderinputbox1,
.newmessagetoheaderbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.newmessagetoheaderbox {
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-61xl);
  text-align: center;
  color: var(--color-black);
}
.newmessageboxheaderinputbox1 {
  border: 1px solid var(--gray-3);
  font-family: var(--h4-medium-16);
  font-size: var(--h4-medium-16-size);
  background-color: transparent;
  flex: 1;
  box-sizing: border-box;
  height: 44px;
  padding: 0 0 0 16px;
  color: black;
}
.newmessageboxinputbutton,
.newmessageboxinputbuttontext {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newmessageboxinputbuttontext {
  position: relative;
  width: 104px;
  flex-shrink: 0;
}
.newmessageboxinputbutton {
  border-radius: var(--br-9xs);
  background-color: var(--logo-color-2);
  flex-direction: row;
}
.newmessageboxsearchnext,
.newmessageboxsearchnext1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.newmessageboxsearchnext1 {
  flex: 1;
  gap: var(--gap-3xs);
}
.newmessageboxsearchnext {
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-61xl);
  text-align: center;
  color: var(--white);
}
.newmessageboxcontactprofile-icon {
  position: relative;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.newmessageboxcontactprofilenam {
  align-self: stretch;
  position: relative;
  display: flex;
  align-items: center;
  height: 16px;
  flex-shrink: 0;
}
.newmessagecontactpnamesummary {
  align-self: stretch;
  position: relative;
  font-size: var(--h5-btns-med-14-size);
  color: var(--gray-4);
}
.newmessageboxcontactprofile1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-10xs);
}
.newmessageboxcheck-icon {
  position: relative;
  width: 20px;
  height: 20px;
}
.newmessagelistbox1,
.newmessagelistbox2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.newmessagelistbox2 {
  flex: 1;
  justify-content: center;
  gap: var(--gap-3xs);
}
.newmessagelistbox1 {
  align-self: stretch;
  overflow: hidden;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-61xl);
}
.newmessage,
.newmessagelistbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.newmessagelistbox {
  align-self: stretch;
}
.newmessage {
  border-radius: var(--br-xl);
  background-color: var(--white);
  width: 597px;
  overflow: hidden;
  padding: 0 0 14px;
  box-sizing: border-box;
  gap: var(--gap-11xs);
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: var(--h4-medium-16-size);
  color: var(--logo-black);
  font-family: var(--h4-medium-16);
}
@media screen and (max-width: 1200px) {
  .newmessage {
    width: 527px;
  }
}
@media screen and (max-width: 850px) {
  .newmessagetoheaderbox {
    padding-left: var(--padding-21xl);
    box-sizing: border-box;
  }
  .newmessageboxheaderinputbox1 {
    width: 200px;
    height: 34px;
  }
  .newmessageboxinputbuttontext {
    font-size: var(--h6-medium-12-size);
  }
  .newmessageboxinputbutton {
    width: 80px;
    height: 34px;
    padding-left: 0;
    box-sizing: border-box;
  }
  .newmessageboxsearchnext {
    padding-left: var(--padding-21xl);
    box-sizing: border-box;
  }
  .newmessageboxcontactprofile-icon {
    width: 40px;
    height: 40px;
  }
  .newmessageboxcontactprofilenam {
    font-size: var(--h6-medium-12-size);
  }
  .newmessagecontactpnamesummary {
    font-size: var(--font-size-4xs);
  }
  .newmessageboxcheck-icon {
    width: 15px;
    height: 15px;
  }
  .newmessagelistbox1 {
    padding-left: var(--padding-21xl);
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .newmessagelistbox {
    padding-top: var(--padding-8xs);
    padding-bottom: var(--padding-8xs);
    box-sizing: border-box;
  }
  .newmessage {
    width: 400px;
  }
}
@media screen and (max-width: 520px) {
  .newmessageboxheadertext {
    font-size: var(--h6-medium-12-size);
  }
  .newmessageboxheaderinputbox1 {
    width: 150px;
  }
  .newmessageboxinputbuttontext {
    font-size: var(--font-size-3xs);
  }
  .newmessageboxinputbutton {
    width: 60px;
  }
  .newmessageboxcontactprofile-icon {
    width: 30px;
    height: 30px;
  }
  .newmessageboxcontactprofilenam {
    font-size: var(--font-size-3xs);
  }
  .newmessagecontactpnamesummary {
    font-size: var(--font-size-4xs);
  }
  .newmessageboxcontactprofile1 {
    gap: var(--gap-0);
  }
  .newmessagelistbox {
    gap: var(--gap-11xs);
    padding-top: var(--padding-8xs);
    padding-bottom: var(--padding-3xs);
    box-sizing: border-box;
  }
  .newmessage {
    width: 300px;
    gap: var(--gap-0);
  }
}
 