.rightbookedsessionnoticon {
    position: relative;
    width: 55px;
    height: 55px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .rightbookedsessionnotdetailste,
  .rightbookedsessionnotdetailste1 {
    position: relative;
    line-height: 120%;
  }
  .rightbookedsessionnotdetailste1 {
    font-weight: 500;
  }
  .rightbookedsessionnot,
  .rightbookedsessionnotdetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .rightbookedsessionnot {
    flex-direction: row;
    align-items: center;
    gap: var(--gap-3xs);
  }
  .leftbookedsessionnotboximg-icon {
    position: relative;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    object-fit: cover;
  }
  .leftbookedsessionnotboxname {
    position: relative;
  }
  .leftbookedsessionnotboxexperti {
    position: relative;
    font-size: var(--font-size-4xs);
    font-weight: 500;
    color: var(--purple-blue);
  }
  .leftbookedsessionnotbox,
  .leftbookedsessionnotdetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-6xs);
  }
  .leftbookedsessionnotbox {
    flex-direction: row;
    gap: var(--gap-3xs);
    font-size: var(--font-size-mid);
  }
  .bookedsessionnotification {
    position: relative;
    border-radius: var(--br-5xs);
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 9px 16px;
    align-items: center;
    justify-content: center;
    gap: 23px;
    max-width: 100%;
    max-height: 100%;
    text-align: left;
    font-size: 14.03px;
    color: var(--logo-black);
    font-family: var(--h6-medium-12);
  }
  @media screen and (max-width: 850px) {
    .rightbookedsessionnoticon {
      width: 35px;
      height: 35px;
    }
    .rightbookedsessionnotdetailste,
    .rightbookedsessionnotdetailste1 {
      font-size: var(--font-size-3xs);
    }
    .leftbookedsessionnotboximg-icon {
      width: 35px;
      height: 35px;
    }
    .leftbookedsessionnotboxname {
      font-size: var(--h6-medium-12-size);
    }
    .leftbookedsessionnotboxexperti {
      font-size: 5px;
    }
  }
  