@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --p1-regular-16: Poppins;
  --base-1-semibold: Inter;

  /* font sizes */
  --p1-regular-16-size: 16px;
  --h5-btns-med-14-size: 14px;
  --p2-regular-12-size: 12px;
  --base-1-semibold-size: 15px;
  --font-size-4xs: 9px;

  /* Colors */
  --white: #fff;
  --color-gray-100: #79797d;
  --color-light-body-text: #1a1b2d;
  --logo-black: #010101;
  --gray-1: #f2f2f2;
  --color-slateblue-100: #5c55d4;
  --purple-blue: #5558cf;
  --color-dark-grey-v2: #535763;
  --color-cornflowerblue: #a3a7f5;
  --color-black: #000;
  --color-mediumaquamarine: #70d499;
  --color-primary-blue: #6759ff;

  /* Gaps */
  --gap-365xl: 384px;
  --gap-67xl: 86px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-7xs: 6px;
  --gap-16xl: 35px;

  /* Paddings */
  --padding-6xl: 25px;
  --padding-7xs: 6px;
  --padding-9xs: 4px;
  --padding-5xs: 8px;
  --padding-base: 16px;
  --padding-mini: 15px;
  --padding-10xs: 3px;
  --padding-8xs: 5px;
  --padding-26xl: 45px;
  --padding-xs: 12px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-7xs: 6px;
  --br-13xl: 32px;
  --br-9xs: 4px;

  /* Effects */
  --button-01: 0px -1px 1px rgba(0, 0, 0, 0.04) inset,
    0px 2px 0px rgba(255, 255, 255, 0.25) inset,
    0px 4px 8px -4px rgba(0, 0, 0, 0.25);
}


@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.navigationavatar-icon {
  width: 48px;
  position: relative;
  border-radius: 48px;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.navigationnameemail,
.navigationnametext {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.navigationnameemail {
  font-size: var(--p2-regular-12-size);
  line-height: 12px;
  font-weight: 500;
  color: var(--color-dark-grey-v2);
}
.navigationname {
  width: 171px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.navigationdrawernamebody2 {
  gap: var(--gap-xs);
}
.navigationdrawernamebody,
.navigationdrawernamebody2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.dashboard-icon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.dashboard-sideNav {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.nav-item {
  align-self: stretch;
  border-radius: var(--br-7xs);
  background-color: var(--color-primary-blue);
  padding: var(--padding-7xs) var(--padding-xs);
  color: var(--white);
}
.left2,
.nav-item,
.nav-item1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left2 {
  flex: 1;
}
.nav-item1 {
  align-self: stretch;
  border-radius: var(--br-7xs);
  padding: var(--padding-7xs) var(--padding-xs);
}
.nav-item1:hover,
.sidenavigationmenunavitem:hover {
  background-color: var(--color-cornflowerblue);
}
.sidenavigationmenunavitemicon {
  width: 18px;
  position: relative;
  height: 18px;
}
.sidenavigationmenunavitemtext {
  width: 164px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
}
.sidenavigationmenunavitemarrow-icon {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.sidenavigationmenunavitem,
.sidenavigationmenunavitembox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.sidenavigationmenunavitembox {
  flex: 1;
  gap: 9px;
}
.sidenavigationmenunavitem {
  align-self: stretch;
  border-radius: var(--br-5xs);
  padding: var(--padding-7xs) var(--padding-mini);
}
.sidemenudropdownnavitemlefttex {
  width: 134px;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
}
.sidemenudropdownnavitemlefttex:active {
  color: var(--color-slateblue-100);
}
.sidemenudropdownnavitemlefttex,
.sidenavnotext {
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 600;
}
.sidenavno {
  border-radius: var(--br-9xs);
  background-color: var(--color-mediumaquamarine);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-10xs) var(--padding-8xs);
  text-align: center;
  font-size: var(--font-size-4xs);
  color: var(--color-black);
}
.sidemenudropdownnavitem,
.sidemenudropdownnavitemleftbod {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.sidemenudropdownnavitemleftbod {
  gap: var(--gap-16xl);
}
.sidemenudropdownnavitem {
  align-self: stretch;
  border-radius: var(--br-5xs);
  padding: 0 var(--padding-mini) var(--padding-8xs) var(--padding-26xl);
}
.sidenavigationmenudropdown {
  font-size: var(--p2-regular-12-size);
}
.sidenavigationmenu,
.sidenavigationmenudropdown {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.sidenavnobookings {
  border-radius: var(--br-9xs);
  background-color: #ed5043;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-10xs) var(--padding-8xs);
  text-align: center;
  font-size: var(--font-size-4xs);
  color: var(--white);
}
.sidenavigationmenunavitemtext4 {
  width: 161px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
}
.sidenavnomessages,
.sidenavnonotification {
  border-radius: var(--br-9xs);
  background-color: #e0f09f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-10xs) var(--padding-8xs);
  text-align: center;
  font-size: var(--font-size-4xs);
  color: var(--logo-black);
}
.sidenavnonotification {
  background-color: #ffbc99;
  color: var(--color-black);
}
.navigationdrawerbody1,
.navigationdrawernamebody3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.navigationdrawernamebody3 {
  height: 525px;
  gap: 2px;
  font-size: var(--base-1-semibold-size);
  color: var(--color-dark-grey-v2);
}
.navigationdrawerbody1 {
  gap: 20px;
}
.sidenavloginboxtext {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.sidenavloginboxicon {
  width: 20px;
  position: relative;
  height: 18px;
}
.sidenavloginbox {
  flex: 1;
  border-radius: var(--br-13xl);
  background-color: var(--white);
  box-shadow: var(--button-01);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
  gap: var(--gap-5xs);
}
.sidenavloginboxicon1 {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: cover;
}
.sidenavloginbox1,
.sidenavloginsignupbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.sidenavloginbox1 {
  flex: 1;
  border-radius: var(--br-13xl);
  background-color: var(--white);
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
  gap: var(--gap-5xs);
}
.sidenavloginsignupbox {
  width: 255px;
  border-radius: 40px;
  background-color: #f0f0f0;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  gap: 4px;
}
.sidenavloginsignup {
  align-self: stretch;
  font-size: var(--base-1-semibold-size);
  color: var(--color-dark-grey-v2);
}
.navigation-drawer,
.navigationdrawerbody,
.sidenavloginsignup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.navigationdrawerbody {
  width: 248px;
  /* gap: 204px; */
}
.navigation-drawer {
  width: 296px;
  height: 100%;
  background-color: var(--white);
  padding: 24px;
  box-sizing: border-box;
  opacity: 0;
  max-width: 90%;
  overflow: auto;
  text-align: left;
  font-size: var(--h5-btns-med-14-size);
  color: var(--color-light-body-text);
  font-family: var(--base-1-semibold);
}
.navigation-drawer.animate {
  animation: 0.25s ease 0s 1 normal forwards slide-in-left;
}
