.billing-dashboard {
  display: flex;
  height: 110vh;
  background-color: var(--gray-0.2);
}

/* Heading */
.billy-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-left: 8%;
  margin-top: 50px; 
}

.billy-head {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18.3px;
  line-height: 50px;
  color: #4a93cf;
  white-space: nowrap;
  margin: 0; /* Remove extra margin */
  padding: 0 20px; /* Add spacing between headers */
}

.billy-header-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 178%;
  height: 2px; /* Thickness of the underline */
  background-color: #4a93cf; /* Color of the underline */
}

/* Form Container */
.right-main-contents {
  margin-left: 6%;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  width: 175%;
  overflow-y: auto;
}

/* Form */
.main-forms {
  display: flex;
  margin-top: 2%;
  flex-direction: column;
  gap: 20px;
}

/* Input Field Container */
.main-labels-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Label Text */
.labels-header {
  font-size: 1rem;
  font-weight: bold;
  color: #393939;
}

/* Input Fields */
.main-inputs-fields {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  background: linear-gradient(0deg, #edf2f7, #edf2f7), #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.main-inputs-fields-text {
  width: 100%;
  height: 140px;
  padding: 10px;
  font-size: 1rem;
  background: linear-gradient(0deg, #edf2f7, #edf2f7), #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.main-inputs-fields-row {
  width: 40%;
  padding: 10px;
  gap: 5px;
  font-size: 1rem;
  background: linear-gradient(0deg, #edf2f7, #edf2f7), #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.main-input-fields:focus {
  border-color: #0056b3;
}

/* Checkbox */
.checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  color: #333;
}

/* Button */
.update-button {
  width: fit-content; /* Adjusted to fit the content of the button */
  padding: 12px 24px; /* Custom padding for better proportions */
  background-color: #0056b3;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 auto; /* Centers the button horizontally */
}

.update-button:hover {
  background-color: #003f8c;
}

@media (max-width: 1400px) {
  .billy-head {
    font-size: 28px; /* Reduce heading font size */
    margin-left: 5%; /* Adjust margin for smaller screens */
  }

  .right-main-content {
    width: 210%; /* Reduce the width of the form container */
    margin-left: 6%; /* Adjust margin for better alignment */
  }

  .main-input-fields {
    font-size: 0.9rem; /* Slightly smaller font size */
    padding: 8px; /* Reduce padding inside input fields */
  }

  .update-button {
    padding: 10px 20px; /* Reduce button padding */
  }
}

@media (max-width: 1300px) {
  .main-input-fields {
    font-size: 0.8rem; /* Slightly smaller font size */
    padding: 4px; /* Reduce padding inside input fields */
  }

  .update-button {
    padding: 10px 20px; /* Reduce button padding */
  }
}
