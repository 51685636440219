.headerlogo-icon9 {
  position: relative;
  width: 134px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link-default36 {
  position: relative;
  font-weight: 500;
}
.headernav-link45,
.headernav-link46 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headernav-link45 {
  display: none;
}
.headernav-link46 {
  display: flex;
  cursor: pointer;
  color: var(--logo-color-1);
}
.headernav-link47 {
  cursor: pointer;
}
.headernav-link47,
.headernav-link49,
.left-side-top-nav9,
.top-nav-left9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left-side-top-nav9,
.top-nav-left9 {
  gap: var(--gap-13xl);
}
.left-side-top-nav9 {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  width: 604.51px;
  padding: 0 0 0 var(--padding-13xl);
  box-sizing: border-box;
  align-items: flex-end;
}
.vector-icon63,
.vector-icon64 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon64 {
  height: 50%;
  width: 75%;
  top: 25%;
  right: 12.5%;
  bottom: 25%;
  left: 12.5%;
}
.menu9 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 44px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.messages-icon8,
.notifications-icon8 {
  position: absolute;
  top: 8px;
  left: -3px;
  width: 28.3px;
  height: 28px;
}
.notifications-icon8 {
  left: 53px;
  width: 22.74px;
}
.mask-group-icon8 {
  position: relative;
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.vector-icon65 {
  position: relative;
  width: 10px;
  height: 6px;
}
.mask-group-parent6 {
  position: absolute;
  top: 0;
  left: 106px;
  width: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.messages-parent6 {
  position: relative;
  width: 152px;
  height: 44px;
}
.right-side-top-nav9 {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  width: 263px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-13xl) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-13xl);
}
.info8 {
  align-self: stretch;
  position: relative;
  height: 44px;
}
.scholarships-line-icon8 {
  position: relative;
  width: 89.5px;
  height: 0;
  display: none;
}
.community-line-icon8,
.resources-icon8,
.services-line-icon8 {
  position: relative;
  width: 59.5px;
  height: 0;
  display: none;
}
.community-line-icon8,
.resources-icon8 {
  width: 83px;
}
.resources-icon8 {
  width: 73px;
}
.navbar8 {
  align-self: stretch;
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-3);
  box-sizing: border-box;
  height: 79px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.page-title25 {
  position: relative;
  line-height: 150%;
}
.underline-icon15 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
}
.page-title24 {
  width: 102px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
  cursor: pointer;
}
.arrow-icon11 {
  position: relative;
  width: 4.28px;
  height: 7.42px;
}
.popular-scholarships {
  position: relative;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  width: 168px;
}
.underline-icon16 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 0;
  flex-shrink: 0;
}
.page-title26 {
  width: 168px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}
.arrow-icon12 {
  position: relative;
  width: 4.25px;
  height: 7.39px;
  display: none;
}
.page-title27 {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}
.headertop-navprimarybreadcr5 {
  width: 340px;
  display: flex;
  flex-direction: row;
  padding: 0 0 0 var(--padding-17xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.popular-scholarships1 {
  position: relative;
  display: flex;
  align-items: center;
  width: 570px;
  flex-shrink: 0;
}
.allpage-header6 {
  align-self: stretch;
  height: 106px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xl) 0 var(--padding-58xl) var(--padding-15xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--h1-bold-52-size);
}
.header9,
.search-bar4 {
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
}
.header9 {
  width: 1440px;
  height: 249px;
  flex-direction: column;
  padding: 0 var(--padding-108xl);
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-3xs);
}
.search-bar4 {
  border: 2px solid var(--gray-3);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  font-size: var(--p1-regular-16-size);
  background-color: transparent;
  border-radius: var(--br-5xs);
  width: 1120px;
  height: 65px;
  flex-direction: row;
  padding: 0 var(--padding-base);
  align-items: center;
  justify-content: flex-start;
}
.button-text64 {
  position: relative;
  font-weight: 500;
}
.vector-icon66 {
  position: absolute;
  top: calc(50% - 12.1px);
  left: calc(50% - 12.11px);
  width: 24.21px;
  height: 24.21px;
}
.div51 {
  position: absolute;
  top: 5.6px;
  left: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5px;
  height: 13px;
}
.circle-124 {
  position: relative;
  width: 24.21px;
  height: 24.21px;
  display: none;
  font-size: var(--p2-regular-12-size);
  color: var(--color-black);
}
.chevrondown-icon24 {
  position: relative;
  width: 11.05px;
  height: 6.83px;
}
.arrows25 {
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-9xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.filter-button24 {
  border-radius: var(--br-5xs);
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  width: 147.05px;
  height: 65px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.circle-125 {
  position: relative;
  width: 24.21px;
  height: 24.21px;
  display: none;
}
.button-text65 {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  flex-shrink: 0;
}
.button-text-parent10 {
  justify-content: flex-start;
  font-size: var(--p1-regular-16-size);
  color: var(--logo-black);
}
.button-text-parent10,
.button-text-parent11,
.filter-button25 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-9xs);
}
.filter-button25 {
  border-radius: var(--br-5xs);
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  width: 208.05px;
  height: 65px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0 var(--padding-5xl);
  justify-content: center;
  font-size: var(--p2-regular-12-size);
  color: var(--color-black);
}
.button-text-parent11 {
  justify-content: flex-start;
  font-size: var(--p1-regular-16-size);
  color: var(--logo-black);
}
.filter-button26 {
  width: 140.05px;
  font-size: var(--p2-regular-12-size);
  color: var(--color-black);
}
.filter-button26,
.filter-button27,
.filter-button28,
.filter-button29 {
  border-radius: var(--br-5xs);
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  height: 65px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.filter-button27 {
  width: 143.05px;
  font-size: var(--p2-regular-12-size);
  color: var(--color-black);
}
.filter-button28,
.filter-button29 {
  width: 159.05px;
}
.filter-button29 {
  width: 169.05px;
}
.vector-icon72 {
  position: relative;
  width: 26px;
  height: 6px;
}
.ellipse-button4 {
  width: 44px;
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.filter4,
.filters-wrapper4 {
  display: flex;
  justify-content: flex-start;
}
.filters-wrapper4 {
  width: 1070.32px;
  overflow-x: auto;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-3xs);
}
.filter4 {
  width: 1422.32px;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs) var(--padding-157xl);
  box-sizing: border-box;
  align-items: flex-start;
}
.tune-icon4 {
  position: relative;
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.filters4 {
  position: relative;
  font-weight: 500;
}
.frame-wrapper58,
.tune-parent2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tune-parent2 {
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frame-wrapper58 {
  flex: 1;
  background-color: var(--color-ghostwhite);
  overflow: hidden;
  padding: var(--padding-5xs) 0;
  justify-content: center;
  cursor: pointer;
}
.vector-icon73 {
  position: relative;
  width: 30px;
  height: 30px;
}
.frame-wrapper59 {
  flex: 1;
  background-color: var(--color-ghostwhite);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  align-items: center;
  justify-content: center;
}
.frame-parent9 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-123xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-14xl);
}
.search-and-filter-inner3 {
  align-self: stretch;
  overflow: hidden;
  display: none;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
}
.search-and-filter4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-smi);
  text-align: center;
}
.scholarships-available4,
.search6 {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.search6 {
  height: 421px;
  overflow: hidden;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-smi);
  font-size: var(--p1-regular-16-size);
}
.scholarships-available4 {
  width: 348.79px;
}
.scholarships-available4,
.sort-by4 {
  position: relative;
  font-weight: 500;
}
.relevance-parent1,
.sort-by-parent2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.sort-by-parent2 {
  position: absolute;
  top: 0;
  left: 0;
}
.filter-dropdown-button-inner2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 207px;
  height: 30px;
}
.filter-dropdown-button4 {
  position: relative;
  width: 220px;
  height: 30px;
  color: var(--color-black);
}
.available4,
.body9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.body9 {
  flex: 1;
  height: 48px;
  gap: var(--gap-518xl);
}
.available4 {
  align-self: stretch;
  overflow: hidden;
  padding: 0 var(--padding-136xl);
  font-size: var(--h3-medium-20-size);
}
.scholarship-available4 {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
}
.see-more-progress-bar9 {
  position: relative;
  width: 183px;
  height: 21px;
}
.availble4 {
  height: 101px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  flex-direction: column;
  padding: var(--padding-21xl) var(--padding-619xl) var(--padding-21xl)
    var(--padding-610xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.cardscholarshipsimage-icon36 {
  position: relative;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.cardscholarshipsbackgroundco36 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-mediumslateblue-100);
  width: 256px;
  height: 97px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.title36 {
  position: absolute;
  top: 110px;
  left: 27px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  width: 212px;
  height: 40px;
}
.award-amount37,
.deadline35,
.organization36 {
  position: absolute;
  top: 158px;
  left: 27px;
  line-height: 150%;
}
.award-amount37,
.deadline35 {
  top: 190px;
}
.deadline35 {
  top: 222px;
}
.component-2-icon36 {
  position: absolute;
  top: 223.68px;
  left: 215px;
  width: 24.63px;
  height: 22.95px;
}
.card36 {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  width: 256px;
  height: 265px;
  overflow: hidden;
  flex-shrink: 0;
}
.cardscholarshipsbackgroundco37 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-darkturquoise);
  width: 256px;
  height: 97px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.cardscholarshipsimage-icon38 {
  position: relative;
  border-radius: var(--br-61xl);
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.cardscholarshipsbackgroundco38 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-gold);
  width: 256px;
  height: 97px;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
}
.cardscholarshipsbackgroundco38,
.cardscholarshipsbackgroundco39,
.row-2-wrapper6 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.cardscholarshipsbackgroundco39 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-red);
  width: 256px;
  height: 97px;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
}
.row-2-wrapper6 {
  align-items: flex-start;
  gap: var(--gap-13xl);
}
.scholarship-body4 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm) var(--padding-139xl);
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--p1-regular-16-size);
}
.div57 {
  position: absolute;
  top: 0;
  left: 52px;
  font-weight: 500;
}
.button36 {
  position: absolute;
  height: 109.38%;
  width: 101.84%;
  top: -4.69%;
  right: -0.92%;
  bottom: -4.69%;
  left: -0.92%;
  border-radius: var(--br-5xs);
  border: 1.5px solid var(--logo-black);
  box-sizing: border-box;
}
.button-text70 {
  position: absolute;
  height: 95%;
  width: 100%;
  top: 2.5%;
  left: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonlarge28 {
  position: absolute;
  top: 56px;
  left: 0;
  width: 163px;
  height: 32px;
  color: var(--logo-black);
}
.progress-bar-icon5 {
  position: absolute;
  top: 28px;
  left: 2px;
  width: 158.5px;
  height: 2px;
}
.see-more-progress-bar10 {
  position: relative;
  width: 163px;
  height: 88px;
}
.see-more6 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-21xl) var(--padding-619xl);
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--gray-4);
}
.footerlogo-icon9 {
  position: relative;
  width: 148px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link27 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}
.footerlinknav27 {
  position: relative;
  width: 160px;
  height: 19px;
}
.footer-links45 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.footericoninstagram9 {
  position: relative;
  width: 20px;
  height: 20px;
}
.footericonfacbook9 {
  position: relative;
  width: 18.75px;
  height: 18.75px;
}
.footericontwitter9 {
  position: relative;
  width: 19.5px;
  height: 15.84px;
}
.footericonyoutube9 {
  position: relative;
  width: 21px;
  height: 14.72px;
}
.icon-wrapper9 {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.footer-menu45 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.students9 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 160px;
}
.footer-link117 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  display: inline-block;
}
.main-footer-link117 {
  position: relative;
  width: 160px;
  height: 24px;
}
.footer-menu46 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.footer-link129 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
}
.main-footer-link129 {
  position: relative;
  width: 160px;
  height: 71px;
  font-size: var(--p2-regular-12-size);
}
.text-box8 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-7xs);
  background-color: var(--white);
  box-shadow: var(--shadow-1);
}
.input-placeholder8 {
  position: absolute;
  top: 4px;
  left: 10px;
  font-weight: 500;
  display: inline-block;
  width: 181px;
  height: 25px;
}
.subscribe-email-text-box9 {
  position: relative;
  width: 198px;
  height: 28px;
  color: var(--gray-4);
}
.button37 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--purple-blue);
}
.buttonlarge29 {
  position: relative;
  width: 198px;
  height: 32px;
  text-align: center;
}
.footer-links49 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--h5-btns-med-14-size);
}
.footer-grid-row9,
.footer-legal9,
.footer-menu-parent7 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-69xl);
}
.footer-grid-row9,
.footer-legal9 {
  justify-content: center;
}
.footer-legal9 {
  width: 827px;
  justify-content: flex-start;
  gap: var(--gap-365xl);
}
.footer9,
.popular1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer9 {
  align-self: stretch;
  background-color: var(--gray-4);
  padding: var(--padding-63xl) var(--padding-105xl) var(--padding-20xl);
  align-items: flex-start;
  gap: var(--gap-62xl);
  font-size: var(--p1-regular-16-size);
  color: var(--gray-1);
}
.popular1 {
  position: relative;
  background-color: var(--white);
  width: 100%;
  align-items: center;
  gap: var(--gap-11xs);
  text-align: left;
  font-size: var(--h5-btns-med-14-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
}
@media screen and (max-width: 1200px) {
  .menu9 {
    display: none;
  }
  .popular-scholarships1 {
    font-size: var(--font-size-17xl);
  }
  .header9 {
    width: 1200px;
    height: 117px;
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--padding-146xl);
    padding-top: var(--padding-xl);
    box-sizing: border-box;
  }
  .search-bar4 {
    width: 800px;
  }
  .button-text64 {
    font-size: var(--font-size-3xs);
  }
  .filter-button24 {
    width: 127px;
    height: 50px;
  }
  .button-text65 {
    font-size: var(--font-size-3xs);
    width: 107px;
  }
  .button-text-parent10 {
    gap: var(--gap-12xs);
  }
  .filter-button25 {
    width: 158px;
    height: 50px;
    padding-left: var(--padding-5xs);
    padding-right: var(--padding-5xs);
    box-sizing: border-box;
  }
  .filter-button26 {
    width: 120px;
    height: 50px;
  }
  .filter-button27 {
    width: 123px;
    height: 50px;
  }
  .filter-button28 {
    width: 139px;
    height: 50px;
  }
  .filter-button29 {
    width: 149px;
    height: 50px;
  }
  .filters-wrapper4 {
    width: 800px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .filter4 {
    width: 1200px;
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--padding-181xl);
    box-sizing: border-box;
  }
  .search-and-filter4 {
    height: 168px;
  }
  .search6 {
    padding-left: var(--padding-131xl);
    padding-right: var(--padding-131xl);
    box-sizing: border-box;
  }
  .scholarships-available4,
  .sort-by4 {
    font-size: var(--font-size-lg);
  }
  .body9 {
    gap: var(--gap-241xl);
  }
  .footer-menu-parent7,
  .row-2-wrapper6,
  .scholarship-body4 {
    flex-wrap: wrap;
  }
  .footer-grid-row9 {
    flex-direction: column;
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 850px) {
  .top-nav-left9 {
    display: none;
  }
  .menu9 {
    display: flex;
  }
  .messages-parent6 {
    display: none;
  }
  .headertop-navprimarybreadcr5 {
    padding-top: var(--padding-11xl);
    box-sizing: border-box;
  }
  .popular-scholarships1 {
    font-size: var(--font-size-11xl);
  }
  .allpage-header6 {
    height: 200px;
    padding-top: var(--padding-8xs);
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .header9 {
    width: 850px;
    height: 140px;
    padding-left: var(--padding-121xl);
    padding-top: 0;
    padding-bottom: var(--padding-3xs);
    box-sizing: border-box;
  }
  .search-bar4 {
    width: 500px;
  }
  .filter-button25 {
    width: 129px;
  }
  .filters-wrapper4 {
    width: 500px;
  }
  .filter4 {
    display: none;
    width: 850px;
    padding-left: var(--padding-157xl);
    box-sizing: border-box;
  }
  .frame-wrapper58 {
    width: 410px;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .frame-parent9 {
    gap: var(--gap-mini);
    padding-left: var(--padding-53xl);
    padding-right: var(--padding-53xl);
    box-sizing: border-box;
  }
  .search-and-filter-inner3 {
    display: flex;
  }
  .search6 {
    padding-top: 0;
    box-sizing: border-box;
  }
  .scholarships-available4 {
    font-size: var(--p1-regular-16-size);
  }
  .filter-dropdown-button-inner2,
  .filter-dropdown-button4 {
    display: none;
  }
  .body9 {
    align-items: center;
    justify-content: center;
  }
  .available4 {
    display: none;
    height: 90px;
    padding-left: var(--padding-271xl);
    padding-right: var(--padding-161xl);
    box-sizing: border-box;
  }
  .availble4 {
    display: flex;
    height: 80px;
  }
  .scholarship-body4 {
    padding-left: var(--padding-21xl);
    padding-top: var(--padding-7xs);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 520px) {
  .headertop-navprimarybreadcr5 {
    padding-top: var(--padding-xl);
    box-sizing: border-box;
  }
  .popular-scholarships1 {
    font-size: var(--font-size-3xl);
  }
  .allpage-header6 {
    height: 10px;
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .header9 {
    width: 520px;
    padding-left: var(--padding-58xl);
    padding-top: var(--padding-xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
  .search-bar4 {
    width: 300px;
    height: 55px;
  }
  .filters-wrapper4 {
    width: 300px;
  }
  .filter4 {
    padding-left: var(--padding-259xl);
    box-sizing: border-box;
  }
  .tune-icon4 {
    width: 20px;
    height: 20px;
  }
  .filters4 {
    font-size: var(--p2-regular-12-size);
  }
  .frame-wrapper58 {
    width: 225px;
    padding-top: var(--padding-11xs);
    padding-bottom: var(--padding-11xs);
    box-sizing: border-box;
  }
  .vector-icon73 {
    width: 20px;
    height: 20px;
  }
  .frame-wrapper59 {
    padding-top: var(--padding-11xs);
    padding-bottom: var(--padding-11xs);
    box-sizing: border-box;
  }
  .frame-parent9 {
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
  .search-and-filter-inner3 {
    padding-top: var(--padding-mid);
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .scholarships-available4 {
    font-size: var(--p1-regular-16-size);
  }
  .available4 {
    padding-left: var(--padding-301xl);
    box-sizing: border-box;
  }
}
