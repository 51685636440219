@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;800&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h5-btns-med-14: Poppins;
  --font-inter: Inter;
  --font-inherit: inherit;

  /* font sizes */
  --font-size-3xs: 10px;
  --h5-btns-med-14-size: 14px;
  --p1-regular-16-size: 16px;
  --h3-medium-20-size: 20px;
  --p2-regular-12-size: 12px;
  --h2-bold-32-size: 32px;
  --font-size-5xl: 24px;
  --h1-bold-52-size: 52px;
  --font-size-11xl: 30px;
  --font-size-smi: 13px;
  --font-size-lg: 18px;
  --font-size-mini: 15px;
  --font-size-lgi: 19px;
  --font-size-5xs: 8px;
  --font-size-6xl: 25px;
  --font-size-21xl: 40px;
  --font-size-4xs: 9px;
  --font-size-7xs: 6px;
  --font-size-2xs: 11px;
  --font-size-2xl-6: 21.6px;
  --font-size-mid: 17px;

  /* Colors */
  --white: #fff;
  --color-gainsboro-100: #e7e7e7;
  --color-gainsboro-200: #dfd6d6;
  --color-black: #000;
  --color-darkgray-100: #b2b1b1;
  --color-darkgray-200: #a1a2af;
  --gray-1: #f2f2f2;
  --purple-blue: #5558cf;
  --gray-4: #79797d;
  --logo-black: #010101;
  --profile-questions-background: rgba(1, 1, 1, 0.7);
  --color-silver-100: #c7c7c7;
  --gray-3: #c4c6c7;
  --royal-blue: #0d0ed9;
  --color-red: #f40000;
  --color-gold: #f8d100;
  --color-darkturquoise: #00b8c3;
  --color-mediumslateblue: #5d57f9;
  --color-ghostwhite: #f4f6fa;
  --logo-color-1: #181048;
  --gray-5: #393939;
  --gradient-2: rgba(57, 57, 57, 0.4);
  --logo-color-2: #4a93cf;
  --color-steelblue-100: rgba(74, 147, 207, 0.3);
  --color-mediumspringgreen: #39f4b1;

  /* Gaps */
  --gap-3xl: 22px;
  --gap-5xs: 8px;
  --gap-69xl: 88px;
  --gap-base: 16px;
  --gap-11xl: 30px;
  --gap-xs: 12px;
  --gap-381xl: 400px;
  --gap-10xs: 3px;
  --gap-6xs: 7px;
  --gap-13xl: 32px;
  --gap-663xl: 682px;
  --gap-331xl: 350px;
  --gap-smi: 13px;
  --gap-mini: 15px;
  --gap-3xs: 10px;
  --gap-8xs: 5px;
  --gap-11xs: 2px;
  --gap-0: 0px;
  --gap-mid: 17px;
  --gap-41xl: 60px;
  --gap-xl: 20px;
  --gap-35xl: 54px;
  --gap-9xl: 28px;
  --gap-6xl: 25px;
  --gap-sm: 14px;
  --gap-9xs: 4px;
  --gap-12xs: 1px;
  --gap-2xs: 11px;
  --gap-10xl: 29px;
  --gap-lg: 18px;
  --gap-7xs: 6px;

  /* Paddings */
  --padding-11xl: 30px;
  --padding-39xl: 58px;
  --padding-mid: 17px;
  --padding-447xl: 466px;
  --padding-3xl: 22px;
  --padding-2xs: 11px;
  --padding-3xs: 10px;
  --padding-5xs: 8px;
  --padding-134xl: 153px;
  --padding-sm: 14px;
  --padding-21xl: 40px;
  --padding-139xl: 158px;
  --padding-136xl: 155px;
  --padding-161xl: 180px;
  --padding-351xl: 370px;
  --padding-7xs: 6px;
  --padding-xs: 12px;
  --padding-10xs: 3px;
  --padding-131xl: 150px;
  --padding-53xl: 72px;
  --padding-11xs: 2px;
  --padding-base: 16px;
  --padding-xl: 20px;
  --padding-31xl: 50px;
  --padding-58xl: 77px;
  --padding-2xl: 21px;
  --padding-8xs: 5px;
  --padding-13xl: 32px;
  --padding-smi: 13px;
  --padding-6xl: 25px;
  --padding-lgi: 19px;
  --padding-9xs: 4px;
  --padding-93xl: 112px;
  --padding-8xl: 27px;
  --padding-6xs: 7px;
  --padding-mini: 15px;
  --padding-5xl: 24px;
  --padding-33xl: 52px;
  --padding-61xl: 80px;
  --padding-57xl: 76px;
  --padding-55xl: 74px;
  --padding-4xl: 23px;
  --padding-17xl: 36px;
  --padding-29xl: 48px;
  --padding-107xl: 126px;
  --padding-27xl: 46px;
  --padding-74xl: 93px;
  --padding-24xl: 43px;
  --padding-54xl: 73px;
  --padding-41xl: 60px;
  --padding-10xl: 29px;
  --padding-75xl: 94px;
  --padding-141xl: 160px;
  --padding-7xs-6: 5.6px;
  --padding-5xs-4: 7.4px;

  /* Border radiuses */
  --br-7xs: 6px;
  --br-5xs: 8px;
  --br-61xl: 80px;
  --br-mid: 17px;
  --br-xl: 20px;
  --br-xs: 12px;
  --br-6xl: 25px;
  --br-9xs: 4px;
  --br-45xl: 64px;
  --br-5xl: 24px;
  --br-31xl: 50px;
  --br-6xs-5: 6.5px;

  /* Effects */
  --shadow-1: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-200px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.morefiltersboxheader {
  flex: 1;
  position: relative;
}
.morefiltersboxheadericon {
  position: relative;
  width: 24px;
  height: 24px;
}
.morefiltersbox2 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-10xs);
  font-size: var(--font-size-5xl);
  color: var(--logo-black);
}
.scholarship {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 600;
}
.conference-grants-wrapper,
.scholarship-wrapper,
.summer-programs-wrapper {
  border-radius: var(--br-xs);
  border: 0.5px solid var(--color-black);
  box-sizing: border-box;
  width: 150px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-5xs);
}
.conference-grants-wrapper,
.summer-programs-wrapper {
  padding: var(--padding-9xs);
}
.frame-group,
.morefiltersbox1-inner {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-group {
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-3xs);
}
.morefiltersbox1-inner {
  overflow: hidden;
  flex-direction: column;
  padding: 0 var(--padding-10xs) var(--padding-xs);
  text-align: center;
  font-size: var(--font-size-5xs);
  color: var(--color-black);
}
.gpa-wrapper,
.morefiltersbox1-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.gpa-wrapper {
  flex: 1;
}
.morefiltersbox1-child {
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-8xs) var(--padding-10xs);
  font-size: var(--font-size-lgi);
  color: var(--profile-questions-background);
}
.morefiltersboxslidercontinuou {
  position: relative;
}
.morefilterssliderbox,
.morefilterssliderboxeasyapply {
  align-self: stretch;
  /* overflow: hidden; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-6xs) var(--padding-10xs);
}
.morefilterssliderboxeasyapply {
  justify-content: center;
  padding: var(--padding-8xs) var(--padding-10xs);
  gap: var(--gap-3xs);
  font-size: var(--font-size-lgi);
  color: var(--profile-questions-background);
}
.morefiltersline {
  flex: 1;
  position: relative;
  border-top: 1px solid var(--color-silver-100);
  box-sizing: border-box;
  height: 1px;
}
.morefilterslinebox {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-8xs) var(--padding-10xs);
}
.morefiltersdropdownboxicon {
  position: relative;
  width: 16px;
  height: 10px;
}
.morefiltersdropdownbox,
.morefiltersdropdownitemsearch {
  display: flex;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
}
.morefiltersdropdownbox {
  border-bottom: 1px solid var(--color-silver-100);
  overflow: hidden;
  justify-content: center;
  padding: var(--padding-8xs) var(--padding-6xl) var(--padding-8xs)
    var(--padding-10xs);
}
.morefiltersdropdownitemsearch {
  border: 1px solid var(--gray-3);
  font-family: var(--h5-btns-med-14);
  font-size: var(--font-size-mini);
  background-color: transparent;
  border-radius: var(--br-5xs);
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--padding-93xl) var(--padding-9xs)
    var(--padding-8xl);
}
.morefiltersdropdownitembox1lis-icon {
  position: relative;
  width: 15px;
  height: 10px;
}
.morefiltersdropdownitembox1lis,
.morefiltersdropdownitembox1lis15 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 var(--padding-lgi);
}
.morefiltersdropdownitembox1lis15 {
  color: var(--gray-5);
}
.morefiltersdropdownitembox1 {
  overflow-y: auto;
  flex-shrink: 0;
  padding: 0 var(--padding-6xl) 0 var(--padding-10xs);
  gap: var(--gap-8xs);
}
.morefiltersdropdownitembox,
.morefiltersdropdownitembox1,
.morefiltersdropdownitembox1-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.morefiltersdropdownitembox1-wrapper {
  overflow-y: auto;
}
.morefiltersdropdownitembox {
  border-bottom: 1px solid var(--color-silver-100);
  box-sizing: border-box;
  height: 188px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-7xs) var(--padding-6xl) var(--padding-7xs)
    var(--padding-10xs);
  gap: var(--gap-3xs);
  color: var(--gray-4);
}
.show-results {
  position: relative;
  font-size: var(--p1-regular-16-size);
  line-height: 150%;
  font-family: var(--h5-btns-med-14);
  color: var(--gray-1);
  text-align: center;
}
.buttonxxlarge {
  cursor: pointer;
  border: 0;
  padding: var(--padding-smi) var(--padding-31xl);
  background-color: var(--royal-blue);
  border-radius: var(--br-xl);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button-text2 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-size: var(--p1-regular-16-size);
  font-weight: 500;
  font-family: var(--h5-btns-med-14);
  color: var(--gray-4);
  text-align: center;
  display: inline-block;
}
.buttonxxlarge-parent,
.morefiltersbox1-inner1,
.morefiltersbox1-inner2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.buttonxxlarge-parent {
  flex: 1;
  gap: var(--gap-3xs);
}
.morefiltersbox1-inner1,
.morefiltersbox1-inner2 {
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-base) var(--padding-21xl) 0;
}
.morefiltersbox1-inner2 {
  align-items: flex-start;
  padding: 0 var(--padding-21xl);
  text-align: center;
  font-size: var(--font-size-smi);
}
.morefiltersbox,
.morefiltersbox1 {
  background-color: var(--white);
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  opacity: 0;
}
.morefiltersbox1 {
  flex: 1;
  border-radius: var(--br-mid);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-3xs);
  width: 100%;
  max-height: 90%;
}
.morefiltersbox.animate,
.morefiltersbox1.animate {
  animation: 0.25s ease 0s 1 normal forwards slide-in-top;
}
.morefiltersbox {
  position: relative;
  border-radius: var(--br-5xs);
  width: 445px;
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-3xs);
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--gradient-2);
  font-family: var(--h5-btns-med-14);
}
@media screen and (max-width: 1200px) {
  .summer-programs-wrapper {
    padding-left: var(--padding-9xs);
    padding-right: var(--padding-9xs);
    box-sizing: border-box;
  }
  .show-results {
    font-size: var(--p1-regular-16-size);
  }
  .button-text2 {
    font-size: var(--font-size-lg);
  }
}
@media screen and (max-width: 850px) {
  .morefiltersdropdownitemsearch {
    padding-top: var(--padding-7xs);
    padding-right: var(--padding-8xl);
    padding-bottom: var(--padding-7xs);
    box-sizing: border-box;
  }
  .morefiltersdropdownitembox1lis-icon {
    width: 25px;
    height: 19px;
  }
  .show-results {
    font-size: var(--h5-btns-med-14-size);
  }
  .button-text2 {
    font-size: var(--p1-regular-16-size);
  }
}
@media screen and (max-width: 520px) {
  .morefiltersdropdownitemsearch {
    padding-top: var(--padding-9xs);
    padding-bottom: var(--padding-9xs);
    box-sizing: border-box;
  }
  .morefiltersdropdownitembox1lis-icon {
    width: 21px;
    height: 16px;
  }
  .show-results {
    font-size: var(--p2-regular-12-size);
  }
  .button-text2 {
    font-size: var(--h5-btns-med-14-size);
  }
}
