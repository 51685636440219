.coach-dashboard {
  display: flex;
  height: 94vh;
  background-color: var(--gray-0.2);
}

/* Main content styling */
.dashboard-main-content {
  padding: 20px;
  width: 100%;
  overflow-y: auto;
}

.comm-stat h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15.2873px;

  color: #7a7a7a;
}

.comm-stat2 h3 {
  color: #7a7a7a;
  margin-top: 3%;
  margin-bottom: 2%;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15.2873px;
}

.welcome-new-section {
  text-align: left;
  margin-top: 33px;
  margin-left: 25px;
  font-size: 28px;
  padding-bottom: 8px;
  /* border-bottom: 1px solid #ddd; */
  margin-bottom: 20px;
}

  .welcome-new-section h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
  }

.coach-name {
  color: #4a93cf;
}

.communities-stats {
  display: flex;
  gap: 20px;
  padding: 20px;
  margin-left: 1%;
  margin-top: 5%;
  margin-bottom: 20px;
  justify-content: space-between;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 160px;
  background-color: #ffffff;
  border-radius: 10px; /* Rounded corners */
  padding: 15px 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.icon {
  font-size: 28px;
  color: #007bff;
  margin-bottom: 10px;
}

.book-icon {
  margin-top: 8%;
}

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20.0522px;
  display: flex;
  align-items: center;

  color: #4a93cf;
}

.value {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24.4172px;
  display: flex;
  align-items: center;
  margin: 4%;

  color: #6bc844;
}

.value2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 19.8574px;
  margin-top: 4%;
  display: flex;
  align-items: center;

  color: #4a93cf;
}

.currency {
  color: #6bc844;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18.0522px;
}

.description2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17.5069px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #7a7a7a;
  margin-bottom: 2%;
}

.icon,
.icon-books .fa-book {
  padding-top: 12%;
  font-size: 55px;
  color: #4a93cf;
  margin-bottom: 8px;
}

/* Specific styling for the two book icons */
.icon-books {
  display: flex;
  align-items: center;
  gap: 6px; /* Space between the two book icons */
}

.upcoming-events {
  display: flex;
  flex-direction: column;
  margin-top: 1%;
  gap: 10px;
}

.up-event {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  height: 88px;
  margin-bottom: 2%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: transform 0.2s ease-in-out;
}

.event-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #4a93cf;
  color: #ffffff;
  width: 70px;
  height: 70px;
  margin-left: 3%;
  border-radius: 50%;
  margin-right: 15px;
}

.event-month {
  font-size: 14px;
  font-weight: bold;
}

.event-day {
  font-size: 18px;
  font-weight: bold;
}

.event-details {
  flex: 1;
}

.event-details h4 {
  margin: 0 0 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;

  color: #393939;
}

.event-details p {
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12.406px;

  color: #7a7a7a;
}

.profile-completion {
  margin-top: 14%;
  margin-right: 4%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.profile-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22.9267px;
  color: #4a93cf;
}

.header {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.bookings-table {
  width: 100%;
  border-collapse: separate; 
  border-spacing: 0 15px;
}

.bookings-table th {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-size: 18.3288px;
  line-height: 27px;
  color: #393939;
  padding-bottom: 15px;
}

.bookings-table tbody tr:first-child {
  padding-top: 15px; 
}

.bookings-table tbody {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18.3288px;
  line-height: 27px;
  align-items: center;
  text-align: center;

  color: #787878;
}
