.mymessagesheaderback {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    font-size: var(--font-size-5xl);
    font-weight: 500;
    font-family: var(--h4-medium-16);
    color: var(--logo-black);
    text-align: left;
    display: inline-block;
  }
  .mymessagesheader {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .writemessagebodyreceiverprofil-icon {
    position: relative;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  .writemessagebodyreceivername {
    flex: 1;
    position: relative;
  }
  .writemessagebodyreceiver {
    align-self: stretch;
    border-radius: var(--br-xl);
    height: 64px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-2xs) var(--padding-11xl);
    box-sizing: border-box;
    gap: var(--gap-9xl);
  }
  .today {
    position: relative;
  }
  .writemessagebodyheader {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--gray-4);
  }
  .writebodysendermessagetext {
    position: relative;
    display: flex;
    align-items: center;
    width: 228px;
    flex-shrink: 0;
  }
  .writemessagebodysendermessage1 {
    border-radius: var(--br-5xs);
    background-color: var(--logo-color-2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-4xl) var(--padding-17xl);
  }
  .writemessagebodysendermessage {
    align-self: stretch;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 0 var(--padding-6xl) 0 0;
    box-sizing: border-box;
  }
  .writemessagebodyreceivermessag1 {
    border-radius: var(--br-5xs);
    background-color: var(--color-mediumspringgreen);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-4xl) var(--padding-17xl);
  }
  .writemessagebodybox,
  .writemessagebodyreceivermessag {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .writemessagebodyreceivermessag {
    padding: 0 0 0 var(--padding-6xl);
    color: var(--color-black);
  }
  .writemessagebodybox {
    gap: var(--gap-35xl);
    color: var(--white);
  }
  .writemessagepaperclipicon {
    position: absolute;
    height: 94.21%;
    width: 89.32%;
    top: 1.62%;
    right: 6.5%;
    bottom: 4.17%;
    left: 4.17%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .writemessagepaperclip {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .writebodytypemessageinput1,
  .writemessagebodysendbuttontext {
    font-size: var(--h4-medium-16-size);
    font-family: var(--h4-medium-16);
    display: flex;
    align-items: center;
  }
  .writebodytypemessageinput1 {
    border: 1px solid var(--color-darkgray-100);
    background-color: transparent;
    align-self: stretch;
    flex: 1;
    border-radius: var(--br-5xl);
    flex-direction: row;
    justify-content: flex-start;
    padding: var(--padding-3xl) var(--padding-3xs) var(--padding-3xl)
      var(--padding-2xl);
  }
  .writemessagebodysendbuttontext {
    position: relative;
    color: var(--color-black);
    text-align: center;
    justify-content: center;
    width: 128px;
    height: 45px;
    flex-shrink: 0;
  }
  .writemessagebodysendbutton1 {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: var(--logo-color-2);
    border-radius: var(--br-45xl);
    width: 128px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .writemessagebodytypemessage1 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-xl);
  }
  .writemessagebody,
  .writemessagebodytypemessage {
    background-color: var(--white);
    overflow: hidden;
    flex-direction: column;
  }
  .writemessagebodytypemessage {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--padding-11xl) var(--padding-55xl);
  }
  .writemessagebody {
    flex: 1;
    border-radius: var(--br-5xs);
    gap: var(--gap-41xl);
  }
  .message-view,
  .mymessagesheader1,
  .writemessagebody {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mymessagesheader1 {
    align-self: stretch;
    flex: 1;
    flex-direction: row;
  }
  .message-view {
    position: relative;
    background-color: var(--color-whitesmoke);
    width: 100%;
    height: 917px;
    overflow: hidden;
    flex-direction: column;
    padding: 130px var(--padding-xl);
    box-sizing: border-box;
    gap: var(--gap-mid);
    text-align: left;
    font-size: var(--h4-medium-16-size);
    color: var(--logo-black);
    font-family: var(--h4-medium-16);
  }
  @media screen and (max-width: 1200px) {
    .mymessagesheader {
      width: 810px;
    }
    .writemessagebodyreceiver {
      width: 450px;
    }
    .writebodysendermessagetext {
      font-size: var(--h5-btns-med-14-size);
      width: 200px;
    }
    .writemessagebodyreceivermessag1,
    .writemessagebodysendermessage1 {
      padding: var(--padding-mid) var(--padding-5xl);
      box-sizing: border-box;
    }
    .writemessagebodytypemessage1 {
      gap: var(--gap-6xs);
    }
    .writemessagebodytypemessage {
      padding-right: var(--padding-31xl);
      box-sizing: border-box;
    }
    .writemessagebody {
      display: flex;
    }
    .message-view {
      padding-top: var(--padding-61xl);
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 850px) {
    .mymessagesheader {
      width: 500px;
    }
    .writebodytypemessageinput1 {
      padding-left: var(--padding-3xs);
      box-sizing: border-box;
    }
    .writemessagebodysendbutton1 {
      width: 108px;
    }
    .writemessagebodytypemessage {
      padding-left: var(--padding-31xl);
      box-sizing: border-box;
    }
    .writemessagebody {
      display: flex;
    }
    .message-view {
      background-color: var(--color-whitesmoke);
      padding-top: var(--padding-xl);
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 520px) {
    .mymessagesheader {
      width: 380px;
    }
    .writemessagebodysendermessage {
      padding-right: var(--padding-3xs);
      box-sizing: border-box;
    }
    .writebodytypemessageinput1,
    .writemessagebodyreceivermessag {
      padding-left: var(--padding-3xs);
      box-sizing: border-box;
    }
    .writemessagebodysendbuttontext {
      width: 108px;
    }
    .writemessagebodytypemessage1 {
      padding-left: 0;
      box-sizing: border-box;
    }
    .writemessagebodytypemessage {
      padding-left: var(--padding-xl);
      padding-right: var(--padding-xl);
      box-sizing: border-box;
    }
    .message-view {
      padding: var(--padding-57xl) var(--padding-xl);
      box-sizing: border-box;
    }
  }
  