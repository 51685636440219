@keyframes slide-in-bottom {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.clear-all1 {
  position: relative;
}
.clear-all-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-215xl);
}
.coach-filters-inner {
  width: 789px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-9xl) var(--padding-52xl) var(--padding-2xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.service-options {
  position: relative;
  font-size: var(--h5-btns-med-14-size);
  font-weight: 500;
}
.essay-review {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 150%;
}
.check-box-outline-blank-parent29,
.frame-wrapper37 {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
}
.check-box-outline-blank-parent29 {
  width: 279px;
  flex-direction: row;
  padding: 0 0 0 var(--padding-3xl);
  align-items: center;
  gap: var(--gap-5xs);
}
.frame-wrapper37 {
  align-self: stretch;
  border-radius: var(--br-7xs);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--color-gainsboro-100);
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-mid) var(--padding-447xl) var(--padding-mid) 0;
  align-items: flex-start;
}
.min {
  position: relative;
  line-height: 14px;
  font-weight: 500;
}
.frame-input {
  background-color: transparent;
  position: relative;
}
.min-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.frame-parent1,
.frame-wrapper50,
.service-options-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent1 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  gap: var(--gap-3xl);
}
.frame-wrapper50,
.service-options-parent {
  align-self: stretch;
  flex-direction: column;
}
.frame-wrapper50 {
  border-radius: var(--br-7xs);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-mid) 0 var(--padding-mid) var(--padding-6xl);
  text-align: center;
}

.languagesFilterBox{
  align-self: stretch;
    border-radius: var(--br-7xs);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
   
    
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: column;
   
    align-items: flex-start;

}
.service-options-parent {
  overflow-y: auto;
  padding: var(--padding-11xl) var(--padding-39xl);
  gap: var(--gap-3xl);
  font-size: var(--font-size-3xs);
}
.coach-filters {
  position: relative;
  background-color: var(--white);
  height: 1110px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  width: 100%;
  /* max-height: 90%; */
  text-align: left;
  font-size: var(--h5-btns-med-14-size);
  color: var(--color-black);
  font-family: var(--p1-regular-16);
}
.coach-filters.animate {
  animation: 0.25s ease 0s 1 normal forwards slide-in-bottom;
}
@media screen and (max-width: 850px) {
  .clear-all-group {
    gap: var(--gap-131xl);
  }
  .frame-wrapper37,
  .frame-wrapper50 {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 520px) {
  .clear-all-group {
    gap: var(--gap-91xl);
    align-items: center;
    justify-content: flex-start;
  }
  .frame-wrapper37,
  .frame-wrapper50 {
    padding-right: 0;
    box-sizing: border-box;
  }
  .coach-filters {
    width: 400px;
  }
}
