/* .coachProfilecardform {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
} */
body {
  margin: 0;
  line-height: normal;
}

.search-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  margin-top: 3%;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-start;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  font-family: Arial, sans-serif;
}

.language-selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

/* Dropdown and Button Styles */
.dropdown-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
}

.dropdown-button span {
  margin-left: 0.5rem;
}

.dropdown-button:hover {
  border-color: #000;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.clear-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.apply-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.apply-button:hover {
  background-color: #0056b3;
}

.coach-application-form {
  /* Coach App V1 */

  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  background: #ffffff;
}

.eye-button {
  outline: none;
  border: none;
  background-color: #007bff;
  color: white;
  font-weight: 400;
  border-radius: 6px;
  height: fit-content;
  padding-top: 4px;
  margin-top: 5px;
  margin-left: 1%;
}

.scrollable-container {
  display: flex;
  flex-direction: column;
  /* box-shadow: 0 0 10px #00000028; */
  max-height: 150px;
  overflow-y: scroll;
  width: 45%;
  padding: 1rem;
  scroll-behavior: smooth;
}

.scrollable-container::-webkit-scrollbar {
  width: 8px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.container {
  background: rgba(74, 147, 207, 0.5);
  display: flex;
  height: 100px;
}
.form-body {
  margin-left: 5%;
}

.headerlogoo {
  position: absolute;
  left: 28.11%;
  top: 0.4%;
  width: 5%;
  height: auto;
}

.headerText {
  color: #010101;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 35%;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sub-header {
  position: absolute;
  width: 45%;
  height: 2%;
  left: 10%;
  top: 13%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  margin-top: 1%;
}

.image-upload-container {
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 6.6rem;
  width: fit-content;
  padding: 0.2rem;
}

.image-upload-container p {
  margin-left: 7rem;
  font-size: large;
  font-weight: 500;
  margin-top: 2rem;
  color: #0d0ed9;
}

.custom-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: lightgray;
  border: 1px solid black;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-left: 5rem;
  overflow: hidden;
  position: relative;
}

.cloud-icon {
  font-size: 56px;
  font-weight: bold;
  color: black;
}

.file-input {
  display: none;
}

/* Styling for the uploaded image */
.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.headerImg {
  text-align: center;
}

.top-section {
  display: flex;
}

.top-section-right {
  /* margin-left: 38%; */
  width: 90%;
  margin-left: 8.2%;
  margin-top: 5%;
  font-size: 15px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 500;
}

.top-section-right input {
  display: block;
  border: solid 2px gray;
  width: 85.6%;
  padding: 0.5rem;
  border-radius: 7px;
  margin-top: 0.5rem;
  margin-bottom: 1%;
}

.sevice-bar {
  width: 80%;
  height: 70%;
  margin-left: 6rem;
  font-size: large;
  font-weight: normal;
  margin-top: 4%;
}

.sevice-bar select {
  width: 50%;
  height: 70%;
  border: solid 2pt gray;
  /* margin-left: 1rem; */
  border-radius: 6px;
  margin-top: 0.2rem;
  padding: 0.2rem;
}

.sevice-bar .showSavedData {
  margin-left: 1%;
}

.sevice-bar p {
  font-weight: 600;
  margin-left: 1rem;
}

/* .bio-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4%;
  margin-left: 4%;
  padding: 10px;
  gap: 10px;

  position: absolute;
  width: 62.6%;
  height: 153px;
  left: 401px;
  top: 205.46px;

  border: 2px solid #000000;
  border-radius: 5px;
} */

.bio-field textarea {
  display: block;
  min-height: 100px;
  border: solid 2px gray;
  width: 85.6%;
  padding: 0.5rem;
  border-radius: 7px;
  margin-top: 0.5rem;
  margin-bottom: 1%;
}

.add-form {
  width: fit-content;
  height: auto;
  /* background-color: rgb(0, 125, 197); */
  color: #000;
  border-radius: 6px;
  border: solid 2pt gray;
  padding: 0.5rem;
  margin: 1rem;
}

.education-bar {
  width: 86%;
  height: 70%;
  margin-top: 1rem;
  margin-left: 6rem;
  font-size: large;
  font-weight: normal;
}

.education-bar input {
  width: 31.7%;
  height: 70%;
  border: solid 2pt gray;
  margin-left: 1rem;
  border-radius: 6px;
  margin-top: 0.2rem;
  padding: 0.2rem;
}

.education-bar p {
  font-weight: 600;
  margin-left: 1rem;
}

.language-selection {
  width: 50%;
  height: auto;
  font-size: large;
  font-weight: normal;
  margin-left: 6.5%;
  margin-bottom: 2%;
}

.language-selection p {
  font-weight: 600;
}

.checkbox-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 0.5rem;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkbox-group input {
  width: 20px;
  height: 20px;
}

.add-form3 {
  width: fit-content;
  height: auto;
  background-color: rgb(0, 125, 197);
  border-radius: 6px;
  color: white;
  padding: 0.5rem;
  margin-top: 1rem;
}

.second-section {
  display: flex;
}

/* .policy-bar {
  width: 50%;
  height: auto;
  font-size: large;
  font-weight: normal;
  margin-left: 6rem;
  margin-top: 1rem;
} */

.policy-bar {
  width: 34%;
  height: 70%;
  margin-left: 6rem;
  font-size: large;
  font-weight: normal;
  margin-top: 1%;
}

.policy-bar select {
  width: 50%;
  height: 70%;
  border: solid 2pt gray;
  margin-left: 1rem;
  border-radius: 6px;
  margin-top: 0.2rem;
  padding: 0.2rem;
}

.policy-bar .trigger {
  width: 50%;
  height: 70%;
  border: solid 2pt gray;
  margin-left: 1rem;
  text-align: left;
  border-radius: 6px;
  margin-top: 0.2rem;
  padding: 0.2rem;
}

.policy-bar p {
  font-weight: 600;
  margin-left: 1rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden; /* Prevents body scroll */
  padding: 20px; /* Adds space around the modal content */
}

.modal-content {
  background-color: #fff;
  width: 450px; /* Default width */
  max-width: 90%; /* Adjusts for smaller screens */
  height: auto; /* Allow content to dictate height */
  max-height: 80vh; /* Keep modal within the screen height */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
  position: relative;
  overflow-y: auto; /* Adds scroll if content overflows */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Optional - Ensure content stays within viewport */
.modal-content {
  max-height: 90vh;
  overflow-y: auto;
}

.modal-content .cancel-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #999;
  transform: rotate(45deg);
}

.modal-content .cancel-button:hover {
  color: #333;
}

/* Modal heading */
.modal-content h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

/* Form labels */
.modal-content label {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  color: #555;
  margin-bottom: 5px;
}

/* Form inputs */
.modal-content input[type="text"],
.modal-content select,
.modal-content textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

/* Input focus effect */
.modal-content input:focus,
.modal-content select:focus,
.modal-content textarea:focus {
  border-color: #007bff;
  outline: none;
}

/* Flex container for Price and Duration */
.flex-row {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Optional space between the inputs */
}

/* Price and Duration inputs */
.flex-row label,
.flex-row input,
.flex-row select {
  flex: 1;
}

/* Set label and input to align properly */
.flex-row label {
  margin-bottom: 5px;
}

.flex-row input,
.flex-row select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 10;
  list-style: none;
  padding: 0;
  margin: 0;
}

.locations-input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.modal-content button.save-button {
  width: 100%;
  padding: 8px;
  font-size: 0.875rem;
  background-color: rgb(0, 125, 197);
  color: #fff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s, transform 0.2s;
}

.modal-content button.save-button:hover {
  background-color: rgb(0, 125, 197);
  transform: translateY(-1px);
}

/* Textarea specific styling */
.modal-content textarea {
  min-height: 100px;
  resize: vertical;
}

.coachProfilecardform label {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.coachProfilecardform input[type="text"],
.coachProfilecardform input[type="number"],
.coachProfilecardform input[type="file"] {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 0.25rem;
}

.coachProfilecardform div {
  margin-bottom: 1rem;
}

.coachProfilecardform .service,
.coachProfilecardform .education,
.coachProfilecardform .language,
.coachProfilecardform .policie {
  margin-bottom: 1rem;
}

.coachProfilecardform .addFormButton {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.coachProfilecardform .addFormButton:hover {
  background-color: #0056b3;
}

/* .coach-application-form button[type="submit"] {
  width: 28%;
  height: 10%;
  margin-left: 37%;
  margin-bottom: 1%;
  white-space: nowrap;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.coachProfilecardform button[type="submit"]:hover {
  background-color: #218838;
} */

.coachProfilecardform .service-item,
.coachProfilecardform .education-item,
.coachProfilecardform .language-item,
.coachProfilecardform .policie-item {
  background-color: #e9ecef;
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.education-inputs {
  max-height: 200px;
  overflow-y: scroll;
  /* Add any other styles you may need here */
}

/* WebKit scrollbar customization */
.education-inputs::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.education-inputs::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.education-inputs::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

.education-inputs::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px; /* Rounded corners for the track */
}

.coachProfilecardform .service-item p,
.coachProfilecardform .education-item p,
.coachProfilecardform .language-item p,
.coachProfilecardform .policie-item p {
  margin: 0.25rem 0;
  font-size: 0.9rem;
}

.coach-submit-button {
  font-size: 16px;
  padding: 12px 24px;
  color: black;
  margin-left: 45%;
  margin-top: 5%;
  margin-bottom: 5%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: normal;
  max-width: 100%;
}

.coach-submit-button p {
  padding-top: 2%;
  font-size: 16px;
  text-align: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .headerText {
    font-size: 230%;
    margin-top: 3%;
    margin-left: 21%;
    text-align: center;
    white-space: nowrap;
  }

  .top-section {
    margin-left: 1%;
    margin-right: 3%;
    margin-top: 2%;
  }

  .coach-submit-button {
    display: block;
    margin-left: 36%;
    padding: 10px;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 1rem;
  }
}

@media (max-width: 600px) {
  .coachProfilecardform {
    max-width: 100%;
    padding: 1rem;
    margin: 0;
    border: none;
    box-shadow: none;
  }

  .dropdown-button {
    width: 100%;
    font-size: 14px;
  }

  .bio-field textarea {
    min-height: 80px;
    font-size: 12px;
  }

  .checkbox-group {
    grid-template-columns: 1fr;
  }

  /* .apply-button,
  .clear-button {
    font-size: 14px;
    padding: 8px;
  } */

  .coach-application-form {
    /* padding: 1rem; */
    overflow-y: auto;
  }

  .form-body {
    margin-left: 0;
    overflow-x: hidden;
  }

  .container {
    flex-direction: column;
    height: auto;
  }

  .headerText {
    font-size: 175%;
    margin-left: 0;
    text-align: center;
    white-space: nowrap;
  }

  .sub-header {
    font-size: 125%;
    width: 100%;
    left: 0;
    top: 9%;
  }

  .scrollable-container {
    width: 100%; 
  }

  .image-upload-container {
    margin-top: 2rem;
    width: 100%;
  }

  .image-upload-container p {
    margin-left: 1.5rem;
    font-size: medium;
    margin-top: 1.5rem;
  }

  .custom-file-upload {
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
  }

  .top-section {
    margin-left: 1%;
    margin-right: 3%;
    margin-top: 2%;
  }

  .top-section-right,
  .sevice-bar,
  .bio-field,
  .education-bar,
  .policy-bar {
    width: 100%;
    margin-left: 0;
  }

  .top-section-right input,
  .bio-field textarea {
    width: 100%;
  }

  .coach-submit-button {
    display: block;
    width: 100%;
    margin-left: 20%;
    padding: 10px;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 1rem;
  }

  .coach-submit-button p {
    text-align: center;
    align-items: center;
  }

  .modal-content .cancel-button {
    top: 5px;
    right: 5px;
    font-size: 1.2rem;
    color: #666;
  }

  .education-bar input,
  .policy-bar select,
  .policy-bar .trigger {
    width: 92% !important;
    margin-left: 3%;
  }
  .policy-bar .trigger {
    margin-bottom: 2%;
  }

  .modal-content {
    width: 88%;
    padding: 20px;
  }
}

@media (max-width: 400px) {
  .top-section {
    margin-left: 1%;
    margin-right: 3%;
    margin-top: 2%;
  }

  .coach-submit-button {
    display: block;
    width: 100%;
    margin-left: 16%;
    padding: 10px;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 1rem;
  }
}
