@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;800;900&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h3-medium-20: Poppins;
  --font-inter: Inter;

  /* font sizes */
  --font-size-lg-8: 18.8px;
  --font-size-smi: 13px;
  --h6-medium-12-size: 12px;
  --font-size-4xs: 9px;
  --font-size-4xl-4: 23.4px;
  --font-size-lgi: 19px;
  --font-size-2xs: 11px;
  --font-size-mid: 17px;
  --h5-btns-med-14-size: 14px;
  --font-size-5xl: 24px;
  --h3-medium-20-size: 20px;
  --font-size-9xl-1: 28.1px;
  --font-size-3xl: 22px;
  --font-size-3xs: 10px;
  --font-size-17xl: 36px;
  --font-size-2xl-6: 21.6px;
  --h2-bold-32-size: 32px;
  --font-size-2xl: 21px;
  --font-size-4xl-2: 23.2px;
  --font-size-mini: 15px;
  --p1-regular-16-size: 16px;
  --font-size-3xl-7: 22.7px;
  --font-size-lg: 18px;
  --h1-bold-52-size: 52px;
  --font-size-11xl: 30px;
  --font-size-5xs: 8px;
  --font-size-6xl: 25px;
  --font-size-21xl: 40px;
  --font-size-7xs: 6px;

  /* Colors */
  --white: #fff;
  --gray-4: #79797d;
  --color-gray-100: #787878;
  --logo-black: #010101;
  --profile-questions-background: rgba(1, 1, 1, 0.7);
  --gray-1: #f2f2f2;
  --gray-5: #393939;
  --gradient-2: rgba(57, 57, 57, 0.4);
  --color-black: #000;
  --logo-color-2: #4a93cf;
  --color-steelblue-100: rgba(74, 147, 207, 0.3);
  --color-mediumaquamarine: #70d499;
  --royal-blue: #0d0ed9;
  --color-lightgray: #d5d4df;
  --color-silver-100: #c7c7c7;
  --gray-3: #c4c6c7;
  --purple-blue: #5558cf;
  --color-mediumslateblue: #5d57f9;
  --color-gainsboro-100: #e7e7e7;
  --color-gainsboro-200: #dfd6d6;
  --color-darkgray-100: #b2b1b1;
  --color-darkgray-200: #a1a2af;
  --color-red: #f40000;
  --color-gold: #f8d100;
  --color-darkturquoise: #00b8c3;
  --color-ghostwhite: #f4f6fa;
  --logo-color-1: #181048;
  --color-mediumspringgreen: #39f4b1;

  /* Gaps */
  --gap-18xl: 37px;
  --gap-6xs: 7px;
  --gap-13xl: 32px;
  --gap-3xs-4: 9.4px;
  --gap-8xs: 5px;
  --gap-xl: 20px;
  --gap-base: 16px;
  --gap-2xs: 11px;
  --gap-mini-1: 14.1px;
  --gap-xs: 12px;
  --gap-0: 0px;
  --gap-5xs: 8px;
  --gap-9xl: 28px;
  --gap-11xs: 2px;
  --gap-9xs: 4px;
  --gap-7xs: 6px;
  --gap-3xs: 10px;
  --gap-31xl: 50px;
  --gap-11xl: 30px;
  --gap-mini: 15px;
  --gap-3xl: 22px;
  --gap-61xl: 80px;
  --gap-10xs: 3px;
  --gap-lgi: 19px;
  --gap-12xs: 1px;
  --gap-mid: 17px;
  --gap-69xl: 88px;
  --gap-381xl: 400px;
  --gap-663xl: 682px;
  --gap-331xl: 350px;
  --gap-smi: 13px;
  --gap-41xl: 60px;
  --gap-35xl: 54px;
  --gap-6xl: 25px;
  --gap-sm: 14px;
  --gap-10xl: 29px;
  --gap-lg: 18px;

  /* Paddings */
  --padding-118xl: 137px;
  --padding-57xl: 76px;
  --padding-11xs: 2px;
  --padding-15xl: 34px;
  --padding-31xl: 50px;
  --padding-51xl: 70px;
  --padding-mid: 17px;
  --padding-55xl: 74px;
  --padding-42xl: 61px;
  --padding-smi: 13px;
  --padding-mini: 15px;
  --padding-12xl: 31px;
  --padding-2xs: 11px;
  --padding-11xl: 30px;
  --padding-2xl: 21px;
  --padding-3xs: 10px;
  --padding-base: 16px;
  --padding-xs: 12px;
  --padding-4xs: 9px;
  --padding-61xl: 80px;
  --padding-84xl: 103px;
  --padding-4xs-2: 8.2px;
  --padding-8xs: 5px;
  --padding-9xs: 4px;
  --padding-141xl: 160px;
  --padding-13xl: 32px;
  --padding-6xl: 25px;
  --padding-10xl: 29px;
  --padding-4xl: 23px;
  --padding-xl: 20px;
  --padding-39xl: 58px;
  --padding-447xl: 466px;
  --padding-3xl: 22px;
  --padding-6xs-5: 6.5px;
  --padding-5xs: 8px;
  --padding-134xl: 153px;
  --padding-sm: 14px;
  --padding-21xl: 40px;
  --padding-139xl: 158px;
  --padding-136xl: 155px;
  --padding-161xl: 180px;
  --padding-351xl: 370px;
  --padding-7xs: 6px;
  --padding-10xs: 3px;
  --padding-131xl: 150px;
  --padding-53xl: 72px;
  --padding-58xl: 77px;
  --padding-lgi: 19px;
  --padding-93xl: 112px;
  --padding-8xl: 27px;
  --padding-6xs: 7px;
  --padding-5xl: 24px;
  --padding-33xl: 52px;
  --padding-17xl: 36px;
  --padding-29xl: 48px;
  --padding-107xl: 126px;
  --padding-27xl: 46px;
  --padding-74xl: 93px;
  --padding-24xl: 43px;
  --padding-54xl: 73px;
  --padding-41xl: 60px;
  --padding-75xl: 94px;
  --padding-7xs-6: 5.6px;
  --padding-5xs-4: 7.4px;

  /* Border radiuses */
  --br-4xs: 9px;
  --br-5xs: 8px;
  --br-xs: 12px;
  --br-3xs-4: 9.4px;
  --br-7xs: 6px;
  --br-61xl: 80px;
  --br-mid: 17px;
  --br-xl: 20px;
  --br-6xl: 25px;
  --br-9xs: 4px;
  --br-45xl: 64px;
  --br-5xl: 24px;
  --br-31xl: 50px;
  --br-6xs-5: 6.5px;

  /* Effects */
  --shadow-1: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.calendarrowdayscellsselectedro:hover {
  background-color: lightblue;
}



.coahavailabilitybackheaderbox1 {
  position: relative;
  font-weight: 500;
  white-space: pre-wrap;
}
.coahavailabilitybackheaderbox {
  width: 1258px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-mid);
  box-sizing: border-box;
}
.coachavailabilityprofileimg-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 2px;
  transform: scale(1.052);
}
.wrapper-coachavailabilityprofi {
  position: relative;
  width: 283.6px;
  height: 270.7px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hailey-johnson {
  position: relative;
  font-weight: 600;
}
.min-11 {
  position: relative;
  font-size: 42.2px;
  color: var(--logo-black);
}
.coachavailabilitydetailsname {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.coachavailabilitydetailsclock-icon {
  position: relative;
  width: 30.5px;
  height: 30.5px;
  object-fit: cover;
}
.min {
  position: relative;
  font-weight: 600;
}
.coachavailabilitydetailshours {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 9.38px;
  font-size: var(--font-size-4xl-4);
}
.coachavailabilitydetailsvideo-icon {
  position: relative;
  width: 27.5px;
  height: 19.7px;
  object-fit: cover;
}
.web-conferencing-details {
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 249px;
  flex-shrink: 0;
}
.coachavailabilitydetailsconfre {
  width: 288.3px;
  height: 74px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 11.72px;
  font-size: var(--font-size-lg-8);
}
.coachavailabilitydetailsbottom-child {
  flex-shrink: 0;
}
.coachavailabilitydetailsbottom,
.sidecoachavailability1 {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.coachavailabilitydetailsbottom {
  gap: 14.06px;
}
.sidecoachavailability1 {
  gap: var(--gap-9xl);
}
.select-date {
  position: relative;
  font-weight: 600;
}
.coachavailabilitycalendarheade {
  position: relative;
  font-weight: 900;
}
.coachavailabilitycalendararrow-child {
  position: relative;
  width: 14px;
  height: 14px;
  object-fit: cover;
}
.coachavailabilitycalendararrow {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base);
}
.coachavailabilitycalendararrow-icon {
  width: 46px;
  height: 46px;
  flex-shrink: 0;
  object-fit: cover;
}
.coachavailabilitycalendaarrows {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.coachavailabilitycalendaheader {
  width: 442px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: var(--font-size-5xl);
}
.bookingcalendarcelldaynum {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 24px;
  flex-shrink: 0;
}
.bookingdaycalendarinnercell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.calendarrowfordayscell {
  border: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  width: 64px;
  height: 35px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
}

.calendarrowfordayscell,
.calendarrowfordayscell1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.calendarrowfordayscell1 {
  border: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  width: 64px;
  height: 35px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  margin-left: -1.5px;
}
.calendarrowfordays {
  width: 442px;
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* Seven columns for each day of the week */
  /* Adjust the gap between cells as needed */
  align-items: flex-start;
  justify-content: flex-start;

}

.calendarrowfordays-header{
  width: 442px;
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* Seven columns for each day of the week */
  /* Adjust the gap between cells as needed */
  align-items: flex-start;
  justify-content: flex-start;
}
.daysbookingcalendarcellnudayna {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  padding-left: 3px;
  padding-right: 3px;
}

/* .calendarrowfordays div:first-child {
  grid-column: 2;
} */


.daybookingcalendarcellcircle {
  position: relative;
  border-radius: 50%;
  background-color: var(--color-mediumaquamarine);
  width: 8px;
  height: 8px;
}
.calendarrowdayscellsrowcell,
.daysbookingcalendarinnercell {
  width: 24px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.calendarrowdayscellsrowcell {
  width: 64px;
  height: 72px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.daysbookingcalendarinnercell3 {
  border-radius: var(--br-5xs);
  background-color: rgba(74, 147, 207, 0.65);
  width: 24px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.calendarrowdayscellsselectedro {
  width: 64px;
  height: 72px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  margin-left: -1.5px;
}
.coachavailabilitycalendarbody {
  border-radius: 16px;
  background-color: var(--white);
  box-shadow: 0 41.8px 33.42px rgba(0, 0, 0, 0.05),
    0 22.3px 17.87px rgba(0, 0, 0, 0.04), 0 12.5px 10.02px rgba(0, 0, 0, 0.04),
    0 6.7px 5.32px rgba(0, 0, 0, 0.03), 0 2.8px 2.21px rgba(0, 0, 0, 0.02);
  border: 1px solid var(--royal-blue);
  box-sizing: border-box;
  width: 484px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xl) var(--padding-2xl);
  gap: var(--gap-xs);
}
.thursday-december-8 {
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 280px;
  flex-shrink: 0;
}
.coachavailabilitydetailssidehe1 {
  border-radius: 5px;
  width: 280px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.ellipsetimeslot {
  border-radius: 50%;
  background-color: var(--color-mediumaquamarine);
  width: 12.9px;
  height: 12.9px;
}
.ellipsetimeslot,
.timeslots-available {
  position: relative;
}
.coachavailabilitydetailstieslo {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 14.06px;
}
.coachavailabilitydetailssidehe,
.coachavailabilitydetailstimesl {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.coachavailabilitydetailstimesl {
  border-radius: 7.3px;
  background-color: #f4f4f4;
  padding: var(--padding-mini) var(--padding-12xl);
  font-size: var(--font-size-lg-8);
  color: var(--gray-5);
}
.coachavailabilitydetailssidehe {
  gap: 23.44px;
}
.am,
.coachavailabilityfreetimes3 {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.am {
  position: relative;
  width: 108px;
  height: 39px;
}
.coachavailabilityfreetimes3 {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.coachavailabilityfreetimes2 {
  border-radius: var(--br-4xs);
  border: 1.8px solid var(--logo-black);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mid) var(--padding-55xl) var(--padding-mid)
    var(--padding-42xl);
}
.coachavailabilityfreetimes1 {
  gap: var(--gap-base);
}
.coachavailabilitydetailsside,
.coachavailabilityfreetimes,
.coachavailabilityfreetimes1 {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.coachavailabilityfreetimes {
  gap: var(--gap-base);
  color: var(--logo-color-2);
}
.coachavailabilitydetailsside {
  gap: 16.41px;
  text-align: left;
  font-size: var(--font-size-4xl-4);
  color: #358fd9;
  font-family: var(--h3-medium-20);
}
.coachavailabilitydetailscalend {
  flex-shrink: 0;
  flex-direction: row;
  gap: 51.57px;
  text-align: center;
  font-size: var(--h5-btns-med-14-size);
  color: var(--color-black);
  font-family: var(--font-inter);
}
.allcalendarbox2,
.coachavailabilitydate,
.coachavailabilitydetailscalend {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.coachavailabilitydate {
  flex-shrink: 0;
  flex-direction: column;
  gap: 38.68px;
  color: var(--gray-5);
}
.allcalendarbox2 {
  flex-direction: row;
  gap: var(--gap-18xl);
}
.allcalendarbox1 {
  border-radius: 16.61px;
  border: 1.2px solid var(--color-black);
  box-sizing: border-box;
  width: 1236.8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-15xl) 45px;
}
.time-zone {
  position: relative;
  font-weight: 600;
}
.group-icon {
  position: absolute;
  height: 83.27%;
  width: 83.27%;
  top: 8.19%;
  right: 8.54%;
  bottom: 8.54%;
  left: 8.19%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.iconoirglobe {
  position: relative;
  width: 28.1px;
  height: 28.1px;
  overflow: hidden;
  flex-shrink: 0;
}
.central-time- {
  position: relative;
  line-height: 193%;
  font-weight: 500;
}
.icon-park-outlinedown {
  position: relative;
  width: 25.8px;
  height: 23.4px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.coachavailabilitytimezonebotto {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 9.38px;
  font-size: var(--font-size-lg-8);
  color: var(--color-gray-100);
}
.coachavailabilitytimezoneside {
  width: 436px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.coachavailabilitytimezonebookt {
  position: relative;
  font-size: var(--font-size-lg-8);
  display: flex;
  font-family: var(--h3-medium-20);
  color: var(--gray-1);
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 280.1px;
  height: 60.1px;
  flex-shrink: 0;
}
.coachavailabilitytimezone,
.coachavailabilitytimezonebookn {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.coachavailabilitytimezonebookn {
  cursor: pointer;
  border: 0;
  padding: var(--padding-11xs) 0;
  background-color: var(--logo-black);
  border-radius: 9.38px;
  width: 280.1px;
  height: 64.1px;
  overflow: hidden;
  justify-content: center;
  box-sizing: border-box;
}
.coachavailabilitytimezone {
  width: 1236px;
  justify-content: flex-end;
  gap: var(--gap-13xl);
  font-size: var(--font-size-4xl-4);
  color: var(--gray-5);
}
.allcalendarbox,
.coach-availabilitycalendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.allcalendarbox {
  flex-shrink: 0;
  gap: var(--gap-6xs);
  font-size: var(--font-size-9xl-1);
  color: var(--color-gray-100);
}
.coach-availabilitycalendar {
  background-color: var(--white);
  overflow: hidden;
  padding: var(--padding-118xl) 91px;
  gap: var(--gap-18xl);
  text-align: left;
  font-size: var(--h3-medium-20-size);
  color: var(--logo-color-2);
  font-family: var(--h3-medium-20);
}
@media screen and (max-width: 1200px) {
  .coahavailabilitybackheaderbox {
    width: 990px;
  }
  .coachavailabilityprofileimg-icon {
    width: 200px;
    height: 200px;
  }
  .hailey-johnson {
    font-size: 23px;
  }
  .min-11 {
    font-size: var(--font-size-17xl);
  }
  .coachavailabilitydetailsclock-icon {
    width: 25px;
    height: 25px;
  }
  .min {
    font-size: var(--font-size-lgi);
  }
  .coachavailabilitydetailsvideo-icon {
    width: 25px;
  }
  .web-conferencing-details {
    font-size: var(--font-size-3xs);
  }
  .coachavailabilitydetailsconfre {
    width: 228px;
  }
  .sidecoachavailability1 {
    gap: var(--gap-11xs);
  }
  .select-date {
    font-size: var(--font-size-3xl);
  }
  .coachavailabilitycalendarheade {
    font-size: var(--h3-medium-20-size);
  }
  .coachavailabilitycalendaheader {
    width: 314px;
  }
  .calendarrowfordayscell,
  .calendarrowfordayscell1 {
    width: 44px;
  }
  .calendarrowfordays {
    width: 324px;
    gap: var(--gap-0);
  }


  .calendarrowfordays-header {
    width: 324px;

  }
  .calendarrowdayscellsrowcell,
  .calendarrowdayscellsselectedro {
    width: 44px;
  }
  .coachavailabilitycalendarbody {
    width: 364px;
  }
  .thursday-december-8 {
    font-size: var(--h5-btns-med-14-size);
  }
  .coachavailabilitydetailssidehe1 {
    width: 280px;
  }
  .ellipsetimeslot {
    width: 8px;
    height: 8px;
  }
  .timeslots-available {
    font-size: var(--font-size-smi);
  }
  .coachavailabilitydetailstimesl {
    padding-left: var(--padding-2xs);
    padding-right: var(--padding-2xs);
    box-sizing: border-box;
  }
  .am {
    font-size: var(--font-size-mid);
  }
  .coachavailabilityfreetimes2 {
    width: 170px;
    height: 66px;
    padding: var(--padding-smi) var(--padding-42xl);
    box-sizing: border-box;
  }
  .coachavailabilitydetailscalend {
    gap: 21.57px;
  }
  .allcalendarbox2 {
    gap: var(--gap-8xs);
  }
  .allcalendarbox1 {
    width: 950px;
    padding-left: var(--padding-31xl);
    padding-right: 0;
    box-sizing: border-box;
  }
  .coachavailabilitytimezonebookn {
    width: 240px;
  }
  .coachavailabilitytimezone {
    width: 950px;
  }
}
@media screen and (max-width: 850px) {
  .coahavailabilitybackheaderbox {
    width: 600px;
  }
  .web-conferencing-details {
    width: 150px;
  }
  .coachavailabilitydetailsconfre {
    width: 270px;
  }
  .sidecoachavailability1 {
    flex-direction: row;
    gap: var(--gap-11xs);
  }
  .coachavailabilityfreetimes2 {
    width: 160px;
    height: 56px;
  }
  .coachavailabilityfreetimes {
    flex-direction: row;
  }
  .coachavailabilitydetailscalend {
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .coachavailabilitydate {
    gap: 26px;
  }
  .allcalendarbox2 {
    flex-direction: column;
  }
  .allcalendarbox1 {
    width: 550px;
    flex-direction: row;
    padding-left: var(--padding-51xl);
    box-sizing: border-box;
  }
  .time-zone {
    font-size: var(--font-size-lgi);
  }
  .group-icon {
    width: 22px;
    height: 22px;
  }
  .central-time- {
    font-size: var(--h6-medium-12-size);
  }
  .icon-park-outlinedown {
    width: 21px;
    height: 20px;
  }
  .coachavailabilitytimezonebookt {
    font-size: var(--font-size-smi);
    width: 280px;
  }
  .coachavailabilitytimezonebookn {
    width: 150px;
    height: 44px;
  }
  .coachavailabilitytimezone {
    width: 550px;
  }
  .coach-availabilitycalendar {
    flex-direction: column;
  }
}
@media screen and (max-width: 520px) {
  .coahavailabilitybackheaderbox {
    width: 450px;
  }
  .sidecoachavailability1 {
    flex-direction: column;
    gap: var(--gap-11xs);
  }
  .coachavailabilitydetailscalend {
    flex-direction: column;
  }
  .allcalendarbox1,
  .allcalendarbox2 {
    align-items: flex-end;
    justify-content: center;
    padding-left: 0;
    box-sizing: border-box;
  }
  .allcalendarbox1 {
    width: 410px;
    align-items: center;
    padding-right: 0;
  }
  .time-zone {
    font-size: var(--font-size-2xs);
  }
  .iconoirglobe {
    width: 26px;
    height: 26px;
  }
  .central-time- {
    font-size: var(--font-size-4xs);
  }
  .icon-park-outlinedown {
    width: 23px;
    height: 21px;
  }
  .coachavailabilitytimezonebookn {
    width: 130px;
    height: 34px;
  }
  .coachavailabilitytimezone {
    width: 420px;
  }
  .coach-availabilitycalendar {
    padding: var(--padding-57xl) 0;
    box-sizing: border-box;
  }
}
