/* .welcomeme {
position: relative;
width: 100%;
height: 465px;
background: #C0CAD3;

}

.welcomeme p {
position: absolute;
width: 752px;
height: 144px;
left: 542px;
top: 174px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 36px;
color: #000000;
} */

.language-icon3 {
  position: relative;
  width: 16px;
  height: 16px;
}
.language3 {
  position: relative;
  font-weight: 500;
}
.down-icon3 {
  position: relative;
  width: 10px;
  height: 6.18px;
}
.headerdrop-downlang3,
.right-side-secondary-nav3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.headerdrop-downlang3 {
  gap: var(--gap-5xs);
}
.right-side-secondary-nav3 {
  position: absolute;
  top: calc(50% - 11px);
  right: 0;
  padding: 0 var(--padding-13xl) 0 0;
}
.line-under-link6 {
  position: relative;
  background-color: var(--gray-4);
  width: 109px;
  height: 2px;
}
.headertop-navlink6 {
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs) 0 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.line-under-link7 {
  position: relative;
  background-color: var(--gray-3);
  width: 109px;
  height: 2px;
}
.headertop-navlink-parent1,
.left-side-secondary-nav3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.headertop-navlink-parent1 {
  gap: var(--gap-13xl);
}
.left-side-secondary-nav3 {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  padding: 0 0 0 var(--padding-13xl);
}
.headertop-navsecondary3 {
  align-self: stretch;
  position: relative;
  background-color: var(--gray-1);
  height: 40px;
}
.headerlogo-icon17 {
  position: relative;
  width: 134px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-active17 {
  position: relative;
  font-weight: 500;
}
.headernav-link85 {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--royal-blue);
}
.headernav-link86 {
  text-decoration: none;
  color: inherit;
}
.headernav-link86,
.headernav-link89 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headernav-link-parent1 {
  flex: 1;
  align-items: flex-start;
  gap: var(--gap-13xl);
}
.headerlogo-parent1,
.headernav-link-parent1,
.top-nav-left17 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.top-nav-left17 {
  flex: 1;
  height: 21px;
  align-items: center;
}
.headerlogo-parent1 {
  position: absolute;
  top: 0;
  left: 32px;
  width: 644px;
  align-items: flex-end;
  gap: var(--gap-13xl);
}
.left-side-top-nav17 {
  flex: 1;
  position: relative;
  height: 41px;
}
.vector-icon104,
.vector-icon105 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon105 {
  height: 50%;
  width: 75%;
  top: 25%;
  right: 12.5%;
  bottom: 25%;
  left: 12.5%;
}
.menu17 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 47px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text91 {
  position: relative;
  font-size: var(--h5-btns-med-14-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--logo-black);
  text-align: center;
}
.buttonmedium8 {
  cursor: pointer;
  border: 1px solid var(--logo-black);
  padding: var(--padding-12xs) 0;
  background-color: transparent;
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  width: 128px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.buttonmedium8:hover {
  background-color: var(--color-limegreen);
}
.buttonmedium8:active {
  background-color: var(--white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.83);
}
.button-text92 {
  position: relative;
  font-size: var(--h5-btns-med-14-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--gray-1);
  text-align: center;
}
.button-text92:hover {
  color: var(--color-black);
}
.button-text92:active {
  -webkit-text-stroke: 1px #000;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.buttonmedium9 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-12xs) 0;
  background-color: var(--royal-blue);
  border-radius: var(--br-5xs);
  width: 128px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.buttonmedium9:hover {
  background-color: var(--color-limegreen);
}
.register3,
.right-side-top-nav17 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.right-side-top-nav17 {
  padding: 0 var(--padding-13xl) 0 0;
  align-items: flex-end;
  justify-content: flex-end;
}
.left-side-top-nav-parent1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-425xl);
}
.headertop-navprimary3,
.nav-bar3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.headertop-navprimary3 {
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-3);
  padding: var(--padding-lgi) 0;
}
.nav-bar3 {
  height: 120px;
}
.footerlogo-icon15 {
  position: relative;
  width: 104px;
  height: 95px;
  overflow: hidden;
  flex-shrink: 0;
}
.header-image1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 38px 210px;
  align-items: center;
  justify-content: center;
}
.welcome-to-scholist {
  position: relative;
  display: inline-block;
  width: 347px;
  flex-shrink: 0;
}
.welcome4 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) var(--padding-69xl);
  align-items: center;
  justify-content: center;
  font-size: var(--h2-bold-32-size);
  color: var(--royal-blue);
}
.would-you-like {
  margin: 0;
}
.would-you-like-container {
  position: relative;
  line-height: 150%;
  display: inline-block;
  width: 210px;
  flex-shrink: 0;
}
.continue {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-138xl);
  align-items: center;
  justify-content: center;
  text-align: center;
}
.continue1 {
  position: relative;
  font-size: var(--p1-regular-16-size);
  line-height: 150%;
  font-family: var(--p1-regular-16);
  color: var(--gray-1);
  text-align: center;
}
.buttonxxlarge3,
.continue-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonxxlarge3 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-8xl);
  background-color: var(--royal-blue);
  border-radius: var(--br-7xs);
  width: 400px;
  height: 44px;
  flex-direction: row;
  box-sizing: border-box;
}
.continue-button {
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-xl) 62px;
}
.no-thanks-ill {
  position: relative;
  text-decoration: underline;
  line-height: 150%;
  display: inline-block;
  width: 312px;
  flex-shrink: 0;
}
.no-thanks-ill-complete-my-pr-wrapper {
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: center;
}
.no-thanks {
  align-self: stretch;
  overflow: hidden;
  padding: 0 var(--padding-7xs) var(--padding-16xl);
  align-items: center;
  justify-content: center;
}
.body18,
.no-thanks,
.welcome2,
.welcome3 {
  display: flex;
  flex-direction: column;
}
.welcome3 {
  border-radius: var(--br-5xs);
  background-color: var(--white);
  width: 524px;
  height: 521px;
  align-items: center;
  justify-content: flex-end;
}
.body18,
.welcome2 {
  overflow: hidden;
}
.body18 {
  align-self: stretch;
  padding: 164px 458px;
  align-items: center;
  justify-content: center;
  font-size: var(--p1-regular-16-size);
}
.welcome2 {
  position: relative;
  background-color: var(--royal-blue);
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
  text-align: left;
  font-size: var(--h5-btns-med-14-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
}
@media screen and (max-width: 1200px) {
  .menu17 {
    display: flex;
  }
  .button-text92:hover {
    color: var(--color-black);
  }
  .register3 {
    display: none;
  }
}
@media screen and (max-width: 850px) {
  .top-nav-left17 {
    display: none;
  }
  .menu17 {
    display: flex;
  }
  .register3 {
    display: none;
  }
  .body18 {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 520px) {
  .language-icon3 {
    width: 14px;
    height: 14px;
  }
  .language3 {
    font-size: var(--font-size-3xs);
  }
  .line-under-link6,
  .line-under-link7 {
    width: 69px;
  }
  .headertop-navlink-parent1 {
    gap: var(--gap-mini);
  }
  .left-side-secondary-nav3 {
    gap: var(--gap-base);
  }
  .left-side-top-nav-parent1 {
    gap: var(--gap-301xl);
  }
  .headertop-navprimary3 {
    align-items: center;
    justify-content: center;
  }
  .footerlogo-icon15 {
    width: 80px;
    height: 65px;
  }
  .header-image1 {
    padding-top: var(--padding-16xl);
    padding-bottom: var(--padding-6xl);
    box-sizing: border-box;
  }
  .welcome-to-scholist {
    font-size: var(--font-size-3xl);
    text-align: center;
  }
  .welcome4 {
    padding-top: var(--padding-3xs);
    padding-bottom: var(--padding-3xs);
    box-sizing: border-box;
  }
  .would-you-like-container {
    font-size: var(--font-size-2xs);
  }
  .continue {
    padding-top: var(--padding-3xs);
    padding-bottom: var(--padding-3xs);
    box-sizing: border-box;
  }
  .continue1 {
    font-size: var(--h5-btns-med-14-size);
  }
  .buttonxxlarge3 {
    width: 270px;
    height: 40px;
  }
  .continue-button {
    padding-top: var(--padding-11xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .no-thanks-ill {
    font-size: var(--font-size-2xs);
    text-align: center;
    display: none;
    width: 200px;
  }
  .no-thanks {
    width: 464px;
    padding-left: var(--padding-161xl);
    padding-top: var(--padding-4xs);
    padding-right: 0;
    box-sizing: border-box;
  }
  .welcome3 {
    width: 350px;
    height: 380px;
  }
  .body18 {
    padding-left: var(--padding-181xl);
    padding-right: var(--padding-181xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 350px) {
  .headertop-navsecondary3 {
    display: none;
  }
  .headerlogo-icon17 {
    width: 124px;
    height: 31px;
  }
  .menu17 {
    width: 37px;
    height: 37px;
  }
  .left-side-top-nav-parent1 {
    gap: var(--gap-281xl);
  }
  .footerlogo-icon15 {
    width: 64px;
    height: 55px;
  }
  .header-image1 {
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .welcome-to-scholist {
    font-size: var(--h3-medium-20-size);
  }
  .welcome4 {
    padding-top: var(--padding-mini);
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .would-you-like-container {
    font-size: var(--p2-regular-12-size);
  }
  .continue {
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
    margin-top: 20px;
  }
  .continue1 {
    font-size: var(--h5-btns-med-14-size);
  }
  .buttonxxlarge3 {
    width: 200px;
  }
  .no-thanks-ill {
    font-size: var(--font-size-3xs);
  }
  .no-thanks,
  .welcome3 {
    justify-content: center;
  }
  .no-thanks {
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    padding-bottom: var(--padding-mid);
    box-sizing: border-box;
  }
  .welcome3 {
    width: 290px;
    height: 450px;
    align-items: center;
  }
  .body18 {
    padding-left: var(--padding-81xl);
    padding-right: var(--padding-81xl);
    box-sizing: border-box;
  }
}
