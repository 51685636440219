@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h4-medium-16: Poppins;
  --font-inherit: inherit;

  /* font sizes */
  --h5-btns-med-14-size: 14px;
  --font-size-4xs: 9px;
  --h4-medium-16-size: 16px;
  --h6-medium-12-size: 12px;
  --font-size-3xs: 10px;
  --font-size-5xl: 24px;
  --font-size-smi: 13px;
  --font-size-2xs: 11px;
  --h3-medium-20-size: 20px;
  --font-size-6xl: 25px;
  --font-size-lg: 18px;
  --font-size-13xl: 32px;
  --font-size-2xl-6: 21.6px;
  --font-size-mid: 17px;
  --font-size-5xs: 8px;

  /* Colors */
  --white: #fff;
  --gray-4: #79797d;
  --logo-black: #010101;
  --logo-color-2: #4a93cf;
  --color-steelblue-100: rgba(74, 147, 207, 0.3);
  --gray-3: #c4c6c7;
  --color-black: #000;
  --color-whitesmoke: #f2f2f2;
  --color-darkgray-100: #b2b1b1;
  --color-mediumspringgreen: #39f4b1;
  --color-darkturquoise: #00b8c3;
  --color-mediumslateblue: #5d57f9;
  --color-gainsboro: #e7e7e7;
  --color-darkslategray: #393939;

  /* Gaps */
  --gap-11xs: 2px;
  --gap-0: 0px;
  --gap-3xs: 10px;
  --gap-10xs: 3px;
  --gap-mid: 17px;
  --gap-41xl: 60px;
  --gap-xl: 20px;
  --gap-6xs: 7px;
  --gap-35xl: 54px;
  --gap-9xl: 28px;
  --gap-6xl: 25px;
  --gap-sm: 14px;
  --gap-mini: 15px;
  --gap-9xs: 4px;
  --gap-12xs: 1px;
  --gap-2xs: 11px;
  --gap-11xl: 30px;
  --gap-10xl: 29px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-3xl: 22px;
  --gap-lg: 18px;
  --gap-8xs: 5px;
  --gap-7xs: 6px;
  --gap-13xl: 32px;

  /* Paddings */
  --padding-8xs: 5px;
  --padding-3xs: 10px;
  --padding-61xl: 80px;
  --padding-21xl: 40px;
  --padding-xl: 20px;
  --padding-57xl: 76px;
  --padding-11xl: 30px;
  --padding-55xl: 74px;
  --padding-31xl: 50px;
  --padding-3xl: 22px;
  --padding-2xl: 21px;
  --padding-6xl: 25px;
  --padding-4xl: 23px;
  --padding-17xl: 36px;
  --padding-mid: 17px;
  --padding-5xl: 24px;
  --padding-2xs: 11px;
  --padding-29xl: 48px;
  --padding-39xl: 58px;
  --padding-107xl: 126px;
  --padding-27xl: 46px;
  --padding-74xl: 93px;
  --padding-24xl: 43px;
  --padding-54xl: 73px;
  --padding-13xl: 32px;
  --padding-xs: 12px;
  --padding-41xl: 60px;
  --padding-10xl: 29px;
  --padding-5xs: 8px;
  --padding-75xl: 94px;
  --padding-smi: 13px;
  --padding-141xl: 160px;
  --padding-mini: 15px;
  --padding-7xs-6: 5.6px;
  --padding-5xs-4: 7.4px;

  /* Border radiuses */
  --br-xl: 20px;
  --br-9xs: 4px;
  --br-5xs: 8px;
  --br-45xl: 64px;
  --br-5xl: 24px;
  --br-31xl: 50px;
  --br-xs: 12px;
  --br-6xs-5: 6.5px;
}


.messages {
  position: relative;
  font-weight: 500;
}
.mymessagesheader2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.mymessageslistprofile-icon {
  position: relative;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.mymessageslistname {
  position: relative;
  display: flex;
  align-items: center;
  width: 173px;
  height: 16px;
  flex-shrink: 0;
}
.mymessageslistdate {
  position: relative;
  font-size: var(--h6-medium-12-size);
  color: var(--gray-4);
  text-align: center;
}
.mymessageslistheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xs);
}
.coca-cola-scholarship-form {
  margin: 0;
}
.mymessageslistsummary-txt {
  line-break: anywhere;
  width: 100%;
}
.mymessageslistsummary {
  position: relative;
  font-size: var(--h5-btns-med-14-size);
  color: var(--gray-4);
  display: flex;
  align-items: center;
  width: 316px;
  /* height: 47px; */
  flex-shrink: 0;
}
.mymessageslistbox3 {
  width: 316px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}
.mymessageslistbox2 {
  position: absolute;
  top: 17px;
  left: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xl);
}
.mymessageslistbox1 {
  cursor: pointer;
}
.mymessageslistbox1,
.mymessageslistbox11 {
  position: relative;
  border-radius: var(--br-xl);
  width: 421px;
  height: 90px;
  overflow: hidden;
  flex-shrink: 0;
}
.mymessageslistbox {
  position: absolute;
  top: 98px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.mymessagessearchinput {
  border: 0;
  font-family: var(--h4-medium-16);
  font-size: var(--h4-medium-16-size);
  background-color: rgba(178, 177, 177, 0.28);
  border-radius: var(--br-9xs);
  width: 261px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-3xs);
  box-sizing: border-box;
  z-index: 0;
}
.mymessagesnewicon1 {
  position: absolute;
  height: 108.89%;
  width: 108.89%;
  top: -4.44%;
  right: -4.44%;
  bottom: -4.44%;
  left: -4.44%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.div {
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 27.78%;
  font-size: var(--h6-medium-12-size);
  font-family: var(--h4-medium-16);
  color: var(--logo-black);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mymessagesnewicon1-parent {
  position: relative;
  width: 22.5px;
  height: 22.5px;
}
.mymessagesnewicon {
  cursor: pointer;
  border: 0;
  padding: 4px;
  background-color: transparent;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.mymessagesnewicon11 {
  position: absolute;
  margin: 0 !important;
  top: 10px;
  left: 361px;
  width: 4px;
  height: 20px;
  display: none;
  z-index: 2;
}
.mymessagessearch {
  position: absolute;
  top: 28px;
  left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 51px;
}
.sidemymessgage {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  width: 424px;
  height: 596px;
  overflow: hidden;
  flex-shrink: 0;
}
.writemessagebodyreceiverprofil-icon1 {
  position: relative;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.writemessagebodyreceivername1 {
  flex: 1;
  position: relative;
}
.writemessagebodyreceiver1 {
  align-self: stretch;
  border-radius: var(--br-xl);
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-2xs) var(--padding-11xl);
  box-sizing: border-box;
  gap: var(--gap-9xl);
}
.today1 {
  position: relative;
}
.writemessagebodyheader1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--gray-4);
}
.writebodysendermessagetext1 {
  position: relative;
  display: flex;
  align-items: center;
  width: 228px;
  flex-shrink: 0;
}
.writemessagebodysendermessage11 {
  border-radius: var(--br-5xs);
  background-color: var(--logo-color-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-4xl) var(--padding-17xl);
}
/* .writemessagebodysendermessage2 {
  align-self: stretch;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 var(--padding-6xl) 0 0;
  box-sizing: border-box;
} */


.writemessagebodysendermessage2 {
  align-self: stretch;
  /* height: 70px; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 var(--padding-6xl) 0 0;
  box-sizing: border-box;
  gap: 2px;
}
.writemessagebodyreceivermessag3 {
  border-radius: var(--br-5xs);
  /* background-color: var(--color-mediumspringgreen); */
  background-color: grey;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-4xl) var(--padding-17xl);
}

/* .writemessagebodyreceivermessag2 {
  /* align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; */


  /* display: flex;
flex-direction: column;
align-items: flex-start;
gap: 54px;
/* flex: 1 0 0; */
/* align-self: stretch;
height: 70px;
} */ 

.writemessagebodyreceivermessag2 {
  align-self: stretch;
  /* height: 70px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-6xl) 0 0;
  box-sizing: border-box;
  gap: 2px;
}
.writemessagebodyreceivermessag2 {
  padding: 0 0 0 var(--padding-6xl);
  color: var(--color-black);
}


.writemessagebodybox1 {

  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 10px;
  color: var(--white);
  flex: 1 0 0;
  align-self: stretch;
  max-height: 400px;
  overflow-y: auto;
  /* Hide the scrollbar for Webkit browsers (Chrome, Safari, etc.) */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Hide the scrollbar for Webkit browsers (Chrome, Safari, etc.) */
.writemessagebodybox1::-webkit-scrollbar {
  width: 6px; /* adjust as needed */
}

.writemessagebodybox1::-webkit-scrollbar-thumb {
  background-color: transparent; /* adjust as needed */
}
.writemessagebodybox1::-webkit-scrollbar-track {
  background-color: transparent; /* adjust as needed */
}
.writemessagepaperclipicon1 {
  position: absolute;
  height: 94.21%;
  width: 89.32%;
  top: 1.62%;
  right: 6.5%;
  bottom: 4.17%;
  left: 4.17%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.writemessagepaperclip1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.writebodytypemessageinput11
{
  font-size: 13px;
  font-family: var(--h4-medium-16);
  display: flex;
  align-items: center;
}

.writemessagebodysendbuttontext1 {
  font-size: var(--h4-medium-16-size);
  font-family: var(--h4-medium-16);
  display: flex;
  align-items: center;
}
.writebodytypemessageinput11 {
  border: 1px solid var(--color-darkgray-100);
  background-color: transparent;
  flex: 1;
  border-radius: var(--br-5xl);
  box-sizing: border-box;
  height: 68px;
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-3xs)
    var(--padding-2xl);
}
.writemessagebodysendbuttontext1 {
  position: relative;
  color: var(--color-black);
  text-align: center;
  justify-content: center;
  width: 128px;
  height: 45px;
  flex-shrink: 0;
}
.writemessagebodysendbutton11 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--logo-color-2);
  border-radius: var(--br-45xl);
  width: 128px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.writemessagebodytypemessage11 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
}
.writemessagebody1,
.writemessagebodytypemessage2 {
  background-color: var(--white);
  /* overflow: hidden; */
  flex-direction: column;
}
.writemessagebodytypemessage2 {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xl) var(--padding-55xl);
}
.writemessagebody1 {
  flex: 1;
  border-radius: var(--br-5xs);
  height: 596px;
  /* gap: var(--gap-41xl); */
}
.my-messages,
.mymessagesheader11 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.writemessagebody1 {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.mymessagesheader11 {
  align-self: stretch;
  flex-direction: row;
  gap: 24px;
  font-size: var(--h4-medium-16-size);
}
.my-messages {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  padding: 137px var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-mid);
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--logo-black);
  font-family: var(--h4-medium-16);
}

.messageBottom{

  font-size: 9px;
  margin-top: 10px;
  color: #000;
 
}
.noConversationText {
  position: absolute;
  /* top: 10%; */
  font-size: 36px;
  color: rgb(224, 220, 220);
  align-self: stretch;
  cursor: default;
  padding-top: 30px;
  padding-left: 30px;
}
.noconversationBox{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  align-self: stretch;
  height: 100%;
}
@media screen and (max-width: 1200px) {
  .mymessagesheader2 {
    width: 810px;
  }
  .mymessageslistprofile-icon {
    width: 40px;
    height: 40px;
  }
  .mymessageslistname {
    font-size: var(--font-size-smi);
    width: 153px;
  }
  .mymessageslistdate {
    font-size: var(--font-size-2xs);
  }
  .mymessageslistheader {
    gap: var(--gap-0);
  }
  .mymessageslistsummary {
    font-size: var(--h6-medium-12-size);
  }
  .mymessageslistbox3 {
    gap: var(--gap-12xs);
  }
  .mymessageslistbox2 {
    gap: var(--gap-sm);
  }
  .mymessageslistbox11 {
    width: 400px;
  }
  .mymessagessearchinput {
    width: 227px;
  }
  .mymessagessearch {
    width: 374px;
    gap: var(--gap-6xl);
  }
  .sidemymessgage {
    width: 334px;
  }
  .writemessagebodyheader1,
  .writemessagebodyreceiver1 {
    /* width: 450px; */
  }
  .writebodysendermessagetext1 {
    font-size: var(--h5-btns-med-14-size);
    width: 200px;
  }
  .writemessagebodyreceivermessag3,
  .writemessagebodysendermessage11 {
    padding: var(--padding-mid) var(--padding-5xl);
    box-sizing: border-box;
  }
  .writemessagebodyreceivermessag2 {
    width: 450px;
  }
  .writemessagebodytypemessage11 {
    gap: var(--gap-6xs);
  }
  .writemessagebodytypemessage2 {
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
  .writemessagebody1 {
    width: 450px;
  }
  .my-messages {
    padding-top: var(--padding-61xl);
    box-sizing: border-box;
  }
  .noConversationText {
   
    /* top: 10%; */
    font-size: 26px;
    
  }
}
@media screen and (max-width: 850px) {
  .noConversationText {
   
    /* top: 10%; */
    font-size: 18px;
    
  }
  /* .noconversationBox{
  display: none;
  } */
  .mymessagesheader2 {
    width: 500px;
  }
  .mymessageslistprofile-icon {
    width: 50px;
    height: 50px;
  }
  .mymessageslistname {
    font-size: var(--h4-medium-16-size);
    width: 173px;
  }
  .mymessageslistdate {
    font-size: var(--font-size-smi);
  }
  .mymessageslistheader {
    gap: var(--gap-11xl);
  }
  .mymessageslistsummary {
    font-size: var(--h4-medium-16-size);
  }
  .mymessageslistbox2 {
    gap: var(--gap-xl);
  }
  .mymessageslistbox11 {
    width: 550px;
  }
  .mymessagessearchinput {
    width: 297px;
  }
  .sidemymessgage {
    display: flex;
    width: 500px;
  }
  .writemessagebodysendbutton11 {
    width: 108px;
  }
  .writemessagebody1 {
    /* display: none; */
    width: 450px;
  }
  .my-messages {
    background-color: var(--color-whitesmoke);
    padding-top: var(--padding-xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 520px) {
  .mymessagesheader2 {
    width: 380px;
  }
  .mymessageslistprofile-icon {
    width: 40px;
    height: 40px;
  }
  .mymessageslistname {
    font-size: var(--font-size-smi);
    width: 153px;
  }
  .mymessageslistdate {
    font-size: var(--font-size-2xs);
  }
  .mymessageslistheader {
    gap: var(--gap-3xs);
  }
  .mymessageslistsummary {
    font-size: var(--font-size-smi);
    height: 40px;
  }
  .mymessageslistbox2 {
    gap: var(--gap-mini);
  }
  .mymessagessearchinput {
    width: 237px;
  }
  .sidemymessgage {
    display: flex;
    width: 380px;
  }
  .writemessagebodysendbuttontext1 {
    width: 108px;
  }
  .writemessagebodytypemessage2 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .my-messages {
    padding: var(--padding-57xl) var(--padding-xl);
    box-sizing: border-box;
  }
}
