.billing-dashboard {
  display: flex;
  height: 110vh;
  background-color: var(--gray-0.2);
}

.billing-main-content {
  padding: 20px;
  width: 20%;
  margin-left: 2%;
  margin-top: 3%;
  overflow-y: auto;
}

.stat {
  display: flex;
  margin-bottom: 5%;
  gap: 5%;
}

.stat h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15.2873px;

  color: #7a7a7a;
}

.icon-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.3122px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 1.2%;

  color: #393939;
}

.stat:hover {
  color: #4a93cf;
}

.stat.active {
  color: #4a93cf;
}

.stat.active .icon-text {
  color: #4a93cf;
}

/* .stat.active img {
    filter: hue-rotate(180deg) brightness(1.2); 
  } */

/* Heading */
.bill-head {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 33.3px;
  line-height: 50px;
  color: #4a93cf;
  white-space: nowrap;
  margin-top: 13%;
  margin-left: 10%;
}

/* Form Container */
.right-main-content {
  margin-left: 6%;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  width: 210%;
  overflow-y: auto;
}

/* Form */
.main-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Input Field Container */
.main-label-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Label Text */
.label-header {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

/* Input Fields */
.main-input-fields {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  background: linear-gradient(0deg, #edf2f7, #edf2f7), #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.main-input-fields:focus {
  border-color: #0056b3;
}

/* Checkbox */
.checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  color: #333;
}

/* Button */
.update-button {
  width: fit-content; /* Adjusted to fit the content of the button */
  padding: 12px 24px; /* Custom padding for better proportions */
  background-color: #0056b3;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 auto; /* Centers the button horizontally */
}

.update-button:hover {
  background-color: #003f8c;
}

@media (max-width: 1400px) {
  .bill-head {
    font-size: 28px; /* Reduce heading font size */
    margin-left: 5%; /* Adjust margin for smaller screens */
  }

  .right-main-content {
    width: 210%; /* Reduce the width of the form container */
    margin-left: 6%; /* Adjust margin for better alignment */
  }

  .main-input-fields {
    font-size: 0.9rem; /* Slightly smaller font size */
    padding: 8px; /* Reduce padding inside input fields */
  }

  .update-button {
    padding: 10px 20px; /* Reduce button padding */
  }
}

@media (max-width: 1300px) {
  .main-input-fields {
    font-size: 0.8rem; /* Slightly smaller font size */
    padding: 4px; /* Reduce padding inside input fields */
  }

  .update-button {
    padding: 10px 20px; /* Reduce button padding */
  }
}

.dashboard-pay-content {
  margin-left: 6%;
  margin-top: 4%;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  width: 180%;
  overflow-y: auto;
}

.pay-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  color: #787878;
}

.pay-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 1%;
  color: #a0a0a0;
}

.payout-option {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  width: 90%;
  height: 88px;
  margin-bottom: 3%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: transform 0.2s ease-in-out;
}

.payment-methods-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: 70px;
  height: 70px;
  margin-left: 3%;
  margin-right: 15px;
}

.pay-details h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20.0032px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4a93cf;
}

.pay-details p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #a0a0a0;
}
