.dasboardheader{
  color: #4A93CF;

/* H3 - Medium 20px */
font-family: Poppins;
/* font-size: 20px; */
font-style: normal;
font-weight: 500;
line-height: normal;
}


.dashboardheaderbox{
  padding-top: 30px; 
  display: inline-flex;
  /* align-items: center; */
 
}

.scholarshipsApplied{

  color: var(--logo-black, #010101);

/* H2 - Bold 32px */
font-family: Poppins;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-left: 10px;
}

.noOfmatches{
  color: #000;
  /* H3 - Medium 20px */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 200px
}

.noOfmatches1{
  color: #000;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.MatchesndDeadline{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  height: 44px;
  gap: 152px;

}


.availablematches{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  overflow: hidden;
  padding: 0 var(--padding-136xl);
  font-size: var(--h3-medium-20-size);
}

.noofmatchesnamebox{
  width: 740px;
}

.savedIconName{
  gap: 20px;
}


@media screen and (max-width: 850px) {
  .noOfmatches{
      display: none;
  }
  .filter-dropdown-button3{
      display: none;
  }

  .MatchesndDeadlin{
      align-items: center;
      justify-content: center;
  }
  .noofmatchesnamebox {
      width: 260px; 
  }
  .allpage-header9{
      align-items: center;
      justify-content: center;
  }

}


@media screen and (max-width: 520px) {
  .scholarshipsApplied{
      font-size: 26px;
  }

  .noOfmatches1{
      font-size: 16px;
  }
  .noofmatchesnamebox {
      width: 209px; 
  }

}