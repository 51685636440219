@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;800;900&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h6-medium-12: Poppins;
  --font-inter: Inter;

  /* font sizes */
  --font-size-lg: 18px;
  --font-size-mini: 15px;
  --h6-medium-12-size: 12px;
  --h5-btns-med-14-size: 14px;
  --font-size-4xl: 23px;
  --font-size-4xs: 9px;
  --font-size-7xs: 6px;
  --font-size-2xl-6: 21.6px;
  --font-size-mid: 17px;
  --font-size-3xs: 10px;
  --font-size-2xs: 11px;
  --font-size-lg-8: 18.8px;
  --h2-bold-32-size: 32px;
  --font-size-2xl: 21px;
  --font-size-4xl-2: 23.2px;
  --font-size-lgi: 19px;
  --font-size-5xl: 24px;
  --h3-medium-20-size: 20px;
  --font-size-17xl: 36px;
  --p1-regular-16-size: 16px;
  --font-size-3xl: 22px;
  --font-size-3xl-7: 22.7px;
  --font-size-smi: 13px;
  --font-size-4xl-4: 23.4px;
  --font-size-9xl-1: 28.1px;
  --h1-bold-52-size: 52px;
  --font-size-11xl: 30px;
  --font-size-5xs: 8px;
  --font-size-6xl: 25px;
  --font-size-21xl: 40px;

  /* Colors */
  --white: #fff;
  --color-darkslateblue: #1b1139;
  --gray-5: #393939;
  --gradient-2: rgba(57, 57, 57, 0.4);
  --logo-color-2: #4a93cf;
  --color-steelblue-100: rgba(74, 147, 207, 0.3);
  --logo-color-1: #181048;
  --gray-4: #79797d;
  --color-gray-100: #787878;
  --logo-black: #010101;
  --profile-questions-background: rgba(1, 1, 1, 0.7);
  --color-silver-100: #c7c7c7;
  --gray-3: #c4c6c7;
  --color-black: #000;
  --purple-blue: #5558cf;
  --gray-1: #f2f2f2;
  --color-mediumaquamarine: #70d499;
  --royal-blue: #0d0ed9;
  --color-lightgray: #d5d4df;
  --color-mediumslateblue: #5d57f9;
  --color-gainsboro-100: #e7e7e7;
  --color-gainsboro-200: #dfd6d6;
  --color-darkgray-100: #b2b1b1;
  --color-darkgray-200: #a1a2af;
  --color-red: #f40000;
  --color-gold: #f8d100;
  --color-darkturquoise: #00b8c3;
  --color-ghostwhite: #f4f6fa;
  --color-mediumspringgreen: #39f4b1;

  /* Gaps */
  --gap-3xs: 10px;
  --gap-14xl: 33px;
  --gap-11xl: 30px;
  --gap-smi: 13px;
  --gap-lgi: 19px;
  --gap-13xl: 32px;
  --gap-sm: 14px;
  --gap-7xs: 6px;
  --gap-11xs: 2px;
  --gap-9xs: 4px;
  --gap-5xs: 8px;
  --gap-6xs: 7px;
  --gap-31xl: 50px;
  --gap-9xl: 28px;
  --gap-xs: 12px;
  --gap-mini: 15px;
  --gap-3xl: 22px;
  --gap-61xl: 80px;
  --gap-0: 0px;
  --gap-base: 16px;
  --gap-10xs: 3px;
  --gap-8xs: 5px;
  --gap-12xs: 1px;
  --gap-18xl: 37px;
  --gap-3xs-4: 9.4px;
  --gap-xl: 20px;
  --gap-2xs: 11px;
  --gap-mini-1: 14.1px;
  --gap-mid: 17px;
  --gap-69xl: 88px;
  --gap-381xl: 400px;
  --gap-663xl: 682px;
  --gap-331xl: 350px;
  --gap-41xl: 60px;
  --gap-35xl: 54px;
  --gap-6xl: 25px;
  --gap-10xl: 29px;
  --gap-lg: 18px;

  /* Paddings */
  --padding-42xl: 61px;
  --padding-base: 16px;
  --padding-3xs: 10px;
  --padding-21xl: 40px;
  --padding-xl: 20px;
  --padding-36xl: 55px;
  --padding-8xs: 5px;
  --padding-15xl: 34px;
  --padding-7xl: 26px;
  --padding-6xl: 25px;
  --padding-mid: 17px;
  --padding-181xl: 200px;
  --padding-101xl: 120px;
  --padding-xs: 12px;
  --padding-smi: 13px;
  --padding-4xs: 9px;
  --padding-61xl: 80px;
  --padding-84xl: 103px;
  --padding-4xs-2: 8.2px;
  --padding-9xs: 4px;
  --padding-118xl: 137px;
  --padding-57xl: 76px;
  --padding-11xs: 2px;
  --padding-31xl: 50px;
  --padding-51xl: 70px;
  --padding-55xl: 74px;
  --padding-mini: 15px;
  --padding-12xl: 31px;
  --padding-2xs: 11px;
  --padding-11xl: 30px;
  --padding-2xl: 21px;
  --padding-141xl: 160px;
  --padding-13xl: 32px;
  --padding-10xl: 29px;
  --padding-4xl: 23px;
  --padding-39xl: 58px;
  --padding-447xl: 466px;
  --padding-3xl: 22px;
  --padding-6xs-5: 6.5px;
  --padding-5xs: 8px;
  --padding-134xl: 153px;
  --padding-sm: 14px;
  --padding-139xl: 158px;
  --padding-136xl: 155px;
  --padding-161xl: 180px;
  --padding-351xl: 370px;
  --padding-7xs: 6px;
  --padding-10xs: 3px;
  --padding-131xl: 150px;
  --padding-53xl: 72px;
  --padding-58xl: 77px;
  --padding-lgi: 19px;
  --padding-93xl: 112px;
  --padding-8xl: 27px;
  --padding-6xs: 7px;
  --padding-5xl: 24px;
  --padding-33xl: 52px;
  --padding-17xl: 36px;
  --padding-29xl: 48px;
  --padding-107xl: 126px;
  --padding-27xl: 46px;
  --padding-74xl: 93px;
  --padding-24xl: 43px;
  --padding-54xl: 73px;
  --padding-41xl: 60px;
  --padding-75xl: 94px;
  --padding-7xs-6: 5.6px;
  --padding-5xs-4: 7.4px;

  /* Border radiuses */
  --br-3xs: 10px;
  --br-xl: 20px;
  --br-5xs: 8px;
  --br-xs: 12px;
  --br-3xs-4: 9.4px;
  --br-4xs: 9px;
  --br-7xs: 6px;
  --br-61xl: 80px;
  --br-mid: 17px;
  --br-6xl: 25px;
  --br-9xs: 4px;
  --br-45xl: 64px;
  --br-5xl: 24px;
  --br-31xl: 50px;
  --br-6xs-5: 6.5px;

  /* Effects */
  --shadow-1: 0px 1px 3px rgba(0, 0, 0, 0.25);
}


.cgi-stacked-lockup-blue1-icon,
.dbf43de48b00044eb5af-icon {
  width: 89.4px;
  position: relative;
  height: 37.4px;
  object-fit: cover;
}
.dbf43de48b00044eb5af-icon {
  width: 102.1px;
}
.future-founders-color-logo-icon,
.verizon-logo-1-icon,
.visa-logo-icon {
  width: 132.5px;
  position: relative;
  height: 37.4px;
  object-fit: cover;
}
.verizon-logo-1-icon,
.visa-logo-icon {
  width: 131.8px;
}
.visa-logo-icon {
  width: 87.3px;
}
.partnersbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.partners,
.partnership-strip {
  display: flex;
  flex-direction: column;
}
.partnership-strip {
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 352px;
}
.partners {
  align-self: stretch;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mid) 0;
}
.faqtext {
  align-self: stretch;
  position: relative;
  line-height: 130%;
  display: inline-block;
  height: 45px;
  flex-shrink: 0;
  padding-bottom: 120px;
}
.faqcardicon {
  width: 30px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--logo-color-2);
  height: 5px;
  opacity: 0.8;
}
.faqcardheader {
  flex: 1;
  position: relative;
  line-height: 130%;
  font-weight: 600;
  opacity: 0.88;
}
.faqcardbox1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.faqcarddescriptioncardbox2 {
  align-self: stretch;
  position: relative;
  font-size: var(--h5-btns-med-14-size);
  letter-spacing: 0.1px;
  line-height: 150.7%;
  color: #363049;
  opacity: 0.88;
}
.faqcard,
.faqcardbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.faqcardbox {
  align-self: stretch;
  align-items: flex-end;
  gap: var(--gap-13xl);
}
.faqcard {
  flex: 1;
  border-radius: var(--br-3xs);
  background-color: var(--white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  align-items: center;
  padding: var(--padding-6xl) var(--padding-7xl);
}
.faqcardplusicon-child,
.faqcardplusicon-item {
  position: absolute;
  top: 12.5px;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-darkslateblue);
  width: 30px;
  height: 5px;
  opacity: 0.8;
}
.faqcardplusicon-item {
  top: 0;
  left: 17.5px;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.faqcardplusicon {
  width: 30px;
  position: relative;
  height: 30px;
}
.faqcardheader1 {
  height: 23px;
  flex: 1;
  position: relative;
  line-height: 130%;
  font-weight: 600;
  display: inline-block;
  opacity: 0.88;
}
.faqcardbox11 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.faqcard1 {
  flex: 1;
  border-radius: var(--br-3xs);
  background-color: var(--white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-15xl) var(--padding-7xl);
}
.cardsframefaq,
.faq,
.homepage {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardsframefaq {
  align-self: stretch;
  /* height: 409px; */
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 85px;
  box-sizing: border-box;
  gap: var(--gap-14xl);
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-darkslateblue);
}
.faq,
.homepage {
  flex-direction: column;
}
.faq {
  align-self: stretch;
  padding: 150px var(--padding-base);
  gap: 30px;
  text-align: center;
  font-size: 39px;
  color: #000339;
}
.homepage {
  width: 1440px;
  background-color: var(--white);
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-4xs);
  color: var(--gray-4);
  font-family: var(--h6-medium-12);
}

.faqcardiconPlus{
  width: 35px;
  height: 35px;
}
@media screen and (max-width: 1200px) {
  html,body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .see-what-others {
    font-size: 7px;
  }
  .cgi-stacked-lockup-blue1-icon {
    width: 83px;
    height: 32px;
  }
  .dbf43de48b00044eb5af-icon {
    width: 78px;
    height: 27px;
  }
  .future-founders-color-logo-icon {
    width: 127px;
    height: 32px;
  }
  .verizon-logo-1-icon {
    width: 126px;
    height: 32px;
  }
  .visa-logo-icon {
    width: 82px;
    height: 32px;
  }
  .partnersbox {
    gap: var(--gap-7xs);
  }
  .partnership-strip {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .partners {
    width: auto;
    align-self: unset;
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--padding-181xl);
    padding-right: var(--padding-181xl);
    box-sizing: border-box;
  }
  .faqtext {
    font-size: 35px;
  }
  .faqcardheader {
    font-size: var(--font-size-mini);
  }
  .faqcarddescriptioncardbox2 {
    font-size: var(--h6-medium-12-size);
  }
  .faqcardbox {
    gap: var(--gap-sm);
  }
  .faqcardheader1 {
    font-size: var(--font-size-mini);
  }
  .faqcardbox11 {
    gap: var(--gap-smi);
  }
  .cardsframefaq,
  .faq {
    padding-left: var(--padding-36xl);
    padding-right: var(--padding-36xl);
    box-sizing: border-box;
  }
  .faq {
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 850px) {
  html,body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .cgi-stacked-lockup-blue1-icon {
    width: 78px;
    height: 27px;
  }
  .dbf43de48b00044eb5af-icon {
    width: 67px;
    height: 22px;
  }
  .future-founders-color-logo-icon,
  .verizon-logo-1-icon,
  .visa-logo-icon {
    width: 73px;
    height: 22px;
  }
  .partnersbox {
    gap: var(--gap-11xs);
  }
  .partners {
    padding-left: var(--padding-101xl);
    padding-right: var(--padding-101xl);
    box-sizing: border-box;
    justify-content: center;
  }
  .faqtext {
    font-size: 27px;
  }
  .faqcardicon {
    width: 23px;
    height: 4px;
  }
  .faqcardplusicon {
    width: 20px;
    height: 20px;
  }
  .faqcardbox11 {
    gap: var(--gap-lgi);
  }
  .cardsframefaq,
  .faq {
    box-sizing: border-box;
  }
  .cardsframefaq {
    padding-left: 8px;
    padding-right: 0;
    padding-bottom: 10px;
  }
  .faq {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    padding-bottom: var(--padding-xl);
  }

  .faqcardiconPlus{
    width: 25px;
    height: 25px;
  }
}
@media screen and (max-width: 520px) {
  html,body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .see-what-others {
    font-size: var(--font-size-7xs);
    width: 90px;
  }
  .cgi-stacked-lockup-blue1-icon,
  .dbf43de48b00044eb5af-icon,
  .future-founders-color-logo-icon,
  .verizon-logo-1-icon,
  .visa-logo-icon {
    width: 48px;
    height: 18px;
  }
  .partnersbox {
    gap: var(--gap-9xs);
  }
  .partners {
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .faqtext {
    font-size: var(--font-size-4xl);
  }
  .faqcardheader {
    font-size: var(--h6-medium-12-size);
  }
  .faqcardplusicon {
    width: 20px;
    height: 20px;
  }
  .faqcardheader1 {
    font-size: var(--h6-medium-12-size);
  }
  .homepage {
    align-items: center;
    justify-content: center;
  }
}
#faqDescription {
  display: block; /* or 'none' if you want it hidden by default */
}

.faqcardheader {
  cursor: pointer;
}

.communitiybutton{
  color: #fff;
}
.language-icon {
  position: relative;
  width: 16px;
  height: 16px;
}
.language {
  position: relative;
  font-weight: 500;
}
.down-icon {
  position: relative;
  width: 10px;
  height: 6.18px;
}
.headerdrop-downlang,
.right-side-secondary-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.headerdrop-downlang {
  gap: var(--gap-5xs);
}
.right-side-secondary-nav {
  position: absolute;
  top: calc(50% - 11px);
  right: 0;
  padding: 0 var(--padding-13xl) 0 0;
}
.line-under-link {
  position: relative;
  background-color: var(--gray-4);
  width: 109px;
  height: 2px;
}
.headertop-navlink {
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs) 0 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.line-under-link1 {
  position: relative;
  background-color: var(--gray-3);
  width: 109px;
  height: 2px;
}
.headertop-navlink-parent,
.left-side-secondary-nav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.headertop-navlink-parent {
  gap: var(--gap-13xl);
}
.left-side-secondary-nav {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  padding: 0 0 0 var(--padding-13xl);
}
.headertop-navsecondary {
  align-self: stretch;
  position: relative;
  background-color: var(--gray-1);
  height: 40px;
}
.logo-bird-vector,
.logo-words-icon {
  position: absolute;
  height: 100%;
  width: 33.26%;
  top: 0;
  right: 66.74%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.logo-words-icon {
  height: 45.93%;
  width: 68.87%;
  top: 47.9%;
  right: 0;
  bottom: 6.18%;
  left: 31.13%;
}
.headerlogo {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 134px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-active {
  position: relative;
  font-weight: 500;
}
.headernav-link {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--royal-blue);
}
.headernav-link1 {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}
.headernav-link1,
.headernav-link4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headernav-link-parent {
  flex: 1;
  align-items: flex-start;
  gap: var(--gap-13xl);
}
.headerlogo-parent,
.headernav-link-parent,
.top-nav-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.top-nav-left {
  flex: 1;
  height: 21px;
  align-items: center;
}
.headerlogo-parent {
  position: absolute;
  top: 0;
  left: 32px;
  width: 644px;
  align-items: flex-end;
  gap: var(--gap-13xl);
}
.left-side-top-nav {
  flex: 1;
  position: relative;
  height: 41px;
}
.vector-icon,
.vector-icon1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon1 {
  height: 50%;
  width: 75%;
  top: 25%;
  right: 12.5%;
  bottom: 25%;
  left: 12.5%;
}
.menu {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 47px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text {
  position: relative;
  font-size: var(--h5-btns-med-14-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--logo-black);
  text-align: center;
}
.buttonmedium {
  cursor: pointer;
  border: 1px solid var(--logo-black);
  padding: var(--padding-12xs) 0;
  background-color: transparent;
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  width: 128px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.buttonmedium:active {
  background-color: var(--white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.83);
}
.button-text1 {
  position: relative;
  font-size: var(--h5-btns-med-14-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--gray-1);
  text-align: center;
}
.button-text1:hover {
  color: var(--color-black);
}
.button-text1:active {
  -webkit-text-stroke: 1px #000;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.buttonmedium1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-12xs) 0;
  background-color: var(--royal-blue);
  border-radius: var(--br-5xs);
  width: 128px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.buttonmedium1:hover {
  box-shadow: 0 4px 4px #010101;
}
.buttonmedium1:active,
.buttonxxlarge-wrapper:active {
  box-shadow: 0 4px 4px rgba(1, 1, 1, 0.84);
}
.register,
.right-side-top-nav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.right-side-top-nav {
  padding: 0 var(--padding-13xl) 0 0;
  align-items: flex-end;
  justify-content: flex-end;
}
.left-side-top-nav-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-425xl);
}
.headertop-navprimary,
.nav-bar {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.headertop-navprimary {
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-3);
  padding: var(--padding-lgi) 0;
}
.nav-bar {
  background-color: var(--white);
  height: 120px;
  font-size: var(--h5-btns-med-14-size);
}
.banner-image-choice {
  position: relative;
  width: 377.12px;
  height: 557px;
  object-fit: cover;
}
.student-name,
.student-quote {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 117px;
}
.student-quote {
  font-size: var(--font-size-4xs);
  width: 133px;
}
.student-testimony {
  width: 135px;
  height: 364px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}
.banner-image-choice-parent {
  gap: var(--gap-9xl);
}
.banner-image-choice-parent,
.frame-container,
.frame-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
.frame-wrapper {
  align-items: flex-start;
}
.graduate-inner {
  width: 639px;
}
.graduate,
.graduate-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.applying-to-scholarships,
.discover-the-right {
  position: relative;
  display: flex;
  align-items: center;
  width: 675px;
}
.discover-the-right {
  font-weight: 500;
  width: 385px;
  flex-shrink: 0;
}
.discover-the-right-scholarship-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: flex-end;
  font-size: var(--h3-medium-20-size);
}
.applying-to-scholarships-made-parent,
.frame-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.applying-to-scholarships-made-parent {
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frame-div {
  align-self: stretch;
  justify-content: center;
}
.sign-up {
  position: relative;
  font-size: var(--p1-regular-16-size);
  line-height: 150%;
  font-family: var(--p1-regular-16);
  color: var(--gray-1);
  text-align: center;
}
.buttonxxlarge {
  border-radius: var(--br-7xs);
  background-color: var(--royal-blue);
  width: 400px;
  height: 40px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-8xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.buttonxxlarge-wrapper {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-items: flex-start;
  justify-content: flex-start;
}
.applying,
.buttonxxlarge-wrapper,
.frame-parent {
  display: flex;
  flex-direction: column;
}
.frame-parent {
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-3xs);
}
.applying {
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -157px;
  text-align: right;
  font-size: var(--h1-bold-52-size);
}
.banner,
.bnner-backgrround {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bnner-backgrround {
  width: 1440px;
  flex-direction: row;
  padding: 0 183px 0 var(--padding-81xl);
  box-sizing: border-box;
}
.banner {
  align-self: stretch;
  background-color: rgba(89, 88, 109, 0.1);
  flex-direction: column;
  font-size: var(--p1-regular-16-size);
}
.section-text {
  position: relative;
}
.homesection-header {
  width: 213px;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.how-it-works {
  overflow: hidden;
  display: none;
  flex-direction: column;
  padding: var(--padding-6xs) 0;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  color: var(--color-black);
}
.section-text1 {
  position: relative;
}
.homesection-header1,
.how-it-works1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.homesection-header1 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 43px;
  opacity: 0;
}
.homesection-header1.animate {
  animation: 1s ease 0s 1 normal forwards fade-in;
}
.how-it-works1 {
  width: 1428px;
  height: 62px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-6xs) 0 var(--padding-6xs) 139px;
  box-sizing: border-box;
  color: var(--color-black);
}
.homesection-divider-icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
}
.divider {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-141xl) var(--padding-smi);
  align-items: center;
  justify-content: center;
}
.how-it-works2 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: inline-block;
}
.details1 {
  position: relative;
  line-height: 150%;
  display: inline-block;
  width: 447px;
}
.details,
.link-to-page {
  font-size: var(--p1-regular-16-size);
}
.link-to-page {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--royal-blue);
  text-align: left;
  display: inline-block;
}
.details {
  position: absolute;
  height: 61.9%;
  width: 100%;
  top: 38.1%;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.homehow-it-workssection-desc {
  position: relative;
  width: 447px;
  height: 168px;
}
.homehow-it-worksimg {
  position: relative;
  width: 457px;
  height: 244px;
  object-fit: cover;
}
.personalized-matches {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-131xl);
}
.how-it-works3,
.how-it-works4 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: inline-block;
}
.how-it-works4 {
  text-transform: uppercase;
}
.community-and-support,
.homehow-it-workssection-desc-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-45xl);
}
.community-and-support {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-131xl);
}
.photo-icon {
  position: absolute;
  height: 99.59%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0.41%;
  left: 0;
  border-radius: var(--br-base);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.homehow-it-worksimg1 {
  position: relative;
  width: 457px;
  height: 244px;
}
.home-section-how-it-works {
  width: 1444px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-8xl) var(--padding-136xl) var(--padding-3xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.see-what-others {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 126px;
  flex-shrink: 0;
}
.cgi-stacked-lockup-blue1-icon,
.dbf43de48b00044eb5af-icon {
  position: relative;
  width: 89.36px;
  height: 37.42px;
  object-fit: cover;
}
.dbf43de48b00044eb5af-icon {
  width: 102.13px;
}
.future-founders-color-logo-icon,
.verizon-logo-1-icon,
.visa-logo-icon {
  position: relative;
  width: 132.54px;
  height: 37.42px;
  object-fit: cover;
}
.verizon-logo-1-icon,
.visa-logo-icon {
  width: 131.76px;
}
.visa-logo-icon {
  width: 87.32px;
}
.see-what-others-are-saying-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.partnership-strip {
  display: flex;
  flex-direction: column;
  padding: 0 352px;
  align-items: flex-start;
  justify-content: flex-start;
}
.partners,
.scholarships1 {
  display: flex;
  align-items: center;
}
.partners {
  align-self: stretch;
  /* background: linear-gradient(
    180deg,
    rgba(121, 121, 125, 0.2),
    rgba(121, 121, 125, 0)
  ); */
  /* fill: linear-gradient(180deg, rgba(121, 121, 125, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%); */
  background-color: white;
/* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.50) inset; */
  /* border: 1px solid var(--gray-4); */
  flex-direction: column;
  padding: var(--padding-mid) 0;
  justify-content: center;
  font-size: var(--font-size-4xs);
  color: var(--gray-4);
  width: 100%
}
.scholarships1 {
  position: absolute;
  width: 130.09%;
  top: -48.8%;
  left: 0.46%;
}
.scholarships {
  position: relative;
  width: 216px;
  height: 48px;
}
.header {
  align-self: stretch;
  height: 88px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xl) var(--padding-141xl) var(--padding-3xs) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.homscholimg {
  position: relative;
  width: 58.16px;
  height: 57px;
  object-fit: cover;
}
.cardscholarshipsbackgroundl {
  background-color: var(--color-azure);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-17xl);
  align-items: center;
  justify-content: center;
  width: 359px;
}
.cardscholarshipsbackgroundl-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.community-title {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 190px;
  height: 23px;
  flex-shrink: 0;
}
.bookmark-disabled-icon {
  position: relative;
  width: 16px;
  height: 21px;
}
.community-title-parent {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-21xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-44xl);
  font-size: var(--p1-regular-16-size);
}
.members {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
  text-indent: 1px;
  display: inline-block;
  width: 269.44px;
  height: 19px;
  flex-shrink: 0;
}
.members-wrapper {
  width: 351px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: var(--royal-blue);
}
.description {
  position: relative;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
  width: 269.44px;
  height: 50px;
  flex-shrink: 0;
}
.description-wrapper {
  width: 352px;
  height: 62px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.button-text-icon {
  position: relative;
  width: 65.52px;
  height: 9.98px;
}
.buttonlarge {
  cursor: pointer;
  border: 1.5px solid var(--logo-black);
  padding: var(--padding-12xs) 0;
  background-color: transparent;
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  width: 137px;
  height: 35px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.buttonlarge:active {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.55);
}
.button-text-icon1 {
  position: relative;
  width: 39.95px;
  height: 14.03px;
}
.buttonlarge1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-12xs) 0;
  background-color: var(--logo-black);
  border-radius: var(--br-5xs);
  width: 134px;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.buttonlarge1:active {
  border: 1px solid var(--color-black);
  box-sizing: border-box;
}
.buttoncardscholarshipslarge {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.buttoncardscholarshipslarge-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-6xs) var(--padding-13xl) var(--padding-mini);
  align-items: flex-start;
  justify-content: flex-start;
}
.buttonlarge2,
.buttonlarge3,
.cardscholarshipslarge-home {
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  align-items: center;
}
.cardscholarshipslarge-home {
  border: 1px solid var(--gray-5);
  height: 286px;
  flex-direction: column;
  justify-content: flex-start;
}
.buttonlarge2,
.buttonlarge3 {
  cursor: pointer;
  padding: var(--padding-12xs) 0;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
}
.buttonlarge2 {
  border: 1.5px solid var(--logo-black);
  background-color: transparent;
  width: 137px;
  height: 35px;
}
.buttonlarge1:active,
.buttonlarge2:active {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.buttonlarge3 {
  border: 0;
  background-color: var(--logo-black);
  width: 134px;
  height: 32px;
}

.homepageHeaderSuccess{
  
  width: 256px;
  justify-content: center;
  align-items: center;
  gap: 20px;

}

.homepageHeaderSuccessBox{
  display: flex;
  width: 256px;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 20px;

}
.buttonlarge3:active,
.buttonlarge3:hover {
  background-color: var(--color-limegreen);
}
.buttonlarge3:active {
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.lineAboveSuccess{
  width: 41px;
height: 4px;

border-radius: 12px;
background: #000339;
}
.scholarship-cards {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.scholarship-section,
.see-more {
  display: flex;
  align-items: center;
  justify-content: center;
}
.see-more {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-size: var(--p1-regular-16-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--royal-blue);
  text-align: center;
  width: 156px;
  height: 54px;
  flex-shrink: 0;
}
.scholarship-section {
  background-color: var(--white);
  flex-direction: column;
  padding: var(--padding-17xl) var(--padding-141xl) 0;
  gap: var(--gap-3xs);
  color: var(--gray-5);
}
.background-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs) var(--br-5xs) 0 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.cardcommunitybackgroundlarg {
  position: relative;
  width: 352px;
  height: 97px;
}
.buttonlarge7 {
  border-radius: var(--br-5xs);
  background-color: var(--logo-black);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-7xs) var(--padding-31xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.buttoncardscholarshipslarge-wrapper1 {
  overflow: hidden;
  padding: var(--padding-6xs) var(--padding-13xl) var(--padding-mini);
  align-items: flex-start;
  text-align: center;
  font-size: var(--h5-btns-med-14-size);
  color: var(--gray-1);
}
.buttoncardscholarshipslarge-wrapper1,
.communities-section,
.divider1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.communities-section {
  align-items: flex-end;
  font-size: var(--p2-regular-12-size);
  color: var(--logo-black);
}
.divider1 {
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-base) var(--padding-141xl);
  align-items: flex-start;
}
.section-text2 {
  position: relative;
  display: inline-block;
  width: 256px;
}
.success-stories {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-8xl) 368px var(--padding-8xl) 379px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.cardtestimonialbackground-icon {
  position: relative;
  width: 256px;
  height: 241px;
}
.line-under-amount {
  position: relative;
  border-top: 3px solid var(--logo-color-2);
  box-sizing: border-box;
  width: 145px;
  height: 3px;
}
.amount-awarded {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.joshua-p {
  margin: 0;
  font-weight: 500;
}
.graphic-design-major {
  margin: 0;
  font-size: var(--p2-regular-12-size);
  line-height: 150%;
}
.name-and-major-container {
  position: relative;
}
.name-and-major {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
}
.testimony1 {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
  display: inline-block;
  width: 226px;
  height: 95px;
  flex-shrink: 0;
}
.cardtestimonialdescription,
.testimony {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.testimony {
  height: 95px;
  flex-direction: row;
  padding: 0 0 0 var(--padding-base);
  box-sizing: border-box;
  font-size: var(--p2-regular-12-size);
}
.cardtestimonialdescription {
  width: 256px;
  height: 210px;
  flex-direction: column;
  gap: var(--gap-5xs);
}
.cardtestimonial {
  overflow: hidden;
}
.cardtestimonial,
.cardtestimonial1 {
  border-radius: var(--br-5xs);
  background-color: var(--gray-1);
  box-shadow: 3px 3px 19px 3px rgba(199, 199, 199, 0.8);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.blank-line {
  margin: 0;
}
.lorem-ipsum-dolor {
  margin: 0;
  letter-spacing: 0.08em;
  font-weight: 500;
}
.testimony7 {
  position: relative;
  line-height: 150%;
  display: inline-block;
  width: 226px;
  height: 95px;
  flex-shrink: 0;
}
.cardtestimonial-parent,
.testimonials {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardtestimonial-parent {
  flex-direction: row;
  gap: var(--gap-13xl);
}
.testimonials {
  flex-direction: column;
  padding: var(--padding-5xs) var(--padding-141xl);
  padding-bottom: 51px;
  font-size: var(--p1-regular-16-size);
}
.wrapper {
  position: relative;
  width: 965px;
  height: 542px;
}
.testimonial-video {
  align-self: stretch;
  background: linear-gradient(#fff, #fff),
    linear-gradient(180deg, rgba(89, 88, 109, 0.2), rgba(89, 88, 109, 0));
  border: 1px solid var(--gray-4);
  box-sizing: border-box;
  height: 668px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nav-link {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}


.icon-wrapper {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}

.students {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 160px;
}

.main-footer-link,
.main-footer-link2 {
  position: relative;
  width: 160px;
  height: 24px;
}
.main-footer-link2 {
  cursor: pointer;
}

.main-footer-link12 {
  position: relative;
  width: 160px;
  height: 71px;
}
.subscribe-email-text-box {
  border: 0;
  font-weight: 500;
  font-family: var(--p1-regular-16);
  font-size: var(--h5-btns-med-14-size);
  background-color: var(--white);
  border-radius: var(--br-7xs);
  box-shadow: var(--shadow-1);
  width: 199px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) 80px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.button,
.button-text5 {
  position: absolute;
  width: 100%;
  left: 0;
}
.button {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-5xs);
  background-color: var(--purple-blue);
}
.button-text5 {
  height: 95%;
  top: 2.5%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonlarge12 {
  position: relative;
  width: 198px;
  height: 32px;
  text-align: center;
  font-size: var(--h5-btns-med-14-size);
}

.scholist-all-rights {
  position: relative;
  line-height: 150%;
}

.homepage {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homepage {
  position: relative;
  background-color: var(--white);
  width: 100%;
  text-align: left;
  font-size: var(--h2-bold-32-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
}
@media screen and (max-width: 1200px) {
  .cardscholarshipsbackgroundl {
    
    width: 309px;
  }

  .cardscholarshipslarge-home {
   
    width: 309px;
   
}
.section-text2 {
font-size: 29px;
}
  .menu {
    display: flex;
  }
  .button-text1:hover {
    color: var(--color-black);
  }
  .register {
    display: none;
  }
  .banner-image-choice {
    width: 371px;
  }
  .graduate {
    width: 280px;
  }
  .applying-to-scholarships {
    font-size: var(--font-size-23xl);
    width: 80%;
  }
  .frame-parent {
    margin-left: 0;
  }
  .bnner-backgrround {
    width: 1200px;
    gap: var(--gap-xl);
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .banner {
    padding-right: var(--padding-81xl);
    box-sizing: border-box;
  }
  .homesection-header {
    display: none;
  }
  .how-it-works,
  .how-it-works1 {
    align-self: stretch;
    width: auto;
    padding-left: -50-;
    box-sizing: border-box;
  }
  .how-it-works1 {
    display: none;
    height: 42px;
    padding-left: 0-;
    padding-right: 0;
  }
  .divider {
    display: none;
  }
  .how-it-works2 {
    font-size: 29px;
  }
  .homehow-it-worksimg {
    width: 407px;
    height: 204px;
  }
  .community-and-support,
  .personalized-matches {
    gap: var(--gap-31xl);
  }
  .homehow-it-worksimg1 {
    width: 407px;
    height: 204px;
  }
  .home-section-how-it-works {
    gap: var(--gap-61xl);
    padding-left: var(--padding-181xl);
    padding-right: var(--padding-181xl);
    box-sizing: border-box;
  }
  .see-what-others {
    font-size: 7px;
  }
  .cgi-stacked-lockup-blue1-icon {
    width: 83px;
    height: 32px;
  }
  .dbf43de48b00044eb5af-icon {
    width: 78px;
    height: 27px;
  }
  .future-founders-color-logo-icon {
    width: 127px;
    height: 32px;
  }
  .verizon-logo-1-icon {
    width: 126px;
    height: 32px;
  }
  .visa-logo-icon {
    width: 82px;
    height: 32px;
  }
  .see-what-others-are-saying-parent {
    gap: var(--gap-7xs);
  }
  .partnership-strip {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .header,
  .partners {
    justify-content: center;
    box-sizing: border-box;
  }
  .partners {
    width: auto;
    align-items: flex-start;
    padding-left: var(--padding-181xl);
    padding-right: var(--padding-181xl);
  }
  .header {
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
  .homscholimg {
    width: 58px;
    height: 57px;
  }
  .buttonlarge {
    width: 117px;
  }
  .buttonlarge1 {
    width: 114px;
  }
  .cardscholarshipslarge {
    width: 314px;
    height: 268px;
  }
  .buttonlarge2 {
    width: 117px;
  }
  .buttonlarge3 {
    width: 114px;
  }
  .scholarship-cards {
    gap: var(--gap-mini);
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .see-more {
    text-align: right;
    justify-content: center;
  }
  .scholarship-section {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .communities-section {
    align-items: center;
  }
  .communities-section,
  .success-stories {
    justify-content: center;
  }
  .cardtestimonial-parent,
  .testimonials {
    flex-wrap: wrap;
  }
  .wrapper {
    width: 70%;
    height: 70%;
  }
  .footer-menu-parent {
    flex-wrap: wrap;
  }
  .footer-grid-row {
    flex-direction: column;
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 850px) {
  .top-nav-left {
    display: none;
  }
  .menu {
    display: flex;
  }
  .graduate,
  .register {
    display: none;
  }
  .graduate {
    margin-bottom: 100px;
  }
  .applying-to-scholarships {
    font-size: var(--h2-bold-32-size);
    width: 70%;
  }
  .buttonxxlarge {
    width: 330px;
  }
  .frame-parent {
    align-items: flex-end;
    justify-content: center;
  }
  .applying {
    margin-bottom: 20px;
  }
  .bnner-backgrround {
    flex-direction: column;
  }
  .banner {
    gap: var(--gap-41xl);
    align-items: center;
    justify-content: center;
    padding-top: var(--padding-31xl);
    padding-bottom: var(--padding-31xl);
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  .how-it-works,
  .how-it-works1 {
    align-self: stretch;
    width: auto;
  }
  .divider {
    display: none;
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-3xs);
    box-sizing: border-box;
  }
  .community-and-support,
  .personalized-matches {
    flex-direction: column;
    gap: var(--gap-xl);
    align-items: flex-start;
    justify-content: center;
  }
  .home-section-how-it-works {
    flex-direction: column;
  }
  .cgi-stacked-lockup-blue1-icon {
    width: 78px;
    height: 27px;
  }
  .dbf43de48b00044eb5af-icon {
    width: 67px;
    height: 22px;
  }
  .future-founders-color-logo-icon,
  .verizon-logo-1-icon,
  .visa-logo-icon {
    width: 73px;
    height: 22px;
  }
  .see-what-others-are-saying-parent {
    gap: var(--gap-11xs);
  }
  .header,
  .partners {
    justify-content: center;
    box-sizing: border-box;
  }
  .partners {
    padding-left: var(--padding-101xl);
    padding-right: var(--padding-101xl);
  }
  .header {
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
  .scholarship-cards {
    flex-direction: row;
  }
  .see-more {
    text-align: left;
  }
  .scholarship-section {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .communities-section,
  .scholarship-section {
    align-items: center;
    justify-content: center;
  }
  .success-stories,
  .testimonials {
    padding-left: var(--padding-301xl);
    box-sizing: border-box;
  }
  .testimonials {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    padding-bottom: var(--padding-xl);
  }
  .wrapper {
    width: 70%;
    height: 60%;
  }
  .scholist-all-rights {
    font-size: var(--font-size-smi);
  }
  .footer-legal {
    width: 500px;
    flex-direction: column;
    gap: var(--gap-3xs);
  }
}
@media screen and (max-width: 520px) {
  .section-text2 {
   font-size: 21px;
  }
  .language-icon {
    width: 14px;
    height: 14px;
  }
  .language {
    font-size: var(--font-size-3xs);
  }
  .line-under-link,
  .line-under-link1 {
    width: 69px;
  }
  .headertop-navlink-parent {
    gap: var(--gap-mini);
  }
  .left-side-secondary-nav {
    gap: var(--gap-base);
  }
  .left-side-top-nav-parent {
    gap: var(--gap-301xl);
  }
  .headertop-navprimary {
    align-items: center;
    justify-content: center;
  }
  .banner-image-choice {
    width: 250px;
    height: 400px;
  }
  .student-testimony {
    width: 135px;
    height: 100px;
  }
  .banner-image-choice-parent {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .frame-container {
    width: 327px;
  }
  .frame-wrapper {
    width: 120px;
  }
  .graduate,
  .graduate-inner {
    width: 180px;
  }
  .graduate {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .applying-to-scholarships {
    font-size: var(--font-size-5xl);
  }
  .discover-the-right {
    font-size: var(--font-size-4xs);
    width: 190px;
  }
  .discover-the-right-scholarship-wrapper {
    align-items: flex-end;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .applying-to-scholarships-made-parent {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div {
    align-items: center;
    justify-content: center;
  }
  .buttonxxlarge {
    width: 200px;
    padding-left: var(--padding-18xl);
    box-sizing: border-box;
  }
  .buttonxxlarge-wrapper {
    width: 10px;
    align-items: center;
    justify-content: center;
  }
  .buttonxxlarge-wrapper:active {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.55);
  }
  .frame-parent {
    align-items: center;
    padding-left: var(--padding-81xl);
  }
  .applying,
  .banner,
  .bnner-backgrround,
  .frame-parent {
    justify-content: center;
    box-sizing: border-box;
  }
  .applying {
    width: 180px;
    align-items: flex-start;
    padding-right: 0;
  }
  .banner,
  .bnner-backgrround {
    align-items: center;
  }
  .bnner-backgrround {
    width: 520px;
    padding-left: 0;
    padding-right: var(--padding-31xl);
  }
  .banner {
    gap: var(--gap-41xl);
    padding-right: 0;
  }
  .section-text {
    font-size: var(--font-size-5xl);
    color: #040405;
  }
  .homesection-header {
    display: flex;
  }
  .how-it-works {
    display: none;
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--padding-71xl);
    padding-right: 0;
    box-sizing: border-box;
  }
  .section-text1 {
    font-size: var(--font-size-7xl);
  }
  .divider,
  .how-it-works1 {
    display: none;
    box-sizing: border-box;
  }
  .how-it-works1 {
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--padding-181xl);
    padding-right: 0;
  }
  .divider {
    padding-top: var(--padding-xl);
    padding-bottom: 0;
  }
  .how-it-works2 {
    font-size: var(--font-size-2xl);
  }
  .details1 {
    width: 300px;
  }
  .details1,
  .link-to-page {
    font-size: var(--p2-regular-12-size);
  }
  .homehow-it-worksimg {
    width: 297px;
  }
  .personalized-matches {
    align-items: flex-start;
    justify-content: center;
    padding-top: 0;
    box-sizing: border-box;
  }
  .how-it-works3,
  .how-it-works4 {
    font-size: var(--font-size-2xl);
  }
  .community-and-support {
    align-items: flex-start;
    justify-content: center;
  }
  .homehow-it-worksimg1 {
    width: 297px;
  }
  .home-section-how-it-works {
    gap: var(--gap-61xl);
    align-items: center;
    justify-content: flex-start;
    padding-left: var(--padding-231xl);
    padding-top: var(--padding-11xl);
    padding-right: var(--padding-81xl);
    box-sizing: border-box;
  }
  .see-what-others {
    font-size: 6px;
    width: 90px;
  }
  .cgi-stacked-lockup-blue1-icon,
  .dbf43de48b00044eb5af-icon,
  .future-founders-color-logo-icon,
  .verizon-logo-1-icon,
  .visa-logo-icon {
    width: 48px;
    height: 18px;
  }
  .see-what-others-are-saying-parent {
    gap: var(--gap-9xs);
  }
  .partners {
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .scholarships1 {
    font-size: var(--font-size-6xl);
  }
  .header {
    height: 82px;
    padding-left: var(--padding-21xl);
    padding-right: 0;
    box-sizing: border-box;
  }
  .buttonlarge {
    gap: var(--gap-365xl);
  }
  .scholarship-cards {
    gap: var(--gap-xl);
  }
  .scholarship-section,
  .success-stories {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .scholarship-section {
    align-items: center;
    justify-content: center;
  }
  .success-stories {
    padding-top: var(--padding-8xl);
  }
  .cardtestimonial-parent {
    flex-direction: column;
  }
  .wrapper {
    height: 40%;
  }
  .footer-legal {
    gap: var(--gap-3xs);
  }
  .homepage {
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 350px) {
  html,body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .headertop-navsecondary {
    display: none;
  }
  .headerlogo {
    width: 124px;
    height: 31px;
  }
  .menu {
    width: 37px;
    height: 37px;
  }
  .left-side-top-nav-parent {
    gap: var(--gap-281xl);
  }
}
