.notificationheadertext {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 500;
  }
  .notificationheader {
    position: relative;
    width: 1120px;
    height: 36px;
    font-size: var(--font-size-5xl);
  }
  .notificationboxicon {
    position: relative;
    width: 54px;
    height: 54px;
  }
  .update {
    font-weight: 500;
  }
  .notificationupdateboxtext {
    position: relative;
  }
  .notificationupdateboxreadmore {
    position: relative;
    color: var(--logo-color-2);
  }
  .notificationbox2,
  .notificationupdatebox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .notificationbox2 {
    position: absolute;
    top: 24px;
    left: 4px;
  }
  .notificationline1 {
    position: absolute;
    top: 97.5px;
    left: -0.5px;
    border-top: 1px solid var(--color-darkgray-300);
    box-sizing: border-box;
    width: 1438px;
    height: 1px;
  }
  .notificationbox,
  .notificationbox1 {
    background-color: var(--color-white);
    overflow: hidden;
  }
  .notificationbox1 {
    position: relative;
    width: 1120px;
    height: 101px;
    flex-shrink: 0;
  }
  .notificationbox {
    border-radius: var(--br-xl);
    width: 1438px;
    display: flex;
    flex-direction: column;
    padding: var(--padding-6xl) 159px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    gap: 6px;
    color: var(--color-black);
  }
  .notificationviewmorebuttontext {
    position: relative;
  }
  .notificationviewmorebutton {
    border-radius: var(--br-5xs);
    background-color: var(--logo-color-2);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-4xl) 82px;
    align-items: center;
    justify-content: center;
  }
  .mynotifications,
  .notificaticationviewmore {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .notificaticationviewmore {
    width: 1438px;
    padding: 0 200px 0 0;
    box-sizing: border-box;
    align-items: flex-end;
    text-align: center;
    color: var(--color-white);
  }
  .mynotifications {
    position: relative;
    background-color: var(--color-white);
    padding: var(--padding-118xl) 0;
    align-items: center;
    gap: var(--gap-18xl);
    text-align: left;
    font-size: var(--h4-medium-16-size);
    color: var(--logo-black);
    font-family: var(--h3-medium-20);
    padding-top: 80px;
  }
  @media screen and (max-width: 1200px) {
    .notificationheader {
      width: 920px;
    }
    .notificationbox1 {
      width: 700px;
    }
    .notificationbox {
      width: 1200px;
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 850px) {
    .notificationheader {
      width: 600px;
    }
    .notificationbox2 {
      width: 580px;
      gap: var(--gap-xl);
    }
    .notificationbox1 {
      width: 600px;
    }
    .notificationbox {
      width: 800px;
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
    .notificationviewmorebutton {
      padding: var(--padding-mid) 62px;
      box-sizing: border-box;
    }
    .notificaticationviewmore {
      width: 1038px;
    }
  }
  @media screen and (max-width: 520px) {
    .notificationheadertext {
      font-size: var(--h3-medium-20-size);
    }
    .notificationheader {
      width: 400px;
    }
    .notificationboxicon {
      width: 54px;
      height: 54px;
    }
    .notificationupdateboxreadmore,
    .notificationupdateboxtext {
      font-size: var(--font-size-smi);
    }
    .notificationupdatebox {
      flex-direction: column;
      gap: var(--gap-0);
      align-items: flex-start;
      justify-content: center;
    }
    .notificationbox2 {
      gap: var(--gap-mini);
    }
    .notificationbox1 {
      width: 400px;
    }
    .notificationviewmorebutton {
      padding: 12px 52px;
      box-sizing: border-box;
    }
    .notificaticationviewmore {
      width: 900px;
    }
    .mynotifications {
      padding: var(--padding-57xl) 0;
      box-sizing: border-box;
    }
  }
  