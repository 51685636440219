.headerlogo-icon11 {
  position: relative;
  width: 134px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link-default44 {
  position: relative;
  font-weight: 500;
}
.headernav-link55 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headernav-link56 {
  color: var(--logo-color-1);
}
.headernav-link56,
.headernav-link57,
.left-side-top-nav11,
.top-nav-left11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left-side-top-nav11,
.top-nav-left11 {
  gap: var(--gap-13xl);
}
.left-side-top-nav11 {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  width: 604.51px;
  padding: 0 0 0 var(--padding-13xl);
  box-sizing: border-box;
  align-items: flex-end;
}
.vector-icon83,
.vector-icon84 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon84 {
  height: 50%;
  width: 75%;
  top: 25%;
  right: 12.5%;
  bottom: 25%;
  left: 12.5%;
}
.menu11 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 44px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.messages-icon10,
.notifications-icon10 {
  position: absolute;
  top: 8px;
  left: -3px;
  width: 28.3px;
  height: 28px;
}
.notifications-icon10 {
  left: 53px;
  width: 22.74px;
}
.mask-group-icon10 {
  position: relative;
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.vector-icon85 {
  position: relative;
  width: 10px;
  height: 6px;
}
.mask-group-parent8 {
  position: absolute;
  top: 0;
  left: 106px;
  width: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.messages-parent8 {
  position: relative;
  width: 152px;
  height: 44px;
}
.right-side-top-nav11 {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  width: 263px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-13xl) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-13xl);
}
.info10 {
  align-self: stretch;
  position: relative;
  height: 44px;
}
.scholarships-line-icon10,
.services-line-icon10 {
  position: relative;
  width: 89.5px;
  height: 0;
  display: none;
}
.services-line-icon10 {
  width: 59.5px;
}
.community-line-icon10,
.resources-icon10 {
  position: relative;
  width: 83px;
  height: 0;
  display: none;
}
.resources-icon10 {
  width: 73px;
}
.navbar10,
.scholarships3 {
  display: flex;
  align-items: center;
  margin-left: 2%;
  margin-top: 3%;
}
.navbar10 {
  align-self: stretch;
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-3);
  box-sizing: border-box;
  height: 79px;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  justify-content: center;
  gap: var(--gap-3xs);
  font-size: var(--h5-btns-med-14-size);
}
.scholarships3 {
  position: relative;
  width: 570px;
  flex-shrink: 0;
}
.allpage-header9,
.header10,
.search-bar5 {
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
}
.allpage-header9 {
  align-self: stretch;
  height: 106px;
  flex-direction: row;
  /* padding: var(--padding-2xl) 0 var(--padding-58xl) var(--padding-15xl); */
  justify-content: flex-start;
}
.header10,
.search-bar5 {
  width: 1440px;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-28xl) var(--padding-108xl);
  justify-content: flex-end;
}
.search-bar5 {
  border: 2px solid var(--gray-3);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  font-size: var(--p1-regular-16-size);
  background-color: transparent;
  border-radius: var(--br-5xs);
  width: 1120px;
  height: 65px;
  flex-shrink: 0;
  flex-direction: row;
  padding: 0 var(--padding-base);
  align-items: center;
  justify-content: flex-start;
}
.vector-icon86 {
  position: relative;
  width: 26px;
  height: 6px;
}
.ellipse-button5 {
  width: 44px;
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.filter5,
.filters-wrapper5 {
  display: flex;
  justify-content: flex-start;
}
.filters-wrapper5 {
  width: 1070.32px;
  overflow-x: auto;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-3xs);
}
.filter5 {
  width: 1422.32px;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs) var(--padding-157xl);
  box-sizing: border-box;
  align-items: flex-start;
}
.tune-icon6 {
  position: relative;
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.filters6 {
  position: relative;
  font-weight: 500;
}
.frame-wrapper62,
.tune-parent4 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tune-parent4 {
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frame-wrapper62 {
  flex: 1;
  background-color: var(--color-ghostwhite);
  overflow: hidden;
  padding: var(--padding-5xs) 0;
  justify-content: center;
  cursor: pointer;
}
.vector-icon87 {
  position: relative;
  width: 30px;
  height: 30px;
}
.frame-wrapper63 {
  flex: 1;
  background-color: var(--color-ghostwhite);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  align-items: center;
  justify-content: center;
}
.frame-parent15 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-123xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-14xl);
}
.search-and-filter-inner4 {
  align-self: stretch;
  overflow: hidden;
  display: none;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  align-items: center;
  justify-content: center;
}
.search-and-filter5 {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-smi);
  text-align: center;
  font-size: var(--p1-regular-16-size);
  color: var(--color-black);
}
.based-on-your7,
.search-and-filter5,
.search8 {
  display: flex;
  align-items: center;
}
.search8 {
  overflow: hidden;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  justify-content: center;
  gap: var(--gap-smi);
  font-size: var(--h1-bold-52-size);
}
.based-on-your7 {
  position: relative;
  width: 348.79px;
  flex-shrink: 0;
}
.see-all6 {
  position: relative;
  font-size: var(--h3-medium-20-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--color-black);
  text-align: center;
}
.arrow-forward-icon4 {
  position: relative;
  width: 24.21px;
  height: 24.22px;
}
.see-all-button4 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 99.21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.based-on-your-profile-parent,
.category-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.based-on-your-profile-parent {
  height: 48px;
  gap: var(--gap-663xl);
}
.category-header {
  overflow: hidden;
  padding: var(--padding-xs) var(--padding-136xl);
  font-size: var(--h2-bold-32-size);
}
.cardscholarshipsimage-icon40 {
  position: relative;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.cardscholarshipsbackgroundco40 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-mediumslateblue-100);
  width: 256px;
  height: 97px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.title40 {
  position: absolute;
  top: 110px;
  left: 27px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  width: 212px;
  height: 40px;
}
.award-amount41,
.deadline39,
.organization40 {
  position: absolute;
  top: 158px;
  left: 27px;
  line-height: 150%;
}
.award-amount41,
.deadline39 {
  top: 190px;
}
.deadline39 {
  top: 222px;
}
.component-2-icon40 {
  position: absolute;
  top: 223.68px;
  left: 215px;
  width: 24.63px;
  height: 22.95px;
}
.card44 {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  width: 256px;
  height: 265px;
  overflow: hidden;
  flex-shrink: 0;
}
.cardscholarshipsbackgroundco41 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-darkturquoise);
  width: 256px;
  height: 97px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.cardscholarshipsimage-icon42 {
  position: relative;
  border-radius: var(--br-61xl);
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.cardscholarshipsbackgroundco42 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-gold);
  width: 256px;
  height: 97px;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
}
.cardscholarshipsbackgroundco42,
.cardscholarshipsbackgroundco43,
.row-2-wrapper7 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.cardscholarshipsbackgroundco43 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-red);
  width: 256px;
  height: 97px;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
}
.row-2-wrapper7 {
  align-items: flex-start;
  gap: var(--gap-13xl);
}
.scholarships-body {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm) var(--padding-139xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.see-all8 {
  position: relative;
  font-weight: 500;
}
.see-all-button-frame,
.see-all-button5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.see-all-button5 {
  width: 99.21px;
  gap: var(--gap-5xs);
}
.see-all-button-frame {
  cursor: pointer;
}
.category-header1,
.see-all7 {
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.see-all7 {
  align-self: stretch;
  height: 52px;
  flex-shrink: 0;
  display: none;
  flex-direction: column;
  padding: var(--padding-2xs) var(--padding-134xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--h3-medium-20-size);
  color: var(--color-black);
}
.category-header1 {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-136xl);
  font-size: var(--h2-bold-32-size);
}
.row-2-wrapper-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm) var(--padding-139xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.see-all-button-wrapper1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.category-header2,
.see-all10 {
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.see-all10 {
  align-self: stretch;
  height: 52px;
  flex-shrink: 0;
  display: none;
  flex-direction: column;
  padding: var(--padding-2xs) var(--padding-134xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--h3-medium-20-size);
  color: var(--color-black);
}
.category-header2 {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-136xl);
  font-size: var(--h2-bold-32-size);
}
.row-2-wrapper-container,
.see-all13 {
  overflow: hidden;
  flex-direction: column;
}
.row-2-wrapper-container {
  display: flex;
  padding: var(--padding-sm) var(--padding-139xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.see-all13 {
  align-self: stretch;
  height: 52px;
  flex-shrink: 0;
  display: none;
  padding: var(--padding-2xs) var(--padding-134xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--h5-btns-med-14-size);
  color: var(--color-black);
}
.footerlogo-icon11 {
  position: relative;
  width: 148px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link33 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}
.footerlinknav33 {
  position: relative;
  width: 160px;
  height: 19px;
}
.footer-links55 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.footericoninstagram11 {
  position: relative;
  width: 20px;
  height: 20px;
}
.footericonfacbook11 {
  position: relative;
  width: 18.75px;
  height: 18.75px;
}
.footericontwitter11 {
  position: relative;
  width: 19.5px;
  height: 15.84px;
}
.footericonyoutube11 {
  position: relative;
  width: 21px;
  height: 14.72px;
}
.icon-wrapper11 {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.footer-menu55 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.students11 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 160px;
}
.footer-link143 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  display: inline-block;
}
.main-footer-link143 {
  position: relative;
  width: 160px;
  height: 24px;
}
.footer-menu56 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.footer-link155 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
}
.main-footer-link155 {
  position: relative;
  width: 160px;
  height: 71px;
  font-size: var(--p2-regular-12-size);
}
.text-box10 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-7xs);
  background-color: var(--white);
  box-shadow: var(--shadow-1);
}
.input-placeholder10 {
  position: absolute;
  top: 4px;
  left: 10px;
  font-weight: 500;
  display: inline-block;
  width: 181px;
  height: 25px;
}
.subscribe-email-text-box11 {
  position: relative;
  width: 198px;
  height: 28px;
  color: var(--gray-4);
}
.button-text83,
.button48 {
  position: absolute;
  width: 100%;
  left: 0;
}
.button48 {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-5xs);
  background-color: var(--purple-blue);
}
.button-text83 {
  height: 95%;
  top: 2.5%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonlarge32 {
  position: relative;
  width: 198px;
  height: 32px;
  text-align: center;
}
.footer-grid-row11,
.footer-links59,
.footer-menu-parent9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-links59 {
  flex-direction: column;
  gap: var(--gap-5xs);
  font-size: var(--h5-btns-med-14-size);
}
.footer-grid-row11,
.footer-menu-parent9 {
  flex-direction: row;
  gap: var(--gap-69xl);
}
.footer-grid-row11 {
  justify-content: center;
}
.scholist-all-rights11 {
  position: relative;
  line-height: 150%;
}
.footer-legal11 {
  width: 827px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-365xl);
}
.footer11,
.scholarships-category-page {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer11 {
  align-self: stretch;
  background-color: var(--gray-4);
  padding: var(--padding-63xl) var(--padding-105xl) var(--padding-20xl);
  align-items: flex-start;
  gap: var(--gap-62xl);
  color: var(--gray-1);
}
.scholarships-category-page {
  position: relative;
  background-color: var(--white);
  width: 100%;
  align-items: center;
  text-align: left;
  font-size: var(--p1-regular-16-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
  height: auto;
}
@media screen and (max-width: 1200px) {
  .menu11 {
    display: none;
  }
  .scholarships3 {
    font-size: var(--font-size-17xl);
  }
  .header10 {
    width: 1200px;
    height: 117px;
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--padding-146xl);
    padding-top: var(--padding-xl);
    box-sizing: border-box;
    margin-top: 20px;
  }
  .search-bar5 {
    width: 800px;
  }
  .filters-wrapper5 {
    width: 800px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .filter5 {
    width: 1200px;
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--padding-181xl);
    box-sizing: border-box;
  }
  .search-and-filter5 {
    height: 168px;
  }
  .search8 {
    padding-left: var(--padding-131xl);
    padding-right: var(--padding-131xl);
    box-sizing: border-box;
  }
  .based-on-your7 {
    font-size: var(--font-size-5xl);
  }
  .see-all6 {
    font-size: var(--p1-regular-16-size);
  }
  .based-on-your-profile-parent {
    gap: var(--gap-331xl);
  }
  .row-2-wrapper7,
  .scholarships-body {
    flex-wrap: wrap;
  }
  .see-all-button-frame {
    gap: var(--gap-381xl);
  }
  .row-2-wrapper-wrapper {
    flex-wrap: wrap;
  }
  .see-all-button-wrapper1 {
    gap: var(--gap-381xl);
  }
  .footer-menu-parent9,
  .row-2-wrapper-container {
    flex-wrap: wrap;
  }
  .footer-grid-row11 {
    flex-direction: column;
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 850px) {
  .top-nav-left11 {
    display: none;
  }
  .menu11 {
    display: flex;
  }
  .messages-parent8 {
    display: none;
  }
  .scholarships3 {
    font-size: var(--font-size-11xl);
  }
  .allpage-header9 {
    height: 65px;
    padding-top: var(--padding-8xs);
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .header10 {
    width: 850px;
    height: 40px;
    padding-left: var(--padding-121xl);
    padding-top: 0;
    padding-bottom: var(--padding-3xs);
    box-sizing: border-box;
    margin-top: 30px;
  }
  .filters-wrapper5,
  .search-bar5 {
    width: 500px;
  }
  .filter5 {
    display: none;
    width: 850px;
    padding-left: var(--padding-157xl);
    box-sizing: border-box;
  }
  .frame-wrapper62 {
    width: 410px;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .frame-parent15 {
    gap: var(--gap-mini);
    padding-left: var(--padding-53xl);
    padding-right: var(--padding-53xl);
    box-sizing: border-box;
  }
  .search-and-filter-inner4 {
    display: flex;
  }
  .search8 {
    padding-top: 0;
    box-sizing: border-box;
  }
  .based-on-your7 {
    font-size: var(--font-size-5xl);
  }
  .see-all-button4 {
    display: none;
  }
  .based-on-your-profile-parent,
  .category-header {
    align-items: center;
    justify-content: center;
  }
  .category-header {
    height: 90px;
    padding-left: 240px;
    padding-right: var(--padding-161xl);
    box-sizing: border-box;
  }
  .scholarships-body {
    padding-left: var(--padding-21xl);
    padding-top: var(--padding-7xs);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
  .see-all-button5,
  .see-all7 {
    display: flex;
  }
  .see-all7 {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .category-header1 {
    height: 90px;
    padding-left: var(--padding-381xl);
    padding-right: var(--padding-161xl);
    box-sizing: border-box;
  }
  .row-2-wrapper-wrapper {
    padding-left: var(--padding-21xl);
    padding-top: var(--padding-7xs);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
  .see-all10 {
    display: flex;
  }
  .category-header2 {
    height: 90px;
    padding-left: 270px;
    padding-right: var(--padding-161xl);
    box-sizing: border-box;
  }
  .row-2-wrapper-container {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
  .see-all13 {
    display: flex;
  }
}
@media screen and (max-width: 520px) {
  .scholarships3 {
    font-size: var(--font-size-3xl);
  }
  .allpage-header9 {
    height: 50px;
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .header10 {
    width: 520px;
    height: 50px;
    padding: var(--padding-xl) var(--padding-31xl) var(--padding-xl)
      var(--padding-58xl);
    box-sizing: border-box;
  }
  .search-bar5 {
    width: 300px;
    height: 55px;
  }
  .filters-wrapper5 {
    width: 300px;
  }
  .filter5 {
    padding-left: var(--padding-259xl);
    box-sizing: border-box;
  }
  .tune-icon6 {
    width: 20px;
    height: 20px;
  }
  .filters6 {
    font-size: var(--p2-regular-12-size);
  }
  .frame-wrapper62 {
    width: 225px;
    padding-top: var(--padding-11xs);
    padding-bottom: var(--padding-11xs);
    box-sizing: border-box;
  }
  .vector-icon87 {
    width: 20px;
    height: 20px;
  }
  .frame-wrapper63 {
    padding-top: var(--padding-11xs);
    padding-bottom: var(--padding-11xs);
    box-sizing: border-box;
  }
  .frame-parent15 {
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
  .search-and-filter-inner4 {
    padding-top: var(--padding-mid);
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .based-on-your7 {
    font-size: var(--p1-regular-16-size);
  }
  .category-header {
    height: 70px;
    padding-left: var(--padding-351xl);
    box-sizing: border-box;
  }
  .see-all8 {
    font-size: var(--h5-btns-med-14-size);
  }
  .see-all-button5 {
    gap: var(--gap-6xs);
  }
  .see-all-button-frame {
    flex-direction: column;
    gap: var(--gap-10xs);
    align-items: flex-start;
    justify-content: center;
  }
  .see-all7 {
    align-self: stretch;
    width: auto;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .category-header1 {
    height: 70px;
    padding-left: 450px;
    box-sizing: border-box;
  }
  .row-2-wrapper-wrapper {
    padding-top: var(--padding-sm);
    box-sizing: border-box;
  }
  .see-all-button-wrapper1 {
    flex-direction: column;
    gap: var(--gap-10xs);
    align-items: flex-start;
    justify-content: center;
  }
  .see-all10 {
    align-self: stretch;
    width: auto;
    padding-left: var(--padding-134xl);
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .category-header2 {
    height: 70px;
    padding-left: var(--padding-351xl);
    box-sizing: border-box;
  }
  .see-all13 {
    align-self: stretch;
    width: auto;
    padding-left: var(--padding-134xl);
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
}


.allpage-header-search{
  align-self: stretch;
  height: 120px;
  /* flex-direction: row; */
  padding: var(--padding-2xl) 0 var(--padding-58xl) var(--padding-15xl); 
  justify-content: flex-start;
  align-items: center;
}


.pageTitles{
    /* flex-direction: row;  */
    display: flex;
    gap: 9px;
    align-items: center;
    
    

}


.scholarshipTitle{
font-size: 14px;
color: black;
/* letter-spacing: 2px; */
font-family: Poppins;
}

.scholarshipTitleMe{
  color: #4A93CF;
  
  /* H3 - Medium 20px */
  font-family: Poppins;
   font-size: 14px; 
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }


.scholarshipsContainer-cat {
  display: flex;
  max-width: 1440px;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  gap: 30px;
  align-self: stretch;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 10px;
  transition: transform 0.5s ease;
}

.scholarshipsContainer-cat :hover {
  transform: scale(1.0);
}


.scholarshipavailable{
  display: flex;
height: 48px;
justify-content: center;
align-items: center;
gap: 537px;
flex: 1 0 0;
}


.sortbyavailable{
  color: #000;

/* H3 - Medium 20px */
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.sortbySelect{
  color: #000;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.sortList{
  color: #000;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}