@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .communitiybutton{
    color: #fff;
  }
  .language-icon {
    position: relative;
    width: 16px;
    height: 16px;
  }
  .language {
    position: relative;
    font-weight: 500;
  }
  .down-icon {
    position: relative;
    width: 10px;
    height: 6.18px;
  }
  .headerdrop-downlang,
  .right-side-secondary-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  .headerdrop-downlang {
    gap: var(--gap-5xs);
  }
  .right-side-secondary-nav {
    position: absolute;
    top: calc(50% - 11px);
    right: 0;
    padding: 0 var(--padding-13xl) 0 0;
  }
  .line-under-link {
    position: relative;
    background-color: var(--gray-4);
    width: 109px;
    height: 2px;
  }
  .headertop-navlink {
    display: flex;
    flex-direction: column;
    padding: var(--padding-5xs) 0 0;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .line-under-link1 {
    position: relative;
    background-color: var(--gray-3);
    width: 109px;
    height: 2px;
  }
  .headertop-navlink-parent,
  .left-side-secondary-nav {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .headertop-navlink-parent {
    gap: var(--gap-13xl);
  }
  .left-side-secondary-nav {
    position: absolute;
    top: calc(50% - 20px);
    left: 0;
    padding: 0 0 0 var(--padding-13xl);
  }
  .headertop-navsecondary {
    align-self: stretch;
    position: relative;
    background-color: var(--gray-1);
    height: 40px;
  }
  .logo-bird-vector,
  .logo-words-icon {
    position: absolute;
    height: 100%;
    width: 33.26%;
    top: 0;
    right: 66.74%;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .logo-words-icon {
    height: 45.93%;
    width: 68.87%;
    top: 47.9%;
    right: 0;
    bottom: 6.18%;
    left: 31.13%;
  }
  .headerlogo {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    width: 134px;
    height: 41px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .nav-active {
    position: relative;
    font-weight: 500;
  }
  .headernav-link {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--royal-blue);
  }
  .headernav-link1 {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
  }
  .headernav-link1,
  .headernav-link4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .headernav-link-parent {
    flex: 1;
    align-items: flex-start;
    gap: var(--gap-13xl);
  }
  .headerlogo-parent,
  .headernav-link-parent,
  .top-nav-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .top-nav-left {
    flex: 1;
    height: 21px;
    align-items: center;
  }
  .headerlogo-parent {
    position: absolute;
    top: 0;
    left: 32px;
    width: 644px;
    align-items: flex-end;
    gap: var(--gap-13xl);
  }
  .left-side-top-nav {
    flex: 1;
    position: relative;
    height: 41px;
  }
  .vector-icon,
  .vector-icon1 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .vector-icon1 {
    height: 50%;
    width: 75%;
    top: 25%;
    right: 12.5%;
    bottom: 25%;
    left: 12.5%;
  }
  .menu {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    width: 47px;
    height: 47px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .button-text {
    position: relative;
    font-size: var(--h5-btns-med-14-size);
    font-weight: 500;
    font-family: var(--p1-regular-16);
    color: var(--logo-black);
    text-align: center;
  }
  .buttonmedium {
    cursor: pointer;
    border: 1px solid var(--logo-black);
    padding: var(--padding-12xs) 0;
    background-color: transparent;
    border-radius: var(--br-5xs);
    box-sizing: border-box;
    width: 128px;
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .buttonmedium:active {
    background-color: var(--white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.83);
  }
  .button-text1 {
    position: relative;
    font-size: var(--h5-btns-med-14-size);
    font-weight: 500;
    font-family: var(--p1-regular-16);
    color: var(--gray-1);
    text-align: center;
  }
  .button-text1:hover {
    color: var(--color-black);
  }
  .button-text1:active {
    -webkit-text-stroke: 1px #000;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
  .buttonmedium1 {
    cursor: pointer;
    border: 0;
    padding: var(--padding-12xs) 0;
    background-color: var(--royal-blue);
    border-radius: var(--br-5xs);
    width: 128px;
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .buttonmedium1:hover {
    box-shadow: 0 4px 4px #010101;
  }
  .buttonmedium1:active,
  .buttonxxlarge-wrapper:active {
    box-shadow: 0 4px 4px rgba(1, 1, 1, 0.84);
  }
  .register,
  .right-side-top-nav {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .right-side-top-nav {
    padding: 0 var(--padding-13xl) 0 0;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .left-side-top-nav-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-425xl);
  }
  .headertop-navprimary,
  .nav-bar {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .headertop-navprimary {
    background-color: var(--gray-1);
    border-bottom: 1px solid var(--gray-3);
    padding: var(--padding-lgi) 0;
  }
  .nav-bar {
    background-color: var(--white);
    height: 120px;
    font-size: var(--h5-btns-med-14-size);
  }
  .banner-image-choice {
    position: relative;
    width: 377.12px;
    height: 557px;
    object-fit: cover;
  }
  .student-name,
  .student-quote {
    position: relative;
    font-weight: 500;
    display: inline-block;
    width: 117px;
  }
  .student-quote {
    font-size: var(--font-size-4xs);
    width: 133px;
  }
  .student-testimony {
    width: 135px;
    height: 364px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-base);
  }
  .banner-image-choice-parent {
    gap: var(--gap-9xl);
  }
  .banner-image-choice-parent,
  .frame-container,
  .frame-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .frame-wrapper {
    align-items: flex-start;
  }
  .graduate-inner {
    width: 639px;
  }
  .graduate,
  .graduate-inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .applying-to-scholarships,
  .discover-the-right {
    position: relative;
    display: flex;
    align-items: center;
    width: 675px;
  }
  .discover-the-right {
    font-weight: 500;
    width: 385px;
    flex-shrink: 0;
  }
  .discover-the-right-scholarship-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    align-items: center;
    justify-content: flex-end;
    font-size: var(--h3-medium-20-size);
  }
  .applying-to-scholarships-made-parent,
  .frame-div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .applying-to-scholarships-made-parent {
    justify-content: flex-start;
    gap: var(--gap-3xs);
  }
  .frame-div {
    align-self: stretch;
    justify-content: center;
  }
  .sign-up {
    position: relative;
    font-size: var(--p1-regular-16-size);
    line-height: 150%;
    font-family: var(--p1-regular-16);
    color: var(--gray-1);
    text-align: center;
  }
  .buttonxxlarge {
    border-radius: var(--br-7xs);
    background-color: var(--royal-blue);
    width: 400px;
    height: 40px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-8xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .buttonxxlarge-wrapper {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .applying,
  .buttonxxlarge-wrapper,
  .frame-parent {
    display: flex;
    flex-direction: column;
  }
  .frame-parent {
    align-items: flex-end;
    justify-content: center;
    gap: var(--gap-3xs);
  }
  .applying {
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: -157px;
    text-align: right;
    font-size: var(--h1-bold-52-size);
  }
  .banner,
  .bnner-backgrround {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bnner-backgrround {
    width: 1440px;
    flex-direction: row;
    padding: 0 183px 0 var(--padding-81xl);
    box-sizing: border-box;
  }
  .banner {
    align-self: stretch;
    background-color: rgba(89, 88, 109, 0.1);
    flex-direction: column;
    font-size: var(--p1-regular-16-size);
  }
  .section-text {
    position: relative;
  }
  .homesection-header {
    width: 213px;
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .how-it-works {
    overflow: hidden;
    display: none;
    flex-direction: column;
    padding: var(--padding-6xs) 0;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    color: var(--color-black);
  }
  .section-text1 {
    position: relative;
  }
  .homesection-header1,
  .how-it-works1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .homesection-header1 {
    align-self: stretch;
    flex-direction: row;
    padding: 0 0 0 43px;
    opacity: 0;
  }
  .homesection-header1.animate {
    animation: 1s ease 0s 1 normal forwards fade-in;
  }
  .how-it-works1 {
    width: 1428px;
    height: 62px;
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: column;
    padding: var(--padding-6xs) 0 var(--padding-6xs) 139px;
    box-sizing: border-box;
    color: var(--color-black);
  }
  .homesection-divider-icon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 1px;
    flex-shrink: 0;
  }
  .divider {
    align-self: stretch;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-3xs) var(--padding-141xl) var(--padding-smi);
    align-items: center;
    justify-content: center;
  }
  .how-it-works2 {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: inline-block;
  }
  .details1 {
    position: relative;
    line-height: 150%;
    display: inline-block;
    width: 447px;
  }
  .details,
  .link-to-page {
    font-size: var(--p1-regular-16-size);
  }
  .link-to-page {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    font-weight: 500;
    font-family: var(--p1-regular-16);
    color: var(--royal-blue);
    text-align: left;
    display: inline-block;
  }
  .details {
    position: absolute;
    height: 61.9%;
    width: 100%;
    top: 38.1%;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .homehow-it-workssection-desc {
    position: relative;
    width: 447px;
    height: 168px;
  }
  .homehow-it-worksimg {
    position: relative;
    width: 457px;
    height: 244px;
    object-fit: cover;
  }
  .personalized-matches {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-131xl);
  }
  .how-it-works3,
  .how-it-works4 {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: inline-block;
  }
  .how-it-works4 {
    text-transform: uppercase;
  }
  .community-and-support,
  .homehow-it-workssection-desc-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-45xl);
  }
  .community-and-support {
    flex-direction: row;
    align-items: center;
    gap: var(--gap-131xl);
  }
  .photo-icon {
    position: absolute;
    height: 99.59%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0.41%;
    left: 0;
    border-radius: var(--br-base);
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
  }
  .homehow-it-worksimg1 {
    position: relative;
    width: 457px;
    height: 244px;
  }
  .home-section-how-it-works {
    width: 1444px;
    display: flex;
    flex-direction: column;
    padding: var(--padding-8xl) var(--padding-136xl) var(--padding-3xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .see-what-others {
    position: relative;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 126px;
    flex-shrink: 0;
  }
  .cgi-stacked-lockup-blue1-icon,
  .dbf43de48b00044eb5af-icon {
    position: relative;
    width: 89.36px;
    height: 37.42px;
    object-fit: cover;
  }
  .dbf43de48b00044eb5af-icon {
    width: 102.13px;
  }
  .future-founders-color-logo-icon,
  .verizon-logo-1-icon,
  .visa-logo-icon {
    position: relative;
    width: 132.54px;
    height: 37.42px;
    object-fit: cover;
  }
  .verizon-logo-1-icon,
  .visa-logo-icon {
    width: 131.76px;
  }
  .visa-logo-icon {
    width: 87.32px;
  }
  .see-what-others-are-saying-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-sm);
  }
  
  .scholarships1 {
    position: absolute;
    width: 130.09%;
    top: -48.8%;
    left: 0.46%;
  }
  .scholarships {
    position: relative;
    width: 216px;
    height: 48px;
  }
  .header {
    align-self: stretch;
    height: 88px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding: var(--padding-11xl) var(--padding-141xl) var(--padding-3xs) 0;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .image-24-icon {
    position: relative;
    width: 278.16px;
    height: 57px;
    object-fit: cover;
  }
  
  .cardscholarshipsbackgroundl-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bookmark-disabled-icon {
    position: relative;
    width: 16px;
    height: 21px;
  }
  .community-title-parent {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-21xl);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-44xl);
    font-size: var(--p1-regular-16-size);
  }
  .members {
    position: relative;
    letter-spacing: 0.08em;
    line-height: 150%;
    font-weight: 500;
    text-indent: 1px;
    display: inline-block;
    width: 269.44px;
    height: 19px;
    flex-shrink: 0;
  }
  .members-wrapper {
    width: 351px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    color: var(--royal-blue);
  }
  .description {
    position: relative;
    line-height: 150%;
    text-indent: 1px;
    display: inline-block;
    width: 269.44px;
    height: 50px;
    flex-shrink: 0;
  }
  .description-wrapper {
    width: 352px;
    height: 62px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .button-text-icon {
    position: relative;
    width: 65.52px;
    height: 9.98px;
  }
  .buttonlarge {
    cursor: pointer;
    border: 1.5px solid var(--logo-black);
    padding: var(--padding-12xs) 0;
    background-color: transparent;
    border-radius: var(--br-5xs);
    box-sizing: border-box;
    width: 137px;
    height: 35px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .buttonlarge:active {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.55);
  }
  .button-text-icon1 {
    position: relative;
    width: 39.95px;
    height: 14.03px;
  }
  .buttonlarge1 {
    cursor: pointer;
    border: 0;
    padding: var(--padding-12xs) 0;
    background-color: var(--logo-black);
    border-radius: var(--br-5xs);
    width: 134px;
    height: 32px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .buttonlarge1:active {
    border: 1px solid var(--color-black);
    box-sizing: border-box;
  }
  .buttoncardscholarshipslarge {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  .buttoncardscholarshipslarge-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-6xs) var(--padding-13xl) var(--padding-mini);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .buttonlarge2,
  .buttonlarge3,
  .cardscholarshipslarge {
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
    align-items: center;
  }
  .cardscholarshipslarge {
    border: 1px solid var(--gray-5);
    height: 286px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .buttonlarge2,
  .buttonlarge3 {
    cursor: pointer;
    padding: var(--padding-12xs) 0;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: center;
  }
  .buttonlarge2 {
    border: 1.5px solid var(--logo-black);
    background-color: transparent;
    width: 137px;
    height: 35px;
  }
  .buttonlarge1:active,
  .buttonlarge2:active {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
  .buttonlarge3 {
    border: 0;
    background-color: var(--logo-black);
    width: 134px;
    height: 32px;
  }
  .buttonlarge3:active,
  .buttonlarge3:hover {
    background-color: var(--color-limegreen);
  }
  .buttonlarge3:active {
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
  .scholarship-cards {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .scholarship-section,
  .see-more {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .see-more {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    font-size: var(--p1-regular-16-size);
    font-weight: 500;
    font-family: var(--p1-regular-16);
    color: var(--royal-blue);
    text-align: center;
    width: 156px;
    height: 54px;
    flex-shrink: 0;
  }
  .scholarship-section {
    background-color: var(--white);
    flex-direction: column;
    padding: var(--padding-17xl) var(--padding-141xl) 0;
    gap: var(--gap-3xs);
    color: var(--gray-5);
  }
  .background-icon {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-5xs) var(--br-5xs) 0 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
  }
  .cardcommunitybackgroundlarg {
    position: relative;
    width: 352px;
    height: 97px;
  }
  .buttonlarge7 {
    border-radius: var(--br-5xs);
    background-color: var(--logo-black);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-7xs) var(--padding-31xl);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .buttoncardscholarshipslarge-wrapper1 {
    overflow: hidden;
    padding: var(--padding-6xs) var(--padding-13xl) var(--padding-mini);
    align-items: flex-start;
    text-align: center;
    font-size: var(--h5-btns-med-14-size);
    color: var(--gray-1);
  }
  .buttoncardscholarshipslarge-wrapper1,
  .communities-section,
  .divider1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .communities-section {
    align-items: flex-end;
    font-size: var(--p2-regular-12-size);
    color: var(--logo-black);
  }
  .divider1 {
    align-self: stretch;
    overflow: hidden;
    padding: var(--padding-base) var(--padding-141xl);
    align-items: flex-start;
  }
 
  .success-stories {
    align-self: stretch;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-8xl) 368px var(--padding-8xl) 379px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .cardtestimonialbackground-icon {
    position: relative;
    width: 256px;
    height: 241px;
  }
  .line-under-amount {
    position: relative;
    border-top: 3px solid var(--logo-color-2);
    box-sizing: border-box;
    width: 145px;
    height: 3px;
  }
  .amount-awarded {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 var(--padding-base);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .joshua-p {
    margin: 0;
    font-weight: 500;
  }
  .graphic-design-major {
    margin: 0;
    font-size: var(--p2-regular-12-size);
    line-height: 150%;
  }
  .name-and-major-container {
    position: relative;
  }
  .name-and-major {
    display: flex;
    flex-direction: row;
    padding: 0 0 0 var(--padding-base);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .testimony1 {
    position: relative;
    letter-spacing: 0.08em;
    line-height: 150%;
    font-weight: 500;
    display: inline-block;
    width: 226px;
    height: 95px;
    flex-shrink: 0;
  }
  .cardtestimonialdescription,
  .testimony {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .testimony {
    height: 95px;
    flex-direction: row;
    padding: 0 0 0 var(--padding-base);
    box-sizing: border-box;
    font-size: var(--p2-regular-12-size);
  }
  .cardtestimonialdescription {
    width: 256px;
    height: 210px;
    flex-direction: column;
    gap: var(--gap-5xs);
  }
  .cardtestimonial {
    overflow: hidden;
  }
  .cardtestimonial,
  .cardtestimonial1 {
    border-radius: var(--br-5xs);
    background-color: var(--gray-1);
    box-shadow: 3px 3px 19px 3px rgba(199, 199, 199, 0.8);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-sm);
  }
  .blank-line {
    margin: 0;
  }
  .lorem-ipsum-dolor {
    margin: 0;
    letter-spacing: 0.08em;
    font-weight: 500;
  }
  .testimony7 {
    position: relative;
    line-height: 150%;
    display: inline-block;
    width: 226px;
    height: 95px;
    flex-shrink: 0;
  }
  .cardtestimonial-parent,
  .testimonials {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cardtestimonial-parent {
    flex-direction: row;
    gap: var(--gap-13xl);
  }
  .testimonials {
    flex-direction: column;
    padding: var(--padding-5xs) var(--padding-141xl);
    font-size: var(--p1-regular-16-size);
  }
  .wrapper {
    position: relative;
    width: 965px;
    height: 542px;
  }
  .testimonial-video {
    align-self: stretch;
    background: linear-gradient(#fff, #fff),
      linear-gradient(180deg, rgba(89, 88, 109, 0.2), rgba(89, 88, 109, 0));
    border: 1px solid var(--gray-4);
    box-sizing: border-box;
    height: 668px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footerlogo-icon {
    position: relative;
    width: 148px;
    height: 45px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .nav-link {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 150%;
  }
  .footerlinknav {
    position: relative;
    width: 160px;
    height: 19px;
  }
  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .footericoninstagram {
    position: relative;
    width: 20px;
    height: 20px;
  }
  .footericonfacbook {
    position: relative;
    width: 18.75px;
    height: 18.75px;
  }
  .footericontwitter {
    position: relative;
    width: 19.5px;
    height: 15.84px;
  }
  .footericonyoutube {
    position: relative;
    width: 21px;
    height: 14.72px;
  }
  .icon-wrapper {
    display: flex;
    flex-direction: row;
    padding: 0 var(--padding-5xs);
    align-items: center;
    justify-content: center;
    gap: var(--gap-xs);
  }
  .footer-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-11xl);
  }
  .students {
    position: relative;
    font-weight: 500;
    display: inline-block;
    width: 160px;
  }
  .footer-link {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    line-height: 150%;
    display: inline-block;
  }
  .main-footer-link,
  .main-footer-link2 {
    position: relative;
    width: 160px;
    height: 24px;
  }
  .main-footer-link2 {
    cursor: pointer;
  }
  .footer-menu1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  .footer-link12 {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    line-height: 150%;
    text-indent: 1px;
    display: inline-block;
  }
  .main-footer-link12 {
    position: relative;
    width: 160px;
    height: 71px;
  }
  .subscribe-email-text-box {
    border: 0;
    font-weight: 500;
    font-family: var(--p1-regular-16);
    font-size: var(--h5-btns-med-14-size);
    background-color: var(--white);
    border-radius: var(--br-7xs);
    box-shadow: var(--shadow-1);
    width: 199px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs) 80px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .button,
  .button-text5 {
    position: absolute;
    width: 100%;
    left: 0;
  }
  .button {
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--br-5xs);
    background-color: var(--purple-blue);
  }
  .button-text5 {
    height: 95%;
    top: 2.5%;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .buttonlarge12 {
    position: relative;
    width: 198px;
    height: 32px;
    text-align: center;
    font-size: var(--h5-btns-med-14-size);
  }
  .footer-grid-row,
  .footer-links4,
  .footer-menu-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .footer-links4 {
    flex-direction: column;
    gap: var(--gap-5xs);
    font-size: var(--p2-regular-12-size);
  }
  .footer-grid-row,
  .footer-menu-parent {
    flex-direction: row;
    gap: var(--gap-69xl);
  }
  .footer-grid-row {
    justify-content: center;
  }
  .scholist-all-rights {
    position: relative;
    line-height: 150%;
  }
  .footer-legal {
    width: 827px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-365xl);
  }
  .footer,
  .homepage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer {
    align-self: stretch;
    background-color: var(--gray-4);
    padding: var(--padding-63xl) var(--padding-105xl) var(--padding-20xl);
    gap: var(--gap-62xl);
    font-size: var(--p1-regular-16-size);
    color: var(--gray-1);
  }
  .homepage {
    position: relative;
    background-color: var(--white);
    width: 100%;
    text-align: left;
    font-size: var(--h2-bold-32-size);
    color: var(--logo-black);
    font-family: var(--p1-regular-16);
  }
  @media screen and (max-width: 1200px) {
    .menu {
      display: flex;
    }
    .button-text1:hover {
      color: var(--color-black);
    }
    .register {
      display: none;
    }
    .banner-image-choice {
      width: 371px;
    }
    .graduate {
      width: 280px;
    }
    .applying-to-scholarships {
      font-size: var(--font-size-23xl);
      width: 80%;
    }
    .frame-parent {
      margin-left: 0;
    }
    .bnner-backgrround {
      width: 1200px;
      gap: var(--gap-xl);
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
    .banner {
      padding-right: var(--padding-81xl);
      box-sizing: border-box;
    }
    .homesection-header {
      display: none;
    }
    .how-it-works,
    .how-it-works1 {
      align-self: stretch;
      width: auto;
      padding-left: -50-;
      box-sizing: border-box;
    }
    .how-it-works1 {
      display: none;
      height: 42px;
      padding-left: 0-;
      padding-right: 0;
    }
    .divider {
      display: none;
    }
    .how-it-works2 {
      font-size: 29px;
    }
    .homehow-it-worksimg {
      width: 407px;
      height: 204px;
    }
    .community-and-support,
    .personalized-matches {
      gap: var(--gap-31xl);
    }
    .homehow-it-worksimg1 {
      width: 407px;
      height: 204px;
    }
    .home-section-how-it-works {
      gap: var(--gap-61xl);
      padding-left: var(--padding-181xl);
      padding-right: var(--padding-181xl);
      box-sizing: border-box;
    }
    .see-what-others {
      font-size: 7px;
    }
    .cgi-stacked-lockup-blue1-icon {
      width: 83px;
      height: 32px;
    }
    .dbf43de48b00044eb5af-icon {
      width: 78px;
      height: 27px;
    }
    .future-founders-color-logo-icon {
      width: 127px;
      height: 32px;
    }
    .verizon-logo-1-icon {
      width: 126px;
      height: 32px;
    }
    .visa-logo-icon {
      width: 82px;
      height: 32px;
    }
    .see-what-others-are-saying-parent {
      gap: var(--gap-7xs);
    }
    .partnership-strip {
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
    .header,
    .partners {
      justify-content: center;
      box-sizing: border-box;
    }
    .partners {
      width: auto;
      align-items: flex-start;
      padding-left: var(--padding-181xl);
      padding-right: var(--padding-181xl);
    }
    .header {
      align-items: center;
      padding-left: 0;
      padding-right: 0;
    }
    .image-24-icon {
      width: 258px;
      height: 37px;
    }
    .buttonlarge {
      width: 117px;
    }
    .buttonlarge1 {
      width: 114px;
    }
    .cardscholarshipslarge {
      width: 314px;
      height: 268px;
    }
    .buttonlarge2 {
      width: 117px;
    }
    .buttonlarge3 {
      width: 114px;
    }
    .scholarship-cards {
      gap: var(--gap-mini);
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    .see-more {
      text-align: right;
      justify-content: center;
    }
    .scholarship-section {
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
    .communities-section {
      align-items: center;
    }
    .communities-section,
    .success-stories {
      justify-content: center;
    }
    .cardtestimonial-parent,
    .testimonials {
      flex-wrap: wrap;
    }
    .wrapper {
      width: 70%;
      height: 70%;
    }
    .footer-menu-parent {
      flex-wrap: wrap;
    }
    .footer-grid-row {
      flex-direction: column;
      gap: var(--gap-31xl);
    }
  }
  @media screen and (max-width: 850px) {
    .top-nav-left {
      display: none;
    }
    .menu {
      display: flex;
    }
    .graduate,
    .register {
      display: none;
    }
    .graduate {
      margin-bottom: 100px;
    }
    .applying-to-scholarships {
      font-size: var(--h2-bold-32-size);
      width: 70%;
    }
    .buttonxxlarge {
      width: 330px;
    }
    .frame-parent {
      align-items: flex-end;
      justify-content: center;
    }
    .applying {
      margin-bottom: 20px;
    }
    .bnner-backgrround {
      flex-direction: column;
    }
    .banner {
      gap: var(--gap-41xl);
      align-items: center;
      justify-content: center;
      padding-top: var(--padding-31xl);
      padding-bottom: var(--padding-31xl);
      box-sizing: border-box;
      margin-bottom: 30px;
    }
    .how-it-works,
    .how-it-works1 {
      align-self: stretch;
      width: auto;
    }
    .divider {
      display: none;
      padding-top: var(--padding-xl);
      padding-bottom: var(--padding-3xs);
      box-sizing: border-box;
    }
    .community-and-support,
    .personalized-matches {
      flex-direction: column;
      gap: var(--gap-xl);
      align-items: flex-start;
      justify-content: center;
    }
    .home-section-how-it-works {
      flex-direction: column;
    }
    .cgi-stacked-lockup-blue1-icon {
      width: 78px;
      height: 27px;
    }
    .dbf43de48b00044eb5af-icon {
      width: 67px;
      height: 22px;
    }
    .future-founders-color-logo-icon,
    .verizon-logo-1-icon,
    .visa-logo-icon {
      width: 73px;
      height: 22px;
    }
    .see-what-others-are-saying-parent {
      gap: var(--gap-11xs);
    }
    .header,
    .partners {
      justify-content: center;
      box-sizing: border-box;
    }
    .partners {
      padding-left: var(--padding-101xl);
      padding-right: var(--padding-101xl);
    }
    .header {
      align-items: center;
      padding-left: 0;
      padding-right: 0;
    }
    .scholarship-cards {
      flex-direction: row;
    }
    .see-more {
      text-align: left;
    }
    .scholarship-section {
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
    .communities-section,
    .scholarship-section {
      align-items: center;
      justify-content: center;
    }
    .success-stories,
    .testimonials {
      padding-left: var(--padding-301xl);
      box-sizing: border-box;
    }
    .testimonials {
      padding-left: var(--padding-21xl);
      padding-right: var(--padding-21xl);
      padding-bottom: var(--padding-xl);
    }
    .wrapper {
      width: 70%;
      height: 60%;
    }
    .scholist-all-rights {
      font-size: var(--font-size-smi);
    }
    .footer-legal {
      width: 500px;
      flex-direction: column;
      gap: var(--gap-3xs);
    }
  }
  @media screen and (max-width: 520px) {
    .language-icon {
      width: 14px;
      height: 14px;
    }
    .language {
      font-size: var(--font-size-3xs);
    }
    .line-under-link,
    .line-under-link1 {
      width: 69px;
    }
    .headertop-navlink-parent {
      gap: var(--gap-mini);
    }
    .left-side-secondary-nav {
      gap: var(--gap-base);
    }
    .left-side-top-nav-parent {
      gap: var(--gap-301xl);
    }
    .headertop-navprimary {
      align-items: center;
      justify-content: center;
    }
    .banner-image-choice {
      width: 250px;
      height: 400px;
    }
    .student-testimony {
      width: 135px;
      height: 100px;
    }
    .banner-image-choice-parent {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .frame-container {
      width: 327px;
    }
    .frame-wrapper {
      width: 120px;
    }
    .graduate,
    .graduate-inner {
      width: 180px;
    }
    .graduate {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .applying-to-scholarships {
      font-size: var(--font-size-5xl);
    }
    .discover-the-right {
      font-size: var(--font-size-4xs);
      width: 190px;
    }
    .discover-the-right-scholarship-wrapper {
      align-items: flex-end;
      justify-content: center;
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
    .applying-to-scholarships-made-parent {
      align-items: flex-start;
      justify-content: flex-start;
    }
    .frame-div {
      align-items: center;
      justify-content: center;
    }
    .buttonxxlarge {
      width: 200px;
      padding-left: var(--padding-18xl);
      box-sizing: border-box;
    }
    .buttonxxlarge-wrapper {
      width: 10px;
      align-items: center;
      justify-content: center;
    }
    .buttonxxlarge-wrapper:active {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.55);
    }
    .frame-parent {
      align-items: center;
      padding-left: var(--padding-81xl);
    }
    .applying,
    .banner,
    .bnner-backgrround,
    .frame-parent {
      justify-content: center;
      box-sizing: border-box;
    }
    .applying {
      width: 180px;
      align-items: flex-start;
      padding-right: 0;
    }
    .banner,
    .bnner-backgrround {
      align-items: center;
    }
    .bnner-backgrround {
      width: 520px;
      padding-left: 0;
      padding-right: var(--padding-31xl);
    }
    .banner {
      gap: var(--gap-41xl);
      padding-right: 0;
    }
    .section-text {
      font-size: var(--font-size-5xl);
      color: #040405;
    }
    .homesection-header {
      display: flex;
    }
    .how-it-works {
      display: none;
      align-items: flex-start;
      justify-content: center;
      padding-left: var(--padding-71xl);
      padding-right: 0;
      box-sizing: border-box;
    }
    .section-text1 {
      font-size: var(--font-size-7xl);
    }
    .divider,
    .how-it-works1 {
      display: none;
      box-sizing: border-box;
    }
    .how-it-works1 {
      align-items: flex-start;
      justify-content: center;
      padding-left: var(--padding-181xl);
      padding-right: 0;
    }
    .divider {
      padding-top: var(--padding-xl);
      padding-bottom: 0;
    }
    .how-it-works2 {
      font-size: var(--font-size-2xl);
    }
    .details1 {
      width: 300px;
    }
    .details1,
    .link-to-page {
      font-size: var(--p2-regular-12-size);
    }
    .homehow-it-worksimg {
      width: 297px;
    }
    .personalized-matches {
      align-items: flex-start;
      justify-content: center;
      padding-top: 0;
      box-sizing: border-box;
    }
    .how-it-works3,
    .how-it-works4 {
      font-size: var(--font-size-2xl);
    }
    .community-and-support {
      align-items: flex-start;
      justify-content: center;
    }
    .homehow-it-worksimg1 {
      width: 297px;
    }
    .home-section-how-it-works {
      gap: var(--gap-61xl);
      align-items: center;
      justify-content: flex-start;
      padding-left: var(--padding-231xl);
      padding-top: var(--padding-11xl);
      padding-right: var(--padding-81xl);
      box-sizing: border-box;
    }
    .see-what-others {
      font-size: 6px;
      width: 90px;
    }
    .cgi-stacked-lockup-blue1-icon,
    .dbf43de48b00044eb5af-icon,
    .future-founders-color-logo-icon,
    .verizon-logo-1-icon,
    .visa-logo-icon {
      width: 48px;
      height: 18px;
    }
    .see-what-others-are-saying-parent {
      gap: var(--gap-9xs);
    }
    .partners {
      align-items: center;
      justify-content: center;
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
    .scholarships1 {
      font-size: var(--font-size-6xl);
    }
    .header {
      height: 82px;
      padding-left: var(--padding-21xl);
      padding-right: 0;
      box-sizing: border-box;
    }
    .buttonlarge {
      gap: var(--gap-365xl);
    }
    .scholarship-cards {
      gap: var(--gap-xl);
    }
    .scholarship-section,
    .success-stories {
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
    .scholarship-section {
      align-items: center;
      justify-content: center;
    }
    .success-stories {
      padding-top: var(--padding-8xl);
    }
    .cardtestimonial-parent {
      flex-direction: column;
    }
    .wrapper {
      height: 40%;
    }
    .footer-legal {
      gap: var(--gap-3xs);
    }
    .homepage {
      align-items: center;
      justify-content: center;
    }
  }
  @media screen and (max-width: 350px) {
    .headertop-navsecondary {
      display: none;
    }
    .headerlogo {
      width: 124px;
      height: 31px;
    }
    .menu {
      width: 37px;
      height: 37px;
    }
    .left-side-top-nav-parent {
      gap: var(--gap-281xl);
    }
  }
  
.communitiybutton{
  color: #fff;
}
.language-icon {
  position: relative;
  width: 16px;
  height: 16px;
}
.language {
  position: relative;
  font-weight: 500;
}
.down-icon {
  position: relative;
  width: 10px;
  height: 6.18px;
}
.headerdrop-downlang,
.right-side-secondary-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.headerdrop-downlang {
  gap: var(--gap-5xs);
}
.right-side-secondary-nav {
  position: absolute;
  top: calc(50% - 11px);
  right: 0;
  padding: 0 var(--padding-13xl) 0 0;
}
.line-under-link {
  position: relative;
  background-color: var(--gray-4);
  width: 109px;
  height: 2px;
}
.headertop-navlink {
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs) 0 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.line-under-link1 {
  position: relative;
  background-color: var(--gray-3);
  width: 109px;
  height: 2px;
}
.headertop-navlink-parent,
.left-side-secondary-nav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.headertop-navlink-parent {
  gap: var(--gap-13xl);
}
.left-side-secondary-nav {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  padding: 0 0 0 var(--padding-13xl);
}
.headertop-navsecondary {
  align-self: stretch;
  position: relative;
  background-color: var(--gray-1);
  height: 40px;
}
.logo-bird-vector,
.logo-words-icon {
  position: absolute;
  height: 100%;
  width: 33.26%;
  top: 0;
  right: 66.74%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.logo-words-icon {
  height: 45.93%;
  width: 68.87%;
  top: 47.9%;
  right: 0;
  bottom: 6.18%;
  left: 31.13%;
}
.headerlogo {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 134px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-active {
  position: relative;
  font-weight: 500;
}
.headernav-link {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--royal-blue);
}
.headernav-link1 {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}
.headernav-link1,
.headernav-link4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headernav-link-parent {
  flex: 1;
  align-items: flex-start;
  gap: var(--gap-13xl);
}
.headerlogo-parent,
.headernav-link-parent,
.top-nav-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.top-nav-left {
  flex: 1;
  height: 21px;
  align-items: center;
}
.headerlogo-parent {
  position: absolute;
  top: 0;
  left: 32px;
  width: 644px;
  align-items: flex-end;
  gap: var(--gap-13xl);
}
.left-side-top-nav {
  flex: 1;
  position: relative;
  height: 41px;
}
.vector-icon,
.vector-icon1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon1 {
  height: 50%;
  width: 75%;
  top: 25%;
  right: 12.5%;
  bottom: 25%;
  left: 12.5%;
}
.menu {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 47px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text {
  position: relative;
  font-size: var(--h5-btns-med-14-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--logo-black);
  text-align: center;
}
.buttonmedium {
  cursor: pointer;
  border: 1px solid var(--logo-black);
  padding: var(--padding-12xs) 0;
  background-color: transparent;
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  width: 128px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.buttonmedium:active {
  background-color: var(--white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.83);
}
.button-text1 {
  position: relative;
  font-size: var(--h5-btns-med-14-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--gray-1);
  text-align: center;
}
.button-text1:hover {
  color: var(--color-black);
}
.button-text1:active {
  -webkit-text-stroke: 1px #000;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.buttonmedium1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-12xs) 0;
  background-color: var(--royal-blue);
  border-radius: var(--br-5xs);
  width: 128px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.buttonmedium1:hover {
  box-shadow: 0 4px 4px #010101;
}
.buttonmedium1:active,
.buttonxxlarge-wrapper:active {
  box-shadow: 0 4px 4px rgba(1, 1, 1, 0.84);
}
.register,
.right-side-top-nav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.right-side-top-nav {
  padding: 0 var(--padding-13xl) 0 0;
  align-items: flex-end;
  justify-content: flex-end;
}
.left-side-top-nav-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-425xl);
}
.headertop-navprimary,
.nav-bar {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.headertop-navprimary {
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-3);
  padding: var(--padding-lgi) 0;
}
.nav-bar {
  background-color: var(--white);
  height: 120px;
  font-size: var(--h5-btns-med-14-size);
}
.banner-image-choice {
  position: relative;
  width: 377.12px;
  height: 557px;
  object-fit: cover;
}
.student-name,
.student-quote {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 117px;
}
.student-quote {
  font-size: var(--font-size-4xs);
  width: 133px;
}
.student-testimony {
  width: 135px;
  height: 364px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}
.banner-image-choice-parent {
  gap: var(--gap-9xl);
}
.banner-image-choice-parent,
.frame-container,
.frame-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
.frame-wrapper {
  align-items: flex-start;
}
.graduate-inner {
  width: 639px;
}
.graduate,
.graduate-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.applying-to-scholarships,
.discover-the-right {
  position: relative;
  display: flex;
  align-items: center;
  width: 675px;
}
.discover-the-right {
  font-weight: 500;
  width: 385px;
  flex-shrink: 0;
}
.discover-the-right-scholarship-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: flex-end;
  font-size: var(--h3-medium-20-size);
}
.applying-to-scholarships-made-parent,
.frame-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.applying-to-scholarships-made-parent {
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frame-div {
  align-self: stretch;
  justify-content: center;
}
.sign-up {
  position: relative;
  font-size: var(--p1-regular-16-size);
  line-height: 150%;
  font-family: var(--p1-regular-16);
  color: var(--gray-1);
  text-align: center;
}
.buttonxxlarge {
  border-radius: var(--br-7xs);
  background-color: var(--royal-blue);
  width: 400px;
  height: 40px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-8xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.buttonxxlarge-wrapper {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-items: flex-start;
  justify-content: flex-start;
}
.applying,
.buttonxxlarge-wrapper,
.frame-parent {
  display: flex;
  flex-direction: column;
}
.frame-parent {
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-3xs);
}
.applying {
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -157px;
  text-align: right;
  font-size: var(--h1-bold-52-size);
}
.banner,
.bnner-backgrround {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bnner-backgrround {
  width: 1440px;
  flex-direction: row;
  padding: 0 183px 0 var(--padding-81xl);
  box-sizing: border-box;
}
.banner {
  align-self: stretch;
  background-color: rgba(89, 88, 109, 0.1);
  flex-direction: column;
  font-size: var(--p1-regular-16-size);
}
.section-text {
  position: relative;
}
.homesection-header {
  width: 213px;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.how-it-works {
  overflow: hidden;
  display: none;
  flex-direction: column;
  padding: var(--padding-6xs) 0;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  color: var(--color-black);
}
.section-text1 {
  position: relative;
}
.homesection-header1,
.how-it-works1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.homesection-header1 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 43px;
  opacity: 0;
}
.homesection-header1.animate {
  animation: 1s ease 0s 1 normal forwards fade-in;
}
.how-it-works1 {
  width: 1428px;
  height: 62px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-6xs) 0 var(--padding-6xs) 139px;
  box-sizing: border-box;
  color: var(--color-black);
}
.homesection-divider-icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
}
.divider {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-141xl) var(--padding-smi);
  align-items: center;
  justify-content: center;
}
.how-it-works2 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: inline-block;
}
.details1 {
  position: relative;
  line-height: 150%;
  display: inline-block;
  width: 447px;
}
.details,
.link-to-page {
  font-size: var(--p1-regular-16-size);
}
.link-to-page {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--royal-blue);
  text-align: left;
  display: inline-block;
}
.details {
  position: absolute;
  height: 61.9%;
  width: 100%;
  top: 38.1%;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.homehow-it-workssection-desc {
  position: relative;
  width: 447px;
  height: 168px;
}
.homehow-it-worksimg {
  position: relative;
  width: 457px;
  height: 244px;
  object-fit: cover;
}
.personalized-matches {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-131xl);
}
.how-it-works3,
.how-it-works4 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: inline-block;
}
.how-it-works4 {
  text-transform: uppercase;
}
.community-and-support,
.homehow-it-workssection-desc-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-45xl);
}
.community-and-support {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-131xl);
}
.photo-icon {
  position: absolute;
  height: 99.59%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0.41%;
  left: 0;
  border-radius: var(--br-base);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.homehow-it-worksimg1 {
  position: relative;
  width: 457px;
  height: 244px;
}
.home-section-how-it-works {
  width: 1444px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-8xl) var(--padding-136xl) var(--padding-3xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.see-what-others {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 126px;
  flex-shrink: 0;
}
.cgi-stacked-lockup-blue1-icon,
.dbf43de48b00044eb5af-icon {
  position: relative;
  width: 89.36px;
  height: 37.42px;
  object-fit: cover;
}
.dbf43de48b00044eb5af-icon {
  width: 102.13px;
}
.future-founders-color-logo-icon,
.verizon-logo-1-icon,
.visa-logo-icon {
  position: relative;
  width: 132.54px;
  height: 37.42px;
  object-fit: cover;
}
.verizon-logo-1-icon,
.visa-logo-icon {
  width: 131.76px;
}
.visa-logo-icon {
  width: 87.32px;
}
.see-what-others-are-saying-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
scholarships1 {
  position: absolute;
  width: 130.09%;
  top: -48.8%;
  left: 0.46%;
}
.scholarships {
  position: relative;
  width: 216px;
  height: 48px;
}
.header {
  align-self: stretch;
  height: 88px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xl) var(--padding-141xl) var(--padding-3xs) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.image-24-icon {
  position: relative;
  width: 278.16px;
  height: 57px;
  object-fit: cover;
}

.cardscholarshipsbackgroundl-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bookmark-disabled-icon {
  position: relative;
  width: 16px;
  height: 21px;
}
.community-title-parent {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-21xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-44xl);
  font-size: var(--p1-regular-16-size);
}
.members {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
  text-indent: 1px;
  display: inline-block;
  width: 269.44px;
  height: 19px;
  flex-shrink: 0;
}
.members-wrapper {
  width: 351px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: var(--royal-blue);
}
.description {
  position: relative;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
  width: 269.44px;
  height: 50px;
  flex-shrink: 0;
}
.description-wrapper {
  width: 352px;
  height: 62px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.button-text-icon {
  position: relative;
  width: 65.52px;
  height: 9.98px;
}
.buttonlarge {
  cursor: pointer;
  border: 1.5px solid var(--logo-black);
  padding: var(--padding-12xs) 0;
  background-color: transparent;
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  width: 137px;
  height: 35px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.buttonlarge:active {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.55);
}
.button-text-icon1 {
  position: relative;
  width: 39.95px;
  height: 14.03px;
}
.buttonlarge1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-12xs) 0;
  background-color: var(--logo-black);
  border-radius: var(--br-5xs);
  width: 134px;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.buttonlarge1:active {
  border: 1px solid var(--color-black);
  box-sizing: border-box;
}
.buttoncardscholarshipslarge {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.buttoncardscholarshipslarge-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-6xs) var(--padding-13xl) var(--padding-mini);
  align-items: flex-start;
  justify-content: flex-start;
}
.buttonlarge2,
.buttonlarge3,
.cardscholarshipslarge {
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  align-items: center;
}
.cardscholarshipslarge {
  border: 1px solid var(--gray-5);
  height: 286px;
  flex-direction: column;
  justify-content: flex-start;
}
.buttonlarge2,
.buttonlarge3 {
  cursor: pointer;
  padding: var(--padding-12xs) 0;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
}
.buttonlarge2 {
  border: 1.5px solid var(--logo-black);
  background-color: transparent;
  width: 137px;
  height: 35px;
}
.buttonlarge1:active,
.buttonlarge2:active {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.buttonlarge3 {
  border: 0;
  background-color: var(--logo-black);
  width: 134px;
  height: 32px;
}
.buttonlarge3:active,
.buttonlarge3:hover {
  background-color: var(--color-limegreen);
}
.buttonlarge3:active {
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.scholarship-cards {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.scholarship-section,
.see-more {
  display: flex;
  align-items: center;
  justify-content: center;
}
.see-more {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-size: var(--p1-regular-16-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--royal-blue);
  text-align: center;
  width: 156px;
  height: 54px;
  flex-shrink: 0;
}
.scholarship-section {
  background-color: var(--white);
  flex-direction: column;
  padding: var(--padding-17xl) var(--padding-141xl) 0;
  gap: var(--gap-3xs);
  color: var(--gray-5);
}
.background-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs) var(--br-5xs) 0 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.cardcommunitybackgroundlarg {
  position: relative;
  width: 352px;
  height: 97px;
}
.buttonlarge7 {
  border-radius: var(--br-5xs);
  background-color: var(--logo-black);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-7xs) var(--padding-31xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.buttoncardscholarshipslarge-wrapper1 {
  overflow: hidden;
  padding: var(--padding-6xs) var(--padding-13xl) var(--padding-mini);
  align-items: flex-start;
  text-align: center;
  font-size: var(--h5-btns-med-14-size);
  color: var(--gray-1);
}
.buttoncardscholarshipslarge-wrapper1,
.communities-section,
.divider1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.communities-section {
  align-items: flex-end;
  font-size: var(--p2-regular-12-size);
  color: var(--logo-black);
}
.divider1 {
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-base) var(--padding-141xl);
  align-items: flex-start;
}

.success-stories {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-8xl) 368px var(--padding-8xl) 379px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.cardtestimonialbackground-icon {
  position: relative;
  width: 256px;
  height: 241px;
}
.line-under-amount {
  position: relative;
  border-top: 3px solid var(--logo-color-2);
  box-sizing: border-box;
  width: 145px;
  height: 3px;
}
.amount-awarded {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.joshua-p {
  margin: 0;
  font-weight: 500;
}
.graphic-design-major {
  margin: 0;
  font-size: var(--p2-regular-12-size);
  line-height: 150%;
}
.name-and-major-container {
  position: relative;
}
.name-and-major {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
}
.testimony1 {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
  display: inline-block;
  width: 226px;
  height: 95px;
  flex-shrink: 0;
}
.cardtestimonialdescription,
.testimony {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.testimony {
  height: 95px;
  flex-direction: row;
  padding: 0 0 0 var(--padding-base);
  box-sizing: border-box;
  font-size: var(--p2-regular-12-size);
}
.cardtestimonialdescription {
  width: 256px;
  height: 210px;
  flex-direction: column;
  gap: var(--gap-5xs);
}
.cardtestimonial {
  overflow: hidden;
}
.cardtestimonial,
.cardtestimonial1 {
  border-radius: var(--br-5xs);
  background-color: var(--gray-1);
  box-shadow: 3px 3px 19px 3px rgba(199, 199, 199, 0.8);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.blank-line {
  margin: 0;
}
.lorem-ipsum-dolor {
  margin: 0;
  letter-spacing: 0.08em;
  font-weight: 500;
}
.testimony7 {
  position: relative;
  line-height: 150%;
  display: inline-block;
  width: 226px;
  height: 95px;
  flex-shrink: 0;
}
.cardtestimonial-parent,
.testimonials {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardtestimonial-parent {
  flex-direction: row;
  gap: var(--gap-13xl);
}
.testimonials {
  flex-direction: column;
  padding: var(--padding-5xs) var(--padding-141xl);
  font-size: var(--p1-regular-16-size);
}
.wrapper {
  position: relative;
  width: 965px;
  height: 542px;
}
.testimonial-video {
  align-self: stretch;
  background: linear-gradient(#fff, #fff),
    linear-gradient(180deg, rgba(89, 88, 109, 0.2), rgba(89, 88, 109, 0));
  border: 1px solid var(--gray-4);
  box-sizing: border-box;
  height: 668px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footerlogo-icon {
  position: relative;
  width: 148px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}
.footerlinknav {
  position: relative;
  width: 160px;
  height: 19px;
}
.footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.footericoninstagram {
  position: relative;
  width: 20px;
  height: 20px;
}
.footericonfacbook {
  position: relative;
  width: 18.75px;
  height: 18.75px;
}
.footericontwitter {
  position: relative;
  width: 19.5px;
  height: 15.84px;
}
.footericonyoutube {
  position: relative;
  width: 21px;
  height: 14.72px;
}
.icon-wrapper {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.footer-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.students {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 160px;
}
.footer-link {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  display: inline-block;
}
.main-footer-link,
.main-footer-link2 {
  position: relative;
  width: 160px;
  height: 24px;
}
.main-footer-link2 {
  cursor: pointer;
}
.footer-menu1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.footer-link12 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
}
.main-footer-link12 {
  position: relative;
  width: 160px;
  height: 71px;
}
.subscribe-email-text-box {
  border: 0;
  font-weight: 500;
  font-family: var(--p1-regular-16);
  font-size: var(--h5-btns-med-14-size);
  background-color: var(--white);
  border-radius: var(--br-7xs);
  box-shadow: var(--shadow-1);
  width: 199px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) 80px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.button,
.button-text5 {
  position: absolute;
  width: 100%;
  left: 0;
}
.button {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-5xs);
  background-color: var(--purple-blue);
}
.button-text5 {
  height: 95%;
  top: 2.5%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonlarge12 {
  position: relative;
  width: 198px;
  height: 32px;
  text-align: center;
  font-size: var(--h5-btns-med-14-size);
}
.footer-grid-row,
.footer-links4,
.footer-menu-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-links4 {
  flex-direction: column;
  gap: var(--gap-5xs);
  font-size: var(--p2-regular-12-size);
}
.footer-grid-row,
.footer-menu-parent {
  flex-direction: row;
  gap: var(--gap-69xl);
}
.footer-grid-row {
  justify-content: center;
}
.scholist-all-rights {
  position: relative;
  line-height: 150%;
}
.footer-legal {
  width: 827px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-365xl);
}
.footer,
.homepage {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer {
  align-self: stretch;
  background-color: var(--gray-4);
  padding: var(--padding-63xl) var(--padding-105xl) var(--padding-20xl);
  gap: var(--gap-62xl);
  font-size: var(--p1-regular-16-size);
  color: var(--gray-1);
}
.homepage {
  position: relative;
  background-color: var(--white);
  width: 100%;
  text-align: left;
  font-size: var(--h2-bold-32-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
}
@media screen and (max-width: 1200px) {
  .menu {
    display: flex;
  }
  .button-text1:hover {
    color: var(--color-black);
  }
  .register {
    display: none;
  }
  .banner-image-choice {
    width: 371px;
  }
  .graduate {
    width: 280px;
  }
  .applying-to-scholarships {
    font-size: var(--font-size-23xl);
    width: 80%;
  }
  .frame-parent {
    margin-left: 0;
  }
  .bnner-backgrround {
    width: 1200px;
    gap: var(--gap-xl);
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .banner {
    padding-right: var(--padding-81xl);
    box-sizing: border-box;
  }
  .homesection-header {
    display: none;
  }
  .how-it-works,
  .how-it-works1 {
    align-self: stretch;
    width: auto;
    padding-left: -50-;
    box-sizing: border-box;
  }
  .how-it-works1 {
    display: none;
    height: 42px;
    padding-left: 0-;
    padding-right: 0;
  }
  .divider {
    display: none;
  }
  .how-it-works2 {
    font-size: 29px;
  }
  .homehow-it-worksimg {
    width: 407px;
    height: 204px;
  }
  .community-and-support,
  .personalized-matches {
    gap: var(--gap-31xl);
  }
  .homehow-it-worksimg1 {
    width: 407px;
    height: 204px;
  }
  .home-section-how-it-works {
    gap: var(--gap-61xl);
    padding-left: var(--padding-181xl);
    padding-right: var(--padding-181xl);
    box-sizing: border-box;
  }
  .see-what-others {
    font-size: 7px;
  }
  .cgi-stacked-lockup-blue1-icon {
    width: 83px;
    height: 32px;
  }
  .dbf43de48b00044eb5af-icon {
    width: 78px;
    height: 27px;
  }
  .future-founders-color-logo-icon {
    width: 127px;
    height: 32px;
  }
  .verizon-logo-1-icon {
    width: 126px;
    height: 32px;
  }
  .visa-logo-icon {
    width: 82px;
    height: 32px;
  }
  .see-what-others-are-saying-parent {
    gap: var(--gap-7xs);
  }
  .partnership-strip {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .header,
  .partners {
    justify-content: center;
    box-sizing: border-box;
  }
  .partners {
    width: auto;
    align-items: flex-start;
    padding-left: var(--padding-181xl);
    padding-right: var(--padding-181xl);
  }
  .header {
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
  .image-24-icon {
    width: 258px;
    height: 37px;
  }
  .buttonlarge {
    width: 117px;
  }
  .buttonlarge1 {
    width: 114px;
  }
  .cardscholarshipslarge {
    width: 314px;
    height: 268px;
  }
  .buttonlarge2 {
    width: 117px;
  }
  .buttonlarge3 {
    width: 114px;
  }
  .scholarship-cards {
    gap: var(--gap-mini);
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .see-more {
    text-align: right;
    justify-content: center;
  }
  .scholarship-section {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .communities-section {
    align-items: center;
  }
  .communities-section,
  .success-stories {
    justify-content: center;
  }
  .cardtestimonial-parent,
  .testimonials {
    flex-wrap: wrap;
  }
  .wrapper {
    width: 70%;
    height: 70%;
  }
  .footer-menu-parent {
    flex-wrap: wrap;
  }
  .footer-grid-row {
    flex-direction: column;
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 850px) {
  .top-nav-left {
    display: none;
  }
  .menu {
    display: flex;
  }
  .graduate,
  .register {
    display: none;
  }
  .graduate {
    margin-bottom: 100px;
  }
  .applying-to-scholarships {
    font-size: var(--h2-bold-32-size);
    width: 70%;
  }
  .buttonxxlarge {
    width: 330px;
  }
  .frame-parent {
    align-items: flex-end;
    justify-content: center;
  }
  .applying {
    margin-bottom: 20px;
  }
  .bnner-backgrround {
    flex-direction: column;
  }
  .banner {
    gap: var(--gap-41xl);
    align-items: center;
    justify-content: center;
    padding-top: var(--padding-31xl);
    padding-bottom: var(--padding-31xl);
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  .how-it-works,
  .how-it-works1 {
    align-self: stretch;
    width: auto;
  }
  .divider {
    display: none;
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-3xs);
    box-sizing: border-box;
  }
  .community-and-support,
  .personalized-matches {
    flex-direction: column;
    gap: var(--gap-xl);
    align-items: flex-start;
    justify-content: center;
  }
  .home-section-how-it-works {
    flex-direction: column;
  }
  .cgi-stacked-lockup-blue1-icon {
    width: 78px;
    height: 27px;
  }
  .dbf43de48b00044eb5af-icon {
    width: 67px;
    height: 22px;
  }
  .future-founders-color-logo-icon,
  .verizon-logo-1-icon,
  .visa-logo-icon {
    width: 73px;
    height: 22px;
  }
  .see-what-others-are-saying-parent {
    gap: var(--gap-11xs);
  }
  .header,
  .partners {
    justify-content: center;
    box-sizing: border-box;
  }
  .partners {
    padding-left: var(--padding-101xl);
    padding-right: var(--padding-101xl);
  }
  .header {
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
  .scholarship-cards {
    flex-direction: row;
  }
  .see-more {
    text-align: left;
  }
  .scholarship-section {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .communities-section,
  .scholarship-section {
    align-items: center;
    justify-content: center;
  }
  .success-stories,
  .testimonials {
    padding-left: var(--padding-301xl);
    box-sizing: border-box;
  }
  .testimonials {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    padding-bottom: var(--padding-xl);
  }
  .wrapper {
    width: 70%;
    height: 60%;
  }
  .scholist-all-rights {
    font-size: var(--font-size-smi);
  }
  .footer-legal {
    width: 500px;
    flex-direction: column;
    gap: var(--gap-3xs);
  }
}
@media screen and (max-width: 520px) {
  .language-icon {
    width: 14px;
    height: 14px;
  }
  .language {
    font-size: var(--font-size-3xs);
  }
  .line-under-link,
  .line-under-link1 {
    width: 69px;
  }
  .headertop-navlink-parent {
    gap: var(--gap-mini);
  }
  .left-side-secondary-nav {
    gap: var(--gap-base);
  }
  .left-side-top-nav-parent {
    gap: var(--gap-301xl);
  }
  .headertop-navprimary {
    align-items: center;
    justify-content: center;
  }
  .banner-image-choice {
    width: 250px;
    height: 400px;
  }
  .student-testimony {
    width: 135px;
    height: 100px;
  }
  .banner-image-choice-parent {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .frame-container {
    width: 327px;
  }
  .frame-wrapper {
    width: 120px;
  }
  .graduate,
  .graduate-inner {
    width: 180px;
  }
  .graduate {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .applying-to-scholarships {
    font-size: var(--font-size-5xl);
  }
  .discover-the-right {
    font-size: var(--font-size-4xs);
    width: 190px;
  }
  .discover-the-right-scholarship-wrapper {
    align-items: flex-end;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .applying-to-scholarships-made-parent {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div {
    align-items: center;
    justify-content: center;
  }
  .buttonxxlarge {
    width: 200px;
    padding-left: var(--padding-18xl);
    box-sizing: border-box;
  }
  .buttonxxlarge-wrapper {
    width: 10px;
    align-items: center;
    justify-content: center;
  }
  .buttonxxlarge-wrapper:active {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.55);
  }
  .frame-parent {
    align-items: center;
    padding-left: var(--padding-81xl);
  }
  .applying,
  .banner,
  .bnner-backgrround,
  .frame-parent {
    justify-content: center;
    box-sizing: border-box;
  }
  .applying {
    width: 180px;
    align-items: flex-start;
    padding-right: 0;
  }
  .banner,
  .bnner-backgrround {
    align-items: center;
  }
  .bnner-backgrround {
    width: 520px;
    padding-left: 0;
    padding-right: var(--padding-31xl);
  }
  .banner {
    gap: var(--gap-41xl);
    padding-right: 0;
  }
  .section-text {
    font-size: var(--font-size-5xl);
    color: #040405;
  }
  .homesection-header {
    display: flex;
  }
  .how-it-works {
    display: none;
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--padding-71xl);
    padding-right: 0;
    box-sizing: border-box;
  }
  .section-text1 {
    font-size: var(--font-size-7xl);
  }
  .divider,
  .how-it-works1 {
    display: none;
    box-sizing: border-box;
  }
  .how-it-works1 {
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--padding-181xl);
    padding-right: 0;
  }
  .divider {
    padding-top: var(--padding-xl);
    padding-bottom: 0;
  }
  .how-it-works2 {
    font-size: var(--font-size-2xl);
  }
  .details1 {
    width: 300px;
  }
  .details1,
  .link-to-page {
    font-size: var(--p2-regular-12-size);
  }
  .homehow-it-worksimg {
    width: 297px;
  }
  .personalized-matches {
    align-items: flex-start;
    justify-content: center;
    padding-top: 0;
    box-sizing: border-box;
  }
  .how-it-works3,
  .how-it-works4 {
    font-size: var(--font-size-2xl);
  }
  .community-and-support {
    align-items: flex-start;
    justify-content: center;
  }
  .homehow-it-worksimg1 {
    width: 297px;
  }
  .home-section-how-it-works {
    gap: var(--gap-61xl);
    align-items: center;
    justify-content: flex-start;
    padding-left: var(--padding-231xl);
    padding-top: var(--padding-11xl);
    padding-right: var(--padding-81xl);
    box-sizing: border-box;
  }
  .see-what-others {
    font-size: 6px;
    width: 90px;
  }
  .cgi-stacked-lockup-blue1-icon,
  .dbf43de48b00044eb5af-icon,
  .future-founders-color-logo-icon,
  .verizon-logo-1-icon,
  .visa-logo-icon {
    width: 48px;
    height: 18px;
  }
  .see-what-others-are-saying-parent {
    gap: var(--gap-9xs);
  }
  .partners {
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .scholarships1 {
    font-size: var(--font-size-6xl);
  }
  .header {
    height: 82px;
    padding-left: var(--padding-21xl);
    padding-right: 0;
    box-sizing: border-box;
  }
  .buttonlarge {
    gap: var(--gap-365xl);
  }
  .scholarship-cards {
    gap: var(--gap-xl);
  }
  .scholarship-section,
  .success-stories {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .scholarship-section {
    align-items: center;
    justify-content: center;
  }
  .success-stories {
    padding-top: var(--padding-8xl);
  }
  .cardtestimonial-parent {
    flex-direction: column;
  }
  .wrapper {
    height: 40%;
  }
  .footer-legal {
    gap: var(--gap-3xs);
  }
  .homepage {
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 350px) {
  .headertop-navsecondary {
    display: none;
  }
  .headerlogo {
    width: 124px;
    height: 31px;
  }
  .menu {
    width: 37px;
    height: 37px;
  }
  .left-side-top-nav-parent {
    gap: var(--gap-281xl);
  }
}
