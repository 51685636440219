/* .language-icon1 {
  position: relative;
  width: 16px;
  height: 16px;
}
.language1 {
  position: relative;
  font-weight: 500;
}
.down-icon1 {
  position: relative;
  width: 10px;
  height: 6.18px;
}
.headerdrop-downlang1,
.right-side-secondary-nav1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.headerdrop-downlang1 {
  gap: var(--gap-5xs);
}
.right-side-secondary-nav1 {
  position: absolute;
  top: calc(50% - 11px);
  right: -0.22px;
  padding: 0 var(--padding-13xl) 0 0;
}
.line-under-link2 {
  position: relative;
  background-color: var(--gray-4);
  width: 109px;
  height: 2px;
}
.headertop-navlink2 {
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs) 0 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.line-under-link3 {
  position: relative;
  background-color: var(--gray-3);
  width: 109px;
  height: 2px;
}
.headertop-navlink-group,
.left-side-secondary-nav1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.headertop-navlink-group {
  gap: var(--gap-13xl);
}
.left-side-secondary-nav1 {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  padding: 0 0 0 var(--padding-13xl);
}
.headertop-navsecondary1 {
  align-self: stretch;
  position: relative;
  background-color: var(--gray-1);
  height: 40px;
}
.headerlogo-icon15 {
  position: relative;
  width: 134px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-active15 {
  position: relative;
  font-weight: 500;
}
.headernav-link75 {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--royal-blue);
}
.headernav-link76 {
  text-decoration: none;
  color: inherit;
}
.headernav-link76,
.headernav-link79 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headernav-link-group {
  flex: 1;
  align-items: flex-start;
  gap: var(--gap-13xl);
}
.headerlogo-group,
.headernav-link-group,
.top-nav-left15 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.top-nav-left15 {
  flex: 1;
  height: 21px;
  align-items: center;
}
.headerlogo-group {
  position: absolute;
  top: 0;
  left: 32px;
  width: 644px;
  align-items: flex-end;
  gap: var(--gap-13xl);
}
.left-side-top-nav15 {
  flex: 1;
  position: relative;
  height: 41px;
}
.vector-icon100,
.vector-icon101 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon101 {
  height: 50%;
  width: 75%;
  top: 25%;
  right: 12.5%;
  bottom: 25%;
  left: 12.5%;
}
.menu15 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 47px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text87 {
  position: relative;
  font-size: var(--h5-btns-med-14-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--logo-black);
  text-align: center;
}
.buttonmedium4 {
  cursor: pointer;
  border: 1px solid var(--logo-black);
  padding: var(--padding-12xs) 0;
  background-color: transparent;
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  width: 128px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.buttonmedium4:hover {
  background-color: var(--color-limegreen);
}
.buttonmedium4:active {
  background-color: var(--white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.83);
}
.button-text88 {
  position: relative;
  font-size: var(--h5-btns-med-14-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--gray-1);
  text-align: center;
}
.button-text88:hover {
  color: var(--color-black);
}
.button-text88:active {
  -webkit-text-stroke: 1px #000;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.buttonmedium5 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-12xs) 0;
  background-color: var(--royal-blue);
  border-radius: var(--br-5xs);
  width: 128px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.buttonmedium5:hover {
  background-color: var(--color-limegreen);
}
.register1,
.right-side-top-nav15 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.right-side-top-nav15 {
  padding: 0 var(--padding-13xl) 0 0;
  align-items: flex-end;
  justify-content: flex-end;
}
.left-side-top-nav-group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-425xl);
}
.headertop-navprimary1,
.nav-bar1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.headertop-navprimary1 {
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-3);
  padding: var(--padding-lgi) 0;
}
.nav-bar1 {
  height: 120px;
}
.image-131-icon {
  position: relative;
  width: 373px;
  height: 250px;
  object-fit: cover;
}
.welcome {
  margin: 0;
}
.welcome-to-scholist-container {
  position: relative;
}
.applying-to-scholarships1 {
  position: relative;
  font-size: var(--h3-medium-20-size);
  font-weight: 500;
  color: var(--logo-black);
}
.welcome-to-scholist-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.image-131-parent,
.left-panel {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-131-parent {
  flex-direction: column;
  gap: var(--gap-10xs);
}
.left-panel {
  flex: 1;
  height: 858px;
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-117xl) var(--padding-89xl);
  box-sizing: border-box;
}
.login2 {
  position: relative;
}
.header14,
.inputtextboxno-label {
  width: 545px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-9xl) 0 var(--padding-6xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.inputtextboxno-label {
  border: 0;
  font-family: var(--p1-regular-16);
  font-size: var(--p1-regular-16-size);
  background-color: var(--white);
  border-radius: var(--br-7xs);
  box-shadow: var(--shadow-1);
  width: 400.63px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.email,
.password {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-7xs) var(--padding-53xl);
  align-items: center;
  justify-content: center;
}
.password {
  padding: var(--padding-6xs) var(--padding-53xl);
}
.login3 {
  position: relative;
  font-size: var(--p1-regular-16-size);
  line-height: 150%;
  font-family: var(--p1-regular-16);
  color: var(--gray-1);
  text-align: left;
}
.buttonxxlarge1,
.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonxxlarge1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-50xl);
  background-color: var(--royal-blue);
  border-radius: var(--br-7xs);
  width: 401px;
  height: 46px;
  flex-direction: row;
  box-sizing: border-box;
}



.buttonxxlarge1:active {
  /* background: #ffffff; */
 /*  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
}
.login-button {
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs) var(--padding-52xl);
}
.terms-conditions {
  text-decoration: underline;
}
.by-submitting-this-container {
  position: relative;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
  width: 372px;
  flex-shrink: 0;
}
.by-submitting-this-form-you-c-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 545px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mid) var(--padding-53xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.terms-and-condition {
  position: relative;
  width: 545px;
  height: 70px;
  text-align: center;
  font-size: var(--p2-regular-12-size);
  color: var(--logo-black);
}
.create-your-account {
  border-radius: var(--br-5xs);
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.right-panel {
  background-color: var(--royal-blue);
  width: 765px;
  height: 853px;
  flex-direction: column;
  padding: var(--padding-175xl) var(--padding-69xl);
  box-sizing: border-box;
  margin-left: -1px;
  font-size: var(--h2-bold-32-size);
}
.body16,
.login1,
.right-panel {
  display: flex;
  align-items: center;
  justify-content: center;
}
.body16 {
  align-self: stretch;
  flex-direction: row;
  font-size: var(--font-size-29xl);
  color: var(--royal-blue);
}
.login1 {
  position: relative;
  background-color: var(--white);
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  text-align: left;
  font-size: var(--h5-btns-med-14-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
}
@media screen and (max-width: 1200px) {
  .menu15 {
    display: flex;
  }
  .button-text88:hover {
    color: var(--color-black);
  }
  .register1 {
    display: none;
  }
  .image-131-icon {
    width: 80%;
    height: 200px;
    justify-content: center;
  }
  .welcome-to-scholist-container {
    font-size: var(--font-size-17xl);
  }
  .applying-to-scholarships1 {
    font-size: var(--font-size-smi);
  }
  .left-panel {
    flex: unset;
    align-self: stretch;
  }
  .right-panel {
    width: 100%;
  }
  .body16 {
    flex-direction: column;
    gap: var(--gap-43xs);
    padding-left: 0;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 850px) {
  .top-nav-left15 {
    display: none;
  }
  .menu15 {
    display: flex;
  }
  .register1 {
    display: none;
  }
  .left-panel {
    flex: unset;
    align-self: stretch;
  }
  .right-panel {
    padding: var(--padding-81xl) var(--padding-11xl);
    box-sizing: border-box;
  }
  .body16 {
    flex-direction: column;
  }
}
@media screen and (max-width: 520px) {
  .language-icon1 {
    width: 14px;
    height: 14px;
  }
  .language1 {
    font-size: var(--font-size-3xs);
  }
  .line-under-link2,
  .line-under-link3 {
    width: 69px;
  }
  .headertop-navlink-group {
    gap: var(--gap-mini);
  }
  .left-side-secondary-nav1 {
    gap: var(--gap-base);
  }
  .left-side-top-nav-group {
    gap: var(--gap-301xl);
  }
  .headertop-navprimary1 {
    align-items: center;
    justify-content: center;
  }
  .left-panel {
    height: 40%;
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .login2 {
    font-size: var(--font-size-mid);
    text-align: center;
  }
  .inputtextboxno-label {
    width: 270px;
  }
  .login3 {
    font-size: var(--p1-regular-16-size);
    text-align: center;
    width: 246px;
  }
  .buttonxxlarge1 {
    width: 270px;
  }
  .by-submitting-this-container {
    font-size: var(--font-size-5xs);
    text-align: left;
    width: 270px;
  }
  .by-submitting-this-form-you-c-wrapper {
    padding-top: var(--padding-8xs);
    box-sizing: border-box;
  }
  .create-your-account {
    width: 90%;
  }
  .right-panel {
    height: 50%;
    padding: var(--padding-31xl) var(--padding-3xs);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 350px) {
  .headertop-navsecondary1 {
    display: none;
  }
  .headerlogo-icon15 {
    width: 124px;
    height: 31px;
  }
  .menu15 {
    width: 37px;
    height: 37px;
  }
  .left-side-top-nav-group {
    gap: var(--gap-281xl);
  }
  .buttonxxlarge1,
  .inputtextboxno-label {
    width: 240px;
  }
}
 */ 


 @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
 body {
   margin: 0;
   line-height: normal;
 }
 :root {
   /* fonts */
   --p1-regular-16: Poppins;
 
   /* font sizes */
   --h6-medium-12-size: 12px;
   --font-size-5xs: 8px;
   --p1-regular-16-size: 16px;
   --h2-bold-32-size: 32px;
   --font-size-mid: 17px;
   --font-size-5xl: 24px;
   --font-size-mini: 15px;
   --font-size-29xl: 48px;
   --font-size-21xl: 40px;
   --font-size-9xl: 28px;
   --font-size-3xl: 22px;
   --h3-medium-20-size: 20px;
   --font-size-2xl-6: 21.6px;
   --font-size-sm: 14px;
   --font-size-3xs: 10px;
   --font-size-2xs: 11px;
   --font-size-4xs: 9px;
   --font-size-lg-8: 18.8px;
   --font-size-2xl: 21px;
   --font-size-4xl-2: 23.2px;
   --font-size-lgi: 19px;
   --font-size-17xl: 36px;
   --font-size-3xl-7: 22.7px;
   --font-size-smi: 13px;
   --font-size-4xl-4: 23.4px;
   --font-size-lg: 18px;
   --font-size-lg-9: 18.9px;
   --font-size-9xl-1: 28.1px;
 
   /* Colors */
   --white: #fff;
   --royal-blue: #0d0ed9;
   --gray-4: #79797d;
   --color-gray-100: #787878;
   --logo-black: #010101;
   --color-gray-200: rgba(255, 255, 255, 0.5);
   --gray-1: #f2f2f2;
   --color-khaki: #f8d665;
   --color-tomato: #ed5043;
   --gray-5: #393939;
   --color-darkslategray-100: #263238;
   --color-silver-100: #c4c6c7;
   --color-silver-200: #bbb;
   --color-black: #000;
   --purple-blue: #5558cf;
   --color-steelblue-100: #4a93cf;
   --teal: #40cfc3;
   --color-mediumaquamarine: #70d499;
 
   /* Gaps */
   --gap-43xs: -30px;
   --gap-192xl: 211px;
   --gap-61xl: 80px;
   --gap-254xl: 273px;
   --gap-28xl: 47px;
   --gap-xs: 12px;
   --gap-6xs: 7px;
   --gap-53xl: 72px;
   --gap-31xl: 50px;
   --gap-9xs: 4px;
   --gap-5xs: 8px;
   --gap-7xs: 6px;
   --gap-3xs: 10px;
   --gap-9xl: 28px;
   --gap-11xl: 30px;
   --gap-mini: 15px;
   --gap-3xl: 22px;
   --gap-11xs: 2px;
   --gap-0: 0px;
   --gap-base: 16px;
   --gap-10xs: 3px;
   --gap-13xl: 32px;
   --gap-3xs-4: 9.4px;
   --gap-2xs: 11px;
   --gap-mini-1: 14.1px;
 
   /* Paddings */
   --padding-175xl: 194px;
   --padding-69xl: 88px;
   --padding-81xl: 100px;
   --padding-11xl: 30px;
   --padding-31xl: 50px;
   --padding-3xs: 10px;
   --padding-mid: 17px;
   --padding-53xl: 72px;
   --padding-8xs: 5px;
   --padding-5xs: 8px;
   --padding-52xl: 71px;
   --padding-50xl: 69px;
   --padding-6xs: 7px;
   --padding-xs: 12px;
   --padding-7xs: 6px;
   --padding-9xl: 28px;
   --padding-23xl: 42px;
   --padding-181xl: 200px;
   --padding-4xs: 9px;
   --padding-xl: 20px;
   --padding-28xl: 47px;
   --padding-mini: 15px;
   --padding-smi: 13px;
   --padding-61xl: 80px;
   --padding-84xl: 103px;
   --padding-4xs-2: 8.2px;
   --padding-9xs: 4px;
   --padding-55xl: 74px;
   --padding-42xl: 61px;
 
   /* Border radiuses */
   --br-5xs: 8px;
   --br-7xs: 6px;
   --br-3xs: 10px;
   --br-9xs: 4px;
   --br-xs: 12px;
   --br-3xs-4: 9.4px;
   --br-4xs: 9px;
   --br-3xs-5: 9.5px;
 
   /* Effects */
   --shadow-1: 0px 1px 3px rgba(0, 0, 0, 0.25);
 }
 
 .errorLoginBox {
 
  display: flex;
  
  align-items: center;
  justify-content: center;
}


 .letfonboardingimage1-icon {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  width: 198.49px;
  height: 226px;
  object-fit: cover;
}
.letfonboardingimagebox1 {
  position: relative;
  width: 199px;
  height: 290px;
}
.letfonboardingimage2-icon {
  position: absolute;
  top: 62px;
  left: 0;
  border-radius: var(--br-3xs);
  width: 198.49px;
  height: 226px;
  object-fit: cover;
}
.letfonboardingimagebox2 {
  position: relative;
  width: 198px;
  height: 290px;
}
.letfonboardingimagebox {
  position: absolute;
  top: 34px;
  left: -0.45px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-254xl);
}
.welcome {
  margin: 0;
}
.letfonboardingapplying,
.letfonboardingwelcome {
  position: absolute;
  width: 48.93%;
  top: 42.22%;
  left: 0.15%;
  line-height: 70px;
  display: inline-block;
}
.letfonboardingapplying {
  width: 81.2%;
  top: 60.89%;
  left: 0.78%;
  font-size: var(--font-size-5xl);
  line-height: 50px;
  color: var(--logo-black);
}
.letfonboardingrectanglered {
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-tomato);
  width: 39.51px;
  height: 42px;
}
.letfonboardingrectanglebox {
  position: absolute;
  top: 0;
  left: 0.55px;
  width: 711px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.leftrectangleyellow {
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-khaki);
  width: 39px;
  height: 42px;
}
.leftonboardingimage-icon {
  position: relative;
  border-radius: var(--br-3xs);
  width: 198.49px;
  height: 226px;
  object-fit: cover;
}
.lowerboxonboarding {
  position: absolute;
  top: 513px;
  left: 95.02px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-192xl);
}
.leftonboadingbox {
  flex: 1;
  position: relative;
  height: 739px;
}
.leftonboarding,
.rightonboarding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.leftonboarding {
  width: 726.89px;
  padding: 0 var(--padding-23xl);
  box-sizing: border-box;
}
.rightonboarding {
  flex: 1;
  height: 839px;
}
.rightonboarding1login {
  position: relative;
  font-weight: 700;
}
.rightonboarding1header,
.rightonboardingbox {
  width: 545px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-9xl) 0 var(--padding-6xs);
 
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.rightonboardingbox {
  border: 0;
  font-family: var(--p1-regular-16);
  font-size: var(--p1-regular-16-size);
  background-color: var(--white);
  border-radius: var(--br-7xs);
  box-shadow: var(--shadow-1);
  width: 400.63px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.rightonboardingemail,
.rightonboardingpassword {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-7xs) var(--padding-53xl);
  align-items: center;
  justify-content: center;
}
.rightonboardingpassword {
  padding: var(--padding-6xs) var(--padding-53xl);
}
.rightonboardingloginbuttontext {
  position: relative;
  font-size: var(--p1-regular-16-size);
  line-height: 150%;
  font-family: var(--p1-regular-16);
  color: var(--gray-1);
  text-align: left;
}
.rightonboardingloginbutton {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-50xl);
  background-color: var(--royal-blue);
  border-radius: var(--br-7xs);
  width: 401px;
  height: 46px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.rightonboardingloginbutton:active {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.rightonboardingboxloginbutton {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs) var(--padding-52xl);
  align-items: center;
  justify-content: center;
}
.terms-conditions {
  text-decoration: underline;
}
.by-submitting-this-container {
  position: relative;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
  width: 372px;
  flex-shrink: 0;
}
.by-submitting-this-form-you-c-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 545px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 6px var(--padding-53xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.terms-and-condition {
  position: relative;
  width: 545px;
  height: 70px;
  text-align: center;
  font-size: var(--h6-medium-12-size);
  color: var(--logo-black);
}
.rightonboarding1createaccount {
  border-radius: var(--br-5xs);
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rightonboarding1 {
  flex: 1;
  background-color: var(--royal-blue);
  height: 853px;
  flex-direction: column;
  padding: var(--padding-175xl) var(--padding-69xl);
  box-sizing: border-box;
  margin-left: -1px;
  font-size: var(--h2-bold-32-size);
}
.login,
.onboardingbox1,
.rightonboarding1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.onboardingbox1 {
  align-self: stretch;
  flex-direction: row;
}
.login {
  position: relative;
  background-color: var(--white);
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  text-align: left;
  font-size: var(--font-size-29xl);
  color: var(--royal-blue);
  font-family: var(--p1-regular-16);
}
@media screen and (max-width: 1200px) {
  .leftonboarding {
    padding-top: var(--padding-11xl);
    padding-bottom: var(--padding-11xl);
    box-sizing: border-box;
  }
  .rightonboarding,
  .rightonboarding1 {
    flex: unset;
    align-self: stretch;
  }
  .rightonboarding1 {
    width: 100%;
  }
  .onboardingbox1 {
    flex-direction: column;
    gap: var(--gap-43xs);
    padding-left: 0;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 850px) {
  .letfonboardingwelcome {
    font-size: var(--font-size-21xl);
  }
  .rightonboarding,
  .rightonboarding1 {
    flex: unset;
    align-self: stretch;
  }
  .rightonboarding1 {
    padding: var(--padding-81xl) var(--padding-11xl);
    box-sizing: border-box;
  }
  .onboardingbox1 {
    flex-direction: column;
  }
}
@media screen and (max-width: 520px) {
  .letfonboardingimage1-icon,
  .letfonboardingimage2-icon {
    width: 128px;
    height: 156px;
  }
  .letfonboardingimagebox {
    gap: var(--gap-28xl);
  }
  .letfonboardingwelcome {
    font-size: var(--font-size-9xl);
    width: 227px;
  }
  .letfonboardingapplying {
    font-size: var(--font-size-mini);
    width: 280px;
  }
  .letfonboardingrectanglered {
    width: 29px;
    height: 31px;
  }
  .letfonboardingrectanglebox {
    width: 411px;
  }
  .leftrectangleyellow {
    width: 29px;
    height: 31px;
  }
  .leftonboardingimage-icon {
    width: 128px;
    height: 156px;
  }
  .lowerboxonboarding {
    gap: var(--gap-61xl);
  }
  .leftonboadingbox {
    width: 369px;
  }
  .leftonboarding {
    width: 400px;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .rightonboarding1login {
    font-size: var(--font-size-mid);
    text-align: center;
  }
  .rightonboardingbox {
    width: 270px;
  }
  .rightonboardingloginbuttontext {
    font-size: var(--p1-regular-16-size);
    text-align: center;
    width: 246px;
  }
  .rightonboardingloginbutton {
    width: 270px;
  }
  .by-submitting-this-container {
    font-size: var(--font-size-5xs);
    text-align: left;
    width: 270px;
  }
  .by-submitting-this-form-you-c-wrapper {
    padding-top: var(--padding-8xs);
    box-sizing: border-box;
  }
  .rightonboarding1createaccount {
    width: 90%;
  }
  .rightonboarding1 {
    height: 50%;
    padding: var(--padding-31xl) var(--padding-3xs);
    box-sizing: border-box;
  }
  .onboardingbox1 {
    padding-left: 0;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 350px) {
  .rightonboardingbox,
  .rightonboardingloginbutton {
    width: 240px;
  }
}
