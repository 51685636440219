@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --p1-regular-16: Poppins;
  --base-1-semibold: Inter;
  --font-inherit: inherit;

  /* font sizes */
  --p1-regular-16-size: 16px;
  --font-size-smi: 13px;
  --h5-btns-med-14-size: 14px;
  --p2-regular-12-size: 12px;
  --h2-bold-32-size: 32px;
  --font-size-6xl: 25px;
  --font-size-4xs: 9px;
  --font-size-2xl: 21px;
  --font-size-7xl: 26px;
  --font-size-5xl: 24px;
  --h3-medium-20-size: 20px;
  --h1-bold-52-size: 52px;
  --font-size-23xl: 42px;
  --font-size-3xs: 10px;
  --font-size-lg: 18px;
  --font-size-17xl: 36px;
  --font-size-11xl: 30px;
  --font-size-3xl: 22px;
  --base-1-semibold-size: 15px;
  --font-size-2xs: 11px;
  --font-size-smi-4: 12.4px;
  --font-size-9xl: 28px;
  --font-size-mid: 17px;
  --font-size-16xl: 35px;
  --font-size-5xs: 8px;
  --font-size-29xl: 48px;

  /* Colors */
  --white: #fff;
  --gray-4: #79797d;
  --color-light-body-text: #1a1b2d;
  --neutral-07: #1a1d1f;
  --logo-black: #010101;
  --gray-1: #f2f2f2;
  --neutral-03: #efefef;
  --purple-blue: #5558cf;
  --logo-color-2: #4a93cf;
  --royal-blue: #0d0ed9;
  --gray-5: #393939;
  --color-limegreen: #34b946;
  --color-black: #000;
  --color-azure: #e5f3f0;
  --color-dark-grey-v2: #535763;
  --gray-3: #c4c6c7;
  --color-red: #f40000;
  --color-gold: #f8d100;
  --color-darkturquoise: #00b8c3;
  --color-primary-blue: #6759ff;
  --color-mediumslateblue-100: #5d57f9;
  --color-ghostwhite: #f4f6fa;
  --logo-color-1: #181048;
  --gray-2: #e7e7e7;
  --color-gainsboro-100: #dfd6d6;
  --color-darkgray: #a1a2af;
  --color-accent-04: #b5ebcd;
  --color-dark-grey: #d1d3d4;
  --color-accent-01: #ffbc99;
  --color-accent-05: #ffd88d;
  --teal: #40cfc3;
  --reviews: #deb00f;
  --light-blue: #cbdff7;

  /* Gaps */
  --gap-62xl: 81px;
  --gap-365xl: 384px;
  --gap-3xs: 10px;
  --gap-69xl: 88px;
  --gap-31xl: 50px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-11xl: 30px;
  --gap-xs: 12px;
  --gap-13xl: 32px;
  --gap-sm: 14px;
  --gap-mini: 15px;
  --gap-xl: 20px;
  --gap-44xl: 63px;
  --gap-7xs: 6px;
  --gap-11xs: 2px;
  --gap-9xs: 4px;
  --gap-61xl: 80px;
  --gap-131xl: 150px;
  --gap-45xl: 64px;
  --gap-41xl: 60px;
  --gap-9xl: 28px;
  --gap-425xl: 444px;
  --gap-301xl: 320px;
  --gap-281xl: 300px;
  --gap-518xl: 537px;
  --gap-241xl: 260px;
  --gap-smi: 13px;
  --gap-14xl: 33px;
  --gap-12xs: 1px;
  --gap-6xs: 7px;
  --gap-3xl: 22px;
  --gap-215xl: 234px;
  --gap-91xl: 110px;
  --gap-29xl: 48px;
  --gap-181xl: 200px;
  --gap-6xs-2: 6.2px;
  --gap-139xl: 158px;
  --gap-10xs: 3px;
  --gap-8xs: 5px;
  --gap-lg: 18px;
  --gap-381xl: 400px;
  --gap-685xl: 704px;
  --gap-17xl: 36px;
  --gap-663xl: 682px;
  --gap-331xl: 350px;
  --gap-140xl: 159px;
  --gap-130xl: 149px;
  --gap-232xl: 251px;
  --gap-111xl: 130px;
  --gap-336xl: 355px;
  --gap-171xl: 190px;
  --gap-285xl: 304px;
  --gap-161xl: 180px;
  --gap-96xl: 115px;
  --gap-32xl: 51px;
  --gap-16xl: 35px;
  --gap-81xl: 100px;
  --gap-292xl: 311px;
  --gap-217xl: 236px;
  --gap-43xs: -30px;

  /* Paddings */
  --padding-63xl: 82px;
  --padding-105xl: 124px;
  --padding-20xl: 39px;
  --padding-9xs: 4px;
  --padding-5xs: 8px;
  --padding-141xl: 160px;
  --padding-21xl: 40px;
  --padding-xl: 20px;
  --padding-base: 16px;
  --padding-8xl: 27px;
  --padding-301xl: 320px;
  --padding-17xl: 36px;
  --padding-231xl: 250px;
  --padding-6xs: 7px;
  --padding-13xl: 32px;
  --padding-mini: 15px;
  --padding-12xs: 1px;
  --padding-3xs: 10px;
  --padding-11xl: 30px;
  --padding-mid: 17px;
  --padding-181xl: 200px;
  --padding-101xl: 120px;
  --padding-136xl: 155px;
  --padding-3xl: 22px;
  --padding-81xl: 100px;
  --padding-smi: 13px;
  --padding-71xl: 90px;
  --padding-31xl: 50px;
  --padding-18xl: 37px;
  --padding-lgi: 19px;
  --padding-619xl: 638px;
  --padding-sm: 14px;
  --padding-139xl: 158px;
  --padding-7xs: 6px;
  --padding-610xl: 629px;
  --padding-271xl: 290px;
  --padding-161xl: 180px;
  --padding-10xs: 3px;
  --padding-131xl: 150px;
  --padding-123xl: 142px;
  --padding-53xl: 72px;
  --padding-11xs: 2px;
  --padding-157xl: 176px;
  --padding-259xl: 278px;
  --padding-5xl: 24px;
  --padding-108xl: 127px;
  --padding-146xl: 165px;
  --padding-121xl: 140px;
  --padding-58xl: 77px;
  --padding-2xl: 21px;
  --padding-15xl: 34px;
  --padding-8xs: 5px;
  --padding-39xl: 58px;
  --padding-447xl: 466px;
  --padding-9xl: 28px;
  --padding-52xl: 71px;
  --padding-2xs: 11px;
  --padding-6xl: 25px;
  --padding-xs: 12px;
  --padding-4xl: 23px;
  --padding-79xl: 98px;
  --padding-44xl: 63px;
  --padding-98xl: 117px;
  --padding-26xl: 45px;
  --padding-51xl: 70px;
  --padding-7xl: 26px;
  --padding-205xl: 224px;
  --padding-381xl: 400px;
  --padding-237xl: 256px;
  --padding-561xl: 580px;
  --padding-283xl: 302px;
  --padding-481xl: 500px;
  --padding-28xl: 47px;
  --padding-137xl: 156px;
  --padding-25xl: 44px;
  --padding-41xl: 60px;
  --padding-29xl: 48px;
  --padding-4xs: 9px;
  --padding-140xl: 159px;
  --padding-134xl: 153px;
  --padding-281xl: 300px;
  --padding-lg: 18px;
  --padding-109xl: 128px;
  --padding-30xl: 49px;
  --padding-144xl: 163px;
  --padding-138xl: 157px;
  --padding-351xl: 370px;
  --padding-243xl: 262px;
  --padding-73xl: 92px;
  --padding-55xl: 74px;
  --padding-34xl: 53px;
  --padding-35xl: 54px;
  --padding-115xl: 134px;
  --padding-76xl: 95px;
  --padding-429xl: 448px;
  --padding-36xl: 55px;
  --padding-16xl: 35px;
  --padding-175xl: 194px;
  --padding-69xl: 88px;
  --padding-50xl: 69px;
  --padding-117xl: 136px;
  --padding-89xl: 108px;

  /* border radiuses */
  --br-5xs: 8px;
  --br-7xs: 6px;
  --br-base: 16px;
  --br-61xl: 80px;
  --br-13xl: 32px;
  --br-9xs: 4px;
  --br-11xs: 2px;
  --br-6xs-2: 6.2px;
  --br-31xl: 50px;

  /* Effects */
  --shadow-1: 0px 1px 3px rgba(0, 0, 0, 0.25);
  --button-01: 0px -1px 1px rgba(0, 0, 0, 0.04) inset,
    0px 2px 0px rgba(255, 255, 255, 0.25) inset,
    0px 4px 8px -4px rgba(0, 0, 0, 0.25);
}


