.headerlogo-icon10 {
  position: relative;
  width: 134px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}


.coach-heading1{
   
    position: relative;
    font-weight: 500;
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    width: 348.79px;
    display: flex;
    align-items: center;

}
.nav-link-default40 {
  position: relative;
  font-weight: 500;
}
.headernav-link50 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headernav-link51 {
  color: var(--logo-color-1);
}
.headernav-link51,
.headernav-link52,
.left-side-top-nav10,
.top-nav-left10 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left-side-top-nav10,
.top-nav-left10 {
  gap: var(--gap-13xl);
}
.left-side-top-nav10 {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  width: 602px;
  padding: 0 0 0 var(--padding-13xl);
  box-sizing: border-box;
  align-items: flex-end;
}
.vector-icon74,
.vector-icon75 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon75 {
  height: 50%;
  width: 75%;
  top: 25%;
  right: 12.5%;
  bottom: 25%;
  left: 12.5%;
}
.menu10 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 44px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.messages-icon9,
.notifications-icon9 {
  position: absolute;
  top: 8px;
  left: -3px;
  width: 28.3px;
  height: 28px;
}
.notifications-icon9 {
  left: 53px;
  width: 22.74px;
}
.mask-group-icon9 {
  position: relative;
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.vector-icon76 {
  position: relative;
  width: 10px;
  height: 6px;
}
.mask-group-parent7 {
  position: absolute;
  top: 0;
  left: 106px;
  width: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.messages-parent7 {
  position: relative;
  width: 152px;
  height: 44px;
}
.right-side-top-nav10 {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  width: 261.91px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-13xl) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-13xl);
}
.info9 {
  align-self: stretch;
  position: relative;
  height: 44px;
}
.scholarships-line-icon9 {
  position: relative;
  width: 89.5px;
  height: 0;
  display: none;
}
.community-line-icon9,
.resources-icon9,
.services-line-icon9 {
  position: relative;
  width: 59.5px;
  height: 0;
  display: none;
}
.community-line-icon9,
.resources-icon9 {
  width: 83px;
}
.resources-icon9 {
  width: 73px;
}
.navbar9 {
  align-self: stretch;
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-3);
  box-sizing: border-box;
  height: 79px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.popular-services {
  position: relative;
}
.allpage-header7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xl) var(--padding-144xl) 0 151px;
  align-items: flex-start;
  justify-content: flex-start;
}
.scholarshipspopular-services-icon {
  position: relative;
  width: 197px;
  height: 196px;
  object-fit: cover;
}
.scholarship-services {
  position: relative;
  font-weight: 600;
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
 
  padding: 8px;
  
  background-color: #ffffff;
  
  width: 100px
}
.vector-icon77 {
  position: relative;
  width: 9.58px;
  height: 17px;
}
.scholarship-services-parent,
.tag {
  display: flex;
  justify-content: flex-start;
}
.scholarship-services-parent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
}

.newfiltersBox{
  width: 1126px;
height: 44px;
flex-shrink: 0;
margin-bottom: 50px;
/* background-color: aqua; */
}
.tag {
  border-radius: var(--br-11xs);
  box-shadow: var(--shadow-1);
  border: 1.5px solid var(--gray-1);
  flex-direction: column;
  /* padding: var(--padding-5xs); */
  padding-top: 7px;
  padding-bottom: 7px;
  
  align-items: flex-start;
  background-color: #ffffff;
}


.tag:active {
  background: #ffffff;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
}
.cardscholarshipspopular-serv,
.cardscholarshipspopular-serv1,
.essay-review-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.cardscholarshipspopular-serv1,
.essay-review-parent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.cardscholarshipspopular-serv1 {
  flex-direction: column;
  gap: var(--gap-base);
}
.scholarshipspopular-services-icon2 {
  position: relative;
  width: 197px;
  height: 196px;
  object-fit: cover;
}
.tag2,
.tag3 {
  border-radius: var(--br-11xs);
  box-shadow: var(--shadow-1);
  border: 1.5px solid var(--gray-2);
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.tag3 {
  border-radius: var(--br-5xs);
}
.scholarshipspopular-services-icon4 {
  position: relative;
  width: 194px;
  height: 196px;
  object-fit: cover;
}
.body10 {
  width: 1110.58px;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-17xl);
}
.search7,
.serice-types {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.serice-types {
  align-self: stretch;
  padding: 0 var(--padding-138xl);
  justify-content: center;
  font-size: var(--p1-regular-16-size);
}
.search7 {
  padding: 0 var(--padding-10xs);
  justify-content: flex-start;
  gap: var(--gap-smi);
  font-size: var(--font-size-23xl);
}
.tune-icon5 {
  position: relative;
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.filters5 {
  position: relative;
  font-size: var(--p1-regular-16-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--color-black);
  text-align: center;
}
.frame-button,
.tune-parent3 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tune-parent3 {
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frame-button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) 0;
  background-color: var(--color-ghostwhite);
  width: 403.5px;
  overflow: hidden;
  flex-shrink: 0;
  box-sizing: border-box;
  justify-content: center;
}
.vector-icon82 {
  position: relative;
  width: 30px;
  height: 30px;
}
.sort5 {
  position: relative;
  font-weight: 500;
}
.frame-wrapper60 {
  background-color: var(--color-ghostwhite);
  width: 403.5px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.servicesdashboadheaderboxtext {
  position: relative;
  font-weight: 500;
  color: #1890ff;
}
.servicesdashboadheaderbox {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 120px;
}
.frame-parent10,
.services-inner {
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.frame-parent10 {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-281xl);
  gap: var(--gap-14xl);
}
.services-inner {
  overflow: hidden;
  display: none;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  text-align: center;
  font-size: var(--p1-regular-16-size);
  color: var(--color-black);
}
.all-coaches {
  position: relative;
}
.allpage-header8 {
  align-self: stretch;
  height: 84px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mid) var(--padding-144xl) var(--padding-mid) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.service-options1 {
  position: relative;
  font-size: var(--p2-regular-12-size);
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--color-black);
  text-align: center;
  width: 146px;
  flex-shrink: 0;
}
.button-text72,
.service-options-wrapper,
.service-options1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-options-wrapper {
  cursor: pointer;
  border: 1.5px solid var(--color-black);
  padding: 0 var(--padding-3xs);
  background-color: transparent;
  border-radius: var(--br-base);
  box-sizing: border-box;
  height: 27px;
  flex-direction: row;
}
.button-text72 {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
  width: 97px;
  flex-shrink: 0;
}

.newcatbody{
  width: 1110.58px;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-17xl);
}


.frame-parent13,
.tagfilter-sort1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.tagfilter-sort1 {
  border-radius: var(--br-base);
  box-shadow: var(--shadow-1);
  border: 1.5px solid var(--logo-black);
  padding: var(--padding-10xs) var(--padding-base);
}
.frame-parent13 {
  gap: var(--gap-mini);
}
.budget-wrapper {
  border-radius: var(--br-base);
  border: 1.5px solid var(--color-black);
  box-sizing: border-box;
  height: 27px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-4xl);
  align-items: center;
  justify-content: center;
}
.availability {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
}
.frame-parent12,
.frame-parent14 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.frame-parent14 {
  align-items: flex-start;
  color: var(--color-black);
}
.frame-parent12 {
  width: 574px;
  padding: 0 var(--padding-sm);
  box-sizing: border-box;
  align-items: flex-end;
}
.frame-parent11,
.newest-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.newest-parent {
  border-radius: var(--br-base);
  border: 1.5px solid var(--color-black);
  box-sizing: border-box;
  height: 27px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0 var(--padding-8xl);
  justify-content: center;
  gap: var(--gap-3xs);
}
.frame-parent11 {
  overflow-x: auto;
  padding: var(--padding-9xs) 0;
  justify-content: flex-start;
  gap: 414px;
}
.allpage-header-group,
.frame-wrapper61 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frame-wrapper61 {
  align-self: stretch;
  overflow: hidden;
  padding: 0 0 var(--padding-18xl);
  justify-content: center;
  text-align: center;
  font-size: var(--p2-regular-12-size);
}
.allpage-header-group {
  justify-content: flex-start;
  gap: var(--gap-12xs);
  font-size: var(--font-size-23xl);
}
.cardcoachprofile-imgsmall-icon {
  position: relative;
  width: 121px;
  height: 119px;
  object-fit: cover;
}
.button38 {
  position: absolute;
  height: 109.38%;
  width: 103.13%;
  top: -4.69%;
  right: -1.56%;
  bottom: -4.69%;
  left: -1.56%;
  border-radius: var(--br-5xs);
  border: 1.5px solid var(--logo-black);
  box-sizing: border-box;
}
.button-text73 {
  position: absolute;
  height: 95%;
  width: 100%;
  top: 2.5%;
  left: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonsmall18 {
  position: absolute;
  width: 37.21%;
  right: 53.49%;
  bottom: 18px;
  left: 9.3%;
  height: 32px;
  cursor: pointer;
}
.button39 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--logo-black);
}
.buttonsmall19 {
  position: absolute;
  width: 37.21%;
  right: 10.08%;
  bottom: 18px;
  left: 52.71%;
  height: 32px;
  color: var(--gray-1);
}
.community-title15 {
  align-self: stretch;
  position: relative;
  font-weight: 500;
}
.members16,
.stars-icon3 {
  position: relative;
  width: 39px;
  height: 8px;
}
.members16 {
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
  display: inline-block;
  width: 191px;
  height: 19px;
  flex-shrink: 0;
}
.coach-review-stars1 {
  width: 199px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--p2-regular-12-size);
  color: var(--gray-4);
}
.description16 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-inherit);
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
  font-family: var(--font-inherit);
  display: inline-block;
  height: 50px;
  flex-shrink: 0;
}
.cardcoachdescription,
.cardcoachdescriptionsmall {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cardcoachdescription {
  width: 331.71px;
  overflow: hidden;
  padding: 0 var(--padding-5xs) var(--padding-5xs);
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-11xs);
}
.cardcoachdescriptionsmall {
  position: absolute;
  width: 64.34%;
  right: 26.36%;
  bottom: 59px;
  left: 9.3%;
  height: 85px;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--p1-regular-16-size);
}
.cardicon-bookmark9 {
  position: absolute;
  top: 8px;
  right: 26px;
  width: 17px;
  height: 23px;
}
.card40 {
  position: relative;
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-1);
  border: 1px solid var(--logo-black);
  box-sizing: border-box;
  width: 258px;
  height: 152px;
  overflow: hidden;
  flex-shrink: 0;
}
.cardcoachsmall {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.buttonsmall20 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
}
.buttonsmall20,
.buttonsmall21 {
  position: relative;
  width: 96px;
  height: 32px;
}
.buttonsmall-parent5 {
  position: absolute;
  top: 102px;
  left: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.card-child {
  position: absolute;
  top: -2px;
  left: 205px;
}
.stars-icon4 {
  position: relative;
  width: 49px;
  height: 8px;
}
.essay-reviews {
  margin-bottom: 0;
}
.essay-reviews-scholarship-cons {
  margin: 0;
  padding-left: var(--padding-base);
}
.cardcoachdescription1,
.cardcoachdescriptionsmall1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cardcoachdescription1 {
  width: 221px;
  overflow: hidden;
  padding: 0 var(--padding-5xs) var(--padding-5xs);
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-11xs);
}
.cardcoachdescriptionsmall1 {
  position: absolute;
  width: 64.34%;
  right: 26.36%;
  bottom: 59px;
  left: 9.3%;
  height: 85px;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--p1-regular-16-size);
  color: var(--logo-black);
}
.cardicon-bookmark10 {
  position: absolute;
  top: 8px;
  left: 215px;
  width: 17px;
  height: 23px;
}
.cardcoachsmall1 {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--gray-1);
}
.cardcoachprofile-imgsmall-icon2 {
  position: relative;
  width: 116.8px;
  height: 117.4px;
  object-fit: cover;
}
.buttonsmall22 {
  position: absolute;
  width: 37.21%;
  right: 53.49%;
  bottom: 18px;
  left: 9.3%;
  height: 32px;
}
.body11,
.serice-types1 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.body11 {
  flex-direction: row;
  padding: 0 var(--padding-137xl);
  gap: var(--gap-17xl);
}
.serice-types1 {
  flex-direction: column;
  padding: var(--padding-20xl) 0;
  text-align: center;
}

.button46 {
  position: absolute;
  height: 109.38%;
  width: 101.84%;
  top: -4.69%;
  right: -0.92%;
  bottom: -4.69%;
  left: -0.92%;
  border-radius: var(--br-5xs);
  border: 1.5px solid var(--logo-black);
  box-sizing: border-box;
}
.buttonlarge30 {
  
  top: 56px;
  left: 0;
  width: 163px;
  height: 32px;
  color: var(--logo-black);
}
.progress-bar-icon6 {
  position: absolute;
  top: 28px;
  left: 2px;
  width: 158.5px;
  height: 2px;
}
.see-more-progress-bar11 {
  position: relative;
  width: 163px;
  height: 88px;
}
.see-more-progress-bar-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-21xl) var(--padding-619xl);
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--gray-4);
}
.footerlogo-icon10 {
  position: relative;
  width: 148px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link30 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}
.footerlinknav30 {
  position: relative;
  width: 160px;
  height: 19px;
}
.footer-links50 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.footericoninstagram10 {
  position: relative;
  width: 20px;
  height: 20px;
}
.footericonfacbook10 {
  position: relative;
  width: 18.75px;
  height: 18.75px;
}
.footericontwitter10 {
  position: relative;
  width: 19.5px;
  height: 15.84px;
}
.footericonyoutube10 {
  position: relative;
  width: 21px;
  height: 14.72px;
}
.icon-wrapper10 {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.footer-menu50 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.students10 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 160px;
}
.footer-link130 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  display: inline-block;
}
.main-footer-link130 {
  position: relative;
  width: 160px;
  height: 24px;
}
.footer-menu51 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.footer-link142 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
}
.main-footer-link142 {
  position: relative;
  width: 160px;
  height: 71px;
  font-size: var(--p2-regular-12-size);
}
.text-box9 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-7xs);
  background-color: var(--white);
  box-shadow: var(--shadow-1);
}
.input-placeholder9 {
  position: absolute;
  top: 4px;
  left: 10px;
  font-weight: 500;
  display: inline-block;
  width: 181px;
  height: 25px;
}
.subscribe-email-text-box10 {
  position: relative;
  width: 198px;
  height: 28px;
  color: var(--gray-4);
}
.button47 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--purple-blue);
}
.buttonlarge31 {
  position: relative;
  width: 198px;
  height: 32px;
  text-align: center;
}
.footer-grid-row10,
.footer-links54,
.footer-menu-parent8 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-links54 {
  flex-direction: column;
  gap: var(--gap-5xs);
  font-size: var(--h5-btns-med-14-size);
}
.footer-grid-row10,
.footer-menu-parent8 {
  flex-direction: row;
  gap: var(--gap-69xl);
}
.footer-grid-row10 {
  justify-content: center;
}
.scholist-all-rights10 {
  position: relative;
  line-height: 150%;
}
.footer-legal10 {
  width: 827px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-365xl);
}
.footer10,
.services-pages {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer10 {
  align-self: stretch;
  background-color: var(--gray-4);
  padding: var(--padding-63xl) var(--padding-105xl) var(--padding-20xl);
  align-items: flex-start;
  gap: var(--gap-62xl);
  font-size: var(--p1-regular-16-size);
  color: var(--gray-1);
}
.services-pages {
  display: flex;
  position: relative;
  background-color: var(--white);
  width: 100%;
  align-items: center;
  text-align: left;
  font-size: var(--h5-btns-med-14-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
  padding-top: 50px;
}
@media screen and (max-width: 1200px) {
  .servicesdashboadheaderbox {
  
    padding: 0 20px;
}
  .scholarshipcardimagebox {
    width: 226px;
  }
  .scholarshipcarddetailsname {
    width: 174px;
  }
  .scholarshipcarddeadlinetext
 {
    font-size: var(--font-size-smi);
  }
  .menu10 {
    display: none;
  }
  .popular-services {
    font-size: var(--font-size-16xl);
  }
  .allpage-header7 {
    width: 1200px;
    height: 80px;
    padding-left: var(--padding-231xl);
    padding-top: var(--padding-11xl);
    padding-right: 0;
    box-sizing: border-box;
  }
  .scholarshipspopular-services-icon {
    width: 170px;
    height: 171px;
  }
  .scholarship-services {
    font-size: var(--h5-btns-med-14-size);
  }
  .scholarshipspopular-services-icon2,
  .scholarshipspopular-services-icon4 {
    width: 170px;
    height: 171px;
  }
  .body10 {
    width: 700px;
    gap: var(--gap-xl);
  }
  .search7,
  .serice-types {
    padding-left: 0;
    box-sizing: border-box;
  }
  .serice-types {
    padding-right: 0;
    flex-wrap: wrap;
  }
  .search7 {
    padding-right: var(--padding-81xl);
  }
  .all-coaches {
    font-size: var(--font-size-16xl);
  }
  .allpage-header8 {
    width: 1200px;
    height: 80px;
    padding-left: var(--padding-181xl);
    padding-top: var(--padding-11xl);
    padding-right: 0;
    box-sizing: border-box;
  }
  .frame-parent11 {
    align-self: stretch;
    width: auto;
    gap: var(--gap-xl);
    align-items: center;
    justify-content: center;
  }
  .frame-wrapper61 {
    align-items: flex-start;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .body11 {
    gap: var(--gap-xl);
    flex-wrap: wrap;
  }
  .serice-types1 {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
  .footer-menu-parent8 {
    flex-wrap: wrap;
  }
  .footer-grid-row10 {
    flex-direction: column;
    gap: var(--gap-31xl);
  }
  .newfiltersBox
   {
    width: 821px;
   
}

.newcatbody{
  width: 825px;
  gap: var(--gap-xl);
}
}
@media screen and (max-width: 850px) {
  .servicesdashboadheaderbox {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
 
  .scholarshipcarddeadlinetext {
    font-size: var(--p2-regular-12-size);
  }
  .top-nav-left10 {
    display: none;
  }
  .menu10 {
    display: flex;
  }
  .messages-parent7 {
    display: none;
  }
  .popular-services {
    font-size: var(--font-size-11xl);
  }
  .allpage-header7 {
    width: 800px;
    height: 60px;
    padding-left: var(--padding-131xl);
    padding-top: var(--padding-11xl);
    padding-bottom: var(--padding-11xl);
    box-sizing: border-box;
  }
  .scholarshipspopular-services-icon {
    width: 140px;
    height: 141px;
  }
  .scholarship-services {
    font-size: var(--font-size-3xs);
  }

  .vector-icon77 {
    width: 7px;
    height: 15px;
  }
  .scholarship-services-parent {
    gap: 9px;
  }
  .scholarshipspopular-services-icon2 {
    width: 147px;
    height: 141px;
  }
  .scholarshipspopular-services-icon4 {
    width: 144px;
    height: 141px;
  }
  .body10 {
    width: 500px;
    gap: var(--gap-xl);
  }
  .serice-types {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  .frame-button,
  .search7,
  .serice-types {
    padding-right: 0;
    box-sizing: border-box;
  }
  .search7 {
    padding-top: 0;
  }
  .frame-button {
    width: 240px;
    padding-left: 0;
  }
  .frame-wrapper60 {
    width: 240px;
  }
  .frame-parent10 {
    gap: var(--gap-mini);
    padding-left: var(--padding-141xl);
    padding-right: var(--padding-141xl);
    box-sizing: border-box;
  }
  .services-inner {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .all-coaches {
    font-size: var(--h3-medium-20-size);
  }
  .allpage-header8 {
    width: 800px;
    height: 60px;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-top: var(--padding-11xl);
    padding-bottom: var(--padding-11xl);
    box-sizing: border-box;
  }
  .service-options1 {
    font-size: var(--font-size-4xs);
  }
  .service-options-wrapper {
    padding-left: var(--padding-mini);
    padding-right: var(--padding-mini);
    box-sizing: border-box;
  }
  .availability,
  .button-text72 {
    font-size: var(--font-size-3xs);
  }
  .frame-parent12 {
    width: 550px;
    align-items: center;
    justify-content: center;
    padding-left: var(--padding-xl);
  }
  .frame-parent11,
  .frame-parent12,
  .frame-wrapper61 {
    padding-right: 0;
    box-sizing: border-box;
  }
  .frame-parent11 {
    width: 500px;
    gap: var(--gap-11xl);
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 0;
  }
  .frame-wrapper61 {
    display: none;
    padding-left: var(--padding-131xl);
  }
  .body11,
  .frame-wrapper61,
  .serice-types1 {
    align-items: center;
    justify-content: center;
  }
  .body11 {
    gap: var(--gap-xl);
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
  .serice-types1 {
    display: flex;
    padding-right: 0;
  }
  .serice-types1,
  .services-pages {
    padding-left: 0;
    box-sizing: border-box;
  }

  .newfiltersBox{
   display: none;
  }

  .newcatbody{
    width: 545px;
    gap: var(--gap-xl);
  }

  .coach-heading1{
   
 
    width: 129.79px;
   

}
}
@media screen and (max-width: 520px) {
  .popular-services {
    font-size: var(--font-size-3xl);
  }
  .allpage-header7 {
    width: 500px;
    padding-left: var(--padding-51xl);
    box-sizing: border-box;
  }
  .cardscholarshipspopular-serv {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .body10 {
    width: 350px;
  }
  .serice-types {
    padding-left: 0;
    padding-top: var(--padding-3xs);
    padding-right: var(--padding-smi);
    box-sizing: border-box;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .tune-icon5 {
    width: 20px;
    height: 20px;
  }
  .filters5 {
    font-size: var(--p2-regular-12-size);
  }
  .frame-button {
    width: 180px;
  }
  .vector-icon82 {
    width: 20px;
    height: 20px;
  }
  .sort5 {
    font-size: var(--p2-regular-12-size);
  }
  .frame-wrapper60 {
    width: 170px;
  }
  .frame-parent10 {
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
  .all-coaches {
    font-size: var(--font-size-3xl);
  }
  .allpage-header8 {
    width: 500px;
    padding-left: 0;
    box-sizing: border-box;
  }
  .service-options-wrapper {
    width: 147px;
  }
  .frame-parent12 {
    flex-direction: row;
    padding-left: 0;
    box-sizing: border-box;
  }
  .frame-parent11 {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-parent11,
  .frame-wrapper61,
  .serice-types1 {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .serice-types1 {
    padding-top: var(--padding-3xs);
    flex-wrap: nowrap;
    justify-content: center;
  }
  .services-pages {
    align-items: center;
    justify-content: center;
  }

  .newcatbody{
    width: 376px;
    gap: var(--gap-xl);
  }
}



.service-options {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 378px;
  height: 260px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
  font-family: var(--font-poppins);
}

/* .service-options-inner {
  position: absolute;
  top: 128px;
  left: 1px;
  border: 1px solid var(--color-whitesmoke);
  box-sizing: border-box;
  width: 375px;
  height: 35px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 30 0 var(--padding-9xs) 30px;
  align-items: center;
  justify-content: center;
} */

.clear-all1 {
  position: relative;
  line-height: 22px;
}
.clear-all-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-210xl);
}
.service-options-inner {
 
  /* left: 1px;
  border: 1px solid var(--color-whitesmoke-100);
  box-sizing: border-box;
  width: 375px;
  height: 35px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
 // padding: 0 0 var(--padding-9xs) var(--padding-8xs);
  align-items: center;
  justify-content: center; */
}
.check-box-outline-blank13,
.essay-review2 {
  position: relative;
  font-size: 12px;
}
.essay-review2 {
  letter-spacing: 0.15px;
  line-height: 150%;
}
.check-box-outline-blank-parent11 {
  width: 183px;
}
.check-box-outline-blank-parent11,
.check-box-outline-blank-parent12,
.check-box-outline-blank-parent13,
.frame-parent6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.check-box-outline-blank-parent13,
.frame-parent6 {
  align-items: flex-start;
  
}
.check-box-outline-blank-parent13 {
  width: 191px;
  align-items: center;
  gap: var(--gap-6xs);
}
.frame-parent7 {
 
}
.frame-parent5,
.frame-parent7,
.frame-wrapper15,
.service-options-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent5,
.service-options-group {
  flex-direction: column;
  gap: var(--gap-10xs);
  font-size: var(--font-size-3xs);
}
.service-options-group {
  position: absolute;
  top: 14px;
  left: 21px;
  gap: var(--gap-4xs);
  font-size: var(--caption-2-medium-size);
}
/* .service-options2 {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--color-light-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 378px;
  height: 163px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
} */








.min1 {
  position: relative;
  line-height: 14px;
  font-weight: 500;
}
.frame-inner {
  background-color: transparent;
  position: relative;
}
.max-group,
.min-group {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.max-group {
  left: 91px;
}
.frame-parent19 {
  /* position: absolute; */
  top: 26px;
  left: 26px;
  width: 172px;
  height: 40px;
}
.clear-all9 {
  position: absolute;
  top: 0.5px;
  left: 11.5px;
  line-height: 22px;
}
.buttoncontained-text6 {
  position: absolute;
  top: -0.5px;
  left: 137.5px;
}
.budget-inner,
.clear-all-parent7 {
  position: absolute;
  top: 4px;
  left: 0;
  width: 218px;
  height: 24px;
}
.budget-inner {
  top: 110px;

  box-sizing: border-box;
  width: 220px;
  height: 35px;
  overflow: hidden;
  text-align: left;
}
.budget2 {
 
 
  
  width: 230px;
  height: 145px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
  font-family: var(--p1-regular-16);
  justify-content: center;
  
}


.serviceFilterBody{
  width: 315px;
  height: 223px;

  border-radius: 20px;
  font-size: 12px;
  color: var(--color-black);
  font-family: "Poppins";
  /* padding-left: 30px; */
  padding-right: 30px;
  padding-top: 10px;
}


.serviceBodyHeader{
  line-height: 22px;
  font-size: 16px;
  font-family:"poppins";
  text-align: left;
  letter-spacing: 0.50px;
  padding-left: 10px;
}

.checkboxBody{
 align-items: center;
 justify-content: center;
 padding-bottom: 3px;
}

.filterCheckBox{
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  vertical-align: bottom;
  position: relative;
  top: -1px;
}

.filterLabel{
  font-size: 14px;
  letter-spacing: 0.15px;

  padding-left: 10px;
  text-indent: -15px;

}

.clearApplyServices{
  display: flex;
  align-content: center;
  /* justify-content: center; */
  gap: 120px;
  margin-top: 20px;
}


.coachDetailFilterBody{
  width: 200px;
  height: 420px;

  border-radius: 20px;
  font-size: 12px;
  color: var(--color-black);
  font-family: "Poppins";
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
}


.clearApplyCoachDetails{
  display: flex;
  align-content: center;
  /* justify-content: center; */
  gap: 25px;
  margin-top: 20px;
  margin-bottom: 10px;
}


.budgetFilterBody{
  width: 250px;
  height: 95px;
  border-radius: 20px;
  font-size: 12px;
  color: var(--color-black);
  font-family: "Poppins";
 padding: 20px;
}

.minMaxBody{
  display: flex;
  gap: 20px;
}

.clearApplyBudget{
  display: flex;
  align-content: center;
  /* justify-content: center; */
  gap: 70px;
  margin-top: 20px;
  margin-bottom: 10px;
}



.locationFilterBody{
  width: 200px;
  height: 170px;
  border-radius: 20px;
  font-size: 12px;
  color: var(--color-black);
  font-family: "Poppins";
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
}

/* 
.service-options1 {
  position: relative;
  line-height: 22px;
}
.check-box-outline-blank,
.essay-review {
  position: relative;
}
.essay-review {
  letter-spacing: 0.15px;
  line-height: 150%;
  padding-left: 10px;
}
.check-box-outline-blank-parent {
  width: 183px;
}
.check-box-outline-blank-container,
.check-box-outline-blank-group,
.check-box-outline-blank-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.check-box-outline-blank-container {
  width: 191px;
}
.body,
.frame-parent,
.frame-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.body,
.frame-parent {
  flex-direction: column;
}
.frame-parent {
  gap: var(--gap-10xs);
  font-size: var(--font-size-3xs);
}
.body {
  gap: var(--gap-4xs);
}
.clear-all {
  position: relative;
  line-height: 22px;
  display: inline-block;
  width: 50px;
  flex-shrink: 0;
}
.clear-all-parent {
  width: 151px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 44px;
}
.service-options,
.service-options-inner {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.service-options-inner {
  border: 1px solid var(--color-whitesmoke-100);
  width: 230px;
  height: 35px;
  flex-shrink: 0;
  padding: 0 0 var(--padding-9xs);
  font-size: var(--font-size-3xs);
}
.service-options {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--color-light-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 220px;
  height: 209px;
  padding: var(--padding-xs) 0 0;
  gap: var(--gap-4xl);
  text-align: left;
  font-size: var(--caption-2-medium-size);
  color: var(--color-black);
  font-family: var(--p1-regular-16);
  left: 70px;
} */




.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-container-budget{
  position: relative;
  display: inline-block;
  width: 186px;
}

.dropdown {
  position: absolute;
  top: 100%; /* Position the dropdown below the button */
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  min-width: 150px;
    z-index: 1000;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 5px;
  cursor: pointer;
}

.dropfilter{
  display: flex;
width: 186px;
height: 44px;
padding: 10px 15px;
justify-content: center;
align-items: center;
gap: 22px;
flex-shrink: 0;
border-radius: 33px;
border: 1px solid #999;
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
background-color: white;
}


.dropfilter-budget{
  display: flex;
width: 150px;
height: 44px;
padding: 10px 15px;
justify-content: center;
align-items: center;
gap: 22px;
flex-shrink: 0;
border-radius: 33px;
border: 1px solid #999;
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
background-color: white;
}
.dropdownfiltername{
  color: #393939;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.serviceoptiondropdown{
  margin-top: 11px;
  position: absolute;
  top: 100%;
  left: 0;
 
  border: 1px solid #ccc;
 
  /* padding: 10px; */
  min-width: 150px;
  width: 270px;
  height: 210px;
  z-index: 1000; /* Set a higher z-index value */
  border-radius: 8px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}


.serviceoptionsheader{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 183.333% */
}


.newfilterLabel{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 15px */
letter-spacing: 0.15px;
margin-left: 10px; 
}


.newclearapply{
  display: flex;
  width: 270px;
  height: 35px;
  padding: 0px 0px 4px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: 1px solid #EFE8E8;
}

.newclearapply1{
  display: flex;
align-items: center;
gap: 77px;
}


.newclearapplytext{
  color: #000;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 220% */
}


.newclearapplybutton{
  /* display: flex;
padding: 0px 16px;
flex-direction: column;
align-items: flex-start;
gap: 8px; */

display: flex;
width: 65px;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 8px;
background:#3589CD;
/* box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20); */
}


.newclearapplybuttonText{
  color: #FFF;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 240% */
letter-spacing: 0.4px;
text-transform: uppercase;
}


.newfilterbody{
  padding-left: 26px;
  padding-top: 15px;
  padding-bottom: 10px;
}



/* .coachdetailsdropdown{
  margin-top: 11px;
  position: absolute;
  top: 100%;
  left: 0;
 
  border: 1px solid #ccc;
 
 
  min-width: 150px;
  width: 277px;
  height: 250px;
  z-index: 1000;



border-radius: 8px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

} */

.coachdetailsdropdown {
  margin-top: 11px;
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #ccc;
  min-width: 150px;
  width: 277px;
  max-height: 250px; /* Adjust the max-height as needed */
  overflow-y: auto; /* or overflow-y: scroll; */
  z-index: 1000;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


/* Hide scrollbar for WebKit browsers */
.coachdetailsdropdown::-webkit-scrollbar {
  width: 0; /* Set width to 0 to hide scrollbar */
  height: 0;
}


.coachdetailsbox{
  display: flex;
  width: 241px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 3px;
  flex-wrap: wrap;
 margin-bottom: 9px;
 margin-top: 3px;
}

.coachBodyHeader{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 183.333% */
}


.newcheckboxBody{
  display: flex;
width: 118px;
align-items: center;
gap: 7px;
}


.newCoachfilterbody{
  padding-left: 26px;
  padding-top: 15px;
  padding-bottom: 10px;
}

.newfilterLabel1{
 
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 15px */
letter-spacing: 0.15px;
}



 .newclearCoachapply{
  display: flex;
width: 276px;
height: 35px;
padding: 0px 1px 4px 0px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border: 1px solid #EFE8E8;
}


.newclearCoachapply1{
  display: flex;
align-items: center;
gap: 98px;
}

.ratinglabel3{
  display: flex;
}



.budgetdropdown{

  margin-top: 11px;
  position: absolute;
  top: 100%;
  left: 0;
 
  border: 1px solid #ccc;
 
  /* padding: 10px; */
  min-width: 150px;

  z-index: 1000; /* Set a higher z-index value */



border-radius: 8px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 220px;
height: 132px;
}


.newclearBudgetapply{
  display: flex;
width: 220px;
height: 35px;
padding: 0px 1px 4px 0px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border: 1px solid #EFE8E8;
}

.newclearBudgetapply1{
  display: flex;
align-items: center;
gap: 72px;
}



.locationdropdown{

  margin-top: 11px;
  position: absolute;
  top: 100%;
  left: 0;
 
  border: 1px solid #ccc;
 
  /* padding: 10px; */
  min-width: 150px;

  z-index: 1000; /* Set a higher z-index value */



border-radius: 8px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 230px;
height: 274px;
}

.newfiltersearch{
  display: flex;
width: 169px;
padding: 4px 22px 4px 6px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
border-radius: 6px;
background: #C4C6C7;
margin-bottom: 10px;
}




.locationbox{
 
  width: 189px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 3px;
  margin-bottom: 9px;
  margin-top: 3px;
  min-height: 200px;
  max-height: 200px; /* Set the maximum height for vertical scrolling */
  overflow-y: auto; /* Add vertical scrolling */
}
.newlocationfilterbody{
  padding-left: 26px;
  padding-top: 15px;
  padding-bottom: 10px;
}


.newclearLocationapply{
  display: flex;
width: 230px;
height: 35px;
padding: 0px 1px 4px 0px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border: 1px solid #EFE8E8;
}

.newclearLocationapply1{
  display: flex;
align-items: center;
gap: 72px;
}


.newCatBox{
  /* display: inline-flex; */
padding: 16px;
/* justify-content: flex-start;
align-items: center;
gap: 23px;
border-radius: 8px;
background: #FFF; */

margin-bottom: 50px;
/* width: 1144px; */
}

.newCatBox1{
  display: flex;
width: 129px;
height: 148px;
padding-top: 8px;
flex-direction: column;
align-items: center;
gap: 16px;
border-radius: 11px;
border: 2px solid #CDCDCD;
box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.50);
min-width: 129px;
background-color: white;
}


.newCatIcon1{
  display: flex;
width: 60px;
height: 60px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 50px;
border: 1px solid #FFF;
background: #4A93CF;
}


.newCatText1{
  color: #393939;
text-align: center;

/* H4 - Medium 16px */
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.newCatIcon2{
  display: flex;
width: 60px;
height: 60px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 50px;
border: 1px solid #FFF;
border-radius: 50px;

background: #DFB625;
}


.newCatIcon3{
  display: flex;
width: 60px;
height: 60px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 50px;

border: 1px solid #FFF;
background: #5BB364;
}


.newCatIcon4{
  display: flex;
  width: 60px;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50px;
border: 1px solid #FFF;
background: #E63833;
}


.newCatIcon5{
  display: flex;
  width: 60px;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid #FFF;
  background: #4A93CF;
}


.newCatIcon6{
  display: flex;
  width: 60px;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50px;
border: 1px solid #FFF;
background: #DFB625;
}


.newCatIcon7{
  display: flex;
  width: 60px;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50px;
border: 1px solid #FFF;
background: #5BB364;
}





.sortdropdown {
  margin-top: 11px;
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #ccc;
  /* padding: 10px; */
  min-width: 150px;
  width: 277px;
  height: 250px;
  z-index: 1000;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}




.sort-dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  width: 200px;
}

.sort-dropdown-toggle:hover + .dropdown-content {
  display: block;
}

.sort-dropdown-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sort-dropdown-content li {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sort-dropdown-content li:hover {
  background-color: #f5f5f5;
}


.frame-wrapper60 {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
}

.vector-icon82 {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.sort5 {
  font-size: 16px;
  color: #333;
}


.sort-dropdown-container {
  position: relative;
  display: inline-block;
}

.sort-dropdown-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.sort-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px;
  top: 100%;
  left: 0;
}

.sort-dropdown-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sort-dropdown-content li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sort-dropdown-content li:hover {
  background-color: #ddd;
}



  /* Hide scrollbar for Chrome, Safari and Opera */
  .newcatbody::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .newcatbody{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }