.headerlogo-icon7 {
  position: relative;
  width: 134px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.headernav-link35 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headernav-link36 {
  color: var(--logo-color-1);
}
.headernav-link36,
.headernav-link37,
.left-side-top-nav7,
.top-nav-left7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left-side-top-nav7,
.top-nav-left7 {
  gap: var(--gap-13xl);
}
.left-side-top-nav7 {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  width: 602px;
  padding: 0 0 0 var(--padding-13xl);
  box-sizing: border-box;
  align-items: flex-end;
}
.vector-icon49,
.vector-icon50 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon50 {
  height: 50%;
  width: 75%;
  top: 25%;
  right: 12.5%;
  bottom: 25%;
  left: 12.5%;
}
.menu7 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 44px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.messages-icon6,
.notifications-icon6 {
  position: absolute;
  top: 8px;
  left: -3px;
  width: 28.3px;
  height: 28px;
}
.notifications-icon6 {
  left: 53px;
  width: 22.74px;
}
.mask-group-icon6 {
  position: relative;
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.vector-icon51 {
  position: relative;
  width: 10px;
  height: 6px;
}
.mask-group-parent4 {
  position: absolute;
  top: 0;
  left: 106px;
  /* width: 46px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.messages-parent4 {
  position: relative;
  width: 152px;
  height: 44px;
}
.right-side-top-nav7 {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  width: 261.91px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-13xl) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-13xl);
}
.info6 {
  align-self: stretch;
  position: relative;
  height: 44px;
}
.scholarships-line-icon6 {
  position: relative;
  width: 89.5px;
  height: 0;
  display: none;
}
.community-line-icon6,
.resources-icon6,
.services-line-icon6 {
  position: relative;
  width: 59.5px;
  height: 0;
  display: none;
}
.community-line-icon6,
.resources-icon6 {
  width: 83px;
}
.resources-icon6 {
  width: 73px;
}
.navbar6 {
  align-self: stretch;
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-3);
  box-sizing: border-box;
  height: 79px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.s-header-active {
  position: relative;
  font-weight: 500;
}
.side-navsection-title {
  width: 222px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs) 0 var(--padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.divider5 {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--logo-black);
  box-sizing: border-box;
  height: 1px;
}
.magnifying-glass-icon,
.search4 {
  position: relative;
  width: 16px;
  height: 16px;
}
.search4 {
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 60px;
  height: 22px;
  flex-shrink: 0;
}
.search3,
.side-navsearch-bar {
  display: flex;
  justify-content: flex-start;
}
.side-navsearch-bar {
  border-radius: var(--br-5xs);
  border: 1px solid var(--logo-black);
  box-sizing: border-box;
  width: 192px;
  flex-direction: row;
  padding: var(--padding-8xs) var(--padding-81xl) var(--padding-8xs)
    var(--padding-5xs);
  align-items: center;
  gap: var(--gap-5xs);
}
.search3 {
  flex-direction: column;
  padding: 0 var(--padding-13xl);
  align-items: flex-start;
  font-size: var(--h5-btns-med-14-size);
}
.section-link-text {
  align-self: stretch;
  position: relative;
  font-weight: 500;
}
.left-menu-links,
.side-navsection-link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.side-navsection-link {
  width: 222px;
  padding: 0 0 0 var(--padding-5xl);
  box-sizing: border-box;
}
.left-menu-links {
  align-self: stretch;
  gap: var(--gap-5xs);
  font-size: var(--p1-regular-16-size);
}
.button13 {
  position: absolute;
  height: 91.6%;
  width: 98.51%;
  top: 0;
  right: 1.49%;
  bottom: 8.4%;
  left: 0;
  border-radius: var(--br-5xs);
}
.button-text35 {
  position: absolute;
  top: calc(50% - 20.85px);
  left: calc(50% - 76.5px);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 38px;
}
.side-navbuttonlarge1 {
  position: relative;
  border-radius: var(--br-11xs);
  border: 1.5px solid var(--logo-black);
  box-sizing: border-box;
  width: 201px;
  height: 35.7px;
}
.side-navbuttonlarge {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 29px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--h5-btns-med-14-size);
}
.my-communities {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 172px;
  flex-shrink: 0;
}
.right-arrow-icon {
  position: relative;
  width: 8px;
  height: 13px;
}
.section-title-and-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.side-navsection-title2 {
  width: 222px;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.section-link-text2 {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.left-menu-link-section {
  width: 256px;
  flex-direction: column;
  padding: 0 0 0 var(--padding-base);
  gap: var(--gap-5xs);
}
.left-menu-link-section,
.side-navcommunities,
.side-navcommunities-wrapper {
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.side-navcommunities {
  border-radius: var(--br-5xs);
  border: 1px solid var(--logo-black);
  width: 256px;
  flex-direction: column;
  padding: var(--padding-base) 0 var(--padding-13xl);
  gap: var(--gap-base);
  opacity: 0.76;
}
.side-navcommunities-wrapper {
  align-self: stretch;
  width: 328px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  padding: 121px var(--padding-30xl) 0;
}
.communities2 {
  position: relative;
}
.allpage-header4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 79px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--h1-bold-52-size);
}
.trending-communities {
  position: relative;
}
.trending-communities-wrapper {
  align-self: stretch;
  height: 65px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.unsplashxrbbxixawy0-icon {
  position: relative;
  width: 257px;
  height: 97px;
  object-fit: cover;
}
.cardcommunitybackgroundsmal {
  height: 97px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.community-title6 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 191px;
  height: 48px;
  flex-shrink: 0;
}
.cardicon-bookmark {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 16px;
  height: 21px;
}
.community-title-parent4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-12xs);
}
.cardcommunitysmall-inner {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-8xs) var(--padding-3xs) 0 var(--padding-13xl);
  align-items: center;
  justify-content: center;
  font-size: var(--p1-regular-16-size);
}
.members7 {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
  display: inline-block;
  width: 96px;
  flex-shrink: 0;
}
.members-wrapper4 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-109xl) 0 var(--padding-13xl);
  align-items: center;
  justify-content: flex-start;
  color: var(--gray-4);
}
.description7 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
  display: inline-block;
  height: 50px;
  flex-shrink: 0;
}
.cardcommunitydescription,
.cardcommunitydescription-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cardcommunitydescription {
  width: 207px;
  height: 52px;
  flex-shrink: 0;
  padding: 0 var(--padding-5xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
}
.cardcommunitydescription-wrapper {
  padding: 0 var(--padding-7xl) var(--padding-10xs) var(--padding-4xl);
  align-items: center;
  color: var(--logo-black);
}
.button14 {
  position: absolute;
  height: 109.38%;
  width: 103.13%;
  top: -4.69%;
  right: -1.56%;
  bottom: -4.69%;
  left: -1.56%;
  border-radius: var(--br-5xs);
  border: 1.5px solid var(--logo-black);
  box-sizing: border-box;
}
.button-text36 {
  position: absolute;
  height: 95%;
  width: 100%;
  top: 2.5%;
  left: 0;
  font-size: var(--h5-btns-med-14-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--logo-black);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonsmall {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 96px;
  height: 32px;
}
.button-text37,
.button15 {
  position: absolute;
  width: 100%;
  left: 0;
}
.button15 {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-5xs);
  background-color: var(--logo-black);
}
.button-text37 {
  height: 95%;
  top: 2.5%;
  font-size: var(--h5-btns-med-14-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--gray-1);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonsmall1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 96px;
  height: 28px;
}
.buttonsmall-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}
.cardcommunitysmall,
.cardcommunitysmall-child {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardcommunitysmall-child {
  padding: var(--padding-8xs) var(--padding-4xl) var(--padding-base)
    var(--padding-3xl);
  justify-content: center;
}
.cardcommunitysmall {
  border-radius: var(--br-5xs);
  border: 1px solid var(--logo-black);
  box-sizing: border-box;
  width: 259px;
  height: 278px;
  flex-shrink: 0;
  justify-content: flex-start;
}
.unsplasheesdjflfx1a-icon {
  position: relative;
  width: 266px;
  height: 97px;
  object-fit: cover;
}
.buttonsmall2 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 96px;
  height: 32px;
}
.cardcommunitysmall-inner4,
.cardcommunitysmall1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardcommunitysmall1 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--logo-black);
  flex-shrink: 0;
  justify-content: flex-start;
}
.cardcommunitysmall-inner4 {
  padding: var(--padding-8xs) var(--padding-4xl) var(--padding-base)
    var(--padding-3xl);
  justify-content: center;
}
.cardcommunitysmall-parent {
  align-self: stretch;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  font-size: var(--p2-regular-12-size);
  color: var(--gray-5);
}
.page-divider3 {
  position: relative;
  border-top: 1px solid var(--logo-black);
  box-sizing: border-box;
  width: 1013px;
  height: 1px;
}
.more-suggestions-wrapper,
.page-divider-wrapper {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xl) var(--padding-7xs) var(--padding-29xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.more-suggestions-wrapper {
  height: 75px;
  flex-shrink: 0;
  flex-direction: row;
  padding: var(--padding-xs) 0;
  box-sizing: border-box;
}
.unsplashwe-kv-zb1l0-icon {
  position: relative;
  width: 258px;
  height: 96px;
  object-fit: cover;
}
.cardcommunitybackgroundsmal6 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.community-title12 {
  position: absolute;
  top: 0;
  left: 28.5px;
  font-weight: 500;
  display: inline-block;
  width: 182px;
}
.cardicon-bookmark6 {
  position: absolute;
  top: 1.5px;
  left: 210.5px;
  width: 16px;
  height: 21px;
}
.community-title-parent10 {
  position: absolute;
  top: 117px;
  left: 0;
  width: 255px;
  height: 24px;
  font-size: var(--p1-regular-16-size);
}
.description13 {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
  display: inline-block;
  width: 191px;
  flex-shrink: 0;
}
.cardcommunitydescription6 {
  position: absolute;
  top: 159px;
  left: 13px;
  width: 243px;
  height: 54px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-25xl) 0 var(--padding-lg);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.members13 {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
  display: inline-block;
  width: 104px;
  flex-shrink: 0;
}
.members-wrapper10 {
  position: absolute;
  top: 140px;
  left: 9px;
  width: 255px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 129px 0 var(--padding-xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--gray-4);
}
.button-text48 {
  position: absolute;
  height: 95%;
  width: 100%;
  top: 2.5%;
  left: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonsmall12,
.buttonsmall13 {
  position: relative;
  width: 96px;
  height: 32px;
}
.buttonsmall13 {
  color: var(--gray-1);
}
.buttonsmall-parent4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.cardcommunitysmall-inner11 {
  position: absolute;
  top: 215px;
  left: 1px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) 31px var(--padding-base) var(--padding-xl);
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--h5-btns-med-14-size);
}
.cardcommunitysmall6 {
  position: relative;
  border-radius: var(--br-5xs);
  border: 1px solid var(--logo-black);
  box-sizing: border-box;
  width: 258px;
  height: 282px;
  overflow: hidden;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--p2-regular-12-size);
}
.community-title13,
.description14,
.members14 {
  align-self: stretch;
  position: relative;
  font-weight: 500;
}
.community-title13 {
  font-size: var(--p1-regular-16-size);
}
.description14,
.members14 {
  letter-spacing: 0.08em;
  line-height: 150%;
  display: inline-block;
  flex-shrink: 0;
}
.members14 {
  color: var(--gray-4);
  height: 19px;
}
.description14 {
  height: 50px;
}
.cardcommunitydescription7 {
  position: absolute;
  width: 80.23%;
  top: calc(50% - 26px);
  right: 10.47%;
  left: 9.3%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-5xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--p2-regular-12-size);
}
.buttonsmall14,
.buttonsmall15 {
  position: absolute;
  height: 11.35%;
  top: 82.27%;
  right: 138px;
  bottom: 6.38%;
  width: 96px;
}
.buttonsmall15 {
  right: 26px;
  color: var(--gray-1);
}
.cardicon-bookmark7 {
  position: absolute;
  top: 115px;
  left: 230px;
  width: 16px;
  height: 21px;
}
.cardcommunitybackgroundsmal-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 256px;
  height: 97px;
  object-fit: cover;
}
.cardcommunitysmall7 {
  position: relative;
  border-radius: var(--br-5xs);
  border: 1px solid var(--logo-black);
  box-sizing: border-box;
  width: 258px;
  height: 282px;
  overflow: hidden;
  flex-shrink: 0;
}
.cardcommunitybackgroundsmal-icon1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 256px;
  height: 98px;
  object-fit: cover;
}
.cardcommunitysmall-group,
.frame-wrapper55 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.cardcommunitysmall-group {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-xs) 33px var(--padding-lgi) 0;
  gap: var(--gap-13xl);
}
.frame-wrapper55 {
  width: 1112px;
  flex-direction: column;
  padding: 0 var(--padding-10xs) 0 0;
  box-sizing: border-box;
  text-align: center;
  font-size: var(--h5-btns-med-14-size);
}
.div43 {
  position: absolute;
  top: 0;
  left: 52px;
  font-weight: 500;
}
.button32 {
  position: absolute;
  height: 109.38%;
  width: 101.84%;
  top: -4.69%;
  right: -0.92%;
  bottom: -4.69%;
  left: -0.92%;
  border-radius: var(--br-5xs);
  border: 1.5px solid var(--logo-black);
  box-sizing: border-box;
}
.buttonlarge24 {
  position: absolute;
  top: 55px;
  left: 0;
  width: 163px;
  height: 32px;
  color: var(--logo-black);
}
.progress-bar-icon3 {
  position: absolute;
  top: 28px;
  left: 2px;
  width: 158.5px;
  height: 2px;
}
.see-more-progress-bar6 {
  position: relative;
  width: 163px;
  height: 87px;
}
.allpage-header-parent,
.see-more-progress-bar-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.see-more-progress-bar-wrapper {
  width: 1103px;
  padding: var(--padding-21xl) var(--padding-619xl);
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  font-size: var(--h5-btns-med-14-size);
  color: var(--gray-4);
}
.allpage-header-parent {
  flex: 1;
  padding: var(--padding-xs) 0;
  align-items: flex-start;
  gap: var(--gap-mini);
  font-size: var(--h2-bold-32-size);
}
.frame-parent7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--h3-medium-20-size);
}
.footerlogo-icon7 {
  position: relative;
  width: 148px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link21 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}
.footerlinknav21 {
  position: relative;
  width: 160px;
  height: 19px;
}
.footer-links35 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.footericoninstagram7 {
  position: relative;
  width: 20px;
  height: 20px;
}
.footericonfacbook7 {
  position: relative;
  width: 18.75px;
  height: 18.75px;
}
.footericontwitter7 {
  position: relative;
  width: 19.5px;
  height: 15.84px;
}
.footericonyoutube7 {
  position: relative;
  width: 21px;
  height: 14.72px;
}
.icon-wrapper7 {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.footer-menu35 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.students7 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 160px;
}
.footer-link91 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  display: inline-block;
}
.main-footer-link91 {
  position: relative;
  width: 160px;
  height: 24px;
}
.footer-menu36 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.footer-link103 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
}
.main-footer-link103 {
  position: relative;
  width: 160px;
  height: 71px;
  font-size: var(--p2-regular-12-size);
}
.text-box6 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-7xs);
  background-color: var(--white);
  box-shadow: var(--shadow-1);
}
.input-placeholder6 {
  position: absolute;
  top: 4px;
  left: 10px;
  font-weight: 500;
  display: inline-block;
  width: 181px;
  height: 25px;
}
.subscribe-email-text-box7 {
  position: relative;
  width: 198px;
  height: 28px;
  color: var(--gray-4);
}
.button33 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--purple-blue);
}
.buttonlarge25 {
  position: relative;
  width: 198px;
  height: 32px;
  text-align: center;
}
.footer-grid-row7,
.footer-links39,
.footer-menu-parent5 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-links39 {
  flex-direction: column;
  gap: var(--gap-5xs);
  font-size: var(--h5-btns-med-14-size);
}
.footer-grid-row7,
.footer-menu-parent5 {
  flex-direction: row;
  gap: var(--gap-69xl);
}
.footer-grid-row7 {
  justify-content: center;
}
.scholist-all-rights7 {
  position: relative;
  line-height: 150%;
}
.footer-legal7 {
  width: 827px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-365xl);
}
.communities1,
.footer7 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer7 {
  align-self: stretch;
  background-color: var(--gray-4);
  padding: var(--padding-63xl) var(--padding-105xl) var(--padding-20xl);
  align-items: flex-start;
  gap: var(--gap-62xl);
  font-size: var(--p1-regular-16-size);
  color: var(--gray-1);
}
.communities1 {
  position: relative;
  background-color: var(--white);
  width: 100%;
  align-items: center;
  text-align: left;
  font-size: var(--h5-btns-med-14-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
}
@media screen and (max-width: 1200px) {
  .menu7 {
    display: none;
  }
  .side-navsearch-bar {
    width: 152px;
  }
  .side-navbuttonlarge1 {
    width: 180px;
  }
  .my-communities {
    font-size: var(--font-size-mid);
    width: 160px;
  }
  .section-title-and-arrow {
    gap: 0;
  }
  .side-navsection-title2 {
    gap: var(--gap-11xs);
  }
  .side-navcommunities {
    width: 216px;
  }
  .communities2 {
    font-size: 46px;
  }
  .trending-communities {
    font-size: var(--font-size-9xl);
  }
  .cardcommunitybackgroundsmal {
    width: auto;
    height: auto;
  }
  .community-title6 {
    font-size: var(--font-size-smi);
    width: 181px;
    height: 48px;
  }
  .cardicon-bookmark {
    width: 14px;
    height: 19px;
  }
  .members7 {
    font-size: var(--font-size-2xs);
  }
  .members-wrapper4 {
    align-items: flex-start;
    justify-content: center;
    padding-right: var(--padding-81xl);
    box-sizing: border-box;
  }
  .description7 {
    font-size: var(--font-size-3xs);
  }
  .cardcommunitydescription {
    width: 190px;
    align-items: center;
    justify-content: center;
  }
  .cardcommunitydescription-wrapper {
    gap: var(--gap-smi);
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xs);
    box-sizing: border-box;
  }
  .button-text36 {
    font-size: var(--p2-regular-12-size);
  }
  .buttonsmall {
    width: 76px;
    height: 23px;
  }
  .button-text37 {
    font-size: var(--p2-regular-12-size);
  }
  .buttonsmall1 {
    width: 76px;
    height: 23px;
  }
  .cardcommunitysmall {
    width: 240px;
    height: 258px;
  }
  .buttonsmall2 {
    width: 76px;
    height: 23px;
  }
  .cardcommunitysmall1 {
    width: 240px;
    height: 258px;
  }
  .cardcommunitysmall-parent {
    background-color: var(--white);
    flex-direction: row;
    gap: var(--gap-mini);
  }
  .cardcommunitysmall-group,
  .footer-menu-parent5 {
    flex-wrap: wrap;
  }
  .footer-grid-row7 {
    flex-direction: column;
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 850px) {
  .top-nav-left7 {
    display: none;
  }
  .menu7 {
    display: flex;
  }
  .messages-parent4,
  .side-navcommunities,
  .side-navcommunities-wrapper {
    display: none;
  }
  .allpage-header4 {
    padding-left: var(--padding-31xl);
    padding-top: 0;
    box-sizing: border-box;
  }
  .trending-communities {
    font-size: var(--font-size-6xl);
  }
  .cardcommunitysmall-parent,
  .trending-communities-wrapper {
    padding-left: var(--padding-31xl);
    box-sizing: border-box;
  }
  .page-divider-wrapper {
    padding-left: var(--padding-81xl);
    box-sizing: border-box;
  }
  .more-suggestions-wrapper {
    align-items: center;
    justify-content: center;
    padding-left: 0;
    box-sizing: border-box;
  }
  .cardcommunitysmall-group {
    width: 700px;
    flex-direction: row;
    gap: var(--gap-3xl);
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
  .frame-wrapper55 {
    width: 800px;
    padding-left: 110px;
    box-sizing: border-box;
  }
  .allpage-header-parent {
    width: 1080px;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 520px) {
  .communities2 {
    font-size: var(--font-size-7xl);
  }
  .allpage-header4 {
    padding-left: var(--padding-31xl);
    padding-top: var(--padding-11xl);
    padding-bottom: var(--padding-11xl);
    box-sizing: border-box;
  }
  .trending-communities {
    font-size: var(--h3-medium-20-size);
  }
  .page-divider3 {
    width: 300px;
  }
  .more-suggestions-wrapper {
    align-items: center;
    justify-content: center;
    padding-left: var(--padding-11xl);
    box-sizing: border-box;
  }
  .buttonsmall12 {
    width: 86px;
  }
  .cardcommunitysmall6 {
    width: 258px;
  }
  .cardcommunitysmall-group {
    width: 320px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .frame-wrapper55 {
    width: 500px;
    padding-right: 0;
    box-sizing: border-box;
  }
  .allpage-header-parent {
    width: 400px;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    box-sizing: border-box;
  }
  .communities1 {
    align-items: flex-start;
    justify-content: center;
  }
}
