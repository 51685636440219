

.bookmarkHeader-Coach{
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;

    padding-top: 30px;
    padding-bottom: 30px;
}


.bookmarkHeader-text{
    color:#1890ff;
    font-size: 27px;
    font-weight: 500;
}


.bookmarkHeader-dash{
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 30px;
    padding-top: 50px;
   
}
.newCoachCard-loading {
    display: flex;
    width: 255px;
    height: 298px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 2px solid #C4C6C7;
    background: #FFF;
    align-items: center;
}

.coachesBookmarkContainer {
    display: flex;
    max-width: 1440px;
    padding: 0px 14px;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    gap: 30px;
    align-self: stretch;
    flex-wrap: wrap;
    padding-bottom: 7px;
    padding-top: 30px;
}