/* .headerlogo-icon5 {
  position: relative;
  width: 134px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.headernav-link25 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headernav-link26 {
  color: var(--logo-color-1);
}
.headernav-link26,
.headernav-link27,
.left-side-top-nav5,
.top-nav-left5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left-side-top-nav5,
.top-nav-left5 {
  gap: var(--gap-13xl);
}
.left-side-top-nav5 {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  width: 602px;
  padding: 0 0 0 var(--padding-13xl);
  box-sizing: border-box;
  align-items: flex-end;
}
.vector-icon42,
.vector-icon43 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon43 {
  height: 50%;
  width: 75%;
  top: 25%;
  right: 12.5%;
  bottom: 25%;
  left: 12.5%;
}
.menu5 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 44px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.messages-icon4,
.notifications-icon4 {
  position: absolute;
  top: 8px;
  left: -3px;
  width: 28.3px;
  height: 28px;
}
.notifications-icon4 {
  left: 53px;
  width: 22.74px;
}
.mask-group-icon4 {
  position: relative;
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.vector-icon44 {
  position: relative;
  width: 10px;
  height: 6px;
}
.mask-group-parent2 {
  position: absolute;
  top: 0;
  left: 106px;
  width: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.messages-parent2 {
  position: relative;
  width: 152px;
  height: 44px;
}
.right-side-top-nav5 {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  width: 261.91px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-13xl) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-13xl);
}
.info4 {
  align-self: stretch;
  position: relative;
  height: 44px;
}
.scholarships-line-icon4 {
  position: relative;
  width: 89.5px;
  height: 0;
  display: none;
}
.community-line-icon4,
.resources-icon4,
.services-line-icon4 {
  position: relative;
  width: 59.5px;
  height: 0;
  display: none;
}
.community-line-icon4,
.resources-icon4 {
  width: 83px;
}
.resources-icon4 {
  width: 73px;
}
.navbar4 {
  align-self: stretch;
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-3);
  box-sizing: border-box;
  height: 79px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  font-size: var(--h5-btns-med-14-size);
}
.page-title16 {
  position: relative;
  line-height: 150%;
}
.underline-icon9 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
}
.page-title15 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}
.arrow-icon6 {
  position: relative;
  width: 4.28px;
  height: 7.42px;
}
.page-title-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.based-on-your3 {
  position: relative;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  width: 199px;
}
.page-title17 {
  width: 168px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}
.frame-parent5,
.page-title-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frame-parent5 {
  align-items: flex-start;
}
.nitro-scholarship-sweepstakes {
  position: relative;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  width: 298px;
}
.underline-icon11 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 0;
  flex-shrink: 0;
}
.page-title18 {
  width: 298px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}
.headertop-navprimarybreadcr3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.chevronbackward-icon {
  position: relative;
  width: 8px;
  height: 13.93px;
}
.arrows18 {
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-7xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.back {
  position: relative;
  font-weight: 500;
}
.back-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--h3-medium-20-size);
  color: var(--color-black);
}
.header6,
.similar-scholarships-label {
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.header6 {
  align-self: stretch;
  height: 95px;
  flex-direction: column;
  padding: var(--padding-8xl) var(--padding-140xl) var(--padding-26xl);
  gap: var(--gap-3xs);
}
.similar-scholarships-label {
  border-radius: var(--br-5xs);
  background-color: var(--gray-3);
  border: 2px solid var(--gray-3);
  width: 253px;
  height: 58px;
  flex-direction: row;
  padding: var(--padding-5xs);
}
.cardscholarshipsimage-icon12 {
  position: relative;
  border-radius: var(--br-61xl);
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.cardscholarshipsbackgroundco12 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-gold);
  width: 256px;
  height: 97px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.title12 {
  position: absolute;
  top: 110px;
  left: 27px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  width: 212px;
  height: 40px;
}
.award-amount13,
.deadline12,
.organization12 {
  position: absolute;
  top: 158px;
  left: 27px;
  line-height: 150%;
}
.award-amount13,
.deadline12 {
  top: 190px;
}
.deadline12 {
  top: 222px;
}
.card13,
.component-2-icon12 {
  position: absolute;
  top: 224px;
  left: 216px;
  width: 22.63px;
  height: 21.64px;
}
.card13 {
  top: 315px;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  width: 256px;
  height: 265px;
  overflow: hidden;
}
.cardscholarshipsimage-icon13 {
  position: relative;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.cardscholarshipsbackgroundco13 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-darkturquoise);
  width: 256px;
  height: 97px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.card12 {
  position: relative;
  border-radius: var(--br-5xs);
  background: linear-gradient(#e7e7e7, #e7e7e7), #fff;
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  width: 253px;
  height: 265px;
  overflow: hidden;
  flex-shrink: 0;
}
.similar-scholarships-label-parent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.cardscholarshipsbackgroundco14 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-mediumslateblue-100);
  width: 256px;
  height: 97px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.card14 {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  width: 256px;
  height: 265px;
  overflow: hidden;
  flex-shrink: 0;
}
.cardscholarshipsbackgroundco15 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-red);
  width: 256px;
  height: 97px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.frame-parent6 {
  display: flex;
  gap: 19px;
}
.frame-parent6,
.selection,
.similar-scholarships-label1 {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.selection {
  align-self: stretch;
  overflow-x: auto;
  display: none;
  padding: 0 0 var(--padding-7xl);
}
.similar-scholarships-label1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--gray-3);
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  width: 256px;
  height: 58px;
  overflow: hidden;
  display: flex;
  padding: var(--padding-5xs);
}
.card19,
.component-2-icon20 {
  position: absolute;
  top: 223.68px;
  left: 215px;
  width: 24.63px;
  height: 22.95px;
}
.card19 {
  top: 42px;
  left: 0;
  border-radius: var(--br-5xs);
  background: linear-gradient(#e7e7e7, #e7e7e7), #fff;
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  width: 256px;
  height: 265px;
  overflow: hidden;
}
.page-divider2,
.similar-scholarships-label-group {
  position: relative;
  width: 256px;
  height: 307px;
}
.page-divider2 {
  border-top: 2px solid var(--gray-3);
  box-sizing: border-box;
  width: 549px;
  height: 2px;
  display: none;
}
.body4 {
  width: 256px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}
.image-32-icon {
  position: relative;
  width: 157px;
  height: 153px;
  object-fit: cover;
}
.header-image {
  align-self: stretch;
  flex: 1;
  background-color: var(--color-darkturquoise);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-8xl) 287px;
  align-items: center;
  justify-content: center;
  height: auto;
}
.nitro-scholarship-sweepstakes1 {
  position: relative;
  line-height: 38px;
  display: flex;
  align-items: flex-end;
  width: 420px;
  flex-shrink: 0;
}
.title-share-icons {
  width: 420px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.arrows-icon,
.health-icon {
  position: relative;
  width: 36px;
  height: 28px;
}
.arrows-icon {
  width: 35.85px;
}
.health-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.scholarship-name,
.title-share-icons-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.title-share-icons-parent {
  flex-direction: row;
  gap: 128px;
}
.scholarship-name {
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-53xl) var(--padding-25xl) var(--padding-mid)
    var(--padding-15xl);
  font-size: var(--h2-bold-32-size);
}
.nitro {
  position: relative;
  font-weight: 500;
}
.amount-due-date {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.amount-and-deadline,
.amount-due-date-wrapper {
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.amount-due-date-wrapper {
  height: 62px;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-8xs) var(--padding-20xl);
  box-sizing: border-box;
}
.amount-and-deadline {
  flex-direction: row;
  padding: 0 var(--padding-25xl) var(--padding-3xl);
  gap: var(--gap-45xl);
}
.arrow-icon8 {
  position: relative;
  width: 11.06px;
  height: 6.59px;
}
.arrow-wrapper {
  display: flex;
  flex-direction: column;
  padding: var(--padding-4xs) var(--padding-6xs);
  align-items: center;
  justify-content: center;
}
.checklist,
.checklist-dropdown {
  display: flex;
  justify-content: flex-start;
}
.checklist-dropdown {
  width: 598px;
  height: 32px;
  flex-direction: row;
  padding: 0 var(--padding-29xl);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-5xs);
}
.checklist {
  height: 37px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--p1-regular-16-size);
  color: var(--gray-4);
}
.essay,
.letters-of-recommendation,
.transcripts {
  position: absolute;
  top: 0;
  left: 40px;
  font-weight: 500;
}
.letters-of-recommendation,
.transcripts {
  top: 72px;
}
.transcripts {
  top: 37px;
}
.checkmarkcirclefill-icon {
 
 
  width: 24.21px;
  height: 24.22px;
}
.essay-parent {
  position: relative;
  width: 264px;
  height: 98.22px;
}
.checklist-body {
  height: 133px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm) 52px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--p1-regular-16-size);
  color: var(--gray-4);
}
.button-text31 {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 41px;
  height: 21px;
  flex-shrink: 0;
}
.vector-icon45 {
  position: relative;
  width: 14.5px;
  height: 14.5px;
}
.buttonmedium2 {
  border-radius: var(--br-5xs);
  background-color: var(--logo-color-2);
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.buttonmedium2:hover {
  box-shadow: 0 4px 4px #010101;
}
.buttonmedium2:active:active {
  box-shadow: 0 4px 4px rgba(1, 1, 1, 0.84);
}
.button-text32,
.button10 {
  position: absolute;
  width: 100%;
  left: 0;
}
.button10 {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-5xs);
  border: 1px solid var(--logo-black);
  box-sizing: border-box;
}
.button-text32 {
  height: 95%;
  top: 2.5%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonmedium3 {
  position: relative;
  width: 128px;
  height: 40px;
  display: none;
  text-align: center;
  color: var(--logo-black);
}
.apply,
.buttons {
  display: flex;
  justify-content: flex-start;
}
.buttons {
  height: 23px;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-base);
}
.apply {
  height: 80px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-2xl) var(--padding-25xl);
  box-sizing: border-box;
  align-items: flex-start;
  font-size: var(--h5-btns-med-14-size);
  color: var(--white);
}
.the-2000-nitro {
  margin: 0;
}
.a-parent-who {
  margin-bottom: 0;
}
.a-parent-who-currently-has-a-c {
  margin: 0;
  padding-left: var(--padding-2xl);
}
.official-rules {
  color: inherit;
}
.the-2000-nitro-container {
  flex: 1;
  position: relative;
  line-height: 150%;
}
.scholarship-description,
.scholarship-description1 {
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
}
.scholarship-description1 {
  align-self: stretch;
  height: 528px;
  flex-shrink: 0;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-25xl);
  justify-content: flex-start;
  font-size: var(--font-inherit);
  color: var(--font-inherit);
  font-family: var(--font-inherit);
}
.scholarship-description {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 2px solid var(--gray-3);
  height: 1354px;
  flex-direction: column;
  justify-content: center;
  font-size: var(--h3-medium-20-size);
}
.body3,
.description6 {
  align-self: stretch;
  display: flex;
  justify-content: center;
}
.description6 {
  flex-direction: row;
  padding: 0 0 var(--padding-79xl);
  align-items: flex-start;
  gap: var(--gap-29xl);
}
.body3 {
  overflow: hidden;
  flex-direction: column;
  padding: 0 var(--padding-137xl);
  align-items: center;
}
.footerlogo-icon5 {
  position: relative;
  width: 148px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link15 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}
.footerlinknav15 {
  position: relative;
  width: 160px;
  height: 19px;
}
.footer-links25 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.footericoninstagram5 {
  position: relative;
  width: 20px;
  height: 20px;
}
.footericonfacbook5 {
  position: relative;
  width: 18.75px;
  height: 18.75px;
}
.footericontwitter5 {
  position: relative;
  width: 19.5px;
  height: 15.84px;
}
.footericonyoutube5 {
  position: relative;
  width: 21px;
  height: 14.72px;
}
.icon-wrapper5 {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.footer-menu25 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.students5 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 160px;
}
.footer-link65 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  display: inline-block;
}
.main-footer-link65 {
  position: relative;
  width: 160px;
  height: 24px;
}
.footer-menu26 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.footer-link77 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
}
.main-footer-link77 {
  position: relative;
  width: 160px;
  height: 71px;
  font-size: var(--p2-regular-12-size);
}
.text-box4 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-7xs);
  background-color: var(--white);
  box-shadow: var(--shadow-1);
}
.input-placeholder4 {
  position: absolute;
  top: 4px;
  left: 10px;
  font-weight: 500;
  display: inline-block;
  width: 181px;
  height: 25px;
}
.subscribe-email-text-box5 {
  position: relative;
  width: 198px;
  height: 28px;
  color: var(--gray-4);
}
.button11 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--purple-blue);
}
.buttonlarge22 {
  position: relative;
  width: 198px;
  height: 32px;
  text-align: center;
}
.footer-grid-row5,
.footer-links29,
.footer-menu-parent3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-links29 {
  flex-direction: column;
  gap: var(--gap-5xs);
  font-size: var(--h5-btns-med-14-size);
}
.footer-grid-row5,
.footer-menu-parent3 {
  flex-direction: row;
  gap: var(--gap-69xl);
}
.footer-grid-row5 {
  justify-content: center;
}
.scholist-all-rights5 {
  position: relative;
  line-height: 150%;
}
.footer-legal5 {
  width: 827px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-365xl);
}
.footer5,
.scholarships-description1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer5 {
  align-self: stretch;
  background-color: var(--gray-4);
  padding: var(--padding-63xl) var(--padding-105xl) var(--padding-20xl);
  align-items: flex-start;
  gap: var(--gap-62xl);
  color: var(--gray-1);
}
.scholarships-description1 {
  position: relative;
  background-color: var(--white);
  width: 100%;
  align-items: center;
  text-align: left;
  font-size: var(--p1-regular-16-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
}
@media screen and (max-width: 1200px) {
  .menu5 {
    display: none;
  }
  .based-on-your3,
  .nitro-scholarship-sweepstakes,
  .page-title16 {
    font-size: var(--h5-btns-med-14-size);
  }
  .selection {
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    gap: var(--gap-mini);
  }
  .body4 {
    display: none;
    width: auto;
    flex-direction: row;
  }
  .image-32-icon {
    width: 130px;
    height: 131px;
  }
  .header-image {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .nitro-scholarship-sweepstakes1 {
    font-size: var(--h3-medium-20-size);
    width: 300px;
  }
  .title-share-icons-parent {
    gap: var(--gap-xl);
  }
  .scholarship-name {
    padding-left: 0;
    box-sizing: border-box;
  }
  .amount-and-deadline,
  .amount-due-date-wrapper,
  .apply,
  .checklist,
  .checklist-body {
    padding-left: var(--padding-41xl);
    box-sizing: border-box;
  }
  .the-2000-nitro-container {
  /* font-size: var(--font-size-3xs); */
    /* width: 500px;
  }
  .scholarship-description1 {
    padding-left: var(--padding-41xl);
    box-sizing: border-box;
  }
  .scholarship-description {
    width: 90%;
    flex: unset;
    align-self: stretch;
  }
  .description6 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .body3 {
    padding-left: var(--padding-137xl);
    box-sizing: border-box;
  }
  .footer-menu-parent3 {
    flex-wrap: wrap;
  }
  .footer-grid-row5 {
    flex-direction: column;
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 850px) {
  .top-nav-left5 {
    display: none;
  }
  .menu5 {
    display: flex;
  }
  .messages-parent2 {
    display: none;
  }
  .page-title-group {
    gap: var(--gap-3xs);
  }
  .headertop-navprimarybreadcr3 {
    gap: var(--gap-7xs);
  }
  .body4 {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .health-parent {
    padding-left: var(--padding-41xl);
    box-sizing: border-box;
  }
  .title-share-icons-parent {
    flex-direction: column;
    gap: var(--gap-8xs);
  }
  .amount-and-deadline,
  .apply,
  .checklist,
  .checklist-body,
  .scholarship-description1 {
    padding-left: var(--padding-41xl);
    box-sizing: border-box;
  }
  .scholarship-description {
    width: 500px;
  }
  .body3,
  .description6 {
    padding-left: var(--padding-21xl);
    box-sizing: border-box;
  }
  .body3 {
    padding-left: var(--padding-137xl);
  }
}
@media screen and (max-width: 520px) {
  .headertop-navprimarybreadcr3 {
    flex-direction: column;
  }
  .header6,
  .selection {
    box-sizing: border-box;
  }
  .header6 {
    padding-left: var(--padding-140xl);
  }
  .selection {
    width: 400px;
    padding-left: 0;
  }
  .image-32-icon {
    width: 100px;
    height: 100px;
  }
  .header-image {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .nitro-scholarship-sweepstakes1 {
    font-size: var(--font-size-lg);
  }
  .health-parent {
    padding-left: var(--padding-41xl);
    box-sizing: border-box;
  }
  .title-share-icons-parent {
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .scholarship-name {
    gap: var(--gap-10xs);
    padding-left: 0;
    padding-top: var(--padding-3xs);
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .nitro {
    font-size: var(--p1-regular-16-size);
  }
  .amount-and-deadline,
  .amount-due-date-wrapper {
    padding-left: var(--padding-41xl);
    box-sizing: border-box;
  }
  .amount-due-date-wrapper {
    align-items: flex-start;
    justify-content: center;
  }
  .amount-and-deadline {
    flex-direction: column;
    gap: var(--gap-3xs);
    padding-top: 0;
    padding-bottom: 0;
  }
  .checklist {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .essay,
  .letters-of-recommendation,
  .transcripts {
    font-size: var(--h5-btns-med-14-size);
  }
  .apply,
  .checklist-body,
  .scholarship-description1 {
    padding-left: var(--padding-41xl);
    box-sizing: border-box;
  }
  .scholarship-description {
    width: 350px;
    align-items: flex-start;
    justify-content: center;
  }
  .description6,
  .scholarship-description {
    padding-left: 0;
    box-sizing: border-box;
  }
  .body3,
  .scholarships-description1 {
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
  }
  .body3 {
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-21xl);
  }
  .scholarships-description1 {
    padding-left: 0;
  }
}


.resultsFor{
  color: var(--logo-black, #010101);

/* H2 - Bold 32px */
/* font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: normal;
}



  .headertop-navprimarybread {
    width: 340px;
    display: flex;
    flex-direction: row;
    
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
}


.page-titleHome{
  width: 73px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}


.didyouapply{
  display: flex;
width: 649px;
padding: 12px 21px;
justify-content: space-between;
align-items: flex-end;
border-radius: 8px;
background: rgba(74, 147, 207, 0.30);
margin-left: 20px;
align-self: stretch;
}

.didyouappplyText{
  color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 125px;
}


.didYouApplyBox{
  width: 443px;
height: 77px;
flex-shrink: 0;
}

.letusknow{
  color: #000;
  width: 443px;
/* H4 - Medium 16px */
/* font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.yesno1{
  display: flex;
align-items: flex-start;
gap: 16px;
}
.frame-parent-head{
  gap: var(--gap-10xs);
  font-size: var(--font-size-3xs); */
/* }

.checklist-dropdown{
  

}
  .checkRow{
    display: flex;
    padding-left: 53px;
    gap: 5px;
  }

  .checkText{
    color: var(--gray-4, #79797D);

/* H4 - Medium 16px */
/* font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  */
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h4-medium-16: Poppins;
  --font-inherit: inherit;

  /* font sizes */
  --font-size-5xl: 24px;
  --h3-medium-20-size: 20px;
  --h4-medium-16-size: 16px;
  --h5-btns-med-14-size: 14px;
  --font-size-smi: 13px;
  --font-size-3xs: 10px;
  --font-size-6xl: 25px;
  --font-size-lg: 18px;
  --font-size-13xl: 32px;
  --h6-medium-12-size: 12px;
  --font-size-4xs: 9px;
  --font-size-2xs: 11px;
  --font-size-2xl-6: 21.6px;
  --font-size-mid: 17px;
  --font-size-5xs: 8px;

  /* Colors */
  --white: #fff;
  --gray-3: #c4c6c7;
  --gray-4: #79797d;
  --logo-black: #010101;
  --logo-color-2: #4a93cf;
  --color-steelblue-100: rgba(74, 147, 207, 0.3);
  --color-black: #000;
  --color-darkturquoise: #00b8c3;
  --color-mediumslateblue: #5d57f9;
  --color-gainsboro: #e7e7e7;
  --color-darkslategray: #393939;

  /* Gaps */
  --gap-3xs: 10px;
  --gap-10xl: 29px;
  --gap-mini: 15px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-3xl: 22px;
  --gap-lg: 18px;
  --gap-xl: 20px;
  --gap-10xs: 3px;
  --gap-mid: 17px;
  --gap-8xs: 5px;
  --gap-6xs: 7px;
  --gap-9xs: 4px;
  --gap-7xs: 6px;
  --gap-13xl: 32px;
  --gap-11xs: 2px;

  /* Paddings */
  --padding-31xl: 50px;
  --padding-29xl: 48px;
  --padding-39xl: 58px;
  --padding-107xl: 126px;
  --padding-27xl: 46px;
  --padding-74xl: 93px;
  --padding-24xl: 43px;
  --padding-54xl: 73px;
  --padding-xl: 20px;
  --padding-11xl: 30px;
  --padding-13xl: 32px;
  --padding-xs: 12px;
  --padding-2xl: 21px;
  --padding-3xs: 10px;
  --padding-8xs: 5px;
  --padding-41xl: 60px;
  --padding-10xl: 29px;
  --padding-4xl: 23px;
  --padding-5xs: 8px;
  --padding-75xl: 94px;
  --padding-smi: 13px;
  --padding-61xl: 80px;
  --padding-141xl: 160px;
  --padding-6xl: 25px;
  --padding-mini: 15px;
  --padding-7xs-6: 5.6px;
  --padding-5xs-4: 7.4px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-31xl: 50px;
  --br-xs: 12px;
  --br-6xs-5: 6.5px;
}


.checklist,
.checklist-dropdown {
  display: flex;
  justify-content: flex-start;
}
.checklist-dropdown {
  width: 598px;
  height: 32px;
  flex-direction: row;
  padding: 0 var(--padding-29xl);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-5xs);
}
.checklist {
  height: 37px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--p1-regular-16-size);
  color: var(--gray-4);
}
.sdescriptiontitlebox1text {
  text-decoration: underline;
}
.sdescriptiontitlebox1text,
.sdescriptiontitleboxarrow {
  position: relative;
  font-size: var(--h4-medium-16-size);
  line-height: 150%;
  font-family: var(--h4-medium-16);
  color: var(--color-black);
  text-align: left;
}
.sdescriptiontitlebox3 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-6xs);
}
.sdescriptiontitlebox2 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
.sdescriptiontitle2boxsname {
  flex: 1;
  position: relative;
  line-height: 150%;
}
.sdescriptiontitle2box,
.sdescriptiontitlebox1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sdescriptiontitle2box {
  width: 314px;
  justify-content: center;
}
.sdescriptiontitlebox1 {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.sdescriptiontitlebox {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-39xl) var(--padding-75xl) 0;
}
.sdescriptiontitlebackbox1arrow,
.sdescriptiontitlebackbox1text {
  position: relative;
  line-height: 150%;
}
.sdescriptiontitlebackbox1text {
  text-decoration: underline;
}
.sdescriptiontitlebackbox2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-6xs);
}
.sdescriptiontitleback,
.sdescriptiontitlebackbox1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
.sdescriptiontitleback {
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-75xl);
}
.sdesdetailssidecardcurrenthead {
  flex: 1;
  position: relative;
  font-weight: 500;
}
.sdesdetailssidecardcurrent {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--gray-3);
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  height: 58px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs);
}
.sdesdetailssidecardcurimgbox1-icon {
  position: relative;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.sdesdetailssidecardcurimgbox {
  background-color: var(--color-mediumslateblue);
  width: 256px;
  height: 97px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.sdesdetailssidecardcurdetsname {
  position: relative;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  width: 174px;
}
.sdesdetailssidecardcurdetsdead,
.sdesdetailssidecardcurdetslink {
  position: relative;
  line-height: 150%;
}
.sdesdetailssidecardcurdetheart-icon {
  position: relative;
  width: 24.13px;
  height: 22.45px;
}
.sdesdetailssidecardcurdetbox,
.sdesdetailssidecardcurdetdeadl {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-mid);
}
.sdesdetailssidecardcurdetbox {
  width: 256px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 var(--padding-4xl);
  box-sizing: border-box;
  gap: var(--gap-10xs);
}
.sdesdetailssidecardcurrentbox {
  border-radius: var(--br-5xs);
  background-color: var(--color-gainsboro);
  border: 2px solid var(--gray-3);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 var(--padding-10xl);
  gap: var(--gap-xl);
  margin-top: -16px;
}
.sdesdetailssidecard {
  align-items: flex-start;
}
.sdesdetailssidebox,
.sdesdetailssidecard,
.sdesdetailssidecardbox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.sdesdetailssidecardbox {
  border-radius: var(--br-5xs);
  border: 2px solid var(--gray-3);
  overflow: hidden;
  align-items: center;
  padding: 0 0 var(--padding-10xl);
  gap: var(--gap-xl);
}
.sdesdetailssidebox {
  align-items: flex-start;
  gap: var(--gap-3xs);
}
.sdessdetailsimg-icon {
  position: relative;
  width: 157px;
  height: 153px;
  object-fit: cover;
}
.sdessdetailsimgbox {
  align-self: stretch;
  background-color: var(--color-darkturquoise);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xl) var(--padding-3xs);
}
.sdessdetailsbox1sname {
  flex: 1;
  position: relative;
  line-height: 38px;
}
.sdessdetailsbox1arrows-icon,
.sdessdetailsbox1health-icon {
  position: relative;
  width: 36px;
  height: 28px;
}
.sdessdetailsbox1arrows-icon {
  width: 35.85px;
}
.sdessdetailsbox1snamebox,
.sdessdetailslovebox1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.sdessdetailsbox1snamebox {
  align-self: stretch;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-3xs);
  font-size: var(--font-size-13xl);
}
.sdessdetailsslinkbox1text {
  flex: 1;
  position: relative;
  font-weight: 500;
}
.sdessdetailsslinkbox1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: var(--h3-medium-20-size);
}
.sdessdetailssamountboxtext1 {
  position: relative;
  font-weight: 500;
}
.sdessdetailssamountbox1 {
  align-self: stretch;
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--h3-medium-20-size);
}
.sdessdetailsscheclistboxtext1,
.sdessdetailssdeadlinetextbox1 {
  flex: 1;
  position: relative;
  font-weight: 500;
}
.sdessdetailsschecklistbox1 {
  align-self: stretch;
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--gray-4);
}
.vector-icon,
.vector-icon1 {
  position: absolute;
  left: calc(50% - 131.84px);
  width: 24.21px;
}
.vector-icon {
  top: calc(50% + 60.89px);
  height: 24.22px;
  opacity: 0;
}
.vector-icon1 {
  top: calc(50% - 11.11px);
  height: 24.21px;
}
.vector-icon2 {
  position: relative;
  width: 11.2px;
  height: 11.02px;
}
.letters-of-recommendation {
  position: relative;
  font-weight: 500;
}
.vector-parent {
  position: absolute;
  top: 0;
  left: 6.48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xl);
}
.group {
  position: absolute;
  top: calc(50% + 22.89px);
  left: calc(50% - 131.84px);
  width: 263.68px;
  height: 26.22px;
}
.group-icon {
  position: relative;
  width: 24.21px;
  height: 24.22px;
}
.group-parent {
  position: absolute;
  top: 37px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}
.group-icon1 {
  position: relative;
  width: 24.21px;
  height: 24.21px;
}
.group-group {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.checkmarkcirclefill {
  position: absolute;
  top: calc(50% - 49.11px);
  left: calc(50% - 131.84px);
  width: 263.68px;
  height: 98.22px;
}
.checkmarkcirclefill-wrapper {
  position: relative;
  width: 263.68px;
  height: 98.22px;
}
.sdessdetailsschecklistbox11 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--gray-4);
}
.button-text-apply {
  position: relative;
  font-size: var(--h5-btns-med-14-size);
  font-weight: 500;
  font-family: var(--h4-medium-16);
  color: var(--white);
  text-align: left;
  display: flex;
  align-items: center;
  width: 41px;
  height: 21px;
  flex-shrink: 0;
}
.vector-icon3 {
  position: relative;
  width: 14.5px;
  height: 14.5px;
}
.sdessdetailsslbuttonbox3 {
  cursor: pointer;
  border: 0;
  padding: 0 var(--padding-13xl);
  background-color: var(--logo-color-2);
  border-radius: var(--br-5xs);
  height: 40px;
  overflow: hidden;
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.sdessdetailssbuttonbox1,
.sdessdetailssbuttonbox2,
.sdessdetailsslbuttonbox3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.sdessdetailssbuttonbox2 {
  height: 23px;
}
.sdessdetailssbuttonbox1 {
  align-self: stretch;
}
.didyouapplysdestext1,
.didyouapplysdestext2 {
  align-self: stretch;
  position: relative;
}
.didyouapplysdestext2 {
  font-weight: 500;
}
.didyouapplysdesbox2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.didyouapplysdesyesyestext {
  position: relative;
  font-size: var(--h4-medium-16-size);
  font-weight: 500;
  font-family: var(--h4-medium-16);
  color: var(--color-black);
  text-align: left;
}
.didyouapplysdesyesyestext:hover {
  background-color: lightgrey; 
  border-radius: 50%; 
  padding: 5px; 
}
.didyouapplysdesyes {
  cursor: pointer;
  border: 0;
  padding: 0 var(--padding-3xs);
  background-color: transparent;
  border-radius: var(--br-31xl);
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.didyouapplysdesydidyouapplysde {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.didyouapplysdes,
.didyouapplysdesbox {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.didyouapplysdes {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--color-steelblue-100);
  overflow: hidden;
  align-items: flex-end;
  padding: var(--padding-xs) var(--padding-2xl);
}
.sdessdetailssbuttonbox11,
.sdessdetailsschecklistbox12 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.sdessdetailssbuttonbox11 {
  flex: 1;
}
.sdessdetailsschecklistbox12 {
  align-self: stretch;
  color: var(--color-black);
}
.applied-on-4192023 {
  font-family: var(--h4-medium-16);
}
.our-tracker {
  text-decoration: underline;
}
.applied-on-4192023-container {
  flex: 1;
  position: relative;
}
.the-2000-nitro {
  margin: 0;
}
.a-student-who-is-currently-enr {
  margin-bottom: 0;
}
.a-parent-who-currently-has-a-c {
  margin: 0;
  padding-left: var(--padding-13xl);
}
.official-rules {
  color: inherit;
}
.sdessdetailssdesboxtext {
  flex: 1;
  position: relative;
  line-height: 150%;
}
.sdessdetailssdesbox1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-inherit);
  color: var(--font-inherit);
  font-family: var(--font-inherit);
}
.sdessdetailsbox,
.sdessdetailsbox1 {
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
}
.sdessdetailsbox1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  padding: var(--padding-39xl) var(--padding-107xl) var(--padding-27xl)
    var(--padding-74xl);
  gap: var(--gap-10xl);
}
.sdessdetailsbox {
  border: 2px solid var(--gray-3);
  gap: var(--gap-3xs);
}
.sdesdetailsbox1,
.sdesdetailsbox2,
.sdessdetailsbox {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.sdesdetailsbox2 {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-3xs);
}
.sdesdetailsbox1 {
  flex-direction: column;
  align-items: center;
}
.sdescriptiontitleunderbox1,
.sdesdetailsbox {
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
}
.sdesdetailsbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-75xl);
  color: var(--logo-black);
}
.sdescriptiontitleunderbox1 {
  display: none;
  align-items: flex-start;
  justify-content: center;
}
.sdesdetailssidecardcurrenthead1 {
  flex: 1;
  position: relative;
  font-weight: 500;
}
.sdesdetailssidecardcurdetheart-icon1 {
  position: relative;
  width: 22.63px;
  height: 21.64px;
}
.sdesdetailssidecard-parent,
.selection {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.sdesdetailssidecard-parent {
  flex: 1;
  gap: 19px;
}
.selection {
  align-self: stretch;
  overflow-x: auto;
  padding: 0 0 26px;
}
.sdessdetailsunder2box,
.selection-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .selection-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
 */


  /* Hide scrollbar for Chrome, Safari and Opera */
  .selection::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .selection{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }



.sdessdetailsunder2box {
  overflow: hidden;
}
.vector-icon4 {
  position: absolute;
  top: calc(50% - 11.11px);
  left: calc(50% - 131.84px);
  width: 24.21px;
  height: 24.22px;
  opacity: 0;
}
.vector-icon7 {
  position: relative;
  width: 13px;
  height: 13px;
}
.sdessdetailsbox11,
.sdessdetailsbox2 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}
.sdessdetailsbox11 {
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-39xl) var(--padding-107xl) var(--padding-27xl)
    var(--padding-74xl);
  gap: var(--gap-10xl);
}
.sdessdetailsbox2 {
  border: 2px solid var(--gray-3);
  box-sizing: border-box;
  height: 1407px;
  flex-shrink: 0;
}
.scholarship-description,
.sdessdetailsbox2,
.sdessdetailsunderbox {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-3xs);
}
.sdessdetailsunderbox {
  align-self: stretch;
  display: none;
  justify-content: center;
  color: var(--logo-black);
}
.scholarship-description {
  position: relative;
  background-color: var(--white);
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--h4-medium-16-size);
  color: var(--color-black);
  font-family: var(--h4-medium-16);
}
.checkmarkcirclefill-icon{
  width: 20px;
  height: 20px;
}

.checkRow{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.checkText {
  font-size: 14px;
  line-height: 1;
  padding-top: 11px;
  color: #333;
}

@media screen and (max-width: 1200px) {
  .sdesdetailssidecardcurimgbox {
    width: 206px;
  }
  .sdesdetailssidecardcurdetsname {
    width: 174px;
  }
  .sdesdetailssidecardcurdetsdead,
  .sdesdetailssidecardcurdetslink,
  .sdesdetailssidecardcurdetsname {
    font-size: var(--h6-medium-12-size);
  }
  .sdesdetailssidecardcurdetsdead {
    width: 152px;
  }
  .sdesdetailssidecardcurdetheart-icon {
    width: 18px;
    height: 16px;
  }
  .sdesdetailssidecardcurdetdeadl {
    gap: var(--gap-8xs);
  }
  .sdesdetailssidecardcurdetbox {
    width: 206px;
  }
  .sdessdetailsimg-icon {
    width: 130px;
    height: 131px;
  }
  .sdessdetailsimgbox {
    padding-top: var(--padding-11xl);
    padding-bottom: var(--padding-11xl);
    box-sizing: border-box;
  }
  .sdessdetailsbox1sname,
  .sdessdetailssamountboxtext1,
  .sdessdetailsslinkbox1text {
    font-size: var(--font-size-6xl);
  }
  .sdessdetailssdesboxtext {
    font-size: var(--h3-medium-20-size);
    width: 500px;
  }
  .sdessdetailsbox1 {
    padding-left: var(--padding-24xl);
    box-sizing: border-box;
  }
  .sdesdetailsbox {
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
  .sdesdetailssidecardcurdetheart-icon1 {
    width: 18px;
    height: 16px;
  }

  .checkmarkcirclefill-icon{
    width: 18px;
    height: 16px;
  }
  .selection {
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    gap: var(--gap-mini);
  }
  .sdessdetailsunder2box {
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
  .sdessdetailsbox11 {
    padding-left: var(--padding-24xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 850px) {
  .sdescriptiontitle2boxsname,
  .sdescriptiontitlebox1text,
  .sdescriptiontitleboxarrow {
    font-size: var(--h6-medium-12-size);
  }
  .sdescriptiontitlebox1 {
    width: 600px;
  }
  .sdescriptiontitlebox {
    display: none;
    width: 600px;
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .sdescriptiontitlebackbox1arrow,
  .sdescriptiontitlebackbox1text {
    font-size: var(--h6-medium-12-size);
  }
  .sdescriptiontitleback {
    display: none;
    padding-left: var(--padding-29xl);
    padding-right: var(--padding-29xl);
    box-sizing: border-box;
  }
  .sdesdetailssidecardcurimgbox1-icon {
    width: 50px;
    height: 50px;
  }
  .sdesdetailssidecardcurimgbox {
    width: 150px;
    height: 67px;
  }
  .sdesdetailssidecardcurdetsname {
    font-size: var(--font-size-3xs);
    width: 144px;
  }
  .sdesdetailssidecardcurdetslink {
    font-size: var(--font-size-3xs);
  }
  .sdesdetailssidecardcurdetsdead {
    font-size: var(--font-size-4xs);
    width: 97px;
  }
  .sdesdetailssidecardcurdetheart-icon {
    width: 14px;
    height: 12px;
  }
  .sdesdetailssidecardcurdetbox {
    width: 150px;
    padding-left: var(--padding-4xl);
    box-sizing: border-box;
    max-height: 90px;
    min-height: 90px;
  }
  .sdesdetailssidecardbox,
  .sdesdetailssidecardcurrentbox {
    display: flex;
  }
  .sdesdetailssidebox {
    display: none;
    flex-direction: row;
  }
  .sdessdetailsbox1sname {
    font-size: var(--font-size-lg);
  }
  .sdessdetailsbox1arrows-icon,
  .sdessdetailsbox1health-icon {
    width: 30px;
    height: 22px;
  }
  .sdessdetailslovebox1 {
    padding-left: var(--padding-41xl);
    box-sizing: border-box;
  }
  .sdessdetailssamountboxtext1,
  .sdessdetailsslinkbox1text {
    font-size: var(--font-size-lg);
  }
  .sdessdetailsscheclistboxtext1,
  .sdessdetailssdeadlinetextbox1 {
    font-size: var(--h4-medium-16-size);
  }
  .applied-on-4192023-container,
  .didyouapplysdestext1,
  .didyouapplysdestext2,
  .didyouapplysdesyesyestext {
    font-size: var(--font-size-smi);
  }
  .sdessdetailssdesboxtext {
    font-size: var(--h4-medium-16-size);
  }
  .sdessdetailsbox1 {
    padding-left: var(--padding-24xl);
    padding-right: var(--padding-54xl);
    box-sizing: border-box;
  }
  .sdessdetailsbox {
    display: flex;
    flex: unset;
    align-self: stretch;
  }
  .sdesdetailsbox2 {
    align-self: stretch;
    width: auto;
    flex-direction: column;
    gap: var(--gap-3xs);
  }
  .sdesdetailsbox {
    display: none;
    flex-direction: row;
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .sdescriptiontitleunderbox1 {
    display: flex;
    padding-left: var(--padding-29xl);
    padding-right: var(--padding-29xl);
    box-sizing: border-box;
  }
  .sdesdetailssidecardcurrenthead1 {
    font-size: var(--font-size-2xs);
  }
  .sdesdetailssidecardcurdetheart-icon1 {
    width: 14px;
    height: 12px;
  }
  .sdesdetailssidecard-parent {
    gap: 12px;
  }
  .selection {
    display: flex;
  }
  .sdessdetailsunder2box {
    padding-right: 0;
    box-sizing: border-box;
  }
  .sdessdetailsbox11 {
    padding-left: var(--padding-24xl);
    padding-right: var(--padding-54xl);
    box-sizing: border-box;
  }
  .sdessdetailsbox2 {
    display: flex;
  }
  .sdessdetailsunderbox {
    display: flex;
    padding-left: var(--padding-29xl);
    padding-top: 0;
    padding-right: var(--padding-29xl);
    box-sizing: border-box;
  }
  .scholarship-description {
    padding-top: 70px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 520px) {
  .sdescriptiontitle2boxsname,
  .sdescriptiontitlebox1text {
    font-size: var(--font-size-4xs);
  }
  .sdescriptiontitlebox {
    padding-left: 40px;
    box-sizing: border-box;
  }
  .sdesdetailssidecardcurimgbox1-icon {
    width: 50px;
    height: 50px;
  }
  .sdesdetailssidecardcurimgbox {
    width: 150px;
    height: 67px;
  }
  .sdesdetailssidecardcurdetsname {
    font-size: var(--font-size-3xs);
    width: 144px;
  }
  .sdesdetailssidecardcurdetslink {
    font-size: var(--font-size-3xs);
  }
  .sdesdetailssidecardcurdetsdead {
    font-size: var(--font-size-4xs);
    width: 97px;
  }
  .sdesdetailssidecardcurdetheart-icon {
    width: 16px;
    height: 14px;
  }
  .sdesdetailssidecardcurdetbox {
    width: 150px;
    padding-left: var(--padding-xs);
  }
  .sdessdetailsimg-icon {
    width: 100px;
    height: 100px;
  }
  .sdessdetailsbox1sname {
    font-size: var(--font-size-smi);
  }
  .sdessdetailsbox1arrows-icon,
  .sdessdetailsbox1health-icon {
    width: 26px;
    height: 18px;
  }
  .sdessdetailslovebox1 {
    padding-left: var(--padding-41xl);
    box-sizing: border-box;
  }
  .sdessdetailssamountboxtext1,
  .sdessdetailssdeadlinetextbox1,
  .sdessdetailsslinkbox1text {
    font-size: var(--font-size-smi);
  }
  .sdessdetailsscheclistboxtext1 {
    font-size: var(--h4-medium-16-size);
  }
  .letters-of-recommendation {
    font-size: var(--h5-btns-med-14-size);
  }
  .didyouapplysdestext1,
  .didyouapplysdestext2,
  .didyouapplysdesyesyestext {
    font-size: var(--font-size-3xs);
  }
  .didyouapplysdesyes {
    padding-left: var(--padding-8xs);
    padding-right: var(--padding-8xs);
    box-sizing: border-box;
  }
  .applied-on-4192023-container {
    font-size: var(--font-size-3xs);
  }
  .sdessdetailssdesboxtext {
    font-size: var(--h5-btns-med-14-size);
  }
  .sdessdetailsbox1 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
  .sdesdetailssidecardcurdetheart-icon1 {
    width: 16px;
    height: 14px;
  }
  .selection {
    padding-left: 0;
    box-sizing: border-box;
  }
  .sdessdetailsbox11 {
    gap: var(--gap-mini);
    padding-left: var(--padding-xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
  .scholarship-description {
    padding-top: var(--padding-31xl);
    box-sizing: border-box;
  }
}
