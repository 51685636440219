
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --p2-regular-12: Poppins;

  /* font sizes */
  --p1-regular-16-size: 16px;
  --font-size-smi: 13px;
  --h5-btns-med-14-size: 14px;
  --p2-regular-12-size: 12px;
  --h2-bold-32-size: 32px;
  --font-size-6xl: 25px;
  --font-size-4xs: 9px;
  --font-size-2xl: 21px;
  --font-size-7xl: 26px;
  --font-size-5xl: 24px;
  --h3-medium-20-size: 20px;
  --h1-bold-52-size: 52px;
  --font-size-3xs: 10px;
  --font-size-11xl: 30px;
  --font-size-2xs: 11px;
  --font-size-9xl: 28px;
  --font-size-27xl: 46px;
  --font-size-mid: 17px;
  --font-size-5xs: 8px;
  --font-size-lg: 18px;
  --font-size-21xl: 40px;
  --font-size-3xl: 22px;
  --font-size-17xl: 36px;
  --font-size-29xl: 48px;
  --font-size-mini: 15px;
  --font-size-smi-4: 12.4px;

  /* Colors */
  --white: #fff;
  --gray-4: #79797d;
  --logo-black: #010101;
  --gray-1: #f2f2f2;
  --purple-blue: #5558cf;
  --logo-color-2: #4a93cf;
  --royal-blue: #0d0ed9;
  --gray-5: #393939;
  --gradient-2: rgba(57, 57, 57, 0.4);
  --color-limegreen: #34b946;
  --color-black: #000;
  --niche-logo-color: #e5f3f0;
  --gray-3: #c4c6c7;
  --gray-2: #e7e7e7;
  --logo-color-1: #181048;
  --color-darkturquoise: #00b8c3;
  --color-red: #f40000;
  --color-mediumslateblue: #5d57f9;
  --color-gold: #f8d100;
  --color-ghostwhite: #f4f6fa;
  --light-blue: #cbdff7;
  --teal: #40cfc3;
  --reviews: #deb00f;
  --salliemae-logo-color: #deeaef;
  --color-darkgray: rgba(178, 177, 177, 0.5);

  /* Gaps */
  --gap-62xl: 81px;
  --gap-365xl: 384px;
  --gap-69xl: 88px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-11xl: 30px;
  --gap-xs: 12px;
  --gap-13xl: 32px;
  --gap-sm: 14px;
  --gap-3xs: 10px;
  --gap-44xl: 63px;
  --gap-0: 0px;
  --gap-131xl: 150px;
  --gap-45xl: 64px;
  --gap-9xl: 28px;
  --gap-425xl: 444px;
  --gap-6xs: 7px;
  --gap-9xs: 4px;
  --gap-11xs: 2px;
  --gap-612xl: 631px;
  --gap-smi: 13px;
  --gap-12xs: 1px;
  --gap-mini: 15px;
  --gap-467xl: 486px;
  --gap-440xl: 459px;
  --gap-17xl: 36px;
  --gap-518xl: 537px;
  --gap-14xl: 33px;
  --gap-299xl: 318px;
  --gap-10xs: 3px;
  --gap-14xs: -1px;
  --gap-685xl: 704px;
  --gap-663xl: 682px;
  --gap-109xl: 128px;
  --gap-lg: 18px;
  --gap-8xl: 27px;
  --gap-140xl: 159px;
  --gap-217xl: 236px;
  --gap-202xl: 221px;
  --gap-290xl: 309px;
  --gap-286xl: 305px;
  --gap-281xl: 300px;
  --gap-211xl: 230px;
  --gap-292xl: 311px;
  --gap-204xl: 223px;
  --gap-287xl: 306px;
  --gap-20xl: 39px;
  --gap-32xl: 51px;
  --gap-6xs-2: 6.2px;
  --gap-415xl: 434px;
  --gap-417xl: 436px;
  --gap-391xl: 410px;
  --gap-462xl: 481px;
  --gap-70xl: 89px;
  --gap-232xl: 251px;
  --gap-268xl: 287px;
  --gap-336xl: 355px;
  --gap-285xl: 304px;
  --gap-151xl: 170px;
  --gap-128xl: 147px;
  --gap-259xl: 278px;
  --gap-302xl: 321px;
  --gap-278xl: 297px;
  --gap-227xl: 246px;
  --gap-174xl: 193px;
  --gap-2xs: 11px;

  /* Paddings */
  --padding-63xl: 82px;
  --padding-105xl: 124px;
  --padding-20xl: 39px;
  --padding-9xs: 4px;
  --padding-61xl: 80px;
  --padding-5xs: 8px;
  --padding-141xl: 160px;
  --padding-21xl: 40px;
  --padding-xl: 20px;
  --padding-base: 16px;
  --padding-8xl: 27px;
  --padding-301xl: 320px;
  --padding-17xl: 36px;
  --padding-231xl: 250px;
  --padding-6xs: 7px;
  --padding-13xl: 32px;
  --padding-mini: 15px;
  --padding-12xs: 1px;
  --padding-3xs: 10px;
  --padding-11xl: 30px;
  --padding-mid: 17px;
  --padding-181xl: 200px;
  --padding-101xl: 120px;
  --padding-136xl: 155px;
  --padding-3xl: 22px;
  --padding-81xl: 100px;
  --padding-smi: 13px;
  --padding-71xl: 90px;
  --padding-31xl: 50px;
  --padding-18xl: 37px;
  --padding-lgi: 19px;
  --padding-5xl: 24px;
  --padding-53xl: 72px;
  --padding-8xs: 5px;
  --padding-10xl: 29px;
  --padding-261xl: 280px;
  --padding-lg: 18px;
  --padding-139xl: 158px;
  --padding-sm: 14px;
  --padding-35xl: 54px;
  --padding-140xl: 159px;
  --padding-4xl: 23px;
  --padding-108xl: 127px;
  --padding-6xl: 25px;
  --padding-xs: 12px;
  --padding-7xs: 6px;
  --padding-41xl: 60px;
  --padding-12xl: 31px;
  --padding-110xl: 129px;
  --padding-25xl: 44px;
  --padding-29xl: 48px;
  --padding-7xl: 26px;
  --padding-10xs: 3px;
  --padding-109xl: 128px;
  --padding-60xl: 79px;
  --padding-93xl: 112px;
  --padding-121xl: 140px;
  --padding-131xl: 150px;
  --padding-138xl: 157px;
  --padding-16xl: 35px;
  --padding-619xl: 638px;
  --padding-27xl: 46px;
  --padding-271xl: 290px;
  --padding-161xl: 180px;
  --padding-151xl: 170px;
  --padding-157xl: 176px;
  --padding-191xl: 210px;
  --padding-259xl: 278px;
  --padding-2xl: 21px;
  --padding-58xl: 77px;
  --padding-15xl: 34px;
  --padding-610xl: 629px;
  --padding-146xl: 165px;
  --padding-137xl: 156px;
  --padding-123xl: 142px;
  --padding-144xl: 163px;
  --padding-51xl: 70px;
  --padding-11xs: 2px;
  --padding-4xs: 9px;
  --padding-69xl: 88px;
  --padding-175xl: 194px;
  --padding-52xl: 71px;
  --padding-50xl: 69px;
  --padding-9xl: 28px;
  --padding-117xl: 136px;
  --padding-89xl: 108px;
  --padding-2xs: 11px;
  --padding-134xl: 153px;
  --padding-351xl: 370px;
  --padding-381xl: 400px;
  --padding-79xl: 98px;
  --padding-26xl: 45px;
  --padding-281xl: 300px;
  --padding-243xl: 262px;
  --padding-73xl: 92px;
  --padding-55xl: 74px;
  --padding-244xl: 263px;
  --padding-36xl: 55px;
  --padding-28xl: 47px;
  --padding-34xl: 53px;
  --padding-115xl: 134px;
  --padding-76xl: 95px;
  --padding-429xl: 448px;
  --padding-44xl: 63px;
  --padding-98xl: 117px;
  --padding-205xl: 224px;
  --padding-237xl: 256px;
  --padding-561xl: 580px;
  --padding-283xl: 302px;
  --padding-481xl: 500px;
  --padding-30xl: 49px;
  --padding-246xl: 265px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-7xs: 6px;
  --br-base: 16px;
  --br-61xl: 80px;
  --br-11xs: 2px;
  --br-31xl: 50px;
  --br-6xs-2: 6.2px;
  --br-9xs: 4px;

  /* Effects */
  --button-drp-shdw: 4px 4px 16px rgba(0, 0, 0, 0.25);
  --shadow-1: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.ticketsubmittedheader,
.ticketsubmittednumber {
  align-self: stretch;
  position: relative;
  font-weight: 500;
}
.ticketsubmittedheader {
  height: 45px;
  display: inline-block;
}
.ticketsubmittednumber {
  font-size: var(--font-size-5xl);
}
.ticketsubmittedquestion-icon {
  width: 96px;
  position: relative;
  height: 96px;
  overflow: hidden;
  flex-shrink: 0;
}
.ticketsubmittedmessagebody {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ticketsubmiitedwillbe {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-mini);
  font-weight: 500;
}
.tickedsubmittedbody,
.ticketsubmitted {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tickedsubmittedbody {
  gap: var(--gap-11xl);
}
.ticketsubmitted {
  width: 1118px;
  height: 486px;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  overflow: hidden;
  padding: 0 var(--padding-246xl);
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  font-size: var(--font-size-11xl);
  color: var(--color-black);
  font-family: var(--p2-regular-12);
}
@media screen and (max-width: 1200px) {
  .ticketsubmitted {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 850px) {
  .ticketsubmittedheader,
  .ticketsubmittednumber {
    font-size: var(--font-size-lg);
  }
  .ticketsubmittedquestion-icon {
    width: 76px;
    height: 76px;
  }
  .ticketsubmiitedwillbe {
    font-size: var(--p2-regular-12-size);
  }
}
