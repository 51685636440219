.coachs-scholarship-dashboard {
  display: flex;
  flex-direction: column;
  background-color: var(--gray-0.2);
}

.scholarships-category-page {
  overflow-y: auto;
}

.schols-sidebar {
    position: fixed;
    height: 110vh;  
    z-index: 100; 
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  .schols-sidebar.hidden {
    transform: translateX(-100%);
    opacity: 0; 
  }
  
  .schols-sidebar.visible {
    transform: translateX(0); 
    opacity: 1; 
  }
  