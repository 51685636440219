/* .headerlogo-icon13 {
  position: relative;
  width: 134px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link-default52 {
  position: relative;
  font-weight: 500;
}
.headernav-link65 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headernav-link66 {
  color: var(--logo-color-1);
}
.headernav-link66,
.headernav-link67,
.left-side-top-nav13,
.top-nav-left13 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left-side-top-nav13,
.top-nav-left13 {
  gap: var(--gap-13xl);
}
.left-side-top-nav13 {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  width: 604.51px;
  padding: 0 0 0 var(--padding-13xl);
  box-sizing: border-box;
  align-items: flex-end;
}
.vector-icon92,
.vector-icon93 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon93 {
  height: 50%;
  width: 75%;
  top: 25%;
  right: 12.5%;
  bottom: 25%;
  left: 12.5%;
}
.menu13 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 44px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.messages-icon12,
.notifications-icon12 {
  position: absolute;
  top: 8px;
  left: -3px;
  width: 28.3px;
  height: 28px;
}
.notifications-icon12 {
  left: 53px;
  width: 22.74px;
}
.mask-group-icon12 {
  position: relative;
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.vector-icon94 {
  position: relative;
  width: 10px;
  height: 6px;
}
.mask-group-parent10 {
  position: absolute;
  top: 0;
  left: 106px;
  width: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.messages-parent10 {
  position: relative;
  width: 152px;
  height: 44px;
}
.right-side-top-nav13 {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  width: 263px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-13xl) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-13xl);
}
.info12 {
  align-self: stretch;
  position: relative;
  height: 44px;
}
.scholarships-line-icon12,
.services-line-icon12 {
  position: relative;
  width: 89.5px;
  height: 0;
  display: none;
}
.services-line-icon12 {
  width: 59.5px;
}
.community-line-icon12,
.resources-icon12 {
  position: relative;
  width: 83px;
  height: 0;
  display: none;
}
.resources-icon12 {
  width: 73px;
}
.navbar12 {
  align-self: stretch;
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-3);
  box-sizing: border-box;
  height: 79px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.scholist-profile1 {
  position: relative;
}
.header12 {
  width: 1446px;
  height: 191px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-76xl) var(--padding-429xl) var(--padding-smi);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.personal-information1 {
  position: relative;
  font-size: var(--p1-regular-16-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--white);
  text-align: center;
}
.personal-information-container {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini) 0;
  background-color: var(--royal-blue);
  border-radius: var(--br-5xs);
  width: 272px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.education3 {
  position: relative;
  font-size: var(--p1-regular-16-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--color-black);
  text-align: center;
}
.education-container {
  cursor: pointer;
  border: 0.8px solid var(--gray-2);
  padding: var(--padding-mini) 0;
  background-color: var(--gray-1);
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  width: 272px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.frame-parent18 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-32xl);
}
.selection-header {
  background-color: var(--white);
  width: 1446px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-2xl) var(--padding-115xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.name11 {
  position: relative;
  line-height: 150%;
}
.multiselectdefault4 {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  box-shadow: var(--shadow-1);
  border: 1px solid var(--gray-3);
  box-sizing: border-box;
  width: 373px;
  height: 42px;
  overflow: hidden;
  flex-shrink: 0;
}
.name-parent8 {
  gap: 193px;
}
.name-parent8,
.name-parent9,
.quiz-question10 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.quiz-question10 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  padding: var(--padding-3xl) var(--padding-35xl) var(--padding-3xl)
    var(--padding-30xl);
}
.name-parent9 {
  gap: 246px;
}
.multiselectdefault6 {
  border: 1px solid var(--gray-3);
  background-color: var(--white);
  position: relative;
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-1);
  box-sizing: border-box;
  width: 373px;
  height: 42px;
  overflow: hidden;
  flex-shrink: 0;
}
.name-parent10,
.quiz-question12 {
  display: flex;
  justify-content: flex-start;
}
.name-parent10 {
  flex-direction: row;
  align-items: center;
  gap: 297px;
}
.quiz-question12 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-34xl) var(--padding-xl)
    var(--padding-30xl);
  align-items: flex-start;
}
.name-parent11 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-292xl);
}
.name-parent11,
.name-parent12,
.quiz-question13 {
  display: flex;
  justify-content: flex-start;
}
.quiz-question13 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-34xl) var(--padding-xl)
    var(--padding-30xl);
  align-items: flex-start;
}
.name-parent12 {
  flex-direction: row;
  align-items: center;
  gap: 321px;
}
.quiz-question14 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  padding: var(--padding-3xl) var(--padding-35xl) var(--padding-3xl)
    var(--padding-30xl);
}
.name-parent13,
.quiz-question14,
.quiz-question15 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.name-parent13 {
  gap: var(--gap-232xl);
}
.quiz-question15 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  padding: var(--padding-3xl) var(--padding-35xl) var(--padding-3xl)
    var(--padding-30xl);
}
.exit1,
.scholarship-questions-21 {
  font-size: var(--p1-regular-16-size);
}
.scholarship-questions-21 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  display: flex;
  flex-direction: column;
  padding: var(--padding-17xl) var(--padding-13xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-lg);
}
.exit1 {
  position: relative;
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--white);
  text-align: left;
}
.exit-container {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini) var(--padding-52xl);
  background-color: var(--royal-blue);
  border-radius: var(--br-5xs);
  width: 169px;
  height: 54px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.vector-icon95 {
  position: relative;
  width: 10.95px;
  height: 18.95px;
}
.frame-parent19,
.vector-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.vector-frame {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mid) var(--padding-55xl);
  background-color: var(--royal-blue);
  border-radius: var(--br-5xs);
  width: 163.14px;
  height: 53.12px;
  overflow: hidden;
  flex-shrink: 0;
  box-sizing: border-box;
}
.frame-parent19 {
  gap: var(--gap-140xl);
}
.exit-button {
  width: 1445px;
  height: 227px;
  flex-shrink: 0;
  padding: var(--padding-6xl) var(--padding-243xl) var(--padding-73xl);
  box-sizing: border-box;
}
.body13,
.exit-button,
.form-body1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.body13 {
  font-size: var(--h1-bold-52-size);
}
.footerlogo-icon13 {
  position: relative;
  width: 148px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link39 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}
.footerlinknav39 {
  position: relative;
  width: 160px;
  height: 19px;
}
.footer-links65 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.footericoninstagram13 {
  position: relative;
  width: 20px;
  height: 20px;
}
.footericonfacbook13 {
  position: relative;
  width: 18.75px;
  height: 18.75px;
}
.footericontwitter13 {
  position: relative;
  width: 19.5px;
  height: 15.84px;
}
.footericonyoutube13 {
  position: relative;
  width: 21px;
  height: 14.72px;
}
.icon-wrapper13 {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.footer-menu65 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.students13 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 160px;
}
.footer-link169 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  display: inline-block;
}
.main-footer-link169 {
  position: relative;
  width: 160px;
  height: 24px;
}
.footer-menu66 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.footer-link181 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
}
.main-footer-link181 {
  position: relative;
  width: 160px;
  height: 71px;
  font-size: var(--p2-regular-12-size);
}
.text-box12 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-7xs);
  background-color: var(--white);
  box-shadow: var(--shadow-1);
}
.input-placeholder12 {
  position: absolute;
  top: 4px;
  left: 10px;
  font-weight: 500;
  display: inline-block;
  width: 181px;
  height: 25px;
}
.subscribe-email-text-box13 {
  position: relative;
  width: 198px;
  height: 28px;
  color: var(--gray-4);
}
.button-text85,
.button50 {
  position: absolute;
  width: 100%;
  left: 0;
}
.button50 {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-5xs);
  background-color: var(--purple-blue);
}
.button-text85 {
  height: 95%;
  top: 2.5%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonlarge34 {
  position: relative;
  width: 198px;
  height: 32px;
  text-align: center;
}
.footer-grid-row13,
.footer-links69,
.footer-menu-parent11 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-links69 {
  flex-direction: column;
  gap: var(--gap-5xs);
  font-size: var(--h5-btns-med-14-size);
}
.footer-grid-row13,
.footer-menu-parent11 {
  flex-direction: row;
  gap: var(--gap-69xl);
}
.footer-grid-row13 {
  justify-content: center;
}
.scholist-all-rights13 {
  position: relative;
  line-height: 150%;
}
.footer-legal13 {
  width: 827px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-365xl);
}
.footer13,
.student-activities-profile {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer13 {
  align-self: stretch;
  background-color: var(--gray-4);
  padding: var(--padding-63xl) var(--padding-105xl) var(--padding-20xl);
  align-items: flex-start;
  gap: var(--gap-62xl);
  font-size: var(--p1-regular-16-size);
  color: var(--gray-1);
}
.student-activities-profile {
  position: relative;
  background-color: var(--white);
  width: 100%;
  align-items: center;
  gap: var(--gap-11xs);
  text-align: left;
  font-size: var(--h5-btns-med-14-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
}
@media screen and (max-width: 1200px) {
  .menu13 {
    display: none;
  }
  .education-container,
  .personal-information-container {
    width: 222px;
  }
  .frame-parent18 {
    gap: var(--gap-16xl);
  }
  .multiselectdefault4 {
    width: 313px;
  }
  .name-parent8 {
    gap: var(--gap-96xl);
  }
  .name-parent9 {
    gap: 160px;
  }
  .multiselectdefault6 {
    width: 313px;
  }
  .name-parent10 {
    gap: 210px;
  }
  .name-parent11 {
    gap: 225px;
  }
  .name-parent12 {
    gap: 233px;
  }
  .name-parent13 {
    gap: 185px;
  }
  .scholarship-questions-21 {
    width: 700px;
  }
  .exit-container {
    width: 149px;
  }
  .form-body1 {
    justify-content: center;
  }
  .footer-menu-parent11 {
    flex-wrap: wrap;
  }
  .footer-grid-row13 {
    flex-direction: column;
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 850px) {
  .top-nav-left13 {
    display: none;
  }
  .menu13 {
    display: flex;
  }
  .messages-parent10 {
    display: none;
  }
  .scholist-profile1 {
    font-size: var(--h2-bold-32-size);
  }
  .personal-information1 {
    font-size: var(--font-size-3xs);
  }
  .personal-information-container {
    width: 150px;
  }
  .education3 {
    font-size: var(--font-size-3xs);
  }
  .education-container {
    width: 150px;
  }
  .frame-parent18 {
    gap: var(--gap-xl);
  }
  .name11 {
    font-size: var(--h5-btns-med-14-size);
  }
  .multiselectdefault4 {
    width: 343px;
  }
  .name-parent8 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .quiz-question10 {
    padding-top: var(--padding-xs);
    padding-bottom: var(--padding-3xl);
    box-sizing: border-box;
  }
  .name-parent10,
  .name-parent9 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .name-parent10 {
    gap: var(--gap-8xs);
  }
  .quiz-question12 {
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--padding-26xl);
    padding-top: var(--padding-3xs);
    padding-right: var(--padding-71xl);
    box-sizing: border-box;
  }
  .name-parent11 {
    flex-direction: column;
    gap: var(--gap-8xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .quiz-question13 {
    padding-top: var(--padding-3xs);
    padding-right: var(--padding-71xl);
    box-sizing: border-box;
  }
  .name-parent12 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .quiz-question14 {
    padding-top: var(--padding-xs);
    padding-right: var(--padding-41xl);
    padding-bottom: var(--padding-3xl);
    box-sizing: border-box;
  }
  .name-parent13 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .quiz-question15 {
    padding-top: var(--padding-xs);
    padding-right: var(--padding-44xl);
    padding-bottom: var(--padding-3xl);
    box-sizing: border-box;
  }
  .scholarship-questions-21 {
    width: 500px;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
    border: 1px solid var(--color-black);
  }
  .exit-container {
    width: 30%;
  }
  .frame-parent19 {
    gap: var(--gap-130xl);
  }
}
@media screen and (max-width: 520px) {
  .personal-information1 {
    font-size: var(--font-size-4xs);
  }
  .personal-information-container {
    width: 130px;
  }
  .education3 {
    font-size: var(--font-size-4xs);
  }
  .education-container {
    width: 130px;
  }
  .frame-parent18 {
    gap: var(--gap-6xs);
  }
  .multiselectdefault4,
  .multiselectdefault6 {
    width: 273px;
  }
  .name-parent10 {
    gap: var(--gap-8xs);
  }
  .quiz-question12 {
    padding-left: var(--padding-30xl);
    padding-right: var(--padding-35xl);
    box-sizing: border-box;
  }
  .name-parent11 {
    gap: var(--gap-8xs);
  }
  .quiz-question13 {
    padding-left: var(--padding-30xl);
    padding-right: var(--padding-35xl);
    box-sizing: border-box;
  }
  .scholarship-questions-21 {
    width: 420px;
  }
  .exit-container {
    width: 129px;
  }
  .vector-frame {
    width: 20px;
  }
  .frame-parent19 {
    gap: var(--gap-81xl);
  }
} */



@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --p1-regular-16: Poppins;

  /* font sizes */
  --p1-regular-16-size: 16px;
  --font-size-sm: 14px;
  --h6-medium-12-size: 12px;
  --font-size-5xs: 8px;
  --h2-bold-32-size: 32px;
  --font-size-mid: 17px;
  --font-size-5xl: 24px;
  --font-size-mini: 15px;
  --font-size-29xl: 48px;
  --font-size-21xl: 40px;
  --font-size-9xl: 28px;
  --font-size-3xl: 22px;
  --h3-medium-20-size: 20px;
  --font-size-2xl-6: 21.6px;
  --font-size-3xs: 10px;
  --font-size-2xs: 11px;
  --font-size-4xs: 9px;
  --font-size-lg-8: 18.8px;
  --font-size-2xl: 21px;
  --font-size-4xl-2: 23.2px;
  --font-size-lgi: 19px;
  --font-size-17xl: 36px;
  --font-size-3xl-7: 22.7px;
  --font-size-smi: 13px;
  --font-size-4xl-4: 23.4px;
  --font-size-lg: 18px;
  --font-size-lg-9: 18.9px;
  --font-size-9xl-1: 28.1px;

  /* Colors */
  --white: #fff;
  --gray-3: #c4c6c7;
  --color-silver-100: #bbb;
  --color-black: #000;
  --gray-4: #79797d;
  --color-gray-100: #787878;
  --logo-black: #010101;
  --color-gray-200: rgba(255, 255, 255, 0.5);
  --gray-2: #e7e7e7;
  --royal-blue: #0d0ed9;
  --gray-1: #f2f2f2;
  --color-khaki: #f8d665;
  --color-tomato: #ed5043;
  --gray-5: #393939;
  --color-darkslategray-100: #263238;
  --purple-blue: #5558cf;
  --color-steelblue-100: #4a93cf;
  --teal: #40cfc3;
  --color-mediumaquamarine: #70d499;

  /* Gaps */
  --gap-11xs: 2px;
  --gap-lg: 18px;
  --gap-111xl: 130px;
  --gap-8xs: 5px;
  --gap-5xs: 8px;
  --gap-13xl: 32px;
  --gap-3xs: 10px;
  --gap-43xs: -30px;
  --gap-192xl: 211px;
  --gap-61xl: 80px;
  --gap-254xl: 273px;
  --gap-28xl: 47px;
  --gap-xs: 12px;
  --gap-6xs: 7px;
  --gap-53xl: 72px;
  --gap-31xl: 50px;
  --gap-9xs: 4px;
  --gap-7xs: 6px;
  --gap-9xl: 28px;
  --gap-11xl: 30px;
  --gap-mini: 15px;
  --gap-3xl: 22px;
  --gap-0: 0px;
  --gap-base: 16px;
  --gap-10xs: 3px;
  --gap-3xs-4: 9.4px;
  --gap-2xs: 11px;
  --gap-mini-1: 14.1px;

  /* Paddings */
  --padding-17xl: 36px;
  --padding-13xl: 32px;
  --padding-xl: 20px;
  --padding-34xl: 53px;
  --padding-3xs: 10px;
  --padding-9xs: 4px;
  --padding-25xl: 44px;
  --padding-5xs: 8px;
  --padding-175xl: 194px;
  --padding-69xl: 88px;
  --padding-81xl: 100px;
  --padding-11xl: 30px;
  --padding-31xl: 50px;
  --padding-mid: 17px;
  --padding-53xl: 72px;
  --padding-8xs: 5px;
  --padding-52xl: 71px;
  --padding-50xl: 69px;
  --padding-6xs: 7px;
  --padding-xs: 12px;
  --padding-7xs: 6px;
  --padding-9xl: 28px;
  --padding-23xl: 42px;
  --padding-181xl: 200px;
  --padding-4xs: 9px;
  --padding-28xl: 47px;
  --padding-mini: 15px;
  --padding-smi: 13px;
  --padding-61xl: 80px;
  --padding-84xl: 103px;
  --padding-4xs-2: 8.2px;
  --padding-55xl: 74px;
  --padding-42xl: 61px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-7xs: 6px;
  --br-3xs: 10px;
  --br-9xs: 4px;
  --br-xs: 12px;
  --br-3xs-4: 9.4px;
  --br-4xs: 9px;
  --br-3xs-5: 9.5px;
}

.profileeditbodyheader {
  width: 1446px;
  height: 1px;
  overflow: hidden;
  flex-shrink: 0;
}
.editprofileformboxtext {
  position: relative;
  line-height: 150%;
  display: inline-block;
  width: 373px;
  flex-shrink: 0;
}
.editprofileformboxinputbox {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--gray-3);
  box-sizing: border-box;
  width: 373px;
  height: 42px;
  overflow: hidden;
  flex-shrink: 0;
}
.editprofileformbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.editprofileform,
.editprofileformboxinputbox3 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
}
.editprofileform {
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-34xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.editprofileformboxinputbox3 {
  position: relative;
  background-color: var(--white);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  width: 373px;
  height: 42px;
  overflow: hidden;
  flex-shrink: 0;
}
.profileeditbodybody,
.scholarship-questions-2,
.student-activities-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.scholarship-questions-2 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  padding: var(--padding-17xl) var(--padding-13xl);
  gap: var(--gap-lg);
}
.profileeditbodybody,
.student-activities-profile {
  overflow: hidden;
}
.student-activities-profile {
  background-color: var(--white);
  height: 989px;
  gap: var(--gap-11xs);
  text-align: left;
  font-size: var(--p1-regular-16-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
}
@media screen and (max-width: 1200px) {
  .editprofileformboxtext {
    width: 200px;
  }
  .editprofileformboxinputbox {
    width: 253px;
  }
  .editprofileformbox {
    gap: var(--gap-111xl);
  }
  .scholarship-questions-2 {
    width: 700px;
  }
}
@media screen and (max-width: 850px) {
  .editprofileformboxtext {
    font-size: var(--font-size-sm);
    width: 173px;
  }
  .editprofileformboxinputbox {
    width: 343px;
  }
  .editprofileformbox {
    flex-direction: column;
    gap: var(--gap-8xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .editprofileform {
    padding-top: var(--padding-3xs);
    box-sizing: border-box;
  }
  .scholarship-questions-2 {
    width: 500px;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
    border: 1px solid var(--color-black);
  }
}
@media screen and (max-width: 520px) {
  .editprofileformboxinputbox {
    width: 273px;
  }
  .editprofileformbox {
    gap: var(--gap-8xs);
  }
  .scholarship-questions-2 {
    width: 420px;
  }
}
