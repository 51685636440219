.sidebar {
    width: 300px;
    height: 110vh;
    background: #fbfbfb;
    left: 0;
    padding-top: 20px;
  }
  
  .sidebar nav .other-one,
  .sidebar nav .first-one,
  .sidebar nav .last-one {
    display: flex;
    color: black;
    align-items: center;
    /* padding: 15px 20px; */
    text-decoration: none;
    font-size: 90%;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  }
  
  .sidebar nav .first-one {
    margin-top: 16%;
    margin-bottom: 5%;
    padding-top: 5px;
  }
  
  .sidebar nav .other-one {
    margin-top: 4%;
    padding: 15px 20px;
  }
  
  .sidebar nav .last-one {
    margin-top: 80%;
    padding: 15px 20px;
  }
  
  .sidebar nav a {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16.3122px;
  }
  
  .sidebar nav a img,
  .sidebar nav a i {
    margin-left: 10%;
    margin-right: 16%;
    font-size: 18px;
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000;
    transition: color 0.3s;
  }
  
  .sidebar nav a:hover {
    background-color: #575757;
    color: #fff;
  }
  
  .sidebar nav a:hover img,
  .sidebar nav a i {
    color: #fff;
  }
  
  .sidebar nav a.active {
    color: #4a93cf;
    border: 1px solid black;
    background-color: rgba(0, 123, 255, 0.1);
  }
  
  .sidebar nav a.active img,
  .sidebar nav a i {
    color: #4a93cf;
  }