.headerlogo-icon10 {
    position: relative;
    width: 134px;
    height: 41px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .nav-link-default40 {
    position: relative;
    font-weight: 500;
  }
  .headernav-link50 {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .headernav-link51 {
    color: var(--logo-color-1);
  }
  .headernav-link51,
  .headernav-link52,
  .left-side-top-nav10,
  .top-nav-left10 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .left-side-top-nav10,
  .top-nav-left10 {
    gap: var(--gap-13xl);
  }
  .left-side-top-nav10 {
    position: absolute;
    top: calc(50% - 20px);
    left: 0;
    width: 602px;
    padding: 0 0 0 var(--padding-13xl);
    box-sizing: border-box;
    align-items: flex-end;
  }
  .vector-icon74,
  .vector-icon75 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .vector-icon75 {
    height: 50%;
    width: 75%;
    top: 25%;
    right: 12.5%;
    bottom: 25%;
    left: 12.5%;
  }
  .menu10 {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    width: 44px;
    height: 47px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .messages-icon9,
  .notifications-icon9 {
    position: absolute;
    top: 8px;
    left: -3px;
    width: 28.3px;
    height: 28px;
  }
  .notifications-icon9 {
    left: 53px;
    width: 22.74px;
  }
  .mask-group-icon9 {
    position: relative;
    width: 44px;
    height: 44px;
    object-fit: cover;
  }
  .vector-icon76 {
    position: relative;
    width: 10px;
    height: 6px;
  }
  .mask-group-parent7 {
    position: absolute;
    top: 0;
    left: 106px;
    width: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-6xs);
  }
  .messages-parent7 {
    position: relative;
    width: 152px;
    height: 44px;
  }
  .right-side-top-nav10 {
    position: absolute;
    top: calc(50% - 22px);
    right: 0;
    width: 261.91px;
    display: flex;
    flex-direction: row;
    padding: 0 var(--padding-13xl) 0 0;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-end;
    gap: var(--gap-13xl);
  }
  .info9 {
    align-self: stretch;
    position: relative;
    height: 44px;
  }
  .scholarships-line-icon9 {
    position: relative;
    width: 89.5px;
    height: 0;
    display: none;
  }
  .community-line-icon9,
  .resources-icon9,
  .services-line-icon9 {
    position: relative;
    width: 59.5px;
    height: 0;
    display: none;
  }
  .community-line-icon9,
  .resources-icon9 {
    width: 83px;
  }
  .resources-icon9 {
    width: 73px;
  }
  .navbar9 {
    align-self: stretch;
    background-color: var(--gray-1);
    border-bottom: 1px solid var(--gray-3);
    box-sizing: border-box;
    height: 79px;
    display: flex;
    flex-direction: column;
    padding: var(--padding-mid) 0;
    align-items: center;
    justify-content: center;
    gap: var(--gap-3xs);
  }
  .popular-services {
    position: relative;
    margin-bottom: 30px;
  }
  .allpage-header7 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xl) var(--padding-144xl) 0 151px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .scholarshipspopular-services-icon {
    position: relative;
    width: 197px;
    height: 196px;
    object-fit: cover;
  }
  .scholarship-services {
    position: relative;
    font-weight: 600;
    gap: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
   
    padding: 8px;
    
    background-color: #ffffff;
    
    width: 100px
  }
  .vector-icon77 {
    position: relative;
    width: 9.58px;
    height: 17px;
  }
 
  .scholarship-services-parent {
    flex-direction: row;
    align-items: center;
    gap: var(--gap-5xs);
  }
 
  .cardscholarshipspopular-serv,
  .cardscholarshipspopular-serv1,
  .essay-review-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  .cardscholarshipspopular-serv1,
  .essay-review-parent {
    flex-direction: row;
    gap: var(--gap-5xs);
  }
  .cardscholarshipspopular-serv1 {
    flex-direction: column;
    gap: var(--gap-base);
  }
  .scholarshipspopular-services-icon2 {
    position: relative;
    width: 197px;
    height: 196px;
    object-fit: cover;
  }
  .tag2,
  .tag3 {
    border-radius: var(--br-11xs);
    box-shadow: var(--shadow-1);
    border: 1.5px solid var(--gray-2);
    display: flex;
    flex-direction: column;
    padding: var(--padding-5xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .tag3 {
    border-radius: var(--br-5xs);
  }
  .scholarshipspopular-services-icon4 {
    position: relative;
    width: 194px;
    height: 196px;
    object-fit: cover;
  }
  .body10 {
    width: 1110.58px;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-17xl);
  }
  .search7,
  .serice-types {
    overflow: hidden;
    display: none;
    flex-direction: column;
    align-items: center;
  }
  .serice-types {
    align-self: stretch;
    padding: 0 var(--padding-138xl);
    justify-content: center;
    font-size: var(--p1-regular-16-size);
  }
  .search7 {
    padding: 0 var(--padding-10xs);
    justify-content: flex-start;
    gap: var(--gap-smi);
    font-size: var(--font-size-23xl);
  }
  .tune-icon5 {
    position: relative;
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
  .filters5 {
    position: relative;
    font-size: var(--p1-regular-16-size);
    font-weight: 500;
    font-family: var(--p1-regular-16);
    color: var(--color-black);
    text-align: center;
  }
  .frame-button,
  .tune-parent3 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .tune-parent3 {
    justify-content: flex-start;
    gap: var(--gap-3xs);
  }
  .frame-button {
    cursor: pointer;
    border: 0;
    padding: var(--padding-5xs) 0;
    background-color: var(--color-ghostwhite);
    width: 403.5px;
    overflow: hidden;
    flex-shrink: 0;
    box-sizing: border-box;
    justify-content: center;
  }
  .vector-icon82 {
    position: relative;
    width: 30px;
    height: 30px;
  }
  .sort5 {
    position: relative;
    font-weight: 500;
  }
  .frame-wrapper60 {
    background-color: var(--color-ghostwhite);
    width: 403.5px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs) 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border: none;
    position: relative;
  }
  .frame-parent10,
  .services-inner {
    align-self: stretch;
    align-items: center;
    justify-content: center;
  }
  .frame-parent10 {
    display: flex;
    flex-direction: row;
    padding: 0 var(--padding-281xl);
    gap: var(--gap-14xl);
  }
  .services-inner {
    overflow: hidden;
    display: none;
    flex-direction: column;
    padding: var(--padding-mid) 0;
    text-align: center;
    font-size: var(--p1-regular-16-size);
    color: var(--color-black);
  }
  .all-coaches {
    position: relative;
  }
  .allpage-header8 {
    

    width: 1107px;
    height: 84px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-mid) var(--padding-144xl) var(--padding-mid) 0;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
  }

  
  .service-options1 {
    position: relative;
    font-size: var(--p2-regular-12-size);
    letter-spacing: 0.08em;
    line-height: 150%;
    font-weight: 500;
    font-family: var(--p1-regular-16);
    color: var(--color-black);
    text-align: center;
    width: 146px;
    flex-shrink: 0;
  }
  .button-text72,
  .service-options-wrapper,
  .service-options1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .service-options-wrapper {
    cursor: pointer;
    border: 1.5px solid var(--color-black);
    padding: 0 var(--padding-3xs);
    background-color: transparent;
    border-radius: var(--br-base);
    box-sizing: border-box;
    height: 27px;
    flex-direction: row;
  }
  .button-text72 {
    position: relative;
    letter-spacing: 0.08em;
    line-height: 150%;
    font-weight: 500;
    width: 97px;
    flex-shrink: 0;
  }
  .frame-parent13,
  .tagfilter-sort1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .tagfilter-sort1 {
    border-radius: var(--br-base);
    box-shadow: var(--shadow-1);
    border: 1.5px solid var(--logo-black);
    padding: var(--padding-10xs) var(--padding-base);
  }
  .frame-parent13 {
    gap: var(--gap-mini);
  }
  .budget-wrapper {
    border-radius: var(--br-base);
    border: 1.5px solid var(--color-black);
    box-sizing: border-box;
    height: 27px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 0 var(--padding-4xl);
    align-items: center;
    justify-content: center;
  }
  .availability {
    position: relative;
    letter-spacing: 0.08em;
    line-height: 150%;
    font-weight: 500;
  }
  .frame-parent12,
  .frame-parent14 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--gap-mini);
  }
  .frame-parent14 {
    align-items: flex-start;
    color: var(--color-black);
  }
  .frame-parent12 {
    width: 574px;
    padding: 0 var(--padding-sm);
    box-sizing: border-box;
    align-items: flex-end;
  }
  .frame-parent11,
  .newest-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .newest-parent {
    border-radius: var(--br-base);
    border: 1.5px solid var(--color-black);
    box-sizing: border-box;
    height: 27px;
    overflow: hidden;
    flex-shrink: 0;
    padding: 0 var(--padding-8xl);
    justify-content: center;
    gap: var(--gap-3xs);
  }
  .frame-parent11 {
    /* overflow-x: auto;
    padding: var(--padding-9xs) 0;
    justify-content: flex-start;
    gap: 414px; */


    overflow-x: auto;
    padding: var(--padding-9xs) 0;
    justify-content: flex-start;
    gap: 414px;
  }
  .allpage-header-group,
  .frame-wrapper61 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .frame-wrapper61 {
    align-self: stretch;
    overflow: hidden;
    padding: 0 0 var(--padding-18xl);
    justify-content: center;
    text-align: center;
    font-size: var(--p2-regular-12-size);
  }
  .allpage-header-group {
    justify-content: flex-start;
    gap: var(--gap-12xs);
    font-size: var(--font-size-23xl);
  }
  .cardcoachprofile-imgsmall-icon {
    position: relative;
    width: 121px;
    height: 119px;
    object-fit: cover;
  }
  .button38 {
    position: absolute;
    height: 109.38%;
    width: 103.13%;
    top: -4.69%;
    right: -1.56%;
    bottom: -4.69%;
    left: -1.56%;
    border-radius: var(--br-5xs);
    border: 1.5px solid var(--logo-black);
    box-sizing: border-box;
  }
  .button-text73 {
    position: absolute;
    height: 95%;
    width: 100%;
    top: 2.5%;
    left: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-seemore {
    position: absolute;
    height: 109.38%;
    width: 101.84%;
    top: -4.69%;
    right: -0.92%;
    bottom: -4.69%;
    left: -0.92%;
    border-radius: var(--br-5xs);
    border: 1.5px solid var(--logo-black);
    box-sizing: border-box;
    background-color: #ffffff;
  }

  

.button-seemore:active {
  background: #ffffff;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
}
  
  .buttonsmall18 {
    position: absolute;
    width: 37.21%;
    right: 53.49%;
    bottom: 18px;
    left: 9.3%;
    height: 32px;
    cursor: pointer;
  }
  .button39 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-5xs);
    background-color: var(--logo-black);
  }
  .buttonsmall19 {
    position: absolute;
    width: 37.21%;
    right: 10.08%;
    bottom: 18px;
    left: 52.71%;
    height: 32px;
    color: var(--gray-1);
  }
  .community-title15 {
    align-self: stretch;
    position: relative;
    font-weight: 500;
  }
  .members16,
  .stars-icon3 {
    position: relative;
    width: 39px;
    height: 8px;
  }
  .members16 {
    letter-spacing: 0.08em;
    line-height: 150%;
    font-weight: 500;
    display: inline-block;
    width: 191px;
    height: 19px;
    flex-shrink: 0;
  }
  .coach-review-stars1 {
    width: 199px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    font-size: var(--p2-regular-12-size);
    color: var(--gray-4);
  }
  .description16 {
    align-self: stretch;
    position: relative;
    font-size: var(--font-inherit);
    letter-spacing: 0.08em;
    line-height: 150%;
    font-weight: 500;
    font-family: var(--font-inherit);
    display: inline-block;
    height: 50px;
    flex-shrink: 0;
  }
  .cardcoachdescription,
  .cardcoachdescriptionsmall {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .cardcoachdescription {
    width: 331.71px;
    overflow: hidden;
    padding: 0 var(--padding-5xs) var(--padding-5xs);
    box-sizing: border-box;
    justify-content: center;
    gap: var(--gap-11xs);
  }
  .cardcoachdescriptionsmall {
    position: absolute;
    width: 64.34%;
    right: 26.36%;
    bottom: 59px;
    left: 9.3%;
    height: 85px;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--p1-regular-16-size);
  }
  .cardicon-bookmark9 {
    position: absolute;
    top: 8px;
    right: 26px;
    width: 17px;
    height: 23px;
  }
  .card40 {
    position: relative;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-1);
    border: 1px solid var(--logo-black);
    box-sizing: border-box;
    width: 258px;
    height: 152px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .cardcoachsmall {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .button-text75 {
    position: absolute;
    height: 95%;
    width: 100%;
    top: 2.5%;
    left: 0;
    font-size: var(--h5-btns-med-14-size);
    font-weight: 500;
    font-family: var(--p1-regular-16);
    color: var(--logo-black);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .buttonsmall20 {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
  }
  .buttonsmall20,
  .buttonsmall21 {
    position: relative;
    width: 96px;
    height: 32px;

 
    top: -4.69%;
    right: -1.56%;
    bottom: -4.69%;
    left: -1.56%;
    border-radius: var(--br-5xs);
    border: 1.5px solid var(--logo-black);
    box-sizing: border-box;
  }

  

.buttonsmall20:active {
  background: #ffffff;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
}
  .buttonsmall-parent5 {
    position: absolute;
    top: 102px;
    left: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  .card-child {
    position: absolute;
    top: -2px;
    left: 205px;
  }
  .stars-icon4 {
    position: relative;
    width: 49px;
    height: 8px;
  }
  .essay-reviews {
    margin-bottom: 0;
    font-size: 12px;
    height: 14px;
  }
  .essay-reviews-scholarship-cons {
    margin: 0;
    padding-left: var(--padding-base);
  }
  .cardcoachdescription1,
  .cardcoachdescriptionsmall1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .cardcoachdescription1 {
    width: 221px;
    overflow: hidden;
    /* padding: 0 var(--padding-5xs) var(--padding-5xs); */
    padding-top: 20px;
    box-sizing: border-box;
    justify-content: center;
    gap: var(--gap-11xs);
  }
  .cardcoachdescriptionsmall1 {
    position: absolute;
    width: 64.34%;
    right: 26.36%;
    bottom: 59px;
    left: 9.3%;
    height: 85px;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--p1-regular-16-size);
    color: var(--logo-black);
  }
  .cardicon-bookmark10 {
    position: absolute;
    top: 8px;
    left: 215px;
    width: 17px;
    height: 23px;
  }
  .cardcoachsmall1 {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    color: var(--gray-1);
  }
  .cardcoachprofile-imgsmall-icon2 {
    position: relative;
    width: 116.8px;
    height: 117.4px;
    object-fit: cover;
  }
  .buttonsmall22 {
    position: absolute;
    width: 37.21%;
    right: 53.49%;
    bottom: 18px;
    left: 9.3%;
    height: 32px;
  }
  .body11,
  .serice-types1 {
    align-self: stretch;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .body11 {
    flex-direction: row;
    padding: 0 var(--padding-137xl);
    gap: var(--gap-17xl);
  }
  .serice-types1 {
    flex-direction: column;
    padding: var(--padding-20xl) 0;
    text-align: center;
  }
  .div58 {
  
    top: 0;
    left: 60px;
    font-weight: 500;
  }
  .button46 {
    position: absolute;
    height: 109.38%;
    width: 101.84%;
    top: -4.69%;
    right: -0.92%;
    bottom: -4.69%;
    left: -0.92%;
    border-radius: var(--br-5xs);
    border: 1.5px solid var(--logo-black);
    box-sizing: border-box;
  }
  .buttonlarge30 {
    position: absolute;
    top: 56px;
    left: 0;
    width: 163px;
    height: 32px;
    color: var(--logo-black);
  }
  .progress-bar-icon6 {
    position: absolute;
    top: 28px;
    left: 2px;
    width: 158.5px;
    height: 2px;
  }
  .see-more-progress-bar11 {
    position: relative;
    width: 163px;
    height: 88px;
    align-items: center;
    justify-content: center;
  }

  .progress-body {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .see-more-progress-bar-container {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--gray-4);

    
  }

  .seemore-progress-body{
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .footerlogo-icon10 {
    position: relative;
    width: 148px;
    height: 45px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .nav-link30 {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 150%;
  }
  .footerlinknav30 { 
    position: relative;
    width: 160px;
    height: 19px;
  }
  .footer-links50 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .footericoninstagram10 {
    position: relative;
    width: 20px;
    height: 20px;
  }
  .footericonfacbook10 {
    position: relative;
    width: 18.75px;
    height: 18.75px;
  }
  .footericontwitter10 {
    position: relative;
    width: 19.5px;
    height: 15.84px;
  }
  .footericonyoutube10 {
    position: relative;
    width: 21px;
    height: 14.72px;
  }
  .icon-wrapper10 {
    display: flex;
    flex-direction: row;
    padding: 0 var(--padding-5xs);
    align-items: center;
    justify-content: center;
    gap: var(--gap-xs);
  }
  .footer-menu50 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-11xl);
  }
  .students10 {
    position: relative;
    font-weight: 500;
    display: inline-block;
    width: 160px;
  }
  .footer-link130 {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    line-height: 150%;
    display: inline-block;
  }
  .main-footer-link130 {
    position: relative;
    width: 160px;
    height: 24px;
  }
  .footer-menu51 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  .footer-link142 {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    line-height: 150%;
    text-indent: 1px;
    display: inline-block;
  }
  .main-footer-link142 {
    position: relative;
    width: 160px;
    height: 71px;
    font-size: var(--p2-regular-12-size);
  }
  .text-box9 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-7xs);
    background-color: var(--white);
    box-shadow: var(--shadow-1);
  }
  .input-placeholder9 {
    position: absolute;
    top: 4px;
    left: 10px;
    font-weight: 500;
    display: inline-block;
    width: 181px;
    height: 25px;
  }
  .subscribe-email-text-box10 {
    position: relative;
    width: 198px;
    height: 28px;
    color: var(--gray-4);
  }
  .button47 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-5xs);
    background-color: var(--purple-blue);
  }
  .buttonlarge31 {
    position: relative;
    width: 198px;
    height: 32px;
    text-align: center;
  }
  .footer-grid-row10,
  .footer-links54,
  .footer-menu-parent8 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .footer-links54 {
    flex-direction: column;
    gap: var(--gap-5xs);
    font-size: var(--h5-btns-med-14-size);
  }
  .footer-grid-row10,
  .footer-menu-parent8 {
    flex-direction: row;
    gap: var(--gap-69xl);
  }
  .footer-grid-row10 {
    justify-content: center;
  }
  .scholist-all-rights10 {
    position: relative;
    line-height: 150%;
  }
  .footer-legal10 {
    width: 827px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-365xl);
  }
  .footer10,
  .services1 {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .footer10 {
    align-self: stretch;
    background-color: var(--gray-4);
    padding: var(--padding-63xl) var(--padding-105xl) var(--padding-20xl);
    align-items: flex-start;
    gap: var(--gap-62xl);
    font-size: var(--p1-regular-16-size);
    color: var(--gray-1);
  }
  .services1 {
    position: relative;
    background-color: var(--white);
    width: 100%;
    align-items: center;
    text-align: left;
    font-size: var(--h5-btns-med-14-size);
    color: var(--logo-black);
    font-family: var(--p1-regular-16);
  }
  @media screen and (max-width: 1200px) {
    .menu10 {
      display: none;
    }
    .popular-services {
      font-size: var(--font-size-16xl);
    }
    .allpage-header7 {
      width: 1200px;
      height: 80px;
      padding-left: var(--padding-231xl);
      padding-top: var(--padding-11xl);
      padding-right: 0;
      box-sizing: border-box;
    }
    .scholarshipspopular-services-icon {
      width: 170px;
      height: 171px;
    }
    .scholarship-services {
      font-size: var(--h5-btns-med-14-size);
    }
    .scholarshipspopular-services-icon2,
    .scholarshipspopular-services-icon4 {
      width: 170px;
      height: 171px;
    }
    .body10 {
      width: 700px;
      gap: var(--gap-xl);
    }
    .search7,
    .serice-types {
      padding-left: 0;
      box-sizing: border-box;
    }
    .serice-types {
      padding-right: 0;
      flex-wrap: wrap;
    }
    .search7 {
      padding-right: var(--padding-81xl);
    }
    .all-coaches {
      font-size: var(--font-size-16xl);
    }
    .allpage-header8 {
      width: 1200px;
      height: 80px;
      padding-left: var(--padding-181xl);
      padding-top: var(--padding-11xl);
      padding-right: 0;
      box-sizing: border-box;


     
      
      
    }
    .frame-parent11 {
      /* align-self: stretch;
      width: auto;
      gap: var(--gap-xl);
      align-items: center;
      justify-content: center; */

      align-self: stretch;
      width: auto;
      gap: 135px;
      align-items: center;
      justify-content: center;
    }
    .frame-wrapper61 {
      align-items: flex-start;
      justify-content: center;
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
    .body11 {
      gap: var(--gap-xl);
      flex-wrap: wrap;
    }
    .serice-types1 {
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
      flex-wrap: wrap;
    }
    .footer-menu-parent8 {
      flex-wrap: wrap;
    }
    .footer-grid-row10 {
      flex-direction: column;
      gap: var(--gap-31xl);
    }
  }
  @media screen and (max-width: 850px) {
    .top-nav-left10 {
      display: none;
    }
    .menu10 {
      display: flex;
    }
    .messages-parent7 {
      display: none;
    }
    .popular-services {
      font-size: var(--font-size-11xl);
      margin-bottom: 15px;
     
    }
    .allpage-header7 {
      width: 800px;
      height: 60px;
      padding-left: var(--padding-131xl);
      padding-top: var(--padding-11xl);
      padding-bottom: var(--padding-11xl);
      box-sizing: border-box;
    }
    .scholarshipspopular-services-icon {
      width: 140px;
      height: 141px;
    }
    .scholarship-services {
      font-size: var(--font-size-3xs);
    }
    .vector-icon77 {
      width: 7px;
      height: 15px;
    }
    .scholarship-services-parent {
      gap: 9px;
    }
    .scholarshipspopular-services-icon2 {
      width: 147px;
      height: 141px;
    }
    .scholarshipspopular-services-icon4 {
      width: 144px;
      height: 141px;
    }
    .body10 {
      width: 500px;
      gap: var(--gap-xl);
    }
    .serice-types {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0;
    }
    .frame-button,
    .search7,
    .serice-types {
      padding-right: 0;
      box-sizing: border-box;
    }
    .search7 {
      padding-top: 0;
    }
    .frame-button {
      width: 240px;
      padding-left: 0;
    }
    .frame-wrapper60 {
      width: 240px;
    }
    .frame-parent10 {
      gap: var(--gap-mini);
      padding-left: var(--padding-141xl);
      padding-right: var(--padding-141xl);
      box-sizing: border-box;
    }
    .services-inner {
      display: flex;
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
    .all-coaches {
      font-size: var(--h3-medium-20-size);
    }
    .allpage-header8 {
      width: 800px;
      height: 60px;
      align-items: center;
      justify-content: center;
      padding-left: 0;
      padding-top: var(--padding-11xl);
      padding-bottom: var(--padding-11xl);
      box-sizing: border-box;
    }
    .service-options1 {
      font-size: var(--font-size-4xs);
    }
    .service-options-wrapper {
      padding-left: var(--padding-mini);
      padding-right: var(--padding-mini);
      box-sizing: border-box;
    }
    .availability,
    .button-text72 {
      font-size: var(--font-size-3xs);
    }
    .frame-parent12 {
      width: 550px;
      align-items: center;
      justify-content: center;
      padding-left: var(--padding-xl);
    }
    .frame-parent11,
    .frame-parent12,
    .frame-wrapper61 {
      padding-right: 0;
      box-sizing: border-box;
    }
    .frame-parent11 {
      width: 500px;
      gap: var(--gap-11xl);
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 0;
    }
    .frame-wrapper61 {
      display: none;
      padding-left: var(--padding-131xl);
    }
    .body11,
    .frame-wrapper61,
    .serice-types1 {
      align-items: center;
      justify-content: center;
    }
    .body11 {
      gap: var(--gap-xl);
      padding-left: var(--padding-31xl);
      padding-right: var(--padding-31xl);
      box-sizing: border-box;
    }
    .serice-types1 {
      display: flex;
      padding-right: 0;
    }
    .serice-types1,
    .services1 {
      padding-left: 0;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 520px) {
    .popular-services {
      font-size: var(--font-size-3xl);
    }
    .allpage-header7 {
      width: 500px;
      padding-left: var(--padding-51xl);
      box-sizing: border-box;
    }
    .cardscholarshipspopular-serv {
      align-items: flex-start;
      justify-content: flex-start;
    }
    .body10 {
      width: 350px;
    }
    .serice-types {
      padding-left: 0;
      padding-top: var(--padding-3xs);
      padding-right: var(--padding-smi);
      box-sizing: border-box;
      flex-wrap: nowrap;
      justify-content: center;
    }
    .tune-icon5 {
      width: 20px;
      height: 20px;
    }
    .filters5 {
      font-size: var(--p2-regular-12-size);
    }
    .frame-button {
      width: 180px;
    }
    .vector-icon82 {
      width: 20px;
      height: 20px;
    }
    .sort5 {
      font-size: var(--p2-regular-12-size);
    }
    .frame-wrapper60 {
      width: 170px;
    }
    .frame-parent10 {
      padding-left: var(--padding-11xl);
      padding-right: var(--padding-11xl);
      box-sizing: border-box;
    }
    .all-coaches {
      font-size: var(--font-size-3xl);
    }
    .allpage-header8 {
      width: 500px;
      padding-left: 0;
      box-sizing: border-box;
    }
    .service-options-wrapper {
      width: 147px;
    }
    .frame-parent12 {
      flex-direction: row;
      padding-left: 0;
      box-sizing: border-box;
    }
    .frame-parent11 {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .frame-parent11,
    .frame-wrapper61,
    .serice-types1 {
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
    .serice-types1 {
      padding-top: var(--padding-3xs);
      flex-wrap: nowrap;
      justify-content: center;
    }
    .services1 {
      align-items: center;
      justify-content: center;
    }
  }
  
  
  
  /* .service-options {
    position: relative;
    border-radius: var(--br-5xs);
    background-color: var(--color-white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 378px;
    height: 260px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-3xs);
    color: var(--color-black);
    font-family: var(--font-poppins);
  }
   */
  /* .service-options-inner {
    position: absolute;
    top: 128px;
    left: 1px;
    border: 1px solid var(--color-whitesmoke);
    box-sizing: border-box;
    width: 375px;
    height: 35px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 30 0 var(--padding-9xs) 30px;
    align-items: center;
    justify-content: center;
  } */
  
  .clear-all1 {
    position: relative;
    line-height: 22px;
  }
  .clear-all-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-210xl);
  }
  .service-options-inner {
   
    /* left: 1px;
    border: 1px solid var(--color-whitesmoke-100);
    box-sizing: border-box;
    width: 375px;
    height: 35px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
   // padding: 0 0 var(--padding-9xs) var(--padding-8xs);
    align-items: center;
    justify-content: center; */
  }
  .check-box-outline-blank13,
  .essay-review2 {
    position: relative;
    font-size: 12px;
  }
  .essay-review2 {
    letter-spacing: 0.15px;
    line-height: 150%;
  }
  .check-box-outline-blank-parent11 {
    width: 183px;
  }
  .check-box-outline-blank-parent11,
  .check-box-outline-blank-parent12,
  .check-box-outline-blank-parent13,
  .frame-parent6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .check-box-outline-blank-parent13,
  .frame-parent6 {
    align-items: flex-start;
    
  }
  .check-box-outline-blank-parent13 {
    width: 191px;
    align-items: center;
    gap: var(--gap-6xs);
  }
  .frame-parent7 {
   
  }
  .frame-parent5,
  .frame-parent7,
  .frame-wrapper15,
  .service-options-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-parent5,
  .service-options-group {
    flex-direction: column;
    gap: var(--gap-10xs);
    font-size: var(--font-size-3xs);
  }
  .service-options-group {
    position: absolute;
    top: 14px;
    left: 21px;
    gap: var(--gap-4xs);
    font-size: var(--caption-2-medium-size);
  }
  /* .service-options2 {
    position: relative;
    border-radius: var(--br-5xs);
    background-color: var(--color-light-white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 378px;
    height: 163px;
    overflow: hidden;
    flex-shrink: 0;
    font-size: var(--font-size-3xs);
    color: var(--color-black);
  } */
  
  
  
  
  
  
  
  
  min1 {
    position: relative;
    line-height: 14px;
    font-weight: 500;
  }
  .frame-inner {
    background-color: transparent;
    position: relative;
  }
  .max-group,
  .min-group {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .max-group {
    left: 91px;
  }
  .frame-parent19 {
    /* position: absolute; */
    top: 26px;
    left: 26px;
    width: 172px;
    height: 40px;
  }
  .clear-all9 {
    position: absolute;
    top: 0.5px;
    left: 11.5px;
    line-height: 22px;
  }
  .buttoncontained-text6 {
    position: absolute;
    top: -0.5px;
    left: 137.5px;
  }
  .budget-inner,
  .clear-all-parent7 {
    position: absolute;
    top: 4px;
    left: 0;
    width: 218px;
    height: 24px;
  }
  .budget-inner {
    top: 110px;
    border: 1px solid var(--color-whitesmoke-100);
    box-sizing: border-box;
    width: 220px;
    height: 35px;
    overflow: hidden;
    text-align: left;
  }
  .budget2 {
   
    border-radius: var(--br-5xs);
    background-color: var(--color-light-white);
    
    width: 230px;
    height: 145px;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-3xs);
    color: var(--color-black);
    font-family: var(--p1-regular-16);
    justify-content: center;
    
  }
  
  
  
  
  
  
  .serice-types1 {
    align-self: stretch;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .seemorebody{
    align-items: center;
    justify-content: center;
  }

  .coachContainer{
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    align-items: center;
    justify-content: center;
  }


  .coachesContainer{
/*     display: flex;
max-width: 1440px;
padding: 0px 38px;
padding-bottom: 30px;
justify-content: center;
align-items: center;
align-content: flex-start;
gap: 10px;
align-self: stretch;
flex-wrap: wrap;
 */


display: flex;
    max-width: 1440px;
    padding: 0px 14px;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    gap: 30px;
    align-self: stretch;
    flex-wrap: wrap;
    padding-bottom: 15px;
  }



  .newCoachCard{
    width: 255px;
height: 298px;
flex-shrink: 0;
border-radius: 12px;
border: 2px solid #C4C6C7;
background: #FFF;
align-items: center;
justify-content: center;
  }

  .imageiconbox{
    display: flex;
    width: 255px;
    align-items: center;
justify-content: center;
padding-top: 20px;
  }


  .newCoachNameBox{
    display: flex;
width: 224px;
height: 143px;
flex-direction: column;
align-items: center;
gap: 3px;
flex-shrink: 0;
margin: 16px;
  }


  .newCoachNameBox1{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 21px;
  }

  .newCoachNameBox2{
  display: flex;
flex-direction: column;
align-items: center;
gap: 15px;}



.newCoachNameBox3{
  display: flex;
flex-direction: column;
align-items: center;
gap: 3px;
height: 79px;
}

.newCoachNameBox4{
  display: flex;
justify-content: center;
align-items: flex-start;
gap: 3px;
height: 25px;
}

.nameBoxCoach{
  display: flex;
width: 186px;
align-items: center;
gap: -11px;
}
.nameBox1{
  color: #393939;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 24px */
letter-spacing: 1.28px;
width: 165px;
height: 25px;
flex-shrink: 0;

}

.serviceBox{
  display: flex;
width: 206px;
align-items: center;
gap: 5px;
}
.serviceBoxIcon{
  display: flex;
height: 24px;
align-items: flex-start;
gap: 6px;
}


.serviceBoxName{
  color: #393939;
font-feature-settings: 'clig' off, 'liga' off;

/* H6 - Medium 12px */
font-family: Poppins;
cursor: default;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 18px */
letter-spacing: 0.96px;
width: 180px;
height: 16px;
flex-shrink: 0;
}

.ratingBox{
  display: flex;
align-items: center;
gap: 24px;
/* smargin-top: 21px; */
}

.ratingBox1{
  display: flex;
width: 98px;
align-items: flex-start;
gap: 2px;
flex-shrink: 0;
}

.ratingBoxNumber{
  width: 22px;
height: 16px;
flex-shrink: 0;

color: #010101;
font-feature-settings: 'clig' off, 'liga' off;

/* H6 - Medium 12px */
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 18px */
letter-spacing: 0.96px;

}

.ratingBoxNumber1{
  color: #79797D;
font-feature-settings: 'clig' off, 'liga' off;

/* H6 - Medium 12px */
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 18px */
letter-spacing: 0.96px;

/* width: 191px; */
height: 19px;
flex-shrink: 0;
}


.seeprofilerating{
  border-radius: 6.49px;
border: 0.927px solid #3589CD;
background: #4A93CF;
display: flex;
width: 93px;
padding: 5.563px 7.417px;
justify-content: center;
align-items: center;
gap: -0.913px;
}
.seeprofilerating1{
  color: #FFF;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 12.98px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 19.47px */
}

