.more {
    position: absolute;
    top: 173px;
    left: 33px;
    font-size: 7px;
    letter-spacing: 0.15px;
    line-height: 150%;
    font-weight: 300;
    color: var(--color-dark-grey-v2);
  }
  .clear-all10 {
    position: relative;
    line-height: 22px;
  }
  .clear-all-parent8 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 98px;
  }
  .coach-details-inner {
   
    top: 198px;
    left: 1px;
    border: 1px solid var(--color-whitesmoke-100);
    box-sizing: border-box;
    width: 276px;
    height: 35px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0 var(--padding-12xs) var(--padding-9xs) 0;
    align-items: center;
    justify-content: center;
  }
  .coach-level {
    position: absolute;
    top: 14px;
    left: 21px;
    font-size: var(--caption-2-medium-size);
    line-height: 22px;
  }
  .check-box-outline-blank58,
  .top-rated-coach {
    position: relative;
  }
  .top-rated-coach {
    letter-spacing: 0.15px;
    line-height: 150%;
  }
  .check-box-outline-blank-parent55 {
    width: 141px;
  }
  .check-box-outline-blank-parent55,
  .check-box-outline-blank-parent56,
  .frame-parent17,
  .frame-parent18 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-6xs);
  }
  .frame-parent17,
  .frame-parent18 {
    align-items: flex-start;
    gap: var(--gap-11xs);
  }
  .frame-parent17 {
    
    top: 45px;
    left: 28px;
    flex-direction: column;
    gap: var(--gap-10xs);
  }
  .coach-speaks1 {
    position: absolute;
    top: 97px;
    left: 21px;
    font-size: var(--caption-2-medium-size);
    line-height: 22px;
  }
  .frame-parent20 {
   
    top: 128px;
    left: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-10xs);
  }
  .coach-details {
    position: relative;
    border-radius: var(--br-5xs);

  
   
    overflow: hidden;
    text-align: left;
    font-size: 30px;
    color: var(--color-black);
    font-family: var(--p1-regular-16);
  }
  