/* 

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  /* --h4-medium-16: Poppins;

  /* font sizes */
  /* --h4-medium-16-size: 16px;
  --font-size-smi-4: 12.4px;
  --h5-btns-med-14-size: 14px;
  --h3-medium-20-size: 20px;
  --h2-bold-32-size: 32px;
  --h6-medium-12-size: 12px;
  --font-size-smi: 13px;
  --font-size-2xs: 11px;
  --font-size-5xl: 24px;
  --font-size-lg: 18px;
  --font-size-lg-4: 18.4px; */

  /* Colors */
  /* --gray-5: #393939;
  --color-gray-100: #898989;
  --gray-4: #79797d;
  --logo-black: #010101;
  --teal: #40cfc3;
  --reviews: #deb00f;
  --gray-1: #f2f2f2;
  --purple-blue: #5558cf;
  --color-white: #fff;
  --color-darkgray-100: #959595;
  --logo-color-2: #4a93cf; */

  /* Gaps */
  /* --gap-smi-4: 12.4px;
  --gap-6xs-2: 6.2px;
  --gap-sm-1: 13.1px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-9xl: 28px;
  --gap-185xl: 204px;
  --gap-141xl: 160px;
  --gap-3xs: 10px;
  --gap-235xl: 254px;
  --gap-81xl: 100px;
  --gap-xl: 20px;
  --gap-3xs-2: 9.2px; */

  /* Paddings */
  /* --padding-8xs: 5px;
  --padding-3xs: 10px;
  --padding-10xs: 3px;
  --padding-base: 16px;
  --padding-98xl: 117px;
  --padding-lgi: 19px;

  /* border radiuses */
  /* --br-6xs-2: 6.2px;
  --br-5xs: 8px;
  --br-6xs: 7px;

  /* Effects */
  /* --shadow-1: 0px 1px 3px rgba(0, 0, 0, 0.25); */ 
/* }   */

/* .viewcoachbachheader {
  position: relative;
  font-weight: 500;
}
.viewcoachdetailsheader {
  width: 1437px;
  display: flex;
  flex-direction: row;
  padding: 0 0 0 32px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--h3-medium-20-size);
  color: var(--logo-color-2);
}
.coach-details1 {
  position: relative;
}
.cardimgsmallshadow-icon1 {
  position: relative;
  width: 254.05px;
  height: 243.05px;
  object-fit: cover;
}
.coachdetailsprofile-img1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.hailey-johnson {
  align-self: stretch;
  position: relative;
  font-size: 38px;
}
.scholarship-essay-expertise {
  align-self: stretch;
  position: relative;
  font-weight: 500;
  color: var(--purple-blue);
}
.members1,
.stars-icon3 {
  position: relative;
  width: 56.2px;
  height: 9.18px;
}
.members1 {
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
  display: inline-block;
  width: 219.06px;
  height: 21.79px;
  flex-shrink: 0;
}
.coach-review-stars {
  width: 228.23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 9.18px;
  font-size: var(--h6-medium-12-size);
  color: var(--gray-4);
}
.hoursworked-icon {
  position: relative;
  width: 22.94px;
  height: 22.94px;
}  */
/* .coach-details2,
.hoursworked-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 9.18px;
}
.coach-details2 {
  width: 326px;
  flex-direction: column;
  align-items: flex-start;
}
.envelopebadge-icon,
.heart-icon {
  position: relative;
  width: 36px;
  height: 25px;
}
.heart-icon {
  width: 25.13px;
  height: 23.45px;
}
.detailsname,
.envelopebadge-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.envelopebadge-parent {
  padding: 7px 0 0;
  gap: var(--gap-9xl);
}
.detailsname {
  width: 700.63px;
  gap: 288px;
}
.select-service-type {
  position: relative;
  font-size: var(--h4-medium-16-size);
  line-height: 20px;
  font-weight: 500;
  color: var(--color-darkgray-100);
  display: inline-block;
  width: 320px;
  height: 19.68px;
  flex-shrink: 0;
}
.button-text2,
.button2 {
  position: absolute;
  width: 100%;
  left: 0;
}
.button2 {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 7.54px;
  background-color: var(--logo-black);
}
.button-text2 {
  height: 95%;
  top: 2.5%;
  font-size: var(--h3-medium-20-size);
  display: flex;
  font-family: var(--h4-medium-16);
  color: var(--gray-1);
  text-align: center;
  align-items: center;
  justify-content: center;
}
.buttonlarge1 {
  position: relative;
  width: 197px;
  height: 50px;
}
.booknow {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.coach-details-box,
.detailsright {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.detailsright {
  width: 700.63px;
  flex-direction: column;
  padding: 65px 0 0;
  box-sizing: border-box;
  gap: 19px;
  font-size: 18.35px;
}
.coach-details-box {
  flex-direction: row;
  gap: 89px;
} */
/* .icround-plus {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.coachdetailsprofile {
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 24px 35px;
  align-items: center;
  justify-content: center;
}
.about-the-coach,
.bio,
.lorem-ipsum-dolor {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.about-the-coach {
  width: 1080px;
  height: 48px;
}
.bio,
.lorem-ipsum-dolor {
  font-weight: 600;
  width: 184px;
}
.lorem-ipsum-dolor {
  font-size: var(--h4-medium-16-size);
  font-weight: 500;
  color: var(--logo-black);
  width: 553px;
}
.biobox {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-185xl);
}
.university-of-prestige {
  position: relative;
  font-size: var(--h4-medium-16-size);
  font-weight: 500;
  color: var(--logo-black);
  display: flex;
  align-items: center;
  width: 553px;
  flex-shrink: 0;
}
.educationbox {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-185xl);
}
.bgcheckicon,
.cancelationicon {
  position: relative;
  width: 21px;
  height: 20px;
}
.bgcheckicon {
  width: 18px;
}
.background-check-last {
  position: relative;
  font-weight: 500;
}
.policiesbox1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--h4-medium-16-size);
  color: var(--logo-black);
}
.service-type {
  position: relative;
  font-weight: 600;

}
.servicetypetitle,
.servicetypetitle1 {
  width: 292px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.servicetypetitle {
  width: 292px;
  flex-direction: column;
}
.essay-review {
  position: relative;
}
.essay-review-wrapper,
.innerrightservicetitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.innerrightservicetitle {
  width: 319px;
  flex-direction: column;
  align-items: flex-start;
}
.b {
  position: relative;
  color: var(--logo-color-2);
}
.innerrightservicetype2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-235xl);
}
.hr-mock-interview {
  position: relative;
  font-size: var(--h4-medium-16-size);
  line-height: 20px;
  font-weight: 500;
  color: var(--color-darkgray-100);
  display: inline-block;
  width: 619px;
  height: 36px;
  flex-shrink: 0;
}
.rightservervicetype1 {
  border-radius: var(--br-6xs);
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  padding: var(--padding-base) var(--padding-lgi);
  gap: var(--gap-3xs);
}
.rightservervicetype1,
.rightservicetype,
.servicetypecoachbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.rightservicetype {
  flex-direction: column;
  gap: var(--gap-base);
  font-size: var(--font-size-5xl);
  color: var(--logo-black);
}
.servicetypecoachbox {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-185xl);
  color: #a7a7a7;
}
.detailsbogbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 114px;
  font-size: var(--h3-medium-20-size);
  color: var(--color-gray-100);
  padding-left: 76px;
} */
/* .page-divider {
  position: relative;
  border-top: 1px solid var(--logo-black);
  box-sizing: border-box;
  width: 1121px;
  height: 1px;
}
.write-a-review1 {
  position: relative;
  font-weight: 600;
}
.have-you-worked1,
.let-us-know1 {
  position: relative;
  font-size: var(--h3-medium-20-size);
  font-weight: 500;
}
.let-us-know1 {
  font-size: var(--h5-btns-med-14-size);
  color: var(--gray-5);
}
.write-review-text1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.button-text3,
.button3 {
  position: absolute;
  width: 100%;
  left: 0;
}
.button3 {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-5xs);
  background-color: var(--purple-blue);
}
.button-text3 {
  height: 95%;
  top: 2.5%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonlarge2 {
  position: relative;
  background-color: var(--purple-blue);
  width: 198px;
  height: 32px;
  text-align: center;
  font-size: var(--h5-btns-med-14-size);
  color: var(--gray-1);
}
.reviewcoachuser-prompt-wrapper,
.reviewcoachuser-prompt1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.reviewcoachuser-prompt1 {
  gap: var(--gap-base);
}
.reviewcoachuser-prompt-wrapper {
  width: 360px;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.stars-icon4 {
  position: relative;
  width: 94.69px;
  height: 15.46px;
}
.ratings2 {
  position: relative;
  font-weight: 500;
  width: 238.85px;
  flex-shrink: 0;
}
.ratings2,
.reviewcoachstars1,
.star5 {
  display: flex;
  align-items: center;
}
.reviewcoachstars1 {
  flex-direction: row;
  justify-content: flex-start;
  gap: 13.14px;
}
.star5 {
  position: relative;
  font-weight: 500;
  width: 35.56px;
  flex-shrink: 0;
}
.frame-child1,
.rating-bar-inner1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6.18px 0 0 6.18px;
  background-color: var(--reviews);
  width: 136.82px;
  height: 18.55px;
  overflow: hidden;
}
.rating-bar-inner1 {
  border-radius: 6.18px;
  background-color: var(--teal);
  width: 148.41px;
}
.rating-bar5 {
  position: relative;
  width: 148.41px;
  height: 18.55px;
}
.div5 {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 48.7px;
  flex-shrink: 0;
}
.star-parent3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.18px;
}
.star6 {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 36.33px;
  flex-shrink: 0;
}
.frame-child2,
.frame-child3 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6.18px 0 0 6.18px;
  background-color: var(--reviews);
  width: 8.5px;
  height: 18.55px;
  overflow: hidden;
}
.frame-child3 {
  width: 5.41px;
} */
/* .star8 {
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 34.78px;
}
.rating-bar8,
.star8,
.star9 {
  position: relative;
  flex-shrink: 0;
}
.rating-bar8 {
  border-radius: 6.18px;
  background-color: var(--teal);
  width: 148.41px;
  height: 18.55px;
  overflow: hidden;
}
.star9 {
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 32.47px;
}
.rating-bars1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 6.18px;
  font-size: 12.37px;
  color: var(--logo-black);
}
.reviewcoachstar-graphicsmal1 {
  justify-content: flex-start;
  gap: 12.37px;
}
.coach-rating,
.reviewcoachstar-graphicsmal-wrapper,
.reviewcoachstar-graphicsmal1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.reviewcoachstar-graphicsmal-wrapper {
  padding: var(--padding-3xs);
  justify-content: flex-start;
  font-size: var(--h4-medium-16-size);
  color: var(--gray-5);
}
.coach-rating {
  width: 666.69px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-98xl) 120px var(--padding-98xl) 180px;
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-9xl);
}
.top-review1 {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
}
.vector-review-icon {
  position: relative;
  width: 10px;
  height: 6px;
}
.coachtopreview {
  border-radius: 16px;
  box-shadow: var(--shadow-1);
  border: 1.5px solid var(--logo-black);
  display: flex;
  flex-direction: row;
  padding: var(--padding-10xs) 8px var(--padding-10xs) var(--padding-base);
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-5xs);
  text-align: center;
  font-size: var(--h6-medium-12-size);
}
.top-reviews-header {
  position: relative;
  font-size: var(--h3-medium-20-size);
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 125px;
}
.reviewcoachreviewer-img-icon {
  position: relative;
  width: 52px;
  height: 52px;
  object-fit: cover;
}
.reviewcoachstarssmall-icon {
  position: relative;
  width: 49px;
  height: 8px;
}
.reviewed-on-august {
  position: relative;
  font-size: var(--h6-medium-12-size);
  line-height: 150%;
  text-indent: 1px;
}
.reviewcoachimgheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.lorem-ipsum-dolor1 {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 480px;
  flex-shrink: 0;
}
.reviewcoachimg,
.reviewcoachreviewer-full {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.reviewcoachimg {
  width: 553px;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-3xs) 63px;
  box-sizing: border-box;
}
.reviewcoachreviewer-full {
  flex-direction: column;
}
.lorem-ipsum-dolor2 {
  position: absolute;
  width: 88.89%;
  top: 34.07%;
  left: 11.11%;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.reviewcoachimg-icon {
  position: absolute;
  height: 28.57%;
  width: 9.63%;
  top: 0;
  right: 90.37%;
  bottom: 71.43%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.reviewcoachimgheader1 {
  position: absolute;
  height: 27.47%;
  width: 31.67%;
  top: 2.2%;
  right: 57.22%;
  bottom: 70.33%;
  left: 11.11%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.reviewcoachreviewer-full1 {
  position: relative;
  width: 540px;
  height: 182px;
}
.reviews,
.top-reviews,
.view-coach-profile {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-reviews {
  width: 769.31px;
  flex-shrink: 0;
  flex-direction: column;
  padding: 23px 260px 96px 201px;
  box-sizing: border-box;
  gap: var(--gap-9xl);
  font-size: var(--h4-medium-16-size);
} */
/* .reviews,
.view-coach-profile {
  flex-direction: row;
  gap: 4px;
}
.view-coach-profile {
  position: relative;
  background-color: var(--color-white);
  flex-direction: column;
  padding: 137px 0;
  justify-content: flex-start;
  gap: 73px;
  text-align: left;
  font-size: var(--h2-bold-32-size);
  color: var(--logo-black);
  font-family: var(--h4-medium-16);
}
@media screen and (max-width: 1200px) {
  .viewcoachdetailsheader {
    width: 990px;
  }
  .envelopebadge-parent {
    padding-top: 15px;
    box-sizing: border-box;
  }
  .detailsname {
    gap: 85px;
  }
  .coach-details-box {
    width: 900px;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .about-the-coach,
  .coachdetailsprofile {
    width: 900px;
  }
  .bio {
    font-size: var(--font-size-lg);
  }
  .lorem-ipsum-dolor {
    font-size: var(--h5-btns-med-14-size);
  }
  .biobox {
    gap: var(--gap-141xl);
  }
  .university-of-prestige {
    font-size: var(--h5-btns-med-14-size);
  }
  .educationbox {
    gap: var(--gap-141xl);
  }
  .innerrightservicetype2 {
    gap: var(--gap-81xl);
  }
  .hr-mock-interview {
    font-size: var(--font-size-smi);
    width: 479px;
  }
  .rightservervicetype1 {
    width: 508px;
  }
  .servicetypecoachbox {
    gap: var(--gap-141xl);
  }
  .detailsbogbox,
  .page-divider {
    width: 900px;
  } */
  /* .write-a-review1 {
    font-size: 26px;
  }
  .have-you-worked1 {
    font-size: 15px;
  }
  .let-us-know1 {
    font-size: var(--h6-medium-12-size);
  }
  .coach-rating {
    background-color: var(--color-white);
    width: 300px;
    align-items: center;
    justify-content: center;
    padding-left: 350px;
    padding-right: 45px;
    box-sizing: border-box;
  }
  .lorem-ipsum-dolor1,
  .lorem-ipsum-dolor2 {
    font-size: var(--font-size-smi);
    width: 360px;
  }
  .top-reviews {
    align-items: center;
    justify-content: center;
    padding-left: 98px;
    padding-right: 0;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 850px) {
  .viewcoachdetailsheader {
    width: 610px;
  }
  .detailsname {
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .detailsright {
    width: 300px;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: var(--padding-8xs);
    box-sizing: border-box;
  }
  .coach-details-box,
  .coachdetailsprofile {
    align-items: center;
    justify-content: center;
  }
  .coach-details-box {
    width: 600px;
    flex-direction: column;
  }
  .coachdetailsprofile {
    width: 500px;
  }
  .about-the-coach {
    width: 500px;
  }
  .bio,
  .lorem-ipsum-dolor {
    font-size: var(--h4-medium-16-size);
  }
  .lorem-ipsum-dolor {
    width: 500px;
  }
  .biobox {
    width: 400px;
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: center;
  }
  .university-of-prestige {
    font-size: var(--h4-medium-16-size);
  }
  .educationbox {
    flex-direction: column;
    gap: var(--gap-3xs);
  }
  .service-type {
    font-size: var(--h4-medium-16-size);
  }
  .servicetypecoachbox {
    flex-direction: column;
    gap: var(--gap-3xs);
  }
  .detailsbogbox {
    width: 500px;
    gap: 30px;
    align-items: center;
    justify-content: center;
    padding-left: 1px;
  }
  .page-divider {
    width: 500px;
  }
  .coach-rating {
    width: 700px;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-top: 50px;
    padding-right: 0;
    box-sizing: border-box;
  }
  .top-reviews {
    padding-top: var(--padding-8xs);
    box-sizing: border-box;
  }
  .reviews {
    flex-direction: column;
  }
}
@media screen and (max-width: 520px) {
  .viewcoachdetailsheader {
    width: 450px;
  }
  .about-the-coach,
  .coachdetailsprofile {
    width: 400px;
  }
  .bio,
  .lorem-ipsum-dolor {
    font-size: var(--h4-medium-16-size);
  }
  .lorem-ipsum-dolor {
    width: 380px;
  }
  .biobox {
    width: 400px;
  }
  .university-of-prestige {
    font-size: var(--h4-medium-16-size);
  }
  .bgcheckicon,
  .cancelationicon {
    width: 16px;
    height: 15px;
  }
  .background-check-last {
    font-size: var(--h5-btns-med-14-size);
  }
  .service-type {
    font-size: var(--h4-medium-16-size);
  }
  .essay-review {
    font-size: var(--h3-medium-20-size);
  }
  .innerrightservicetitle {
    width: 300px;
  }
  .b {
    font-size: var(--h3-medium-20-size);
  }
  .innerrightservicetype2 {
    gap: var(--gap-xl);
  }
  .hr-mock-interview {
    font-size: var(--h4-medium-16-size);
    width: 379px;
  }
  .rightservervicetype1 {
    width: 410px;
    height: 140px;
  }
  .detailsbogbox,
  .page-divider {
    width: 400px;
  }
  .coach-rating {
    padding-left: 70px;
    box-sizing: border-box;
  }
  .lorem-ipsum-dolor1,
  .lorem-ipsum-dolor2 {
    font-size: var(--font-size-2xs);
    width: 220px;
  }
  .top-reviews {
    align-items: center;
    justify-content: center;
    padding-left: 250px;
    box-sizing: border-box;
  }
  .view-coach-profile {
    padding: 76px 0;
    box-sizing: border-box;
  }
} */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h4-medium-16: Poppins;

  /* font sizes */
  --h4-medium-16-size: 16px;
  --font-size-smi-4: 12.4px;
  --h5-btns-med-14-size: 14px;
  --h3-medium-20-size: 20px;
  --h2-bold-32-size: 32px;
  --h6-medium-12-size: 12px;
  --font-size-smi: 13px;
  --font-size-2xs: 11px;
  --font-size-5xl: 24px;
  --font-size-lg: 18px;
  --font-size-lg-4: 18.4px;
  --font-size-5xs: 8px;
  --font-size-mid: 17px;
  --font-size-mini: 15px;
  --font-size-29xl: 48px;
  --font-size-21xl: 40px;
  --font-size-9xl: 28px;
  --font-size-3xl: 22px;
  --font-size-2xl-6: 21.6px;
  --font-size-3xs: 10px;
  --font-size-4xs: 9px;
  --font-size-lg-8: 18.8px;
  --font-size-2xl: 21px;
  --font-size-4xl-2: 23.2px;
  --font-size-lgi: 19px;
  --font-size-17xl: 36px;
  --font-size-3xl-7: 22.7px;
  --font-size-4xl-4: 23.4px;
  --font-size-lg-9: 18.9px;
  --font-size-9xl-1: 28.1px;

  /* Colors */
  --gray-5: #393939;
  --color-darkslategray-100: #263238;
  --color-gray-100: #898989;
  --gray-4: #79797d;
  --color-gray-200: #787878;
  --logo-black: #010101;
  --color-gray-300: rgba(255, 255, 255, 0.5);
  --teal: #40cfc3;
  --reviews: #deb00f;
  --white: #fff;
  --logo-color-2: #4a93cf;
  --color-silver-100: #c4c6c7;
  --color-silver-200: #bbb;
  --color-black: #000;
  --purple-blue: #5558cf;
  --color-darkgray-100: #959595;
  --gray-1: #f2f2f2;
  --gray-2: #e7e7e7;
  --royal-blue: #0d0ed9;
  --color-khaki: #f8d665;
  --color-tomato: #ed5043;
  --color-mediumaquamarine: #70d499;

  /* Gaps */
  --gap-smi-4: 12.4px;
  --gap-6xs-2: 6.2px;
  --gap-sm-1: 13.1px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-lgi: 19px;
  --gap-3xs: 10px;
  --gap-mini: 15px;
  --gap-9xs: 4px;
  --gap-9xl: 28px;
  --gap-11xl: 30px;
  --gap-185xl: 204px;
  --gap-141xl: 160px;
  --gap-235xl: 254px;
  --gap-81xl: 100px;
  --gap-xl: 20px;
  --gap-3xs-2: 9.2px;
  --gap-lg: 18px;
  --gap-111xl: 130px;
  --gap-8xs: 5px;
  --gap-13xl: 32px;
  --gap-11xs: 2px;
  --gap-43xs: -30px;
  --gap-192xl: 211px;
  --gap-61xl: 80px;
  --gap-254xl: 273px;
  --gap-28xl: 47px;
  --gap-xs: 12px;
  --gap-6xs: 7px;
  --gap-53xl: 72px;
  --gap-31xl: 50px;
  --gap-7xs: 6px;
  --gap-3xl: 22px;
  --gap-0: 0px;
  --gap-10xs: 3px;
  --gap-3xs-4: 9.4px;
  --gap-2xs: 11px;
  --gap-mini-1: 14.1px;

  /* Paddings */
  --padding-mid: 17px;
  --padding-4xs: 9px;
  --padding-3xs: 10px;
  --padding-sm: 14px;
  --padding-4xl: 23px;
  --padding-118xl: 137px;
  --padding-57xl: 76px;
  --padding-77xl: 96px;
  --padding-8xs: 5px;
  --padding-10xs: 3px;
  --padding-5xs: 8px;
  --padding-base: 16px;
  --padding-161xl: 180px;
  --padding-31xl: 50px;
  --padding-lgi: 19px;
  --padding-16xl: 35px;
  --padding-xl: 20px;
  --padding-6xs: 7px;
  --padding-mini: 15px;
  --padding-13xl: 32px;
  --padding-17xl: 36px;
  --padding-34xl: 53px;
  --padding-9xs: 4px;
  --padding-25xl: 44px;
  --padding-175xl: 194px;
  --padding-69xl: 88px;
  --padding-81xl: 100px;
  --padding-11xl: 30px;
  --padding-53xl: 72px;
  --padding-52xl: 71px;
  --padding-50xl: 69px;
  --padding-xs: 12px;
  --padding-7xs: 6px;
  --padding-9xl: 28px;
  --padding-23xl: 42px;
  --padding-181xl: 200px;
  --padding-28xl: 47px;
  --padding-smi: 13px;
  --padding-61xl: 80px;
  --padding-84xl: 103px;
  --padding-4xs-2: 8.2px;
  --padding-55xl: 74px;
  --padding-42xl: 61px;

  /* Border radiuses */
  --br-6xs-2: 6.2px;
  --br-5xs: 8px;
  --br-9xs: 4px;
  --br-6xs: 7px;
  --br-7xs: 6px;
  --br-3xs: 10px;
  --br-xs: 12px;
  --br-3xs-4: 9.4px;
  --br-4xs: 9px;
  --br-3xs-5: 9.5px;

  /* Effects */
  --shadow-1: 0px 1px 3px rgba(0, 0, 0, 0.25);
}


.viewcoachbachheader {
  position: relative;
  font-weight: 500;
  background-color: #ffffff;
  border: none;
}
.viewcoachdetailsheader {
  width: 1437px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-13xl);
  box-sizing: border-box;
  font-size: var(--h3-medium-20-size);
  color: var(--logo-color-2);
}
.coach-details1 {
  position: relative;
}
.cardimgsmallshadow-icon1 {
  position: relative;
  width: 254.05px;
  height: 243.05px;
  object-fit: cover;
}
.coachdetailsprofile-img1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.hailey-johnson {
  align-self: stretch;
  position: relative;
  font-size: 35px;
}

.writereviewbutton{
  background-color: #40cfc3;
  border-radius: 12px;
 
}

.writereviewbox{
  display: block;
  height: 160px;
}
.scholarship-essay-expertise {
  align-self: stretch;
  position: relative;
  font-weight: 500;
  color: var(--purple-blue);
}
.members1,
.stars-icon3 {
  position: relative;
  width: 56.2px;
  height: 9.18px;
}
.members1 {
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
  display: inline-block;
  width: 219.06px;
  height: 21.79px;
  flex-shrink: 0;
}
.coach-review-stars {
  width: 228.23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 54px;
  font-size: var(--h6-medium-12-size);
  color: var(--gray-4);
}
.hoursworked-icon {
  position: relative;
  width: 22.94px;
  height: 22.94px;
}
.coach-details2,
.hoursworked-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 9.18px;
}
.coach-details2 {
  width: 326px;
  flex-direction: column;
  align-items: flex-start;
}
.group-icon {
  position: absolute;
  top: calc(50% - 12.5px);
  left: calc(50% - 18px);
  width: 36px;
  height: 25px;
}
.envelopebadge {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 36px;
  height: 25px;
}
.heart-icon {
  position: relative;
  width: 25.13px;
  height: 23.45px;
}
.detailsname,
.envelopebadge-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.envelopebadge-parent {
  padding: var(--padding-6xs) 0 0;
  gap: var(--gap-9xl);
}
.detailsname {
  width: 700.63px;
  gap: 288px;
}
.coach-page-error{
  color: red;
  font-size: 15px;
}
.button-text2,
.button2 {
  position: absolute;
  width: 100%;
  left: 0;
}
.button2 {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 7.54px;
  background-color: var(--logo-black);
} 
.rating-bar-inner-5star{
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6.18px 0 0 6.18px;
  background-color: var(--reviews);
  height: 18.55px;
  overflow: hidden;
}
.button-text2 {
  height: 95%;
  top: 2.5%;
  font-size: var(--h3-medium-20-size);
  display: flex;
  font-family: var(--h4-medium-16);
  color: var(--gray-1);
  text-align: center;
  align-items: center;
  justify-content: center;
}
.buttonlarge1 {
  position: relative;
  width: 197px;
  height: 50px;
}
.booknow {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.coach-details-box,
.detailsright {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.detailsright {
  width: 700.63px;
  flex-direction: column;
  padding: 65px 0 0;
  box-sizing: border-box;
  gap: var(--gap-lgi);
  font-size: 18.35px;
}
.coach-details-box {
  flex-direction: row;
  gap: 89px;
}
.icround-plus {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.coachdetailsprofile {
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px var(--padding-16xl);
}
.about-the-coach,
.bio,
.lorem-ipsum-dolor {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.about-the-coach {
  width: 1080px;
  height: 48px;
}
.bio,
.lorem-ipsum-dolor {
  font-weight: 600;
  width: 184px;
}
.lorem-ipsum-dolor {
  font-size: var(--h4-medium-16-size);
  font-weight: 500;
  color: var(--logo-black);
  width: 553px;
}
.biobox {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-185xl);
}
.university-of-prestige {
  position: relative;
  font-size: var(--h4-medium-16-size);
  font-weight: 500;
  color: var(--logo-black);
  display: flex;
  align-items: center;
  width: 553px;
  flex-shrink: 0;
}
.educationbox {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-185xl);
}
.bgcheckicon,
.cancelationicon {
  position: relative;
  width: 21px;
  height: 20px;
}
.bgcheckicon {
  width: 18px;
}
.background-check-last {
  position: relative;
  font-weight: 500;
}
.policiesbox1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--h4-medium-16-size);
  color: var(--logo-black);
}
.languagebox1 {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--h4-medium-16-size);
  color: var(--logo-black);
}
.service-type {
  position: relative;
  font-weight: 600;
}
.servicetypetitle,
.servicetypetitle1 {
  width: 129px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.servicetypetitle {
  width: 184px;
  flex-direction: column;
}
.essay-review {
  position: relative;
}
.essay-review-wrapper,
.innerrightservicetitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.innerrightservicetitle {
  width: 319px;
  flex-direction: column;
  align-items: flex-start;
}
.rightservicetypeprice {
  position: relative;
  color: var(--logo-color-2);
}
.innerrightservicetype2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-235xl);
}
.hr-mock-interview {
  position: relative;
  font-size: var(--h4-medium-16-size);
  line-height: 20px;
  font-weight: 500;
  color: var(--color-darkgray-100);
  display: inline-block;
  width: 619px;
  height: 36px;
  flex-shrink: 0;
}
.rightservervicetype1 {
  border-radius: var(--br-6xs);
  background-color: var(--white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  padding: var(--padding-base) var(--padding-lgi);
  gap: var(--gap-3xs);
}
.rightservervicetype1,
.rightservicetype,
.servicetypecoachbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.rightservicetype {
  flex-direction: column;
  gap: var(--gap-base);
  font-size: var(--font-size-5xl);
  color: var(--logo-black);
}
.servicetypecoachbox {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-185xl);
  color: #a7a7a7;
}
.detailsbogbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 114px;
  font-size: var(--h3-medium-20-size);
  color: var(--color-gray-100);
}
.page-divider {
  position: relative;
  border-top: 1px solid var(--logo-black);
  box-sizing: border-box;
  width: 1121px;
  height: 1px;
}
.write-a-review1 {
  position: relative;
  font-weight: 600;
}
.have-you-worked1,
.let-us-know1 {
  position: relative;
  font-size: var(--h3-medium-20-size);
  font-weight: 500;
}
.let-us-know1 {
  font-size: var(--h5-btns-med-14-size);
  color: var(--gray-5);
}
.write-review-text1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.button-text3,
.button3 {
  position: absolute;
  width: 100%;
  left: 0;
}
.button3 {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-5xs);
  background-color: var(--logo-color-2);
}
.button-text3 {
  height: 95%;
  top: 2.5%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonlarge2 {
  position: relative;
  background-color: var(--purple-blue);
  width: 198px;
  height: 32px;
  text-align: center;
  font-size: var(--h5-btns-med-14-size);
  color: var(--white);
}
.reviewcoachuser-prompt1 {
  width: 349px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.stars-icon4 {
  position: relative;
  width: 94.69px;
  height: 15.46px;
}
.ratings2 {
  position: relative;
  font-weight: 500;
  width: 238.85px;
  flex-shrink: 0;
}
.ratings2,
.reviewcoachstars1,
.star5 {
  display: flex;
  align-items: center;
}
.reviewcoachstars1 {
  flex-direction: row;
  justify-content: flex-start;
  gap: 13.14px;
}
.star5 {
  position: relative;
  font-weight: 500;
  width: 35.56px;
  flex-shrink: 0;
}
.frame-child1,
.rating-bar-inner1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6.18px 0 0 6.18px;
  background-color: var(--reviews);
  width: 136.82px;
  height: 18.55px;
  overflow: hidden;
}
.rating-bar-inner1 {
  border-radius: 6.18px;
  background-color: var(--teal);
  width: 148.41px;
}
.rating-bar5 {
  position: relative;
  width: 148.41px;
  height: 18.55px;
}
.div6 {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 48.7px;
  flex-shrink: 0;
}
.star-parent3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.18px;
}
.star6 {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 36.33px;
  flex-shrink: 0;
}
.frame-child2,
.frame-child3 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6.18px 0 0 6.18px;
  background-color: var(--reviews);
  width: 8.5px;
  height: 18.55px;
  overflow: hidden;
}
.frame-child3 {
  width: 5.41px;
}
.star8 {
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 34.78px;
}
.rating-bar8,
.star8,
.star9 {
  position: relative;
  flex-shrink: 0;
}
.rating-bar8 {
  border-radius: 6.18px;
  background-color: var(--teal);
  width: 148.41px;
  height: 18.55px;
  overflow: hidden;
}
.star9 {
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 32.47px;
}
.rating-bars1,
.reviewcoachstar-graphicsmal1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.rating-bars1 {
  align-items: flex-end;
  gap: 6.18px;
  font-size: 12.37px;
  color: var(--logo-black);
}
.reviewcoachstar-graphicsmal1 {
  align-items: flex-start;
  gap: 12.37px;
  font-size: var(--h4-medium-16-size);
  color: var(--gray-5);
}
.reviewcoachuser-prompt-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 126px;
}
.coach-rating {
  width: 666.69px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-77xl) 120px 117px var(--padding-161xl);
  box-sizing: border-box;
}
.top-review1 {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 150%;
  font-weight: 500;
}
.vector-review-icon {
  position: relative;
  width: 10px;
  height: 6px;
}
.coachtopreview {
  margin: 0 !important;
  position: absolute;
  top: -1.5px;
  left: -5.5px;
  border-radius: 16px;
  box-shadow: var(--shadow-1);
  border: 1.5px solid var(--logo-black);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-10xs) var(--padding-5xs) var(--padding-10xs)
    var(--padding-base);
  gap: var(--gap-5xs);
  z-index: 0;
}
.coachtopreview-wrapper,
.top-reviews-header {
  position: relative;
  display: flex;
  align-items: center;
}
.coachtopreview-wrapper {
  width: 542px;
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--h6-medium-12-size);
}
.top-reviews-header {
  font-weight: 500;
  width: 152px;
  height: 30px;
  flex-shrink: 0;
}
.top-reviews-header-wrapper {
  width: 548px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--h3-medium-20-size);
}
.reviewcoachreviewer-img-icon {
  position: relative;
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 60px;
}
.reviewcoachstarssmall-icon {
  position: relative;
  width: 49px;
  height: 8px;
}
.reviewed-on-august {
  position: relative;
  font-size: var(--h6-medium-12-size);
  line-height: 150%;
  text-indent: 1px;
  display: flex;
  align-items: center;
  width: 205px;
}
.reviewcoachimgheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.lorem-ipsum-dolor1 {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 480px;
  flex-shrink: 0;
}
.reviewcoachimg,
.reviewcoachreviewer-full {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.reviewcoachimg {
  width: 553px;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-3xs) 63px;
  box-sizing: border-box;
}
.reviewcoachreviewer-full {
  flex-direction: column;
}
.lorem-ipsum-dolor2 {
  position: absolute;
  width: 88.89%;
  top: 34.07%;
  left: 11.11%;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.reviewcoachimg-icon {
  position: absolute;
  height: 28.57%;
  width: 9.63%;
  top: 0;
  right: 90.37%;
  bottom: 71.43%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.reviewcoachimgheader1 {
  position: absolute;
  height: 27.47%;
  width: 31.67%;
  top: 2.2%;
  right: 57.22%;
  bottom: 70.33%;
  left: 11.11%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.reviewcoachreviewer-full1 {
  position: relative;
  width: 540px;
  height: 182px;
}
.top-reviews {
  width: 769.31px;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-4xl) 260px var(--padding-77xl) 201px;
  box-sizing: border-box;
  gap: var(--gap-9xl);
  font-size: var(--h4-medium-16-size);
}
.reviews,
.top-reviews,
.view-coach-profile {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.reviews {
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-9xs);
}
.view-coach-profile {
  position: relative;
  background-color: var(--white);
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-118xl) 0;
  box-sizing: border-box;
  gap: 73px;
  text-align: left;
  font-size: var(--h2-bold-32-size);
  color: var(--logo-black);
  font-family: var(--h4-medium-16);
}
@media screen and (max-width: 1200px) {
  .viewcoachdetailsheader {
    width: 990px;
  }
  .envelopebadge-parent {
    padding-top: var(--padding-mini);
    box-sizing: border-box;
  }
  .detailsname {
    gap: 85px;
  }
  .coach-details-box {
    width: 900px;
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .about-the-coach,
  .coachdetailsprofile {
    width: 900px;
  }
  .bio {
    font-size: var(--font-size-lg);
  }
  .lorem-ipsum-dolor {
    font-size: var(--h5-btns-med-14-size);
  }
  .biobox {
    gap: var(--gap-141xl);
  }
  .university-of-prestige {
    font-size: var(--h5-btns-med-14-size);
  }
  .educationbox {
    gap: var(--gap-141xl);
  }
  .innerrightservicetype2 {
    gap: var(--gap-81xl);
  }
  .hr-mock-interview {
    font-size: var(--font-size-smi);
    width: 479px;
  }
  .rightservervicetype1 {
    width: 508px;
  }
  .servicetypecoachbox {
    gap: var(--gap-141xl);
  }
  .detailsbogbox,
  .page-divider {
    width: 900px;
  }
  .coach-rating {
    background-color: var(--white);
    width: 300px;
    align-items: center;
    justify-content: center;
    padding-left: 350px;
    padding-right: 150px;
    box-sizing: border-box;
  }
  .lorem-ipsum-dolor1,
  .lorem-ipsum-dolor2 {
    font-size: var(--font-size-smi);
    width: 360px;
  }
  .top-reviews {
    align-items: center;
    justify-content: center;
    padding-left: 98px;
    padding-right: 0;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 850px) {
  .viewcoachdetailsheader {
    width: 610px;
  }
  .detailsname {
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .detailsright {
    width: 300px;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: var(--padding-8xs);
    box-sizing: border-box;
  }
  .coach-details-box,
  .coachdetailsprofile {
    align-items: center;
    justify-content: center;
  }
  .coach-details-box {
    width: 600px;
    flex-direction: column;
  }
  .coachdetailsprofile {
    width: 500px;
  }
  .about-the-coach {
    width: 500px;
  }
  .bio,
  .lorem-ipsum-dolor {
    font-size: var(--h4-medium-16-size);
  }
  .lorem-ipsum-dolor {
    width: 500px;
  }
  .biobox {
    width: 400px;
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: center;
  }
  .university-of-prestige {
    font-size: var(--h4-medium-16-size);
  }
  .educationbox {
    flex-direction: column;
    gap: var(--gap-3xs);
  }
  .service-type {
    font-size: var(--h4-medium-16-size);
  }
  .servicetypecoachbox {
    flex-direction: column;
    gap: var(--gap-3xs);
  }
  .detailsbogbox {
    width: 500px;
    gap: var(--gap-11xl);
    align-items: center;
    justify-content: center;
  }
  .page-divider {
    width: 500px;
  }
  .coach-rating {
    width: 700px;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-top: var(--padding-31xl);
    padding-right: 0;
    box-sizing: border-box;
  }
  .top-reviews {
    padding-top: var(--padding-8xs);
    box-sizing: border-box;
  }
  .reviews {
    flex-direction: column;
  }
}
@media screen and (max-width: 520px) {
  .viewcoachdetailsheader {
    width: 450px;
  }
  .about-the-coach,
  .coachdetailsprofile {
    width: 400px;
  }
  .bio,
  .lorem-ipsum-dolor {
    font-size: var(--h4-medium-16-size);
  }
  .lorem-ipsum-dolor {
    width: 380px;
  }
  .biobox {
    width: 400px;
  }
  .university-of-prestige {
    font-size: var(--h4-medium-16-size);
  }
  .bgcheckicon,
  .cancelationicon {
    width: 16px;
    height: 15px;
  }
  .background-check-last {
    font-size: var(--h5-btns-med-14-size);
  }
  .service-type {
    font-size: var(--h4-medium-16-size);
  }
  .essay-review {
    font-size: var(--h3-medium-20-size);
  }
  .innerrightservicetitle {
    width: 300px;
  }
  .rightservicetypeprice {
    font-size: var(--h3-medium-20-size);
  }
  .innerrightservicetype2 {
    gap: var(--gap-xl);
  }
  .hr-mock-interview {
    font-size: var(--h4-medium-16-size);
    width: 379px;
  }
  .rightservervicetype1 {
    width: 410px;
    height: 140px;
  }
  .detailsbogbox,
  .page-divider {
    width: 400px;
  }
  .coach-rating {
    padding-left: 70px;
    box-sizing: border-box;
  }
  .lorem-ipsum-dolor1,
  .lorem-ipsum-dolor2 {
    font-size: var(--font-size-2xs);
    width: 220px;
  }
  .top-reviews {
    align-items: center;
    justify-content: center;
    padding-left: 250px;
    box-sizing: border-box;
  }
  .view-coach-profile {
    padding: var(--padding-57xl) 0;
    box-sizing: border-box;
  }
} 
