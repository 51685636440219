.headerlogo-icon14 {
  position: relative;
  width: 134px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link-default56 {
  position: relative;
  font-weight: 500;
}
.headernav-link70 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headernav-link71 {
  color: var(--logo-color-1);
}
.headernav-link71,
.headernav-link72,
.left-side-top-nav14,
.top-nav-left14 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left-side-top-nav14,
.top-nav-left14 {
  gap: var(--gap-13xl);
}
.left-side-top-nav14 {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  width: 604.51px;
  padding: 0 0 0 var(--padding-13xl);
  box-sizing: border-box;
  align-items: flex-end;
}
.vector-icon96,
.vector-icon97 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon97 {
  height: 50%;
  width: 75%;
  top: 25%;
  right: 12.5%;
  bottom: 25%;
  left: 12.5%;
}
.menu14 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 44px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.messages-icon13,
.notifications-icon13 {
  position: absolute;
  top: 8px;
  left: -3px;
  width: 28.3px;
  height: 28px;
}
.notifications-icon13 {
  left: 53px;
  width: 22.74px;
}
.mask-group-icon13 {
  position: relative;
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.vector-icon98 {
  position: relative;
  width: 10px;
  height: 6px;
}
.mask-group-parent11 {
  position: absolute;
  top: 0;
  left: 106px;
  width: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.messages-parent11 {
  position: relative;
  width: 152px;
  height: 44px;
}
.right-side-top-nav14 {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  width: 263px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-13xl) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-13xl);
}
.info13 {
  align-self: stretch;
  position: relative;
  height: 44px;
}
.scholarships-line-icon13,
.services-line-icon13 {
  position: relative;
  width: 89.5px;
  height: 0;
  display: none;
}
.services-line-icon13 {
  width: 59.5px;
}
.community-line-icon13,
.resources-icon13 {
  position: relative;
  width: 83px;
  height: 0;
  display: none;
}
.resources-icon13 {
  width: 73px;
}
.navbar13 {
  align-self: stretch;
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-3);
  box-sizing: border-box;
  height: 79px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.scholist-profile2 {
  position: relative;
}
.header13 {
  width: 1446px;
  height: 191px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-76xl) var(--padding-429xl) var(--padding-smi);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.personal-information2 {
  position: relative;
  font-size: var(--p1-regular-16-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--white);
  text-align: center;
}
.personal-information-frame {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini) 0;
  background-color: var(--royal-blue);
  border-radius: var(--br-5xs);
  width: 272px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.education4 {
  position: relative;
  font-size: var(--p1-regular-16-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--color-black);
  text-align: center;
}
.education-frame {
  cursor: pointer;
  border: 0.8px solid var(--gray-2);
  padding: var(--padding-mini) 0;
  background-color: var(--gray-1);
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  width: 272px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.frame-parent20 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-32xl);
}
.selection2 {
  background-color: var(--white);
  width: 1446px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-2xl) var(--padding-115xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.name17 {
  position: relative;
  line-height: 150%;
}
.inputtextboxdatemonth,
.inputtextboxdateyear7 {
  border: 1px solid var(--gray-2);
  font-family: var(--p1-regular-16);
  font-size: var(--p1-regular-16-size);
  background-color: var(--white);
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-1);
  box-sizing: border-box;
  width: 373px;
  height: 42px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-mini);
  align-items: center;
  justify-content: center;
}
.inputtextboxdateyear7 {
  width: 125px;
  padding: var(--padding-5xs) var(--padding-lg);
}
.inputtextboxdatemonth-parent,
.name-parent14 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 39px;
}
.name-parent14 {
  align-items: center;
  gap: 306px;
}
.multiselectdefault10,
.quiz-question16 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
}
.quiz-question16 {
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-34xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.multiselectdefault10 {
  position: relative;
  background-color: var(--white);
  box-shadow: var(--shadow-1);
  box-sizing: border-box;
  width: 373px;
  height: 42px;
  overflow: hidden;
  flex-shrink: 0;
}
.name-parent15,
.quiz-question17 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.name-parent15 {
  gap: 223px;
}
.quiz-question17 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  padding: var(--padding-3xl) var(--padding-35xl);
}
.name19,
.text {
  line-height: 150%;
}
.name19 {
  position: relative;
  display: inline-block;
  width: 136px;
  flex-shrink: 0;
}
.text {
  position: absolute;
  top: 3.85%;
  left: 56.25%;
}
.outer-radio-container,
.radio-circle-empty {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
}
.outer-radio-container {
  width: 100%;
  right: 0;
  border-radius: var(--br-31xl);
  background-color: var(--gray-1);
  box-shadow: var(--shadow-1);
  border: 1px solid var(--gray-3);
  box-sizing: border-box;
}
.radio-circle-empty {
  width: 40.63%;
  right: 59.38%;
}
.buttonradio {
  position: relative;
  width: 64px;
  height: 26px;
}
.name-parent16,
.yes-no-radio-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.yes-no-radio-buttons {
  width: 373px;
  gap: var(--gap-13xl);
}
.name-parent16 {
  gap: var(--gap-217xl);
}
.quiz-question18 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xl) var(--padding-36xl);
  align-items: flex-start;
  justify-content: center;
}
.multiselectgender {
  position: relative;
  width: 373px;
  height: 42px;
}
.name-parent17,
.quiz-question19 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.name-parent17 {
  gap: var(--gap-292xl);
}
.quiz-question19 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  padding: var(--padding-3xl) 56px;
}
.sofiadropdownsdropdown-2 {
  position: relative;
  width: 337px;
  height: 42px;
}
.name-parent18,
.quiz-question20 {
  display: flex;
  justify-content: flex-start;
}
.name-parent18 {
  height: 47px;
  flex-direction: row;
  padding: 0 var(--padding-13xl) 0 0;
  box-sizing: border-box;
  align-items: center;
  gap: 230px;
}
.quiz-question20 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  flex-direction: column;
  padding: var(--padding-2xl) var(--padding-35xl);
  align-items: flex-start;
}
.multiselectdisability {
  position: relative;
  width: 380px;
  height: 42px;
}
.name-parent19,
.quiz-question21 {
  display: flex;
  justify-content: flex-start;
}
.name-parent19 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-281xl);
}
.quiz-question21 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  flex-direction: column;
  padding: var(--padding-3xl) var(--padding-28xl);
  align-items: flex-start;
}
.multiselectethnicity {
  position: relative;
  width: 373px;
  height: 42px;
}
.name-parent20,
.quiz-question22 {
  display: flex;
  justify-content: flex-start;
}
.name-parent20 {
  flex-direction: row;
  align-items: center;
  gap: 305px;
}
.quiz-question22 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  flex-direction: column;
  padding: var(--padding-3xl) var(--padding-35xl);
  align-items: flex-start;
}
.multiselectreligion {
  position: relative;
  width: 373px;
  height: 42px;
}
.name-parent21 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 309px;
}
.name25 {
  position: relative;
  line-height: 150%;
  display: inline-block;
  width: 151px;
  flex-shrink: 0;
}
.multiselectattributes {
  position: relative;
  width: 373px;
  height: 42px;
}
.name-parent22 {
  align-items: center;
  gap: 221px;
}
.name-parent22,
.name-parent23,
.yes-no-radio-buttons1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.yes-no-radio-buttons1 {
  width: 373px;
  align-items: flex-start;
  gap: var(--gap-13xl);
}
.name-parent23 {
  align-items: center;
  gap: var(--gap-217xl);
}
.quiz-question25,
.scholarship-questions-22 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xl) var(--padding-36xl);
  align-items: flex-start;
  justify-content: center;
}
.scholarship-questions-22 {
  padding: var(--padding-17xl) var(--padding-13xl);
  align-items: center;
  gap: var(--gap-lg);
}
.exit2 {
  position: relative;
  font-size: var(--p1-regular-16-size);
  font-weight: 500;
  font-family: var(--p1-regular-16);
  color: var(--white);
  text-align: left;
}
.exit-frame {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini) var(--padding-52xl);
  background-color: var(--royal-blue);
  border-radius: var(--br-5xs);
  width: 169px;
  height: 54px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.vector-icon99 {
  position: relative;
  width: 10.95px;
  height: 18.95px;
}
.vector-wrapper1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mid) var(--padding-55xl);
  background-color: var(--royal-blue);
  border-radius: var(--br-5xs);
  width: 163.14px;
  height: 53.12px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  box-sizing: border-box;
}
.body14,
.body15,
.exit-button1,
.frame-parent21,
.vector-wrapper1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.frame-parent21 {
  flex-direction: row;
  gap: var(--gap-140xl);
}
.body14,
.body15,
.exit-button1 {
  overflow: hidden;
  flex-direction: column;
}
.exit-button1 {
  width: 1445px;
  height: 227px;
  flex-shrink: 0;
  padding: var(--padding-6xl) var(--padding-243xl) var(--padding-73xl);
  box-sizing: border-box;
}
.body14,
.body15 {
  font-size: var(--p1-regular-16-size);
}
.body14 {
  font-size: var(--h1-bold-52-size);
}
.footerlogo-icon14 {
  position: relative;
  width: 148px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
}
.nav-link42 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}
.footerlinknav42 {
  position: relative;
  width: 160px;
  height: 19px;
}
.footer-links70 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.footericoninstagram14 {
  position: relative;
  width: 20px;
  height: 20px;
}
.footericonfacbook14 {
  position: relative;
  width: 18.75px;
  height: 18.75px;
}
.footericontwitter14 {
  position: relative;
  width: 19.5px;
  height: 15.84px;
}
.footericonyoutube14 {
  position: relative;
  width: 21px;
  height: 14.72px;
}
.icon-wrapper14 {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.footer-menu70 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.students14 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 160px;
}
.footer-link182 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  display: inline-block;
}
.main-footer-link182 {
  position: relative;
  width: 160px;
  height: 24px;
}
.footer-menu71 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.footer-link194 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 150%;
  text-indent: 1px;
  display: inline-block;
}
.main-footer-link194 {
  position: relative;
  width: 160px;
  height: 71px;
  font-size: var(--p2-regular-12-size);
}
.text-box13 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-7xs);
  background-color: var(--white);
  box-shadow: var(--shadow-1);
}
.input-placeholder13 {
  position: absolute;
  top: 4px;
  left: 10px;
  font-weight: 500;
  display: inline-block;
  width: 181px;
  height: 25px;
}
.subscribe-email-text-box14 {
  position: relative;
  width: 198px;
  height: 28px;
  color: var(--gray-4);
}
.button-text86,
.button51 {
  position: absolute;
  width: 100%;
  left: 0;
}
.button51 {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-5xs);
  background-color: var(--purple-blue);
}
.button-text86 {
  height: 95%;
  top: 2.5%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonlarge35 {
  position: relative;
  width: 198px;
  height: 32px;
  text-align: center;
}
.footer-grid-row14,
.footer-links74,
.footer-menu-parent12 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-links74 {
  flex-direction: column;
  gap: var(--gap-5xs);
  font-size: var(--h5-btns-med-14-size);
}
.footer-grid-row14,
.footer-menu-parent12 {
  flex-direction: row;
  gap: var(--gap-69xl);
}
.footer-grid-row14 {
  justify-content: center;
}
.scholist-all-rights14 {
  position: relative;
  line-height: 150%;
}
.footer-legal14 {
  width: 827px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-365xl);
}
.footer14,
.personal-profile {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer14 {
  align-self: stretch;
  background-color: var(--gray-4);
  padding: var(--padding-63xl) var(--padding-105xl) var(--padding-20xl);
  align-items: flex-start;
  gap: var(--gap-62xl);
  font-size: var(--p1-regular-16-size);
  color: var(--gray-1);
}
.personal-profile {
  position: relative;
  background-color: var(--white);
  width: 100%;
  align-items: center;
  gap: var(--gap-11xs);
  text-align: left;
  font-size: var(--h5-btns-med-14-size);
  color: var(--logo-black);
  font-family: var(--p1-regular-16);
}


.boxerror{
  display: flex;
  flex-direction: column;
  max-height: 44px;
}
.suggestions {
  position: absolute; /* Position the suggestions container absolutely */
 
  left: 0; /* Align it with the left edge of the input field */
  width: 373px; /* Make it as wide as the input field */
  background-color: white; /* Style the background color */
  border: 1px solid #ccc; /* Add a border for visual separation */
  max-height: 150px; /* Limit the maximum height of the suggestions container */
  overflow-y: auto; /* Add a scrollbar if there are too many suggestions */

  z-index: 0;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.suggestions li {
  list-style: none; /* Remove the default list styles */
  padding: 8px; /* Add some padding for better readability */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

.suggestions li:hover {
  background-color: #f0f0f0; /* Highlight the suggestion on hover */
}
/* Add this CSS to your stylesheet or component */

@media screen and (max-width: 1200px) {
  .menu14 {
    display: none;
  }
  .education-frame,
  .personal-information-frame {
    width: 222px;
  }
  .frame-parent20 {
    gap: var(--gap-16xl);
  }
  .name-parent14 {
    gap: var(--gap-111xl);
  }
  .multiselectdefault10 {
    width: 313px;
  }
  .name-parent15 {
    gap: var(--gap-96xl);
  }
  .name-parent16 {
    gap: 66px;
  }
  .multiselectgender {
    width: 313px;
  }
  .name-parent17 {
    gap: var(--gap-181xl);
  }
  .sofiadropdownsdropdown-2 {
    width: 290px;
  }
  .name-parent18 {
    gap: var(--gap-96xl);
  }
  .multiselectdisability {
    width: 320px;
  }
  .name-parent19 {
    gap: var(--gap-181xl);
  }
  .multiselectethnicity {
    width: 323px;
  }
  .name-parent20 {
    gap: var(--gap-171xl);
  }
  .multiselectreligion {
    width: 323px;
  }
  .name-parent21 {
    gap: 198px;
  }
  .multiselectattributes {
    width: 323px;
  }
  .name-parent22 {
    gap: var(--gap-91xl);
  }
  .name-parent23 {
    gap: 76px;
  }
  .exit-frame {
    width: 90%;
  }
  .body15 {
    justify-content: center;
  }
  .footer-menu-parent12 {
    flex-wrap: wrap;
  }
  .footer-grid-row14 {
    flex-direction: column;
    gap: var(--gap-31xl);
  }

  .suggestions {

   
    width: 253px; /* Make it as wide as the input field */

  }
}
@media screen and (max-width: 850px) {
  .top-nav-left14 {
    display: none;
  }
  .menu14 {
    display: flex;
  }
  .messages-parent11 {
    display: none;
  }
  .scholist-profile2 {
    font-size: var(--h2-bold-32-size);
  }
  .personal-information2 {
    font-size: var(--font-size-3xs);
  }
  .personal-information-frame {
    width: 150px;
  }
  .education4 {
    font-size: var(--font-size-3xs);
  }
  .education-frame {
    width: 150px;
  }
  .frame-parent20 {
    gap: var(--gap-xl);
  }
  .name17 {
    font-size: var(--h5-btns-med-14-size);
  }
  .inputtextboxdatemonth,
  .inputtextboxdateyear7 {
    height: 37px;
  }
  .inputtextboxdatemonth-parent {
    gap: 25px;
  }
  .name-parent14 {
    flex-direction: column;
    gap: var(--gap-8xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .quiz-question16 {
    padding-top: var(--padding-3xs);
    box-sizing: border-box;
  }
  .multiselectdefault10 {
    width: 343px;
  }
  .name-parent15 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .quiz-question17 {
    padding-top: var(--padding-xs);
    padding-bottom: var(--padding-3xl);
    box-sizing: border-box;
  }
  .name19 {
    font-size: var(--h5-btns-med-14-size);
  }
  .yes-no-radio-buttons {
    width: 343px;
  }
  .name-parent16 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .quiz-question18 {
    padding-top: var(--padding-3xs);
    box-sizing: border-box;
  }
  .multiselectgender {
    width: 343px;
  }
  .name-parent17 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .quiz-question19 {
    padding-top: var(--padding-xs);
    box-sizing: border-box;
  }
  .sofiadropdownsdropdown-2 {
    width: 315px;
  }
  .name-parent18 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .quiz-question20 {
    padding-top: var(--padding-sm);
    padding-bottom: var(--padding-16xl);
    box-sizing: border-box;
  }
  .multiselectdisability {
    width: 360px;
  }
  .name-parent19 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .quiz-question21 {
    padding-top: var(--padding-xs);
    box-sizing: border-box;
  }
  .multiselectethnicity {
    width: 347px;
  }
  .name-parent20 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .quiz-question22 {
    padding-top: var(--padding-xs);
    box-sizing: border-box;
  }
  .multiselectreligion {
    width: 346px;
  }
  .name-parent21 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .name25 {
    font-size: var(--h5-btns-med-14-size);
  }
  .multiselectattributes {
    width: 347px;
  }
  .name-parent22 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .yes-no-radio-buttons1 {
    width: 343px;
  }
  .name-parent23 {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .quiz-question25 {
    padding-top: var(--padding-mini);
    box-sizing: border-box;
  }
  .exit-frame {
    width: 30%;
  }
  .frame-parent21 {
    gap: var(--gap-130xl);
  }

  .suggestions {

   
    width: 343px; /* Make it as wide as the input field */

  }
}
@media screen and (max-width: 520px) {
  .personal-information2 {
    font-size: var(--font-size-4xs);
  }
  .personal-information-frame {
    width: 130px;
  }
  .education4 {
    font-size: var(--font-size-4xs);
  }
  .education-frame {
    width: 130px;
  }
  .frame-parent20 {
    gap: var(--gap-6xs);
  }
  .inputtextboxdatemonth {
    width: 75px;
    height: 32px;
  }
  .inputtextboxdateyear7 {
    width: 105px;
    height: 32px;
  }
  .inputtextboxdatemonth-parent {
    gap: var(--gap-3xs);
  }
  .name-parent14 {
    gap: var(--gap-8xs);
  }
  .multiselectdefault10 {
    width: 273px;
  }
  .yes-no-radio-buttons {
    width: 272px;
    gap: var(--gap-11xl);
  }
  .multiselectgender {
    width: 272px;
  }
  .name-parent17 {
    gap: var(--gap-3xs);
  }
  .sofiadropdownsdropdown-2 {
    width: 245px;
  }
  .multiselectdisability {
    width: 290px;
  }
  .multiselectethnicity {
    width: 279px;
  }
  .multiselectreligion {
    width: 276px;
  }
  .multiselectattributes {
    width: 278px;
  }
  .yes-no-radio-buttons1 {
    width: 283px;
  }
  .scholarship-questions-22 {
    padding-left: var(--padding-5xs);
    padding-right: var(--padding-5xs);
    box-sizing: border-box;
  }
  .exit-frame {
    width: 129px;
  }
  .vector-wrapper1 {
    width: 20px;
  }
  .frame-parent21 {
    gap: var(--gap-81xl);
  }
  .suggestions {

   
    width: 273px; /* Make it as wide as the input field */

  }
}
.errormessage{
  font-size: 9px;
  padding: 3px;
  color: red;
  /* display: none; */
/* padding-left: 50px; */

}
.birthdaywrapper{
  align-items: center;
    justify-content: center;
    
}
.yesno{
  display: block;
}

/* input:invalid ~ span{
  display: block;
} */



.profileeditbodyheader2 {
  width: 1446px;
  height: 1px;
  overflow: hidden;
  flex-shrink: 0;
}
.birthday1 {
  position: relative;
  line-height: 150%;
  display: inline-block;
  width: 373px;
  flex-shrink: 0;
}

.editprofileformboxinputboxtext {
  position: absolute;
  top: 9px;
  left: 15px;
  line-height: 150%;
}
.editprofileformboxinputbox16 {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--gray-3);
  box-sizing: border-box;
  width: 373px;
  height: 42px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--gray-4);
}
.birthdaybody {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
 
}


.autodrop{
  position: relative;
  display: inline-block;
  z-index: 1;
}
.birthday,
.editprofileformboxinputbox17 {
  border: 1px solid var(--gray-3);
  border-radius: var(--br-5xs);
}
.birthday {
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-34xl);
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 84px;
}
.editprofileformboxinputbox17 {
  font-family: var(--p1-regular-16);
  font-size: var(--p1-regular-16-size);
  background-color: var(--white);
  position: relative;
  
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  width: 373px;
  height: 42px;
  overflow: hidden;
  flex-shrink: 0;
  display: inline-block;
}
.radio-circle-empty {
  cursor: pointer;
  position: relative;
  width: 26px;
  height: 26px;
}



.text {
  position: relative;
  line-height: 150%;
}
.buttonradio,
.editprofileradiogroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.editprofileradiogroup {
  /* position: absolute; */
  top: 0;
  left: 0.5px;
  gap: var(--gap-13xl);
}
.editprofileformboxinputbox18 {
  position: relative;
  border-radius: var(--br-5xs);
  width: 373px;
  height: 42px;
  overflow: hidden;
  flex-shrink: 0;
  padding-left: 12px;
}
.editprofileformboxinputbox19,
.scholarship-questions-22 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--gray-3);
}
.editprofileformboxinputbox19 {
  position: relative; 
 
  background-color: var(--white);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  width: 373px;
  height: 42px;
  overflow: hidden;
  flex-shrink: 0;
}



.scholarship-questions-22 {
  display: flex;
  flex-direction: column;
  padding: var(--padding-17xl) var(--padding-13xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-lg);
}
.personal-profile,
.profileeditbodybox11,
.profileeditbodybox2 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profileeditbodybox2 {
  align-self: stretch;
}

@media screen and (max-width: 1200px) {
  .birthday1 {
    width: 200px;
  }
  .birthdaybody {
    gap: var(--gap-111xl);
  }
  .editprofileformboxinputbox17,
  .editprofileformboxinputbox18,
  .editprofileformboxinputbox19 {
    width: 253px;
  }
  .profileeditbodybox11 {
    justify-content: center;
  }
}
@media screen and (max-width: 850px) {
  .birthday1 {
    font-size: var(--font-size-sm);
    width: 173px;
  }
  .birthdaybody {
    flex-direction: column;
    gap: var(--gap-8xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .birthday {
    padding-top: var(--padding-3xs);
    box-sizing: border-box;
    max-height: 119px;
  }
  .editprofileformboxinputbox17,
  .editprofileformboxinputbox18,
  .editprofileformboxinputbox19 {
    width: 343px;
  }
}
@media screen and (max-width: 520px) {
  .birthdaybody {
    gap: var(--gap-8xs);
  }
  .editprofileformboxinputbox17,
  .editprofileformboxinputbox18,
  .editprofileformboxinputbox19 {
    width: 273px;
  }
  .scholarship-questions-22 {
    padding-left: var(--padding-5xs);
    padding-right: var(--padding-5xs);
    box-sizing: border-box;
  }
}


