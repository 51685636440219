.coach-dashboard {
  display: flex;
  height: 94vh;
  background-color: var(--gray-0.2);
}

.calendar-container {
  width: 350px;
  margin: 0 auto;
  margin-left: 20px;
  margin-right: 20px;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
}

.react-calendar {
  border: none;
  font-size: 16px;
}

/* Header section containing the Month, Year, and Navigation Keys */
.react-calendar__navigation {
  background-color: #b3d4fc;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

/* Style for the navigation buttons (prev/next arrows) */
.react-calendar__navigation button {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.react-calendar__tile {
  color: #4a93cf;
  position: relative;
  text-align: center;
  padding: 15px 0;
  background: none;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15.8022px;
}

.react-calendar__tile:enabled:hover {
  background-color: #f0f0f0;
}

.react-calendar__tile--now {
  background-color: transparent;
  font-weight: bold;
  color: #000;
}

.react-calendar__tile--active {
  background-color: #b3d4fc;
  border-radius: 50%;
  color: white;
}

.react-calendar__month-view__weekdays {
  color: #7a7a7a;
  text-decoration: none;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.dot {
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  background-color: #6BC844;
  border-radius: 50%;
}

.datet {
  margin-top: 28%;
}

.datet2 {
  display: flex;
  flex-direction: column; 
  gap: 50px;
  margin-top: 16%;
}

.app {
  padding: 20px;
  width: 60%;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 6px;
}
.availability-section {
  text-align: left;
  margin-top: 47px;
  margin-left: 85px;
  font-size: 28px;
  padding-bottom: 8px;
}

.availability-section h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  color: #4a93cf;
}

.availability-navbutton {
  display: flex;
  gap: 20px;
  margin-bottom: 50px;
}

.availability-navlink {
  margin-left: 85px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20.8464px;
  text-align: center;
  color: #393939;
  cursor: pointer;
  text-decoration: none;
}

.availability-navlink:hover {
  text-decoration: none;
}

.schedule {
  margin-top: 20px;
  margin-left: 85px;
}

.day-row {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

.day-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18.256px;
  line-height: 27px;
  text-align: center;
  color: #757575;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time-slots {
  display: flex;
  align-items: center;
  margin-left: 2%;
  gap: 10px;
}

.time-slot {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;         
}

.time-slot input[type="time"] {
  background-color: #EDF2F6;  
  border: 1px solid #ccc;    
  border-radius: 4.50425px;    
  padding: 5px;               
  font-size: 14px;          
  width: 80px;               
}

.time-slot input[type="time"]:focus {
  outline: none;
  border: 1px solid #4a93cf;
  background-color: white;    
}


.add-slot-button {
  background-color: #EDF2F6;
  color: #4a93cf;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggle-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle-checkbox:checked + .slider {
  background-color: #4a93cf;
}

.toggle-checkbox:checked + .slider:before {
  transform: translateX(20px);
}


.add-to-calendar {
  background-color: #ffffff;;
  color: #4a93cf;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  gap: 16%; 
  margin-left: 48%; 
  border-radius: 5px; 
  transition: background-color 0.3s ease;
}

.add-to-calendar:hover {
  background-color: #b3d4fc
}

.plus-sign {
  font-size: 18px; 
}

.cancel-slot-button {
  padding: 2%;
}