@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h2-bold-32: Poppins;

  /* font sizes */
  --h2-bold-32-size: 32px;
  --font-size-3xl: 22px;
  --font-size-lg: 18px;
  --font-size-5xl: 24px;
  --font-size-4xl-2: 23.2px;
  --font-size-10xl-5: 29.5px;
  --font-size-3xl-1: 22.1px;
  --font-size-mid-4: 17.4px;
  --font-size-smi: 13px;
  --font-size-3xs: 10px;
  --h3-medium-20-size: 20px;
  --font-size-mini: 15px;
  --font-size-xl-5: 20.5px;
  --h5-btns-med-14-size: 14px;
  --h4-medium-16-size: 16px;
  --h6-medium-12-size: 12px;
  --font-size-2xs: 11px;
  --font-size-lg-8: 18.8px;
  --font-size-4xl-4: 23.4px;
  --font-size-lgi: 19px;
  --font-size-mid: 17px;
  --font-size-lg-9: 18.9px;
  --font-size-9xl-1: 28.1px;
  --font-size-smi-4: 12.4px;
  --font-size-lg-4: 18.4px;

  /* Colors */
  --white: #fff;
  --color-gray-100: #898989;
  --gray-4: #79797d;
  --color-gray-200: #787878;
  --logo-black: #010101;
  --color-gray-300: rgba(255, 255, 255, 0.5);
  --color-silver-100: #c4c6c7;
  --color-silver-200: #bbb;
  --logo-color-2: #4a93cf;
  --color-cornflowerblue: #358fd9;
  --gray-5: #393939;
  --color-darkslategray-100: #263238;
  --gray-2: #e7e7e7;
  --color-black: #000;
  --color-darkgray-100: #b2b1b1;
  --color-darkgray-200: #959595;
  --color-darkgray-300: rgba(178, 177, 177, 0.25);
  --gray-1: #f2f2f2;
  --teal: #40cfc3;
  --color-mediumaquamarine: #70d499;
  --royal-blue: #0d0ed9;
  --reviews: #deb00f;
  --purple-blue: #5558cf;

  /* Gaps */
  --gap-22xl: 41px;
  --gap-3xs: 10px;
  --gap-3xl: 22px;
  --gap-11xs: 2px;
  --gap-14xl: 33px;
  --gap-12xl: 31px;
  --gap-31xl: 50px;
  --gap-2xl: 21px;
  --gap-20xl: 39px;
  --gap-xl: 20px;
  --gap-10xl: 29px;
  --gap-25xl: 44px;
  --gap-9xs: 4px;
  --gap-mini-7: 14.7px;
  --gap-0: 0px;
  --gap-5xs-2: 7.2px;
  --gap-18xl: 37px;
  --gap-13xl: 32px;
  --gap-mini: 15px;
  --gap-lgi: 19px;
  --gap-10xs: 3px;
  --gap-12xs: 1px;
  --gap-7xl: 26px;
  --gap-mid: 17px;
  --gap-9xl: 28px;
  --gap-6xs: 7px;
  --gap-6xl: 25px;
  --gap-sm: 14px;
  --gap-2xs: 11px;
  --gap-11xl: 30px;
  --gap-3xs-4: 9.4px;
  --gap-base: 16px;
  --gap-mini-1: 14.1px;
  --gap-smi-4: 12.4px;
  --gap-6xs-2: 6.2px;
  --gap-sm-1: 13.1px;
  --gap-5xs: 8px;
  --gap-185xl: 204px;
  --gap-141xl: 160px;
  --gap-235xl: 254px;
  --gap-81xl: 100px;
  --gap-3xs-2: 9.2px;

  /* Paddings */
  --padding-24xl: 43px;
  --padding-22xl: 41px;
  --padding-lg-3: 18.3px;
  --padding-5xl-4: 24.4px;
  --padding-8xs: 5px;
  --padding-9xs: 4px;
  --padding-xl: 20px;
  --padding-13xl: 32px;
  --padding-118xl: 137px;
  --padding-57xl: 76px;
  --padding-4xl: 23px;
  --padding-mid: 17px;
  --padding-6xl: 25px;
  --padding-base: 16px;
  --padding-3xs: 10px;
  --padding-mini: 15px;
  --padding-6xs: 7px;
  --padding-11xl: 30px;
  --padding-17xl: 36px;
  --padding-5xl: 24px;
  --padding-55xl: 74px;
  --padding-31xl: 50px;
  --padding-26xl: 45px;
  --padding-42xl: 61px;
  --padding-smi: 13px;
  --padding-lgi: 19px;
  --padding-10xs: 3px;
  --padding-98xl: 117px;

  /* border radiuses */
  --br-5xs: 8px;
  --br-2xl-3: 21.3px;
  --br-5xl-2: 24.2px;
  --br-xl: 20px;
  --br-9xs: 4px;
  --br-4xs: 9px;
  --br-3xs-5: 9.5px;
  --br-6xs-2: 6.2px;
  --br-6xs: 7px;

  /* Effects */
  --shadow-1: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.mybookingsdashboardheadertext {
    position: relative;
    font-weight: 500;
  }
  .mybookingsdashboardheader {
    width: 1437px;
    display: flex;
    flex-direction: row;
    padding: 0 0 0 var(--padding-13xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    color: var(--logo-color-2);
  }
  .mybookingsupcomingheadertext {
    position: absolute;
    top: 0;
    left: 0;
  }
  .mybookingsupcomingheader {
    position: relative;
    width: 1082px;
    height: 48px;
  }
  .saved-scholarships-matches {
    font-weight: 500;
  }
  .mybookingsupcomingsubheadertex {
    position: absolute;
    top: 0;
    left: 0;
  }
  .mybookingsupcomingsubheader {
    position: relative;
    width: 1082px;
    height: 30px;
    font-size: var(--h3-medium-20-size);
    color: var(--color-black);
  }
  .mybookingsupcomingheader1text {
    position: relative;
    font-weight: 500;
  }
  .mybookingsupcomingheader1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 3.8px solid var(--logo-color-2);
    box-sizing: border-box;
    /* width: 121.92px;
    height: 3.85px; */
  }
  .mybookingsupcomingcategorybox,
  .mybookingsupcomingcategorybox1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .mybookingsupcomingcategorybox1 {
    gap: var(--gap-22xl);
  }
  .mybookingsupcomingcategorybox {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10.27px;
    background-color: var(--white);
    border: 2.6px solid var(--gray-2);
    padding: 20.53333282470703px;
  }
  .mybookingsupcomingcategoryline {
    position: absolute;
    top: 75.08px;
    left: 13.48px;
    border-top: 3.8px solid var(--logo-color-2);
    box-sizing: border-box;
    width: 121.92px;
    height: 3.85px;
  }
  .mybookingsupcomingcategory {
    position: relative;
    width: 1082px;
    height: 77px;
    text-align: center;
    font-size: 20.53px;
    color: var(--gray-5);
  }
  .mybookingsupcoming {
    width: 1438px;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 var(--padding-xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    gap: var(--gap-xl);
    font-size: var(--h2-bold-32-size);
  }
  .bookingscardprofileimg-icon {
    position: relative;
    width: 176.87px;
    height: 177.78px;
    object-fit: cover;
    border-radius: 90px;
  }
  .bookingscardprofileimgbox {
    border-radius: 24.23px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
  .sarah-winters {
    margin: 0;
  }
  .bookingscardprofilenametext2 {
    position: relative;
    letter-spacing: 0.08em;
    line-height: 150%;
    font-weight: 500;
    display: inline-block;
    width: 260.56px;
    height: 23.16px;
    flex-shrink: 0;
  }
  .bookingscardprofilenameheader,
  .bookingscardprofilenametext {
    width: 275.03px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .bookingscardprofilenameheader {
    height: 45px;
    align-items: flex-start;
    justify-content: center;
    font-size: 23.16px;
  }
  .bookingscardservicesiconbox1 {
    position: relative;
    width: 27.23px;
    height: 24.85px;
  }
  .bookingscardprofileservicesico,
  .bookingscardservicesiconbox {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .bookingscardservicesiconbox {
    overflow: hidden;
    flex-direction: column;
    padding: var(--padding-8xs) var(--padding-9xs);
  }
  .bookingscardprofileservicesico {
    height: 34.74px;
    flex-direction: row;
  }
  .bookingscardservicesiconboxtex {
    position: relative;
    letter-spacing: 0.08em;
    line-height: 150%;
    font-weight: 500;
    display: inline-block;
    width: 260.56px;
    height: 23.16px;
    flex-shrink: 0;
  }
  .bookingscardprofileservices {
    width: 272px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 7.24px;

  }
  .bookingscardprofilenamebox1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    min-height: 127px;
  }
  .bookingscardratingicon {
    position: relative;
    width: 29.47px;
    height: 29.47px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .bookingscardratingno {
    position: relative;
    letter-spacing: 0.08em;
    line-height: 150%;
    font-weight: 500;
    display: inline-block;
    width: 40.53px;
    height: 30.47px;
    flex-shrink: 0;
  }
  .stars-icon {
    position: relative;
    width: 90.26px;
    height: 14.74px;
    display: none;
  }
  .bookingscardratingtext {
    position: relative;
    letter-spacing: 0.08em;
    line-height: 150%;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 351.84px;
    height: 20px;
    flex-shrink: 0;
    font-size: 19px;
  }
  .bookingscardratingreciewnumber {
    width: 175px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 14.74px;
    font-size: 29.47px;
    color: var(--gray-4);
  }
  .bookingscardratingbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-9xs);
  }
  .bookingscardrating {
    width: 210px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 22.11px;
  }
  .bookingscardprofilenamebox,
  .bookingslistcardprofile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-25xl);
  }
  .bookingslistcardprofile {
    flex-direction: row;
    gap: var(--gap-20xl);
   
  }
  .bookingscardsessionheader {
    position: relative;
    line-height: 150%;
    display: inline-block;
    width: 260.56px;
    height: 23.16px;
    flex-shrink: 0;
  }
  .bookingscardsessionmin {
    position: relative;
    font-size: 20px;
    font-weight: 600;
    color: var(--gray-5);
  }
  .bookingscardsessiontype {
    width: 152px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-2xl);
  }
  .bookingscardsessiondate1 {
    position: absolute;
    top: 0;
    left: 70px;
    line-height: 150%;
    display: inline-block;
    color: var(--logo-color-2);
    width: 89px;
    height: 23px;
  }
  .bookingscardsessiondate {
    position: absolute;
    top: 44px;
    left: 0;
    font-size: 20px;
    font-weight: 600;
  }
  .bookingscardsessiontime {
    position: absolute;
    top: 90px;
    left: 59px;
    font-size: 20px;
  }
  .bookingscardsessiondatebox {
    position: relative;
    width: 200px;
    height: 126px;
    color: var(--gray-5);
  }
  .bookingscardsessionbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-31xl);
  }
  .see-profile {
    position: relative;
    line-height: 150%;
  }
  .bookingscardsessionbuttonbox {
    border-radius: 21.32px;
    background-color: var(--logo-color-2);
    border: 3px solid var(--color-cornflowerblue);
    box-sizing: border-box;
    width: 335px;
    height: 54px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: 18.27482795715332px 24.366437911987305px;
    align-items: center;
    justify-content: center;
  }
  .bookingscardsessionbuttonicon {
    position: relative;
    width: 24.93px;
    height: 34.11px;
    display: none;
  }
  .bookingscardsessionbutton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: var(--gap-3xs);
    font-size: var(--font-size-3xl);
    color: var(--white);
    border: none;
  }
  .bookingscardsession {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: var(--gap-12xl);
    font-size: 23.16px;
    color: var(--logo-color-2);
  }
  .bookingslistcardbox1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    gap: var(--gap-14xl);
  }
  .bookingscardsessionbuttonicon11 {
    position: relative;
    width: 24.93px;
    height: 34.11px;
  }
  .bookingscardsessionbuttonicon1,
  .bookingslistcardbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .bookingslistcardbox {
    justify-content: flex-end;
    gap: var(--gap-3xl);
  }
  .bookingslistcard {
    border-radius: var(--br-5xs);
    background-color: var(--white);
    border: 3px solid var(--color-silver-100);
    box-sizing: border-box;
    width: 1075.27px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-24xl) var(--padding-22xl);
    align-items: center;
    justify-content: center;
  }
  .bookingslistbox1 {
    width: 1082px;
    gap: var(--gap-22xl);
  }
  .bookingslistbox,
  .bookingslistbox1,
  .mybookingsmainbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .bookingslistbox {
    width: 1440px;
    font-size: 17.37px;
  }
  .mybookingsmainbox {
    align-self: stretch;
    gap: 64px;
    z-index: 0;
  }
  .text {
    position: absolute;
    margin: 0 !important;
    top: 207px;
    left: 159px;
    font-size: var(--h2-bold-32-size);
    display: inline-block;
    z-index: 1;
  }
  .mybookings {
    position: relative;
    background-color: var(--white);
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 99px 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--h3-medium-20-size);
    color: var(--logo-black);
    font-family: var(--h2-bold-32);
  }
  @media screen and (max-width: 1200px) {
    .mybookingsdashboardheader {
      width: 920px;
    }
    .mybookingsupcomingcategory,
    .mybookingsupcomingheader,
    .mybookingsupcomingsubheader {
      width: 782px;
    }
    .mybookingsupcoming {
      width: 1038px;
    }
    .bookingslistcardbox1 {
      flex-direction: column;
    }
    .bookingslistcard {
      width: 775px;
      align-items: center;
      justify-content: center;
    }
    .bookingslistbox1 {
      width: 782px;
    }
    .bookingslistbox {
      width: 1038px;
    }
  }
  @media screen and (max-width: 850px) {

    .bookingscardprofilenamebox1 {
      
      gap: var(--gap-9xs);
      min-height: 27px;
    }

    .bookingscardsessionheader {
     
      width: 158px;
      height: 23.16px;
     
    }

    .bookingscardsessiondatebox {
      flex-direction: column;
      width: 200px;
      height: 126px;
      color: var(--gray-5);
      align-items: center;
      justify-content: center;
      gap: 3px;
    }

    .bookingscardsessiontype {
     
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
    .mybookingsdashboardheader {
      width: 610px;
    }
    .mybookingsupcomingheadertext {
      font-size: 25px;
    }
    .mybookingsupcomingheader {
      width: 510px;
    }
    .mybookingsupcomingsubheadertex {
      font-size: var(--font-size-mini);
    }
    .mybookingsupcomingcategory,
    .mybookingsupcomingsubheader {
      width: 510px;
    }
    .mybookingsupcoming {
      gap: var(--gap-3xs);
    }
    .bookingscardprofileimg-icon {
      width: 156px;
      height: 157px;
    }
    .bookingscardprofilenametext2 {
      font-size: var(--h3-medium-20-size);
    }
    .bookingscardprofilenameheader {
      width: 235px;
    }
    .bookingscardservicesiconbox1 {
      width: 23px;
      height: 21px;
    }
    .bookingscardservicesiconboxtex {
      font-size: var(--font-size-smi);
    }
    .bookingscardprofileservices {
      width: 232px;
    }
    .bookingscardratingno {
      font-size: var(--font-size-lg);
    }
    .bookingscardratingtext {
      font-size: 15px;
    }
    .bookingscardratingreciewnumber {
      gap: 14.74px;
    }
    .bookingslistcardprofile {
      gap: var(--gap-xl);
    }
    .bookingslistcardbox1 {
      flex-direction: column;
    }
    .bookingslistcardbox {
      gap: var(--gap-11xs);
    }
    .bookingslistcard {
      width: 510px;
      gap: var(--gap-3xs);
    }
    .bookingslistbox1 {
      width: 510px;
    }
    .bookingslistbox {
      width: 1038px;
    }
    .bookingscardprofilenamebox{
     
      gap: 10px;
    }
  }
  @media screen and (max-width: 520px) {
    .mybookingsdashboardheader {
      width: 450px;
    }
    .mybookingsupcomingheader {
      width: 375px;
    }
    .mybookingsupcomingsubheadertex {
      font-size: var(--font-size-3xs);
    }
    .mybookingsupcomingsubheader {
      width: 375px;
    }
    .mybookingsupcomingheader1text {
      font-size: var(--h5-btns-med-14-size);
    }
    .mybookingsupcomingcategory {
      width: 375px;
    }
    .bookingscardprofileimg-icon {
      width: 126px;
      height: 127px;
    }
    .bookingscardprofilenametext2 {
      font-size: var(--font-size-mini);
      width: 205px;
    }
    .bookingscardprofilenameheader {
      width: 185px;
      height: 30px;
    }
    .bookingscardservicesiconbox1 {
      width: 20px;
      height: 18px;
    }
    .bookingscardservicesiconboxtex {
      font-size: var(--font-size-3xs);
    }
    .bookingscardprofileservices {
      width: 185px;
    }
    .bookingscardprofilenamebox1 {
      gap: var(--gap-0);
    }
    .bookingscardrating {
      width: 185px;
    }
    .bookingscardprofilenamebox {
      gap: var(--gap-11xs);
    }
    .bookingslistcardprofile {
      flex-direction: row;
      gap: var(--gap-10xl);
      align-items: center;
      justify-content: flex-start;
    }
    .bookingscardsessionbox {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
    .see-profile {
      font-size: var(--font-size-lg);
    }
    .bookingscardsessionbuttonbox {
      width: 285px;
    }
    .bookingscardsessionbuttonicon {
      display: flex;
      width: 24px;
      height: 24px;
    }
    .bookingscardsession {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .bookingslistcardbox1 {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
    }
    .bookingscardsessionbuttonicon11 {
      width: 19px;
      height: 17px;
    }
    .bookingscardsessionbuttonicon1 {
      display: none;
    }
    .bookingslistcardbox {
      flex-direction: column;
      gap: var(--gap-11xs);
      align-items: flex-start;
      justify-content: flex-start;
    }
    .bookingslistcard {
      width: 375px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
  