@keyframes slide-in-bottom {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.clear-all {
  position: relative;
}
.clear-all-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-215xl);
}
.scholarship-filters-inner {
  width: 789px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-9xl) var(--padding-52xl) var(--padding-2xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.categories {
  position: relative;
  font-size: var(--h5-btns-med-14-size);
  font-weight: 500;
}
.based-on-your {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 150%;
}
.check-box-outline-blank-parent,
.frame-wrapper3 {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
}
.check-box-outline-blank-parent {
  width: 279px;
  flex-direction: row;
  padding: 0 0 0 var(--padding-3xl);
  align-items: center;
  gap: var(--gap-5xs);
}
.frame-wrapper3 {
  align-self: stretch;
  border-radius: var(--br-7xs);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--color-gainsboro-100);
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-mid) var(--padding-447xl) var(--padding-mid) 0;
  align-items: flex-start;
}
.div10 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 150%;
  display: inline-block;
  width: 78px;
  flex-shrink: 0;
}
.frame-child,
.frame-wrapper19 {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.frame-child {
  border: 1px solid var(--color-darkgray);
  font-family: var(--p1-regular-16);
  font-size: var(--font-size-3xs);
  background-color: transparent;
  border-radius: var(--br-5xs);
  width: 382px;
  height: 28px;
  flex-direction: row;
  padding: 0 var(--padding-3xl);
  justify-content: flex-start;
}
.frame-wrapper19 {
  align-self: stretch;
  border-radius: var(--br-7xs);
  border: 1px solid var(--color-gainsboro-100);
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-mid) 0;
  justify-content: center;
}
.biology {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 150%;
  display: inline-block;
  width: 48px;
  flex-shrink: 0;
}
.categories-parent {
  align-self: stretch;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xl) var(--padding-39xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xl);
  font-size: var(--font-size-3xs);
}
.scholarship-filters {
  position: relative;
  background-color: var(--white);
  height: 1000px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: var(--h5-btns-med-14-size);
  color: var(--color-black);
  font-family: var(--p1-regular-16);
}
.scholarship-filters.animate {
  animation: 0.25s ease 0s 1 normal forwards slide-in-bottom;
}
@media screen and (max-width: 850px) {
  .clear-all-parent {
    gap: var(--gap-131xl);
  }
  .frame-wrapper19,
  .frame-wrapper3 {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 520px) {
  .clear-all-parent {
    gap: var(--gap-91xl);
    align-items: center;
    justify-content: flex-start;
  }
  .frame-wrapper3 {
    padding-right: 0;
    box-sizing: border-box;
  }
  .frame-child {
    width: 200px;
  }
  .frame-wrapper19 {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .scholarship-filters {
    width: 400px;
  }
}


.categoryFilterBody{
  display: flex;
width: 220px;
padding-top: 8px;
padding-left: 20px;
flex-direction: column;
/* align-items: center; */
gap: 3px;

border-radius: 8px;
background: #FFF;
/* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */

}


.categoryFilterLocBody{
  display: flex;
width: 250px;
padding-top: 8px;
padding-left: 20px;
flex-direction: column;
/* align-items: center; */
gap: 3px;

border-radius: 8px;
background: #FFF;
/* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */

}

.checkboxBodycat{
  display: flex;
  width: 190px;
  flex-direction: column;
  align-items: flex-start;

}

.invidualcheckbox{
  display: flex;
width: 210px;
align-items: center;
gap: 11px;
padding-bottom: 8px;
}
.scholarshipfilterLabel{
  color: #000;
font-size: 12px;
font-family: Poppins;
font-style: normal;
font-weight: 400;
line-height: 150%;
letter-spacing: 0.15px;
/* //min-width: 181px; */
max-width: 181px;
}


.buttonBody{
  display: flex;
width: 181px;
padding: 1px 0px;
/* flex-direction: column;
justify-content: center; */
align-items: center;
gap: 10px;
border: 1px solid #EFE8E8;
}

.applyButton{
  margin-left: 50px;
  display: flex;
justify-content: center;
align-items: center;
border-radius: 4px;
background: #0D0ED9;
width: 80px;
box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
}

.applyfilters{
  color: #FFF;
font-size: 11px;
font-family: Poppins;
font-style: normal;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.4px;
text-transform: uppercase;
}

.clearAllFilters{
  display: flex;
width: 57px;
flex-direction: column;
color: #000;
font-size: 11px;
font-family: Poppins;
font-style: normal;
font-weight: 400;
line-height: 22px;
}