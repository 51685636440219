.welcomepathheaderlogobox-icon {
  position: relative;
  width: 104px;
  height: 95px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.welcomepathheader {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 38px 210px;
  align-items: center;
  justify-content: center;
}
.welcomepathheaderwelcometext {
  position: relative;
  font-weight: 700;
  display: inline-block;
  width: 347px;
  flex-shrink: 0;
}
.welcomepathheaderwelcome {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) var(--padding-69xl);
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--h2-bold-32-size);
}
.welcomepathheadercontinuetext {
  position: relative;
  line-height: 150%;
  display: inline-block;
  width: 255px;
  flex-shrink: 0;
}
.welcomepathheaderbuttonsstuden,
.welcomepathheadercontinue {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.welcomepathheadercontinue {
  width: 524px;
  padding: var(--padding-3xs) 157px;
  box-sizing: border-box;
  color: var(--logo-black);
}
.welcomepathheaderbuttonsstuden {
  border-radius: var(--br-7xs);
  background-color: var(--royal-blue);
  padding: var(--padding-3xs) var(--padding-28xl);
  cursor: pointer;
}
.welcomepathheaderbuttonsstuden2 {
  color: var(--logo-black);
}
.welcomepathbuttonscoachtext,
.welcomepathheaderbuttonsstuden2 {
  position: relative;
  line-height: 150%;
}
.welcomepathbuttonscoach,
.welcomepathheaderbuttonsbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.welcomepathbuttonscoach {
  border-radius: var(--br-7xs);
  background-color: var(--royal-blue);
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-28xl);
  cursor: pointer;
}
.welcomepathheaderbuttonsbox {
  gap: 26px;
}
.welcomepathheaderbuttons {
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) 62px;
  align-items: center;
  justify-content: center;
  color: var(--gray-1);
}
.welcomepathbuttonsnothanks,
.welcomepathheaderbuttons {
  align-self: stretch;
  overflow: hidden;
}
.welcomepathbody1 {
  border-radius: var(--br-5xs);
  background-color: var(--white);
  width: 524px;
  height: 521px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.welcomepath,
.welcomepathbody {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.welcomepathbody {
  align-self: stretch;
  padding: 164px 458px;
  align-items: center;
  justify-content: center;
}
.welcomepath {
  position: relative;
  background-color: var(--royal-blue);
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--p1-regular-16-size);
  color: var(--royal-blue);
  font-family: var(--p1-regular-16);
}
@media screen and (max-width: 850px) {
  .welcomepathbody {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 520px) {
  .welcomepathheaderlogobox-icon {
    width: 80px;
    height: 65px;
  }
  .welcomepathheader {
    padding-top: 35px;
    padding-bottom: 25px;
    box-sizing: border-box;
  }
  .welcomepathheaderwelcometext {
    font-size: var(--font-size-3xl);
    text-align: center;
  }
  .welcomepathheaderwelcome {
    padding-top: var(--padding-3xs);
    padding-bottom: var(--padding-3xs);
    box-sizing: border-box;
  }
  .welcomepathheadercontinuetext {
    font-size: var(--h6-medium-12-size);
  }
  .welcomepathheadercontinue {
    padding-top: var(--padding-3xs);
    padding-bottom: var(--padding-3xs);
    box-sizing: border-box;
  }
  .welcomepathheaderbuttonsstuden {
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
  .welcomepathbuttonscoachtext {
    font-size: var(--h6-medium-12-size);
  }
  .welcomepathbuttonscoach {
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
  .welcomepathheaderbuttonsbox {
    gap: var(--gap-xs);
  }
  .welcomepathheaderbuttons {
    padding-top: var(--padding-11xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .welcomepathbuttonsnothanks {
    width: 464px;
    padding-left: 180px;
    padding-top: var(--padding-4xs);
    padding-right: 0;
    box-sizing: border-box;
  }
  .welcomepathbody1 {
    width: 350px;
    height: 380px;
  }
  .welcomepathbody {
    padding-left: var(--padding-181xl);
    padding-right: var(--padding-181xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 400px) {
  .welcomepathheaderlogobox-icon {
    width: 64px;
    height: 55px;
  }
  .welcomepathheader {
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .welcomepathheaderwelcometext {
    font-size: var(--h3-medium-20-size);
  }
  .welcomepathheaderwelcome {
    padding-top: var(--padding-mini);
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .welcomepathheadercontinue {
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
    margin-top: 20px;
  }
  .welcomepathbuttonsnothanks {
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    padding-bottom: var(--padding-mid);
    box-sizing: border-box;
    justify-content: center;
  }
  .welcomepathbody1 {
    width: 290px;
    height: 450px;
    align-items: center;
    justify-content: center;
  }
  .welcomepathbody {
    padding-left: var(--padding-81xl);
    padding-right: var(--padding-81xl);
    box-sizing: border-box;
  }
}
