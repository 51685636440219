@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
.profile {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 1120px;
}
.upload-icon {
  width: 104px;
  height: 116px;
}
.abidemi-awojuyigbe,
.upload-icon {
  position: relative;
}

.upload-image-button{
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.upload-image-form {
  width: 200px;
  display: inline-block; /* Change display to inline-flex or grid */
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.upload-choose-file{
  padding-bottom: 10px;
}

.upload-choose-file-button{
 background-color: green;
 border-radius: 30px;
 color: white;
 
}
.profile-update {
  width: 328px;
  /* height: 199px; */
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 14px 75px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 11px;
  color: #010101;
}
.bio-data-box-child {
  position: absolute;
  top: -22.5px;
  left: -24.5px;
  border-top: 15px solid rgba(178, 177, 177, 0.01);
  box-sizing: border-box;
  width: 336px;
  height: 15px;
}
.bio,
.striving-for-academic {
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  width: 292px;
}
.striving-for-academic {
  color: #010101;
}
.biobox1-child {
  position: relative;
  border-top: 1px solid rgba(178, 177, 177, 0.25);
  box-sizing: border-box;
  width: 293px;
  height: 1px;
}
.biobox1 {
  position: absolute;
  top: 0;
  left: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.abidemiawojuyigbegmailcom,
.email {
  margin: 0;
  position: absolute;
  top: 6.5px;
  left: -1px;
  display: flex;
  align-items: center;
  width: 292px;
}
.abidemiawojuyigbegmailcom {
  top: 35.5px;
  color: #010101;
}
.emailbox-child {
  position: absolute;
  top: 64px;
  left: -1.5px;
  border-top: 1px solid rgba(178, 177, 177, 0.25);
  box-sizing: border-box;
  width: 293px;
  height: 1px;
}
.age,
.emailbox,
.genderbox {
  position: absolute;
  top: 56px;
  left: 0;
  width: 294px;
  height: 73px;
  overflow: hidden;
}
.age,
.genderbox {
  top: 129px;
}
.age {
  top: 202px;
}
.bio-data-box {
  position: relative;
  width: 294px;
  /* height: 275px; */
}

.bio-line{
  width: 294px;
height: 1px;
background: rgba(178, 177, 177, 0.25);
border-top: 1px solid rgba(178, 177, 177, 0.25);
}

.bio-detail{
  color: #010101;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 16px;
}

.bio-detail-header{
  color: #79797D;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 294px;
height: 17px;
}
.bio-data {
  width: 328px;
  height: 322px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 13px 8px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.edit {
  position: relative;
  cursor: pointer;
}
.editbox {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 9px 42px 27px 257px;
  align-items: center;
  justify-content: center;
  color: #4a93cf;
}
.referral-link {
  margin: 0;
}
.click-to-invite {
  margin: 0;
  color: #4a93cf;
}
.referral-link-click-container {
  position: relative;
}
.profile-section,
.referallbox {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.referallbox {
  flex-direction: row;
  padding: 15px 160px 25px 24px;
  font-size: 14px;
}
.profile-section {
  border-radius: 8px;
  background-color: #fff;
  flex-direction: column;
  gap: 1px;
  min-height: 667px;
}
.edit-data-box-child {
 
  border-top: 1px solid rgba(178, 177, 177, 0.25);
  box-sizing: border-box;
  width: 673px;
  height: 1px;
}
.personal-information {
  position: relative;
  font-weight: 500;
  cursor: default;
  color: #010101;
  height: 1px;
}


.profile-active-tab{
  position: relative;
  font-weight: 500;
  color:#4A93CF;;
  height: 1px;
  text-decoration: underline;
}

.upload-icon{
  display: flex;
  width: 34px;
  height: 34px;
  flex-shrink: 0;
  background-color: #fff;
 
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}
.selectedheaderprofile-child {
  position: relative;
  border-top: 2px solid #4a93cf;
  box-sizing: border-box;
  width: 172px;
  height: 2px;
}
.profileeditbox,
.selectedheaderprofile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  color: #4a93cf;
}
.profileeditbox {
 
  
  flex-direction: row;
  gap: 54px;
  color: #010101;
  padding-bottom: 20px;
}
.birthday {
  margin: 0;
 
}
.birthday-box-child {
 
 
  border-top: 1px solid rgba(178, 177, 177, 0.25);
  box-sizing: border-box;
  width: 642px;
  height: 1px;
}
.edit1 {
  position: relative;
  color: #4a93cf;
}
.display-details-box {
  
  
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  justify-content: flex-start;
  gap: 181px;
  color: #010101;
}
.birthday-box {
 
  width: 641px;
  height: 73px;
  overflow: hidden;
}
.state-of-residence {
  margin: 0;
 

}
.save-changes{
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.t {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border: none;
}


.personala-display{
  height: 400px;
  max-height: 400px;
  background-color: aqua;
  overflow-y: auto;
}
.t {
  margin: 0;
  width: 357px;
}
.save-changes {
  color: #4a93cf;
  text-align: right;
  width: 117px;
}
.edit-details-box {
 
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 161px;
  color: #010101;
}
.stateofresidencebox {
  
  width: 640px;
  height: 73px;
  overflow: hidden;
}
.yes {
  margin: 0;
 
  display: flex;
  align-items: center;
  width: 407px;
  flex-shrink: 0;
  border: none;
}
.firstgenerationbox {

  width: 641px;
  height: 73px;
  overflow: hidden;
}
.citizenshipstatusbox,
.disabilitybox,
.ethnicitybox,
.personalattributebox {
  
  width: 642px;
  height: 73px;
  overflow: hidden;
}
.disabilitybox,
.ethnicitybox,
.personalattributebox {
  
}
.ethnicitybox,
.personalattributebox {
 
  width: 641px;
}
.personalattributebox {
 
  width: 642px;
}
.display-details-box6 {
 
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 181px;
  color: #010101;
}
.militaryaffilitionbox {
  
  width: 642px;
  height: 73px;
  overflow: hidden;
}
.edit-data-box {
 
  border-radius: 8px;
  background-color: #fff;
  width: 768px;
  height: 667px;
  max-height: 667px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 32px;
}
.profile-section-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  font-size: 16px;
  color: #79797d;
}
.viewprofile {
  position: relative;
  background-color: #f2f2f2;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 176px 159px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 27px;
  text-align: left;
  font-size: 24px;
  color: #010101;
  font-family: Poppins;
}
@media screen and (max-width: 1200px) {
  .profile {
    width: 820px;
  }
  .edit-data-box-child {
    width: 470px;
  }
  .personal-information {
    font-size: 14px;
  }
  .selectedheaderprofile-child {
    width: 150px;
  }
  .profileeditbox {
    gap: 30px;
  }
  .birthday {
    font-size: 13px;
  }
  .birthday-box-child {
    width: 386px;
  }
  .edit1 {
    font-size: 14px;
  }
  .display-details-box {
    gap: 50px;
  }
  .state-of-residence {
    font-size: 14px;
  }
  .t {
    width: 279px;
  }
  .save-changes,
  .t {
    font-size: 14px;
  }
  .edit-details-box {
    width: 300px;
    gap: 0;
  }
  .yes {
    font-size: 14px;
    width: 300px;
  }
  .display-details-box6 {
    width: 468px;
    gap: 50px;
  }
  .edit-data-box {
    width: 468px;
  }
}
@media screen and (max-width: 850px) {
  .profile {
    width: 468px;
  }
  .abidemiawojuyigbegmailcom,
  .edit,
  .email,
  .striving-for-academic {
    font-size: 14px;
  }
  .profile-section {
    width: 468px;
  }
  .profile-section-parent {
    flex-direction: column;
  }
}
@media screen and (max-width: 520px) {
  .profile {
    width: 408px;
  }
  .edit,
  .referral-link-click-container,
  .striving-for-academic {
    font-size: 14px;
  }
  .profile-section {
    width: 408px;
  }
  .personal-information {
    font-size: 12px;
  }
  .selectedheaderprofile-child {
    width: 132px;
  }
  .profileeditbox {
    gap: 25px;
  }
  .birthday {
    font-size: 12px;
  }
  .birthday-box-child {
    width: 360px;
  }
  .edit1 {
    font-size: 12px;
  }
  .display-details-box {
    gap: 20px;
  }
  .state-of-residence {
    font-size: 12px;
  }
  .t {
    width: 257px;
  }
  .save-changes,
  .t,
  .yes {
    font-size: 12px;
  }
  .display-details-box6 {
    gap: 20px;
  }
  .edit-data-box {
    width: 408px;
  }
  .viewprofile {
    padding: 76px 0;
    box-sizing: border-box;
  }
}


.editSideBox{
  width: 768px;
height: 667px;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
padding: 32px;
}


.profile-edit-body{
  overflow-y: auto;
  max-height: 575px;
  background-color: #fff;
  overflow-x: hidden;
}