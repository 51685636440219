@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --p1-regular-16: Poppins;

  /* font sizes */
  --h6-medium-12-size: 12px;
  --font-size-5xs: 8px;
  --p1-regular-16-size: 16px;
  --h2-bold-32-size: 32px;
  --font-size-mid: 17px;
  --font-size-5xl: 24px;
  --font-size-mini: 15px;
  --font-size-29xl: 48px;
  --font-size-21xl: 40px;
  --font-size-9xl: 28px;
  --font-size-3xl: 22px;
  --h3-medium-20-size: 20px;
  --font-size-2xl-6: 21.6px;
  --font-size-sm: 14px;
  --font-size-3xs: 10px;
  --font-size-2xs: 11px;
  --font-size-4xs: 9px;
  --font-size-lg-8: 18.8px;
  --font-size-2xl: 21px;
  --font-size-4xl-2: 23.2px;
  --font-size-lgi: 19px;
  --font-size-17xl: 36px;
  --font-size-3xl-7: 22.7px;
  --font-size-smi: 13px;
  --font-size-4xl-4: 23.4px;
  --font-size-lg: 18px;
  --font-size-lg-9: 18.9px;
  --font-size-9xl-1: 28.1px;

  /* Colors */
  --white: #fff;
  --royal-blue: #0d0ed9;
  --gray-4: #79797d;
  --color-gray-100: #787878;
  --logo-black: #010101;
  --color-gray-200: rgba(255, 255, 255, 0.5);
  --gray-1: #f2f2f2;
  --color-khaki: #f8d665;
  --color-tomato: #ed5043;
  --gray-5: #393939;
  --color-darkslategray-100: #263238;
  --color-silver-100: #c4c6c7;
  --color-silver-200: #bbb;
  --color-black: #000;
  --purple-blue: #5558cf;
  --color-steelblue-100: #4a93cf;
  --teal: #40cfc3;
  --color-mediumaquamarine: #70d499;

  /* Gaps */
  --gap-43xs: -30px;
  --gap-192xl: 211px;
  --gap-61xl: 80px;
  --gap-254xl: 273px;
  --gap-28xl: 47px;
  --gap-xs: 12px;
  --gap-6xs: 7px;
  --gap-53xl: 72px;
  --gap-31xl: 50px;
  --gap-9xs: 4px;
  --gap-5xs: 8px;
  --gap-7xs: 6px;
  --gap-3xs: 10px;
  --gap-9xl: 28px;
  --gap-11xl: 30px;
  --gap-mini: 15px;
  --gap-3xl: 22px;
  --gap-11xs: 2px;
  --gap-0: 0px;
  --gap-base: 16px;
  --gap-10xs: 3px;
  --gap-13xl: 32px;
  --gap-3xs-4: 9.4px;
  --gap-2xs: 11px;
  --gap-mini-1: 14.1px;

  /* Paddings */
  --padding-175xl: 194px;
  --padding-69xl: 88px;
  --padding-81xl: 100px;
  --padding-11xl: 30px;
  --padding-31xl: 50px;
  --padding-3xs: 10px;
  --padding-mid: 17px;
  --padding-53xl: 72px;
  --padding-8xs: 5px;
  --padding-5xs: 8px;
  --padding-52xl: 71px;
  --padding-50xl: 69px;
  --padding-6xs: 7px;
  --padding-xs: 12px;
  --padding-7xs: 6px;
  --padding-9xl: 28px;
  --padding-23xl: 42px;
  --padding-181xl: 200px;
  --padding-4xs: 9px;
  --padding-xl: 20px;
  --padding-28xl: 47px;
  --padding-mini: 15px;
  --padding-smi: 13px;
  --padding-61xl: 80px;
  --padding-84xl: 103px;
  --padding-4xs-2: 8.2px;
  --padding-9xs: 4px;
  --padding-55xl: 74px;
  --padding-42xl: 61px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-7xs: 6px;
  --br-3xs: 10px;
  --br-9xs: 4px;
  --br-xs: 12px;
  --br-3xs-4: 9.4px;
  --br-4xs: 9px;
  --br-3xs-5: 9.5px;

  /* Effects */
  --shadow-1: 0px 1px 3px rgba(0, 0, 0, 0.25);
}


.letfonboardingimage1-icon2 {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-3xs);
    width: 198.49px;
    height: 226px;
    object-fit: cover;
  }
  .letfonboardingimagebox12 {
    position: relative;
    width: 199px;
    height: 290px;
  }
  .letfonboardingimage2-icon2 {
    position: absolute;
    top: 62px;
    left: 0;
    border-radius: var(--br-3xs);
    width: 198.49px;
    height: 226px;
    object-fit: cover;
  }
  .letfonboardingimagebox22 {
    position: relative;
    width: 198px;
    height: 290px;
  }
  .letfonboardingimagebox4 {
    position: absolute;
    top: 34px;
    left: -0.45px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-254xl);
  }
  .welcome2 {
    margin: 0;
  }
  .letfonboardingapplying2,
  .letfonboardingwelcome2 {
    position: absolute;
    width: 48.93%;
    top: 42.22%;
    left: 0.15%;
    line-height: 70px;
    display: inline-block;
  }
  .letfonboardingapplying2 {
    width: 81.2%;
    top: 60.89%;
    left: 0.78%;
    font-size: var(--font-size-5xl);
    line-height: 50px;
    color: var(--logo-black);
  }
  .letfonboardingrectanglered2 {
    position: relative;
    border-radius: var(--br-9xs);
    background-color: var(--color-tomato);
    width: 39.51px;
    height: 42px;
  }
  .letfonboardingrectanglebox2 {
    position: absolute;
    top: 0;
    left: 0.55px;
    width: 711px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .leftrectangleyellow2 {
    position: relative;
    border-radius: var(--br-9xs);
    background-color: var(--color-khaki);
    width: 39px;
    height: 42px;
  }
  .leftonboardingimage-icon2 {
    position: relative;
    border-radius: var(--br-3xs);
    width: 198.49px;
    height: 226px;
    object-fit: cover;
  }
  .lowerboxonboarding2 {
    position: absolute;
    top: 513px;
    left: 95.02px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-192xl);
  }
  .leftonboadingbox2 {
    flex: 1;
    position: relative;
    height: 739px;
  }
  .leftonboarding2,
  .rightonboarding3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .leftonboarding2 {
    width: 726.89px;
    padding: 0 var(--padding-23xl);
    box-sizing: border-box;
  }
  .rightonboarding3 {
    flex: 1;
    height: 839px;
  }
  .rightonboardingboxsignuptext {
    position: relative;
    font-weight: 700;
  }
  .rightonboardingboxsignup {
    width: 545px;
    display: flex;
    flex-direction: column;
    padding: var(--padding-9xl) 0 var(--padding-6xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .onboardingicongoogle {
    position: relative;
    width: 16px;
    height: 17px;
    object-fit: cover;
  }
  .rightonboardingboxbuttongoogle {
    position: relative;
    font-size: var(--p1-regular-16-size);
    line-height: 150%;
    font-family: var(--p1-regular-16);
    color: var(--royal-blue);
    text-align: center;
  }
  .rightonboardingboxgoogle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-53xl);
  }
  .rightonboardingboxbutton1 {
    cursor: pointer;
    border: 1px solid var(--royal-blue);
    padding: var(--padding-3xs) var(--padding-xs);
    background-color: var(--white);
    border-radius: var(--br-7xs);
    box-shadow: var(--shadow-1);
    box-sizing: border-box;
    width: 400.63px;
    height: 44px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .rightonboardingsignupbox {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-7xs) var(--padding-53xl);
    align-items: center;
    justify-content: center;
  }
  .onboardingiconmail {
    position: relative;
    width: 16px;
    height: 13px;
    object-fit: cover;
  }
  .rightonboardingboxbuttongoogle1 {
    position: relative;
    line-height: 150%;
  }
  .rightonboardingboxbutton11 {
    border-radius: var(--br-7xs);
    background-color: var(--white);
    box-shadow: var(--shadow-1);
    border: 1px solid var(--royal-blue);
    box-sizing: border-box;
    width: 400.63px;
    height: 44px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-xs);
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
  }
  .rightonboardingsignupbox1 {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-7xs) var(--padding-53xl);
    align-items: center;
    justify-content: center;
    font-size: var(--p1-regular-16-size);
  }
  .already-have-an {
    color: var(--gray-5);
  }
  .log-in {
    color: var(--royal-blue);
  }
  .rightonboardingboxalreadycreat {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    font-size: var(--p1-regular-16-size);
    line-height: 150%;
    font-family: var(--p1-regular-16);
    text-align: left;
    display: inline-block;
    width: 261px;
    height: 22.48px;
    flex-shrink: 0;
  }
  .rightonboardingbox1 {
    border-radius: var(--br-5xs);
    background-color: var(--white);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 58px 0;
    align-items: center;
    justify-content: center;
    gap: var(--gap-6xs);
  }
  .rightonboarding12 {
    flex: 1;
    background-color: var(--royal-blue);
    height: 853px;
    flex-direction: column;
    padding: var(--padding-175xl) var(--padding-69xl);
    box-sizing: border-box;
    margin-left: -1px;
    font-size: var(--h2-bold-32-size);
  }
  .onboardingbox12,
  .rightonboarding12,
  .welcomesignup {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .onboardingbox12 {
    align-self: stretch;
    flex-direction: row;
  }
  .welcomesignup {
    position: relative;
    background-color: var(--white);
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    text-align: left;
    font-size: var(--font-size-29xl);
    color: var(--royal-blue);
    font-family: var(--p1-regular-16);
  }
  @media screen and (max-width: 1200px) {
    .leftonboarding2 {
      padding-top: var(--padding-11xl);
      padding-bottom: var(--padding-11xl);
      box-sizing: border-box;
    }
    .rightonboarding12,
    .rightonboarding3 {
      flex: unset;
      align-self: stretch;
    }
    .rightonboarding12 {
      width: 100%;
    }
    .onboardingbox12 {
      flex-direction: column;
      gap: var(--gap-43xs);
      padding-left: 0;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 850px) {
    .letfonboardingwelcome2 {
      font-size: var(--font-size-21xl);
    }
    .rightonboarding3 {
      flex: unset;
      align-self: stretch;
    }
    .rightonboardingboxgoogle {
      gap: var(--gap-31xl);
    }
    .rightonboardingboxbutton1 {
      width: 320px;
    }
    .rightonboarding12 {
      padding: var(--padding-81xl) var(--padding-11xl);
      box-sizing: border-box;
      flex: unset;
      align-self: stretch;
    }
    .onboardingbox12 {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 520px) {
    .letfonboardingimage1-icon2,
    .letfonboardingimage2-icon2 {
      width: 128px;
      height: 156px;
    }
    .letfonboardingimagebox4 {
      gap: var(--gap-28xl);
    }
    .letfonboardingwelcome2 {
      font-size: var(--font-size-9xl);
      width: 227px;
    }
    .letfonboardingapplying2 {
      font-size: var(--font-size-mini);
      width: 280px;
    }
    .letfonboardingrectanglered2 {
      width: 29px;
      height: 31px;
    }
    .letfonboardingrectanglebox2 {
      width: 411px;
    }
    .leftrectangleyellow2 {
      width: 29px;
      height: 31px;
    }
    .leftonboardingimage-icon2 {
      width: 128px;
      height: 156px;
    }
    .lowerboxonboarding2 {
      gap: var(--gap-61xl);
    }
    .leftonboadingbox2 {
      width: 369px;
    }
    .leftonboarding2 {
      width: 400px;
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
    }
    .rightonboardingboxsignuptext {
      font-size: var(--font-size-mid);
      text-align: center;
    }
    .rightonboardingboxbuttongoogle {
      font-size: var(--font-sizesize-mini);
    }
    .rightonboardingboxbutton1 {
      width: 270px;
    }
    .rightonboardingboxbuttongoogle1 {
      font-size: var(--font-size-mini);
    }
    .rightonboardingbox1 {
      width: 90%;
    }
    .rightonboarding12 {
      height: 50%;
      padding: var(--padding-31xl) var(--padding-3xs);
      box-sizing: border-box;
    }
    .onboardingbox12 {
      padding-left: 0;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 350px) {
    .rightonboardingboxbutton1 {
      width: 240px;
    }
  }
  